import { Contact } from '../models/Contact';
import { getMatchingItems, getSearchStrings } from '../utilities/search';

/**
 * Regexps to detect dates-like sting
 */
const dateRe = /([0-9]{1,2}[.\-/][0-9]{1,2}[.\-/][0-9]{2,4})/g;
const dateISORe = /([0-9]{4}[.\-/][0-9]{1,2}[.\-/][0-9]{1,2})/g;
// const dateWithMonthRe = /([0-9]{1,2}[\.\-/][a-z]{3,}[\.\-/][0-9]{2,4})/g;

/**
 * Regexp to detect amount-like number in the string
 */
const amountRe = /[0-9]{1,3}([,.\s]?[0-9]{1,3}?)*([,.][0-9]{2})(\D|.|)/g;

/**
 * Currency symbols and their ISO codes
 */
const currencySymbols: { [sym: string]: string } = {
  '€': 'EUR',
  $: 'USD',
  '£': 'GBP',
  '¥': 'JPY',
  '₽': 'RUB',
  '₹': 'INR',
  R$: 'BRL',
  '₺': 'TRY',
  '₩': 'KRW',
  Fr: 'CHF',
  kr: 'NOK',
  zł: 'PLN',
  NT$: 'TWD',
  '฿': 'THB',
  '₪': 'ILS',
  '₱': 'PHP',
  'د.إ': 'AED',
  'S/': 'PEN',
  '₴': 'UAH',
  '₫': 'VND',
  $b: 'BOB',
  Ft: 'HUF',
  RM: 'MYR',
  '₲': 'PYG',
  лв: 'BGN',
};

const constructDateFromNumbers = (
  first: number,
  second: number,
  year: number,
  preferedDateFormat: string,
): Date | null => {
  if (first > 0 && second > 0 && year > 0) {
    if (preferedDateFormat === 'dd-mm-yyyy' && first <= 31 && second <= 12) {
      return new Date(year, second - 1, first, 12);
    } else if (preferedDateFormat === 'mm-dd-yyyy' && first <= 12 && second <= 31) {
      return new Date(year, first - 1, second, 12);
    } else if (first <= 12 && second <= 31) {
      return new Date(year, first - 1, second, 12);
    } else if (first <= 31 && second <= 12) {
      return new Date(year, second - 1, first, 12);
    }
  }
  return null;
};

/**
 * Returns a Date object if any date-like string is found
 *
 * @param text  string to search for date
 * @returns Date
 */
export const getDateFromString = (text: string, dateFormat: string): Date | null => {
  if (dateRe.test(text)) {
    let dateStr = text.match(dateRe)?.[0];
    if (dateStr) {
      dateStr = dateStr.replace(/[.\-/]/g, '.');
      const dateParts = dateStr.split('.');
      if (dateParts.length === 3) {
        const date = constructDateFromNumbers(
          parseInt(dateParts[0]),
          parseInt(dateParts[1]),
          parseInt(dateParts[2]),
          dateFormat,
        );
        if (date && !isNaN(date.getTime())) {
          return date;
        }
      }
    }
  }
  if (dateISORe.test(text)) {
    let dateStr = text.match(dateISORe)?.[0];
    if (dateStr) {
      dateStr = dateStr.replace(/[.\-/]/g, '.');
      const dateParts = dateStr.split('.');
      if (dateParts.length === 3) {
        const date = constructDateFromNumbers(
          parseInt(dateParts[1]),
          parseInt(dateParts[2]),
          parseInt(dateParts[0]),
          dateFormat,
        );
        if (date && !isNaN(date.getTime())) {
          return date;
        }
      }
    }
  }
  return null;
};

/**
 * Returns if any amount-like number is found in the string
 * in case of success returns a positive float number
 *
 * @param text  string to search for amount
 * @returns number
 */
export const getAmountFromString = (text: string): number => {
  let amount: number = 0;
  if (amountRe.test(text)) {
    amount = parseFloat(
      text
        .match(amountRe)?.[0]
        // replace decimal separator with # (if any)
        .replace(/([,.])([0-9]{2})/g, '#$2')
        // remove all commas and dots
        .replace(/[,.\s]/g, '')
        // replace # with decimal separator
        .replace('#', '.') || '0',
    );
  }
  return amount;
};

/**
 * Returns if any currency-like string is found in the string
 * in case of success returns a currency string
 * otherwise returns the default param
 *
 * @param text  string to search for currency
 * @param defaultCurrency  default currency to return if no currency is found
 * @returns string
 */
export const getCurrencyFromString = (
  text: string,
  defaultCurrency: string = 'EUR',
  currencies: string[] = [],
): string => {
  const currencyRe = new RegExp(`(${currencies.join('|')})`, 'gi');
  let currency: string | undefined = defaultCurrency;
  if (currencyRe.test(text)) {
    currency = text.match(currencyRe)?.[0];
  } else {
    const currencySymbolsRe = new RegExp(`(${Object.keys(currencySymbols).join('|')})`, 'gi');
    if (currencySymbolsRe.test(text)) {
      currency = currencySymbols[text.match(currencySymbolsRe)?.[0]?.toUpperCase() || ''];
    }
  }
  return currency || defaultCurrency;
};

let searchCache: { [itemId: string]: string } = {};

/**
 * Searches for a contact in the contacts list
 * in case of success returns a contact object
 * otherwise returns null
 *
 * @param text  string to search for contact
 * @param contacts  list of contacts
 * @returns string  contact id
 */
export const getContactFromString = (
  text: string,
  contacts: { [id: string]: Contact },
): string | null => {
  const searchString = getSearchStrings(text);

  // found matching items
  const [itemsFound, itemsData] = getMatchingItems(contacts, searchString, searchCache, [
    'names',
    'displayName',
    'otherNames',
    'phone',
    'email',
  ]);

  searchCache = itemsData;

  if (itemsFound && Object.keys(itemsFound).length > 0) {
    return Object.keys(itemsFound)
      .filter(item => itemsFound[item] > 1) // filter items with searchWeight more than 1
      .sort((a, b) => (itemsFound[a] > itemsFound[b] ? -1 : 1))[0];
  }

  return null;
};
