// hooks
import { useEffect, useMemo, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

// helpers
import classNames from 'classnames';
import { getValue } from '../utilities/values';

// components
import { IonList } from '@ionic/react';
import ContactsListItem from './ContactsListItem';
import EmptySearchResults from './EmptySearchResults';
import { FixedSizeList } from 'react-window';

// types
import { getMatchingItems, getSearchStrings, TEntitySearchResults } from '../utilities/search';
import { Contact, ContactsState } from '../models/Contact';
import { TStoreState } from '../store/store';

type TContactsListProps = {
  routerLink?: string;
  searchText: string;
  clickHandler?: (paperId: string) => void;
  favoritesOnly: boolean;
  selectedContacts?: string[];
  fabListActive?: boolean;
};

const ContactsList = ({
  routerLink,
  searchText = '',
  clickHandler,
  favoritesOnly = false,
  selectedContacts = [],
  fabListActive = false,
}: TContactsListProps) => {
  const location = useLocation();
  /**
   * Data
   */
  const contacts = useSelector(
    (state: TStoreState) => state.data?.contacts,
    shallowEqual,
  ) as ContactsState;

  const isDesktop = useSelector((state: TStoreState) => state.ui.isDesktop, shallowEqual);

  /**
   * Search
   */
  const [searchStrings, setSearchStrings] = useState<string[]>([]);
  const itemsData = useRef<{ [id: string]: string }>({});

  const itemsFound = useRef<TEntitySearchResults>();

  useEffect(() => {
    if (searchText === '') {
      if (searchStrings.length !== 0) {
        setSearchStrings([]);
      }
    } else {
      // prepare search strings
      const newSearchStrings = getSearchStrings(searchText);
      if (JSON.stringify(newSearchStrings) !== JSON.stringify(searchStrings)) {
        setSearchStrings(getSearchStrings(searchText));

        // found matching items
        const [iFound, iData] = getMatchingItems(
          contacts.items,
          newSearchStrings,
          itemsData.current,
        );
        itemsFound.current = iFound;
        itemsData.current = iData;
      }
    }
  }, [searchText]);

  const filterItems = (item: any) => {
    if (!item || Object.keys(item).length === 0) return false;

    // filter by favorites
    if (favoritesOnly && item.favorite?.value !== true) return false;

    // not searching anything
    if (searchText === '') return true;

    // found matching items
    if (itemsFound.current?.[item.id]) {
      return true;
    }

    return false;
  };

  const sortContacts = (a: Contact, b: Contact) => {
    return (getValue(a.displayName) || getValue(a.names) || 'ZZ') >
      (getValue(b.displayName) || getValue(b.names) || 'ZZ')
      ? 1
      : -1;
  };

  // initial data prepare
  const contactsArr = useMemo(() => {
    if (contacts.state.isLoaded && !contacts.state.isEmpty) {
      return Object.keys(contacts.items)
        .filter(key => key && contacts.items[key])
        .map(key => contacts.items[key])
        .filter(contact => filterItems(contact))
        .sort(sortContacts);
    } else {
      return [];
    }
    // eslint-disable-next-line
  }, [contacts.items, searchText, favoritesOnly]);

  return (
    <IonList
      className={classNames({
        'leave-space-for-fab-at-the-bottom': true,
        'list-full-height': true,
        'list-transparent': fabListActive,
        'list-virtualized': true,
      })}
    >
      {contactsArr.length > 0 ? (
        <FixedSizeList
          height={window.innerHeight - (isDesktop ? 110 : 175)}
          width="100%"
          itemSize={66}
          itemCount={contactsArr.length}
          itemData={contactsArr}
          overscanCount={4}
        >
          {({ index, style }) => (
            <ContactsListItem
              key={contactsArr[index].id}
              contact={contactsArr[index]}
              routerLink={routerLink}
              currentPath={location.pathname}
              clickHandler={clickHandler}
              style={style}
            />
          )}
        </FixedSizeList>
      ) : (
        <EmptySearchResults searchText={searchText} isPeriod={false} type="contact" />
      )}
    </IonList>
  );
};

export default ContactsList;
