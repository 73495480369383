// hooks
import { useState } from 'react';
import { useIntl } from 'react-intl';

// components
import ModalForm from '../components/ModalForm';
import ContactsList from '../components/ContactsList';
import { IonFab, IonFabButton, IonIcon } from '@ionic/react';
import ContactModal from './ContactModal';

// types
import { add } from 'ionicons/icons';

type TLinkContactModalProps = {
  opened: boolean;
  onDismiss: Function;
  setContactId?: (paperId: string) => void;
  contactId?: string | number | null;
  title?: string;
};

const LinkContactModal: React.FC<TLinkContactModalProps> = (props: TLinkContactModalProps) => {
  const intl = useIntl();

  const selectContact = (contactId: string) => {
    if (props.setContactId) {
      props.setContactId(contactId);
    }
    if (props.onDismiss) {
      props.onDismiss();
    }
  };

  // search
  const [searchText, setSearchText] = useState<string>('');
  const onSearchUpdate = (text: string) => {
    setSearchText(text);
  };

  /**
   * Create New Contact
   */
  const [formModalOpened, setFormModalOpened] = useState(false);

  const showCreateModal = () => {
    setFormModalOpened(true);
  };

  const hideCreateModal = () => {
    setFormModalOpened(false);
  };

  return (
    <ModalForm
      {...props}
      title={
        props.title ||
        intl.formatMessage({
          id: 'form.link-contact.title',
          defaultMessage: 'Choose a Contact to Link',
        })
      }
      searchStr={searchText}
      setSearchStr={onSearchUpdate}
      searchPlaceholder={intl.formatMessage({
        id: 'page.contacts.search.placeholder',
        defaultMessage: 'Filter Contacts',
      })}
    >
      <ContactsList
        searchText={searchText}
        selectedContacts={[props.contactId ? props.contactId.toString() : '']}
        favoritesOnly={false}
        clickHandler={selectContact}
      />

      <IonFab vertical="bottom" horizontal="end" slot="fixed" className="modal-action">
        <IonFabButton mode="ios" onClick={showCreateModal} color="tertiary">
          <IonIcon icon={add} />
        </IonFabButton>
      </IonFab>

      <ContactModal
        opened={formModalOpened}
        onDismiss={hideCreateModal}
        onCreateNew={selectContact}
        contactId="new"
      />
    </ModalForm>
  );
};

export default LinkContactModal;
