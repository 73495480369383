// hooks
import { shallowEqual, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useFirebase } from 'react-redux-firebase';

// plugins
import { Glassfy } from 'capacitor-plugin-glassfy';

// components
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonTitle,
  IonToolbar,
  isPlatform,
  useIonToast,
} from '@ionic/react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import LoadingScreen from './LoadingScreen';

// icons
import { closeOutline } from 'ionicons/icons';

// types
import { TStoreState } from '../store/store';

type TInAppPurchasesModal = {
  isOpen: boolean;
  onDidDismiss: () => void;
};

const mockProducts = [
  {
    identifier: 'papers_199_1m',
    title: 'Advanced Account',
    description: 'Allows you to store up to 2000 papers',
    price: 1.99,
    currency: 'EUR',
    priceString: '€1,99',
  },
  {
    identifier: 'papers_999_1m',
    title: 'Pro Account',
    description: 'Allows you to store up to 20000 papers',
    price: 9.99,
    currency: 'EUR',
    priceString: '€9,99',
  },
  {
    identifier: 'papers_2999_1m',
    title: 'Business Account',
    description: 'Allows you to store up to 100000 papers',
    price: 29.99,
    currency: 'EUR',
    priceString: '€29,99',
  },
];

const InAppPurchasesModal: React.FC<TInAppPurchasesModal> = (props: TInAppPurchasesModal) => {
  const routerRef = useSelector((state: TStoreState) => state.ui?.routerRef);
  const profile = useSelector((state: TStoreState) => state.firebase?.profile, shallowEqual);

  const firebase = useFirebase();

  const [isLoading, setIsLoading] = useState(true);

  const [presentToast] = useIonToast();

  // declare variables
  const [products, setProducts] = useState<any>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [subscription, setSubscription] = useState<string>('');
  const [disableButtons, setDisableButtons] = useState<boolean>(false);

  //initiate initInAppPurchase function
  useEffect(() => {
    initInAppPurchase();
  }, []);

  useEffect(() => {
    if (props.isOpen) {
      initInAppPurchase();
    }
  }, [props.isOpen]);

  const initInAppPurchase = () => {
    if (isPlatform('capacitor')) {
      try {
        Glassfy.offerings()
          .then(offerings => {
            // get products from offerings
            const products = offerings.all.map(offering => {
              return {
                offeringId: offering.offeringId,
                identifier: offering.skus[0].skuId,
                // @ts-ignore there is title in the object
                title: offering.skus[0].product.title,
                description: offering.skus[0].product.description,
                price: offering.skus[0].product.price,
                currency: offering.skus[0].product.currencyCode,
                sku: offering.skus[0],
              };
            });

            // sort products by price
            products.sort((p1, p2) => (p1.price > p2.price ? 1 : -1));

            // set products
            setProducts(products);
            setIsLoading(false);
          })
          .catch(error => {
            setIsLoading(false);
            // setProducts(mockProducts);
            setErrorMessage('modal.iap.not-available');
          });
      } catch (e) {
        setIsLoading(false);
        setErrorMessage('modal.iap.not-ready');
      }
    } else {
      setIsLoading(false);
      // setProducts(mockProducts);
      setErrorMessage('modal.iap.not-ready');
    }
  };

  //if user clicks purchase button
  const purchase = async (product: any) => {
    setDisableButtons(true);
    try {
      const transaction = await Glassfy.purchaseSku({ sku: product.sku });
      const permission = transaction.permissions.all.find(p => p.isValid);
      if (permission && permission.isValid) {
        setSubscription(permission.permissionId);
      }
      setDisableButtons(false);
    } catch (error: any) {
      if (error?.message) {
        presentToast({
          message: error.message,
          duration: 2000,
          position: 'top',
          color: 'medium',
        });
      }
      console.error('purchase error:', error);
      setDisableButtons(false);
    }
  };

  //if user clicks retore or promo code button
  const restorePurchases = () => {
    setDisableButtons(true);

    try {
      Glassfy.restorePurchases()
        .then(permissions => {
          const permission = permissions.all.find(p => p.isValid);
          if (permission) {
            setSubscription(permission.permissionId);
          }
          setDisableButtons(false);
        })
        .catch(error => {
          if (error?.message) {
            presentToast({
              message: error.message,
              duration: 2000,
              position: 'top',
              color: 'danger',
            });
          }
          console.error('restorePurchases error:', error);
          setDisableButtons(false);
        });
    } catch (e: any) {
      if (e?.message) {
        presentToast({
          message: e.message,
          duration: 2000,
          position: 'top',
          color: 'danger',
        });
      }
      console.error('restorePurchases error:', e);
      setDisableButtons(false);
    }
  };

  /**
   * Update purchases in the db according to the data coming from RevenueCat
   */
  useEffect(() => {
    if (subscription !== '' && profile?.isLoaded && !profile.manualAccount) {
      if (profile.account !== subscription) {
        // there is an active subcription
        // should update the profile
        firebase.updateProfile({
          account: subscription,
        });
        // stop update the profile
        setSubscription('');
        // close the popup
      }
      props.onDidDismiss();
    }
  }, [subscription, profile]);

  /**
   * Check is the product purchased
   */
  const isPurchased = (productId: string): boolean => {
    return profile.account === productId;
  };

  return (
    <IonModal
      mode="md"
      isOpen={props.isOpen}
      onDidDismiss={props.onDidDismiss}
      presentingElement={routerRef || undefined}
    >
      <IonHeader mode="md">
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={props.onDidDismiss}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>
            <FormattedMessage id="modal.iap.title" defaultMessage="Upgrade Account" />
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {isLoading ? (
          <LoadingScreen />
        ) : errorMessage !== null ? (
          <p className="modal-description-text">
            <FormattedMessage
              id={errorMessage}
              defaultMessage="Subscrptions are not available on this device"
            />
          </p>
        ) : products.length === 0 ? (
          <p className="modal-description-text">
            <FormattedMessage
              id="modal.iap.not-available"
              defaultMessage="Subscrptions are not available right now"
            />
          </p>
        ) : (
          <>
            <p className="modal-description-text">
              <FormattedMessage
                id="modal.iap.description"
                defaultMessage="To be able to store more papers in the app, following subscriptions are available"
              />
            </p>
            <IonList>
              {products.map((product: any) => (
                <IonItem key={product.identifier}>
                  <IonLabel color={isPurchased(product.identifier) ? 'primary' : ''}>
                    <h2>{product.title}</h2>
                    <p className="item--long-description">{product.description}</p>
                  </IonLabel>
                  {isPurchased(product.offeringId) ? (
                    <IonLabel slot="end" style={{ textAlign: 'right', fontSize: '0.8rem' }}>
                      <FormattedMessage id="ui.label.active-account" defaultMessage="Active" />
                      <br />
                      <FormattedNumber
                        value={product.price}
                        style="currency"
                        currency={product.currency}
                      />{' '}
                      <FormattedMessage
                        id="ui.buttons.per-month-suffix"
                        defaultMessage="per month"
                      />
                    </IonLabel>
                  ) : (
                    <IonButton
                      mode="md"
                      shape="round"
                      fill="outline"
                      className="ion-activated"
                      size="small"
                      slot="end"
                      onClick={() => purchase(product)}
                      disabled={disableButtons}
                    >
                      <FormattedNumber
                        value={product.price}
                        style="currency"
                        currency={product.currency}
                      />{' '}
                      <FormattedMessage
                        id="ui.buttons.per-month-suffix"
                        defaultMessage="per month"
                      />
                    </IonButton>
                  )}
                </IonItem>
              ))}
            </IonList>
            <div style={{ textAlign: 'center', margin: '2rem' }}>
              <IonButton
                mode="md"
                shape="round"
                fill="outline"
                className="ion-activated"
                onClick={restorePurchases}
                disabled={disableButtons}
              >
                <FormattedMessage
                  id="ui.buttons.restore-purchases"
                  defaultMessage="Restore Purchases"
                />
              </IonButton>
            </div>
          </>
        )}
      </IonContent>
    </IonModal>
  );
};

export default InAppPurchasesModal;
