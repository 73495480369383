/**
 * Converts file size in bytes to the most appropriate size in KB, MB or GB
 *
 * @param bytes  file size in bytes
 * @param dp     number of digits after the comma
 * @param si     divider should be the 10^3 (SI) or 2^10
 */
export const formatFileSize = (bytes: number, dp = 0, si = true) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + ' ' + units[u];
};

/**
 * Calculates the total size of the file encoded in base64 in bytes
 *
 * @param base64
 * @returns number
 */
export const filesizeFromBase64 = (base64: string): number => {
  const padding = (base64.match(/=/g) || []).length;
  const size = base64.length * 0.75 - padding;
  return size;
};

/**
 * Returns the mimeType from the base64 encoded file
 *
 * @param base64
 * @returns string
 */
export const mimeTypeFromBase64 = (base64: string): string => {
  const result = base64.match(/^data:([A-Za-z-+/]+);base64,/);
  return result && result.length ? result[1] : '';
};
