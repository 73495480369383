// hooks
import { memo, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useLongPress } from '../hooks/useLongPress';
import { useHistory } from 'react-router-dom';

// components
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { IonBadge, IonIcon, IonItem, IonLabel, IonReorder } from '@ionic/react';
import { PaperBackPage, PaperFrame } from './PaperFrames';

// utils
import { getCurrency, getValue } from '../utilities/values';
import classNames from 'classnames';
import { getContactName } from '../utilities/lists';

// types
import { TStoreState } from '../store/store';
import { Paper } from '../models/Paper';
import { ContactsState } from '../models/Contact';

// icons
import { ReactComponent as NoPreview } from '../assets/no-preview.svg';
import { checkmarkCircle, ellipse, ellipseOutline, walletOutline } from 'ionicons/icons';
import { classes } from '../utilities/strings';

type TPapersListItemsProps = {
  paper: Paper;
  routerLink?: string;
  currentPath?: string;
  clickHandler?: (paperId: string) => void;
  selectHandler?: (paperId: string) => void;
  selected?: boolean;
  isSelectionActive?: boolean;
  thumbs?: string[];
  reorder?: boolean;
};

const PapersListItem = ({
  paper,
  routerLink,
  currentPath,
  clickHandler,
  selectHandler,
  selected,
  isSelectionActive,
  thumbs = [],
  reorder,
}: TPapersListItemsProps) => {
  const intl = useIntl();
  const uid = useSelector((state: TStoreState) => state.firebase.auth.uid);

  const history = useHistory();

  const memoPaper = useMemo(() => paper, [paper]);

  /**
   * Get the contacts for the paper
   */
  const contacts = useSelector(
    (state: TStoreState) => state.data?.contacts,
    shallowEqual,
  ) as ContactsState;

  // contact issued the paper
  const issuedBy = getValue(memoPaper?.issuedBy, undefined, 1) as string;
  const issuedByContact = issuedBy && contacts?.items?.[issuedBy];
  const issuedByName = getContactName(issuedByContact, issuedBy);

  // contact received the paper
  const issuedTo = getValue(memoPaper?.issuedTo, undefined, 1) as string;
  const issuedToContact = issuedTo && contacts?.items?.[issuedTo];
  const issuedToName = getContactName(issuedToContact, issuedTo);

  // paper title
  const title = getValue(
    memoPaper?.title,
    memoPaper?.new
      ? intl.formatMessage({ id: 'paper.new' })
      : intl.formatMessage({ id: 'paper.untitled' }),
  );

  // subtitle (reference + issue date)
  const reference = getValue(
    memoPaper?.reference,
    memoPaper?.new ? intl.formatMessage({ id: 'paper.new.reference' }) : undefined,
  );
  const issuedOn = getValue(memoPaper?.issuedOn);
  const validUntil = getValue(memoPaper?.validUntil);
  const tripDate = getValue(memoPaper?.tripDate);
  const tripTime = getValue(memoPaper?.tripTime);
  const date =
    memoPaper?.form === 'ticket' && typeof tripDate === 'string'
      ? new Date(tripDate).toLocaleDateString(intl.locale + '-GB') +
        (tripTime ? ' ' + tripTime : '')
      : typeof issuedOn === 'string'
      ? new Date(issuedOn).toLocaleDateString(intl.locale + '-GB')
      : typeof validUntil === 'string'
      ? validUntil.indexOf('•') < 0
        ? intl.formatMessage(
            { id: 'paper.validUntil' },
            {
              date: new Date(validUntil).toLocaleString(intl.locale + '-GB', {
                year: 'numeric',
                month: 'long',
              }),
            },
          )
        : validUntil
      : null;

  const subtitle =
    memoPaper?.form === 'ticket' && typeof tripDate === 'string'
      ? intl.formatMessage({ id: 'paper.departure' }) + ' ' + date
      : reference && date
      ? intl.formatMessage({ id: 'paper.ref-and-date' }, { reference, date })
      : date || reference || intl.formatMessage({ id: 'paper.no-reference' });

  // paper item strings:
  const preTitle =
    memoPaper?.form === 'bill' && issuedByContact ? (issuedToName ? ' · ' : '') + title : '';
  const paperTitle = memoPaper?.form === 'bill' && issuedByName ? issuedByName : title;

  // privacy
  // should we show the total amonts or just dots?
  const privacy = getValue(memoPaper?.privacy);

  // total
  // with a hack for the encryption demo
  const displayTotalValue = getValue(memoPaper?.displayTotal, '');
  const totalValue = getValue(memoPaper?.total, '');
  const total = privacy ? '• • • • •' : displayTotalValue ? displayTotalValue : totalValue;
  const totalCurrency = getCurrency(displayTotalValue ? memoPaper?.displayTotal : memoPaper?.total);

  // income
  const incomeField =
    (memoPaper?.form === 'reimbursement' && memoPaper?.reimbursed) || memoPaper?.net;
  const income = privacy ? '• • • • •' : getValue(incomeField);
  const incomeCurrency = getCurrency(incomeField);
  // const baseSalary = getValue(paper?.base);

  // requested amount (for reimbursements)
  const requestedValue = memoPaper?.form === 'reimbursement' && getValue(memoPaper?.requested);
  const requested = requestedValue && privacy ? '• • • • •' : requestedValue;
  const requestedCurrency = getCurrency(memoPaper?.requested);

  // reimbursement (for invoices)
  const reimbursedValue = getValue(memoPaper?.reimbursedAmount);
  const reimbursed = reimbursedValue && privacy ? '• • • • •' : reimbursedValue;
  const reimbursedCurrency = getCurrency(memoPaper?.reimbursedAmount);

  /**
   * Selection
   */
  const onLongPress = (event?: any, onClickAction = false) => {
    if (onClickAction || event?.type !== 'click') {
      if (selectHandler) {
        selectHandler(memoPaper?.id);
      } else if (clickHandler) {
        clickHandler(memoPaper?.id);
      } else if (routerLink) {
        history.push(`${routerLink}${memoPaper?.id}${memoPaper?.new ? '?edit' : ''}`);
      }
    }
  };

  const longPressEvent = useLongPress(onLongPress, {
    onClick: () => {
      if (clickHandler) {
        clickHandler(memoPaper?.id);
      } else if (routerLink) {
        history.push(`${routerLink}${memoPaper?.id}${memoPaper?.new ? '?edit' : ''}`);
      }
    },
  });

  const iconClickHandler = (event: any) => {
    if (isSelectionActive && selectHandler) {
      selectHandler(memoPaper?.id);
      event.stopPropagation();
    }
  };

  const preventTouchStart = (event: any) => {
    if (isSelectionActive && selectHandler) {
      event.stopPropagation();
    }
  };

  return (
    <IonItem
      className={classes({
        'list-item': true,
        'list-item--active': !!currentPath?.includes(routerLink + memoPaper?.id || '', 0),
      })}
      // routerLink={routerLink && `${routerLink}${memoPaper?.id}${memoPaper?.new ? '?edit' : ''}`}
      // onClick={clickHandler && (() => clickHandler(memoPaper?.id))}
      button={!!clickHandler || !!routerLink}
      {...longPressEvent}
    >
      <div
        className="list-item__thumb-container"
        slot="start"
        onMouseDown={iconClickHandler}
        onTouchStart={preventTouchStart}
      >
        {isSelectionActive && (
          <>
            <IonIcon
              icon={ellipse}
              className="list-item__selected-checkmark-background"
              color="light"
            />
            <IonIcon
              icon={selected ? checkmarkCircle : ellipseOutline}
              className="list-item__selected-checkmark"
              color="primary"
            />
          </>
        )}
        {thumbs.length > 0 ? (
          <>
            <img src={thumbs[0].replace('{uid}', uid)} alt="thumb" className="list-item__thumb" />
            {thumbs
              .filter((_: any, index: number) => index < 5)
              .map((_: any, index: number) => {
                if (index === 0) {
                  return <PaperFrame key={`thumb-${index}`} />;
                } else {
                  return <PaperBackPage key={`thumb-${index}`} index={index} />;
                }
              })}
          </>
        ) : (
          <NoPreview className="list-item__thumb" />
        )}
      </div>
      <IonLabel>
        {(issuedToName !== '' || preTitle !== '') && (
          <p className="list-item__pre-title">
            {issuedToName !== '' && <span className="list-item__issued-to">{issuedToName}</span>}
            {preTitle}
          </p>
        )}
        <h2 className="list-item__title">{paperTitle}</h2>
        <h3 className="list-item__subtitle">{subtitle}</h3>
      </IonLabel>
      {reorder ? (
        <IonReorder slot="end" />
      ) : memoPaper?.new ? (
        <IonBadge color="danger">
          <FormattedMessage id="paper.new.badge" defaultMessage="new" />
        </IonBadge>
      ) : (
        <>
          {total && !income && (
            <div
              className={classNames({
                'list-item__total': true,
                'list-item__total-unpaid':
                  memoPaper.form === 'bill' && getValue(memoPaper?.paid) !== true,
              })}
              slot="end"
            >
              {total.toString().indexOf('•') >= 0 ? (
                '• • • • •'
              ) : (
                <FormattedNumber
                  value={parseFloat(total?.toString().replace(',', '.') || '0') || 0}
                  style="currency"
                  currency={totalCurrency}
                />
              )}
              {reimbursed?.toString().indexOf('•') === -1 && (
                <div className="list-item__reimbursed">
                  <IonIcon icon={walletOutline} />
                  <FormattedNumber
                    value={parseFloat(reimbursed?.toString().replace(',', '.') || '0') || 0}
                    style="currency"
                    currency={reimbursedCurrency}
                  />
                </div>
              )}
            </div>
          )}
          {income && (
            <div
              className={classNames({
                'list-item__total': true,
                'list-item__total-income':
                  (memoPaper.form === 'payslip' && getValue(memoPaper?.paid) !== true) ||
                  (!privacy && !getValue(memoPaper?.paid)),
              })}
              slot="end"
            >
              {requested?.toString().indexOf('•') === -1 &&
                parseInt(requested as string, 10) > 0 && (
                  <div className="list-item__requested">
                    <FormattedNumber
                      value={parseFloat(requested?.toString().replace(',', '.') || '0') || 0}
                      style="currency"
                      currency={requestedCurrency}
                    />
                  </div>
                )}
              {income.toString().indexOf('•') >= 0 ? (
                '• • • • •'
              ) : (
                <FormattedNumber
                  value={parseFloat(income?.toString().replace(',', '.') || '0') || 0}
                  style="currency"
                  currency={incomeCurrency}
                />
              )}
            </div>
          )}
        </>
      )}
    </IonItem>
  );
};

export default memo(PapersListItem);
