// hooks
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

// components
import ModalForm from '../components/ModalForm';
import { IonCheckbox, IonItem, IonList, isPlatform } from '@ionic/react';

// types
import { useSelector } from 'react-redux';
import { Category } from '../models/Category';

type TUpdateCategoriesModalProps = {
  opened: boolean;
  onDismiss: Function;
  setCategories?: (categories: { [catId: string]: boolean }) => void;
  selectedCategories?: { [catId: string]: boolean | 'mix' };
  title?: string;
};

const UpdpateCategoriesModal: React.FC<TUpdateCategoriesModalProps> = (
  props: TUpdateCategoriesModalProps,
) => {
  const intl = useIntl();

  const categories = useSelector(
    (state: any) => state.settings.categories.filter((cat: Category) => !cat.hidden) || [],
  );

  const [updatedValues, setUpdatedValues] = useState<{ [catId: string]: boolean }>({});

  useEffect(() => {
    return () => setUpdatedValues({});
  }, [props.selectedCategories]);

  const toggleCategory = (catId: string, newValue: boolean, value: boolean | 'mix') => {
    if (value === 'mix' && newValue === true && updatedValues.hasOwnProperty(catId)) {
      setTimeout(() => {
        setUpdatedValues(oldValues => {
          const { [catId]: updated, ...newValues } = oldValues;
          return newValues;
        });
      }, 1);
    } else {
      setUpdatedValues(oldValues => ({ ...oldValues, [catId]: newValue }));
    }
  };

  const updatePapers = () => {
    const updatedCategories = Object.keys(updatedValues).reduce(
      (acc: { [catId: string]: boolean }, catId: string) => {
        if (props.selectedCategories?.[catId] !== updatedValues[catId]) {
          acc[catId] = updatedValues[catId];
        }
        return acc;
      },
      {},
    );
    if (Object.keys(updatedCategories).length > 0) {
      props.setCategories?.(updatedCategories);
    }
    props.onDismiss();
  };

  return (
    <ModalForm
      {...props}
      title={
        props.title ||
        intl.formatMessage({
          id: 'form.update-categories.title',
          defaultMessage: 'Update categories',
        })
      }
      action={{
        title: intl.formatMessage({
          id: 'ui.buttons.update',
          defaultMessage: 'Update',
        }),
        callback: updatePapers,
      }}
      style={{ '--width': '540px' } as React.CSSProperties}
    >
      <IonList>
        {categories.map((cat: Category) => {
          const label =
            cat.label || intl.formatMessage({ id: cat.labelId, defaultMessage: cat.value });
          const value = props.selectedCategories?.[cat.value] || false;
          return (
            <IonItem key={cat.value}>
              <IonCheckbox
                justify="space-between"
                mode={isPlatform('ios') ? 'ios' : 'md'}
                checked={
                  updatedValues.hasOwnProperty(cat.value)
                    ? updatedValues[cat.value]
                    : value === true
                }
                indeterminate={value === 'mix' && !updatedValues.hasOwnProperty(cat.value)}
                onIonChange={evt => toggleCategory(cat.value, evt.detail.checked, value)}
                color="light"
                className={
                  updatedValues.hasOwnProperty(cat.value) && updatedValues[cat.value] !== value
                    ? 'checkbox--updated'
                    : ''
                }
              >
                {value === true ? <b>{label}</b> : value === 'mix' ? <i>{label}</i> : label}
              </IonCheckbox>
            </IonItem>
          );
        })}
      </IonList>
    </ModalForm>
  );
};

export default UpdpateCategoriesModal;
