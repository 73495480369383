// second link to Firebase to call the functions
import fb from 'firebase/app';

// hooks
import { FormEvent, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';

// components
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonList,
  IonModal,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { FormattedMessage, useIntl } from 'react-intl';
import PinCode from './PinCode';
import TextField from '../forms/TextField';

// types and icons
import { TStoreState } from '../store/store';
import { closeOutline } from 'ionicons/icons';

type TPinSetupModalProps = {
  isOpen: boolean;
  onDidDismiss: () => void;
};

const PinSetupModal: React.FC<TPinSetupModalProps> = (props: TPinSetupModalProps) => {
  const intl = useIntl();
  const firebase = useFirebase();

  const routerRef = useSelector((state: TStoreState) => state.ui?.routerRef);
  const uid = useSelector((state: TStoreState) => state.firebase?.auth?.uid);

  const newPin = useRef('');
  const confirmPin = useRef('');
  const password = useRef('');

  const [phase, setPhase] = useState<number>(0);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const updatePin = (value: string) => {
    setError('');
    if (phase === 0) {
      newPin.current = value;
    }
    if (phase === 1) {
      confirmPin.current = value;
    }
  };

  const [error, setError] = useState<string | undefined>('');

  const nextPhase = async () => {
    if (phase === 0 && newPin.current.length >= 4) {
      setPhase(1);
    }
    if (phase === 1) {
      if (newPin.current === confirmPin.current) {
        setPhase(2);
      } else {
        setError('do-not-match');
      }
    }
    if (phase === 2) {
      setButtonDisabled(true);
      // send the new pin to the server with the password
      const storeNewPinFn = fb.app().functions('europe-west3').httpsCallable('users-storePin');
      await storeNewPinFn({ uid, pin: newPin.current, password: password.current })
        .then(() => {
          firebase.updateProfile({ pin: true });
          setButtonDisabled(false);
          props.onDidDismiss();
        })
        .catch(err => {
          console.error('error setting new pin', err.code);
          setButtonDisabled(false);
          setError(err.code);
        });
    }
  };

  const previousPhase = () => {
    if (phase > 0) {
      setPhase(phase - 1);
      setError('');
    }
  };

  /**
   * password field
   */
  const passwordRef = useRef<any>(null);

  const updatePassword = (value: string) => {
    password.current = value;
    setError('');
  };

  const processForm = (evt: FormEvent) => {
    evt.defaultPrevented = true;
    evt.preventDefault();
    evt.stopPropagation();
    nextPhase();
  };

  return (
    <IonModal
      mode="md"
      isOpen={props.isOpen}
      onDidDismiss={props.onDidDismiss}
      presentingElement={routerRef || undefined}
    >
      <IonHeader mode="md">
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={props.onDidDismiss}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>
            <FormattedMessage id="modal.pin-setup.title" defaultMessage="Setup PIN" />
          </IonTitle>
          {phase > 0 && (
            <IonButtons slot="end" style={{ paddingRight: '8px' }}>
              <IonButton
                onClick={() => previousPhase()}
                shape="round"
                fill="clear"
                className="cancel-button"
              >
                <FormattedMessage id="ui.buttons.back" defaultMessage="Back" />
              </IonButton>
            </IonButtons>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <form
          onSubmit={processForm}
          style={{
            display: 'flex',
            width: '100%',
            height: 'calc(100% - 130px)',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {phase === 0 && (
            <PinCode
              value=""
              onChange={updatePin}
              onEnter={() => nextPhase()}
              text={intl.formatMessage({ id: 'modal.pin-setup.new-pin.label' })}
              error={error ? `auth.errors.pin.${error}` : ''}
              disabled={buttonDisabled}
            />
          )}
          {phase === 1 && (
            <PinCode
              value=""
              onChange={updatePin}
              onEnter={() => nextPhase()}
              text={intl.formatMessage({ id: 'modal.pin-setup.confirm-pin.label' })}
              error={error ? `auth.errors.pin.${error}` : ''}
              disabled={buttonDisabled}
            />
          )}
          {phase === 2 && (
            <IonList style={{ margin: '1rem 0 3rem', width: '80%' }}>
              <TextField
                ref={passwordRef}
                name="password"
                value={password.current}
                type="password"
                onValueUpdate={value => updatePassword(value.value?.toString() || '')}
                label={intl.formatMessage({ id: 'ui.field.account-password.label' })}
                autofocus={true}
                onNext={nextPhase}
                error={
                  error ? intl.formatMessage({ id: `auth.errors.password.${error}` }) : undefined
                }
              />
              <IonButton
                size="default"
                expand="block"
                mode="ios"
                color="secondary"
                style={{
                  margin: '1.6rem calc(var(--padding-start) + var(--ion-safe-area-left, 0px))',
                }}
                onClick={nextPhase}
                disabled={buttonDisabled}
              >
                <FormattedMessage id="ui.buttons.confirm-change" defaultMessage="Confirm Change" />
              </IonButton>
            </IonList>
          )}
        </form>
      </IonContent>
    </IonModal>
  );
};

export default PinSetupModal;
