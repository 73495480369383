// hooks
import { Fragment, useEffect, useRef, useState } from 'react';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router';
import { shallowEqual, useSelector } from 'react-redux';
import { useCollectionItem } from '../hooks/useCollectionItem';
import { useObjectCollection } from '../hooks/useObjectCollection';
import { useCollectionItems } from '../hooks/useCollectionItems';

// helper functions
import {
  getLabel,
  getValue,
  getDateValue,
  getLabelId,
  hasValueOf,
  reorderArray,
} from '../utilities/values';
import { _label } from '../utilities/strings';
import { getReminderData } from '../utilities/reminders';
import { emitCustomEvent } from 'react-custom-events';
import { str2key } from '../utilities/encryption';
import { processData } from '../utilities/forms';
import {
  createFirestoreDocument,
  deleteFirestoreDocument,
  updateFirestoreDocument,
} from '../utilities/firestore';
import { deleteFiles, uploadFiles } from '../utilities/files';

// components
import AppPage from '../components/AppPage';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonIcon,
  IonImg,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonList,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToolbar,
  useIonAlert,
  useIonLoading,
  useIonPopover,
  useIonViewDidEnter,
} from '@ionic/react';
import LoadingScreen from '../components/LoadingScreen';
import DetailsItem from '../components/DetailsItem';
import PapersList from '../components/PapersList';
import ModalFields from '../forms/ModalFields';
import ActionsToolbar from '../components/ActionsToolbar';

// icons
import {
  createOutline,
  lockOpenOutline,
  removeCircleOutline,
  star,
  starOutline,
} from 'ionicons/icons';

// types
import { TStoreState } from '../store/store';
import { TFormFields } from '../models/Form';
import { TCommonDocument, TFieldValue } from '../models/FieldValues';
import { PapersState } from '../models/Paper';
import { RecordTypeField } from '../models/RecordType';
import { TRemindersState } from '../models/Reminder';
import { newContact } from '../models/Contact';

import { customAlphabet } from 'nanoid';
import { alphanumeric } from 'nanoid-dictionary';
const nanoid = customAlphabet(alphanumeric, 14);

// paper fields that contains links with the contacts
const contactFields = ['issuedBy', 'issuedTo', 'linkedContact'];

const Contact: React.FC = () => {
  const intl = useIntl();
  const history = useHistory();

  // Save the data to the DB
  const firebase = useFirebase();
  const firestore = useFirestore();

  const { contactId } = useParams<{ contactId: string }>();

  const contact = useSelector(
    (state: TStoreState) => state.data.contacts?.items?.[contactId],
    shallowEqual,
  ) as any;
  const fields = useCollectionItem('types', 'fields');
  const forms = useCollectionItems('types', { record: 'contact' });
  const typeFields = useCollectionItem('types', 'contact');
  const user = useSelector((state: TStoreState) => state.firebase?.auth);

  const profile = useSelector((state: TStoreState) => state.firebase.profile, shallowEqual);
  const privateKey = useSelector((state: TStoreState) => state.ui?.privateKey);

  const [isLocked, setIsLocked] = useState(profile?.publicKey && !privateKey);
  useEffect(() => {
    setIsLocked(profile?.publicKey && !privateKey);
  }, [profile, privateKey]);

  /**
   * Request authorization
   */
  const requestAuthorization = async () => {
    emitCustomEvent('request-authorization');
  };

  useIonViewDidEnter(() => {
    if (contactId !== 'new' && isLocked) {
      requestAuthorization();
    }
  }, [isLocked]);

  /**
   * Favorite
   */
  const [isFavorite, setFavorite] = useState<boolean>(false);
  useEffect(() => {
    setFavorite(getValue(contact?.favorite) === true);
  }, [contact]);

  const editContact = () => {
    showEditModal();
  };

  const [presentAlert] = useIonAlert();

  const confirmDeleteContact = () => {
    presentAlert({
      header: intl.formatMessage({ id: 'page.contact.delete-alert.title' }),
      message: intl.formatMessage({ id: 'page.contact.delete-alert.message' }),
      buttons: [
        intl.formatMessage({ id: 'ui.buttons.no' }),
        {
          text: intl.formatMessage({ id: 'ui.buttons.yes' }),
          handler: () => deleteContact(),
        },
      ],
    });
  };

  const toggleFavorite = () => {
    firestore.doc(`/users/${user.uid}/contacts/${contactId}`).update({
      'favorite.value': !isFavorite,
    });
  };

  /**
   * Menu popover
   */
  const [presentMenu, closeMenu] = useIonPopover(() => {
    return (
      <IonList>
        {!isLocked ? (
          <>
            <IonItem
              button
              onClick={() => {
                editContact();
                closeMenu();
              }}
              detail={false}
            >
              <IonIcon icon={createOutline} slot="start" />
              {intl.formatMessage({ id: 'ui.buttons.edit' })}
            </IonItem>
            <IonItem
              button
              onClick={() => {
                toggleFavorite();
                closeMenu();
              }}
              detail={false}
            >
              <IonIcon
                icon={isFavorite ? starOutline : star}
                color={isFavorite ? 'medium' : 'secondary'}
                slot="start"
              />
              {intl.formatMessage({
                id: isFavorite ? 'ui.buttons.remove-from-favorites' : 'ui.buttons.add-to-favorites',
              })}
            </IonItem>
            <IonItem
              button
              onClick={() => {
                confirmDeleteContact();
                closeMenu();
              }}
              lines="none"
              detail={false}
            >
              <IonIcon icon={removeCircleOutline} color="danger" slot="start" />
              {intl.formatMessage({ id: 'ui.buttons.delete' })}
            </IonItem>
          </>
        ) : (
          <IonItem
            button
            onClick={() => {
              requestAuthorization();
              closeMenu();
            }}
            lines="none"
            detail={false}
          >
            <IonIcon icon={lockOpenOutline} slot="start" />
            <FormattedMessage id="ui.buttons.unlock-the-data" defaultMessage="Unlock the Data" />
          </IonItem>
        )}
      </IonList>
    );
  });

  /**
   * Edit modal
   */
  const [editMode, setEditMode] = useState(contactId === 'new');

  const showEditModal = () => {
    // setFormModalOpened(true);
    setEditMode(true);
  };

  /**
   * View switch
   */
  const [currentView, setCurrentView] = useState<'info' | 'papers'>('info');

  const updateCurrentView = (view: 'info' | 'papers') => {
    setCurrentView(view);
  };

  useEffect(() => {
    setCurrentView('info');
  }, [contactId]);

  /**
   * Papers
   */
  const papers = useSelector((state: TStoreState) => state.data.papers) as PapersState;
  const [contactPapers, setContactPapers] = useState<string[]>([]);

  useEffect(() => {
    if (papers.items) {
      const contactPapersList = Object.keys(papers.items).filter(paperId => {
        return contactFields.some(field => hasValueOf(papers.items[paperId]?.[field], contactId));
      });
      setContactPapers(contactPapersList);
    } else {
      setContactPapers([]);
    }
  }, [papers, contactId]);

  const name = getValue(contact?.displayName) || getValue(contact?.names);

  /**
   * Reminders
   */
  const reminders = useObjectCollection('reminders') as TRemindersState;
  const [contactReminders, setContactReminders] = useState<{ [field: string]: string }>({});
  useEffect(() => {
    if (reminders.items) {
      const updatedContactReminders = Object.keys(reminders.items).reduce((acc, remindersDoc) => {
        if (reminders.items[remindersDoc]) {
          Object.keys(reminders.items[remindersDoc]).forEach(reminderId => {
            if (reminderId.indexOf(`contact-${contactId}`) === 0) {
              const fieldId = reminderId.replace(`contact-${contactId}-`, '');
              acc[fieldId] = remindersDoc;
            }
          });
        }
        return acc;
      }, {} as { [field: string]: string });

      if (JSON.stringify(contactReminders) !== JSON.stringify(updatedContactReminders)) {
        setContactReminders(updatedContactReminders);
      }
    } else {
      if (JSON.stringify(contactReminders) !== '{}') {
        setContactReminders({});
      }
    }
    // eslint-disable-next-line
  }, [reminders]);

  const createReminder = (
    field: string,
    fieldNo: number,
    offset: number,
    value: string,
    repeat: boolean = false,
  ) => {
    // create reminder object
    let itemField = contact?.[field];
    if (itemField && Array.isArray(itemField)) {
      itemField = itemField[fieldNo];
    }
    const fieldLabel = getLabel(itemField);

    const reminder = getReminderData({
      type: 'contact',
      itemId: contactId,
      field,
      fieldNo,
      offset,
      value,
      repeat,
      title:
        fieldLabel !== ''
          ? fieldLabel
          : intl.formatMessage({ id: `reminder.contact.${field}.title` }),
      body: intl.formatMessage({ id: `reminder.contact.${field}.body` }, { name, offset }),
    });

    if (contact?.avatar?.[0] && reminder) {
      reminder.data.image = contact.avatar[0].url.replace('{uid}', user.uid);
    }

    // save the object to the DB
    if (reminder) {
      firestore.doc(`/users/${user.uid}/reminders/${reminder.docName}`).set(
        {
          docName: reminder.docName,
          uid: user.uid,
          [reminder.reminderId]: reminder.data,
        },
        { merge: true },
      );
    }
  };
  const deleteReminder = (docName: string, reminderId: string) => {
    // get the document value
    const reminderDoc = { ...(reminders.items?.[docName] || {}) };
    delete reminderDoc[`contact-${contactId}-${reminderId}`];

    if (Object.keys(reminderDoc).length < 3) {
      // if length is less than 3, means there are only docName and uid
      // delete the document
      firestore.doc(`/users/${user.uid}/reminders/${docName}`).delete();
    } else {
      // update the document
      firestore.doc(`/users/${user.uid}/reminders/${docName}`).update(reminderDoc);
    }
  };

  /**
   * Contact editing
   */
  const [presentLoading, dismissLoading] = useIonLoading();

  const returnNewFormData = (newData: TCommonDocument) => ({
    ...newData,
    ...(contact || {}),
    id: nanoid(14),
  });

  // encryption
  const publicKey = useRef<CryptoKey | null>(null);
  useEffect(() => {
    if (profile?.publicKey) {
      str2key(profile.publicKey as string).then(key => {
        publicKey.current = key;
      });
    }
  }, [profile]);

  // data that will be updated in the form
  // starting with a new blank contact and then adding data that can come from the DB
  const [formData, setFormData] = useState<TCommonDocument>(returnNewFormData(newContact));

  // const clearFormData = () => {
  //   setFormData(returnNewFormData(newContact));
  // };

  const updateData = (property: string, value: any) => {
    if ((formData as any)[property] !== value) {
      setFormData(data => ({ ...data, [property]: value }));
    }
  };

  // in case client's data in the DB is changed, we have to update the form too
  const [wasLocked, setWasLocked] = useState<boolean>(isLocked);
  useEffect(() => {
    const justUnlocked = !isLocked && wasLocked !== isLocked;
    if (justUnlocked) {
      setWasLocked(isLocked);
    }
    if (contact && (contact.id !== formData.id || justUnlocked)) {
      updateFormData();
    }
    // eslint-disable-next-line
  }, [contact, editMode]);

  useEffect(() => {
    if (contact?.id && editMode) {
      updateFormData();
    }
    // eslint-disable-next-line
  }, [editMode]);

  const updateFormData = (updatedData: TCommonDocument = {}) => {
    setFormData(data => ({
      ...data,
      ...contact,
      ...updatedData,
    }));
  };

  const createItem = async () => {
    // show loading
    presentLoading({
      message: intl.formatMessage({
        id: 'form.contact.creating',
        defaultMessage: 'Creating Contact...',
      }),
    });

    // get the files data out of the form
    const [docData, filesToUpload] = await processData(
      formData,
      contact,
      publicKey.current || undefined,
    );

    // save the data to the DB
    await createFirestoreDocument(user, firebase, firestore, `contacts/${formData.id}`, docData)
      .then(async res => {
        // upload the files to the storage
        const uid = user.uid || res.uid;
        await uploadFiles(firebase, filesToUpload, uid, 'contact', docData.id as string);

        dismissLoading();
        updateFormData(docData);
        setEditMode(false);

        // redirect to the paper page
        if (contactId === 'new') {
          history.replace('/contacts/' + docData.id);
        }
      })
      .catch(err => {
        dismissLoading();
        console.error('Create Contact Error', err);
      });
  };

  const updateItem = async () => {
    // show loading
    presentLoading({
      message: intl.formatMessage({
        id: 'form.contact.updating',
        defaultMessage: 'Updating Contact...',
      }),
    });

    // get the files data out of the form
    const [docData, filesToUpload, filesToDelete] = await processData(
      formData,
      contact,
      publicKey.current || undefined,
    );

    // update the data in the DB
    await updateFirestoreDocument(user, firestore, `contacts/${formData.id}`, docData)
      .then(async () => {
        // upload new files to the storage
        await uploadFiles(firebase, filesToUpload, user.uid, 'contact', docData.id as string);

        // delete the removed files and their previews
        await deleteFiles(
          firebase,
          filesToDelete,
          user.uid,
          `/${user.uid}/contact/${contactId}`,
          contact,
        );

        dismissLoading();
        updateFormData(docData);
        setEditMode(false);
      })
      .catch(err => {
        dismissLoading();
        console.error('Update Contact Error', err);
      });
  };

  const deleteContact = async () => {
    // show loading
    presentLoading({
      message: intl.formatMessage({
        id: 'form.contact.deleting',
        defaultMessage: 'Deleting Contact...',
      }),
    });

    // files to delete are all the files we have]
    const filesToDelete = (contact?.files as TFieldValue)?.files;
    if (filesToDelete?.length) {
      await deleteFiles(
        firebase,
        filesToDelete,
        user.uid,
        `/${user.uid}/contact/${contactId}`,
        contact,
      );
    }

    // update the data in the DB
    await deleteFirestoreDocument(user, firestore, `contacts/${contactId}`)
      .then(async () => {
        // remove the message
        await dismissLoading();

        // redirect to the papers list
        history.replace('/contacts');
      })
      .catch(err => {
        dismissLoading();
        console.error('Update Contact Error', err);
      });
  };

  /**
   * Add paper to the stack
   */
  const addSelectedPaper = (paperId: string) => {
    if (contact) {
      if (
        contact.selectedPapers &&
        contact.selectedPapers.some((paper: any) => paper?.value === paperId)
      ) {
        // TODO: show a message that this paper is already attached
        return;
      }
      const updatedPapers: { value: string }[] = [
        {
          value: paperId,
        },
        ...(contact.selectedPapers || []),
      ];
      updateFirestoreDocument(user, firestore, `contacts/${contactId}`, {
        selectedPapers: updatedPapers,
      });
    }
  };

  /**
   * Remove paper from stack
   */
  const removeSelectedPaper = (paperId: string) => {
    if (
      contact?.selectedPapers &&
      contact.selectedPapers.some((paper: any) => paper?.value === paperId)
    ) {
      const updatedPapers: { value: string }[] = contact.selectedPapers.filter(
        (paper: any) => paper?.value !== paperId,
      );
      updateFirestoreDocument(user, firestore, `contacts/${contactId}`, {
        selectedPapers: updatedPapers,
      });
    }
  };

  /**
   * Reorder selected papers
   */
  const reorderSelectedPapers = (from: number, to: number) => {
    if (contact?.selectedPapers) {
      const updatedPapers: { value: string }[] = reorderArray(contact.selectedPapers, from, to);
      updateFirestoreDocument(user, firestore, `contacts/${contactId}`, {
        selectedPapers: updatedPapers,
      });
    }
  };

  return (
    <AppPage
      title={intl.formatMessage({ id: 'page.contact.title' }, { name })}
      header={
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/contacts" />
          </IonButtons>
          <IonTitle>
            {contactId === 'new'
              ? intl.formatMessage({ id: 'form.contact.new.title' })
              : contact
              ? name
              : intl.formatMessage({ id: 'page.contact.title.loading' })}
          </IonTitle>
          <ActionsToolbar
            desktop={true}
            open={true}
            presentMenu={presentMenu}
            isEditMode={editMode}
            updateButton={contactId === 'new' ? 'ui.buttons.create' : 'ui.buttons.update'}
            onSave={contactId === 'new' ? createItem : updateItem}
            cancelButton={contactId === 'new' ? undefined : 'ui.buttons.cancel'}
            onCancel={() => {
              setEditMode(false);
            }}
          />
        </IonToolbar>
      }
      path={`/contacts/${contactId}`}
    >
      {contact || contactId === 'new' ? (
        editMode ? (
          <IonList className="form-fields">
            {forms?.items && Array.isArray(forms?.items) && forms.items.length > 0 && (
              <ModalFields
                fields={fields?.item}
                form={forms.items[0].fields}
                data={formData}
                avatars={contact?.avatar || contact?.avatars}
                updateData={updateData}
              />
            )}
          </IonList>
        ) : (
          <>
            {contact?.avatar?.[0] ? (
              <div className="contact__avatar">
                <IonImg src={contact?.avatar[0].url.replace('{uid}', user.uid) || ''} />
              </div>
            ) : (
              contact?.avatars?.[0] && (
                <div className="contact__avatar">
                  <IonImg src={contact?.avatars[0].url.replace('{uid}', user.uid) || ''} />
                </div>
              )
            )}
            <h1 className="contact__names">
              {getValue(contact?.names) || getValue(contact?.displayName)}
              <IonIcon
                onClick={toggleFavorite}
                icon={isFavorite ? star : starOutline}
                color={isFavorite ? 'secondary' : 'medium'}
                className="favorite-star"
              />
            </h1>
            {isLocked && (
              <div style={{ textAlign: 'center', margin: '2rem' }}>
                <IonButton
                  mode="md"
                  shape="round"
                  fill="outline"
                  className="ion-activated"
                  onClick={requestAuthorization}
                >
                  <FormattedMessage
                    id="ui.buttons.unlock-the-data"
                    defaultMessage="Unlock the Data"
                  />
                </IonButton>
              </div>
            )}

            {contactPapers?.length > 0 && (
              <div className="ion-padding sticky-bar">
                <IonSegment
                  value={currentView}
                  onIonChange={evt =>
                    updateCurrentView(evt.detail.value === 'papers' ? 'papers' : 'info')
                  }
                  mode="ios"
                >
                  <IonSegmentButton value="info">
                    <IonLabel>
                      <FormattedMessage id="page.contact.view.info" defaultMessage="Details" />
                    </IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton value="papers">
                    <IonLabel>
                      <FormattedMessage
                        id="page.contact.view.papers"
                        defaultMessage="Papers"
                        values={{
                          count: contactPapers?.length || 0,
                        }}
                      />
                    </IonLabel>
                  </IonSegmentButton>
                </IonSegment>
              </div>
            )}
            {currentView === 'info' ? (
              <IonList className="details-list">
                {typeFields.item?.fields
                  ?.map((field: TFormFields | string) => {
                    return typeof field === 'string' && fields?.item?.[field]
                      ? fields.item[field]
                      : field;
                  })
                  .filter((field: TFormFields) => !!field && !field.hideFromDetails)
                  .map((field: TFormFields) => (
                    <Fragment key={field.id}>
                      {field.type === 'group' ? (
                        field.fields
                          ?.map((subField: TFormFields | string) => {
                            return typeof subField === 'string' && fields?.item?.[subField]
                              ? fields.item[subField]
                              : subField;
                          })
                          .filter(
                            (subField: TFormFields) =>
                              !!subField &&
                              !subField.hideFromDetails &&
                              contact?.[subField.id] &&
                              (!Array.isArray(contact[subField.id]) ||
                                contact[subField.id].filter((v: any) => !!v.value).length > 0),
                          )
                          .map((subField: TFormFields) => (
                            <IonItemGroup key={subField.id}>
                              <IonItemDivider mode="md" className="details__divider">
                                <IonLabel>
                                  {subField.label ? (
                                    _label(
                                      (Array.isArray(contact[subField.id]) &&
                                        contact[subField.id].length > 1 &&
                                        subField.labelPlural) ||
                                        subField.label,
                                      intl.locale,
                                    )
                                  ) : (
                                    <FormattedMessage
                                      id={getLabelId(
                                        subField as RecordTypeField,
                                        contact[subField.id],
                                      )}
                                    />
                                  )}
                                </IonLabel>
                              </IonItemDivider>
                              {Array.isArray(contact[subField.id]) ? (
                                contact[subField.id]
                                  .filter((value: TFieldValue) => value.value)
                                  .map((value: TFieldValue, idx: number, arr: TFieldValue[]) => (
                                    <DetailsItem
                                      name={subField.id}
                                      index={idx}
                                      itemId={contactId}
                                      type={subField.type}
                                      key={'value' + idx}
                                      lines={idx < arr.length - 1 ? 'inset' : 'none'}
                                      value={
                                        subField.type === 'date'
                                          ? getDateValue(value, undefined, undefined, intl.locale)
                                          : getValue(value)
                                      }
                                      copyValue={getValue(value)}
                                      label={getLabel(value)}
                                      actions={subField.actions}
                                      reminders={contactReminders}
                                      repeat={subField.repeat}
                                      createReminder={createReminder}
                                      deleteReminder={deleteReminder}
                                    />
                                  ))
                              ) : (
                                <DetailsItem
                                  name={subField.id}
                                  itemId={contactId}
                                  type={subField.type}
                                  lines="none"
                                  value={
                                    subField.type === 'date'
                                      ? getDateValue(
                                          contact[subField.id],
                                          undefined,
                                          undefined,
                                          intl.locale,
                                        )
                                      : getValue(contact[subField.id])
                                  }
                                  copyValue={getValue(contact[subField.id])}
                                  label={getLabel(contact[subField.id])}
                                  actions={subField.actions}
                                  reminders={contactReminders}
                                  repeat={subField.repeat}
                                  createReminder={createReminder}
                                  deleteReminder={deleteReminder}
                                />
                              )}
                            </IonItemGroup>
                          ))
                      ) : (
                        <DetailsItem
                          name={field.id}
                          itemId={contactId}
                          type={field.type}
                          lines="none"
                          value={
                            field.type === 'date'
                              ? getDateValue(contact?.[field.id], undefined, undefined, intl.locale)
                              : getValue(contact?.[field.id])
                          }
                          copyValue={getValue(contact?.[field.id])}
                          label={getLabel(contact?.[field.id])}
                          labelId={getLabelId(field as RecordTypeField, contact?.[field.id])}
                          actions={field.actions}
                          reminders={contactReminders}
                          repeat={field.repeat}
                          createReminder={createReminder}
                          deleteReminder={deleteReminder}
                          addPaper={addSelectedPaper}
                          removePaper={removeSelectedPaper}
                          reorderPapers={reorderSelectedPapers}
                          alwaysVisibleInDetails={field.alwaysVisibleInDetails}
                        />
                      )}
                    </Fragment>
                  ))}
              </IonList>
            ) : (
              <PapersList
                routerLink="/papers/"
                searchText=""
                papersToShow={contactPapers}
                periods="all"
                periodField="issuedOn"
                selectedPeriod={0}
              />
            )}
          </>
        )
      ) : (
        <LoadingScreen />
      )}
    </AppPage>
  );
};

export default Contact;
