import { PapersState } from '../models/Paper';
import { ContactsState } from '../models/Contact';
import { OCRQueueItem, OCRQueuePageState, OCRQueueState } from '../models/OCR';

export type TDataStore = {
  papers: PapersState;
  contacts: ContactsState;
  recognition: OCRQueueState;
  urls: {
    [fileName: string]: {
      fileId?: string;
      name?: string;
      url: string;
      pageFileName?: string;
      onDeviceUri?: string;
    };
  };
};

export const dataInitialState: TDataStore = {
  papers: {
    state: {
      isLoading: true,
      isEmpty: true,
      isLoaded: false,
      isNotFound: false,
      isNotLoaded: true,
    },
    items: {},
  },
  contacts: {
    state: {
      isLoading: true,
      isEmpty: true,
      isLoaded: false,
      isNotFound: false,
      isNotLoaded: true,
    },
    items: {},
  },
  recognition: {
    active: false,
    queue: [],
    papers: {},
  },
  urls: {},
};

export const dataReducer = (state: any = {}, action: any) => {
  switch (action.type) {
    case 'SET_PAPERS':
      return {
        ...state,
        papers: action.payload,
      };
    case 'SET_CONTACTS':
      return {
        ...state,
        contacts: action.payload,
      };
    case 'ADD_TO_OCR_QUEUE':
      return {
        ...state,
        recognition: {
          ...state.recognition,
          queue: [...state.recognition.queue, ...action.payload],
          papers: {
            ...state.recognition.papers,
            ...action.payload.reduce(
              (acc: { [paperId: string]: OCRQueuePageState }, item: OCRQueueItem) => {
                acc[item.pid] = {
                  ...(state.recognition.papers[item.pid] || {}),
                  ...(acc[item.pid] || {}),
                  [item.pageNo!]: 'waiting',
                };
                return acc;
              },
              {},
            ),
          },
        },
      };
    case 'SET_OCR_ACTIVE':
      return {
        ...state,
        recognition: {
          ...state.recognition,
          active: action.payload.active,
          papers: {
            ...state.recognition.papers,
            [action.payload.pid]: {
              ...state.recognition.papers[action.payload.pid],
              state: action.payload.active
                ? 'processing'
                : Object.keys(state.recognition.papers[action.payload.pid]).some(
                    page => state.recognition.papers[action.payload.pid][page] === 'waiting',
                  )
                ? 'waiting'
                : 'done',
              [action.payload.pageNo]: action.payload.active ? 'processing' : 'done',
            },
          },
        },
      };
    case 'REMOVE_FROM_OCR_QUEUE':
      return {
        ...state,
        recognition: {
          ...state.recognition,
          queue: state.recognition.queue.filter(
            (item: any) => item.pid !== action.payload.pid || item.pageNo !== action.payload.pageNo,
          ),
          papers: {
            ...state.recognition.state,
            [action.payload.pid]: {
              ...state.recognition.papers[action.payload.pid],
              [action.payload.pageNo]: 'done',
              state: Object.keys(state.recognition.papers[action.payload.pid]).some(
                page =>
                  page !== action.payload.pageNo &&
                  state.recognition.papers[action.payload.pid][page] === 'waiting',
              )
                ? 'waiting'
                : 'done',
            },
          },
        },
      };
    case 'ADD_URL':
      return {
        ...state,
        urls: {
          ...state.urls,
          [action.payload.fileName]: {
            ...state.urls[action.payload.fileName],
            fileId: action.payload.fileId,
            name: action.payload.name,
            url: action.payload.url,
            pageFileName: action.payload.pageFileName,
          },
        },
      };
    case 'ADD_DEVICE_URI':
      return {
        ...state,
        urls: {
          ...state.urls,
          [action.payload.fileName]: {
            ...state.urls[action.payload.fileName],
            onDeviceUri: action.payload.deviceUri,
          },
        },
      };
    case 'CLEAR_DATA':
      return dataInitialState;
    default:
      return state;
  }
};
