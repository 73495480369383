export type TUiStore = {
  showTabs: boolean;
  isDesktop: boolean;
  shrinkMenu: boolean;
  lang?: string;
  routerRef?: HTMLIonRouterOutletElement | null;
  privateKey?: string | null;
  password?: string | null;
  pin?: string | null;
  currencies?: { [key: string]: number };
  ocrScripts?: { [key: string]: number };
};

export const uiInitialState = {
  showTabs: true,
  isDesktop: true,
  shrinkMenu: false,
  lang: '',
  privateKey: null,
  password: null,
  pin: null,
  currencies: {
    EUR: 10,
    USD: 9,
    CHF: 4,
    GBP: 3,
    BGN: 2,
    RUB: 1,
  },
  ocrScripts: {
    eng: 1,
  },
};

export const uiReducer = (state = {} as TUiStore, action: any) => {
  switch (action.type) {
    case 'SHOW_TABS':
      return {
        ...state,
        showTabs: true,
      };
    case 'HIDE_TABS':
      return {
        ...state,
        showTabs: false,
      };
    case 'DESKTOP_MODE':
      return {
        ...state,
        isDesktop: true,
      };
    case 'MOBILE_MODE':
      return {
        ...state,
        isDesktop: false,
      };
    case 'EXPAND_MENU':
      return {
        ...state,
        shrinkMenu: false,
      };
    case 'SHRINK_MENU':
      return {
        ...state,
        shrinkMenu: true,
      };
    case 'SET_LANG':
      try {
        window.localStorage.setItem('lang', action.payload);
      } catch (e) {
        console.error('Error storing language in the local storage', action.payload);
      }
      return {
        ...state,
        lang: action.payload,
      };
    case 'SET_ROUTER_REF':
      return {
        ...state,
        routerRef: action.payload,
      };
    case 'SET_PRIVATE_KEY':
      return {
        ...state,
        privateKey: action.payload,
      };
    case 'REMOVE_PRIVATE_KEY':
      return {
        ...state,
        privateKey: null,
      };
    case 'SET_PASSWORD':
      return {
        ...state,
        password: action.payload,
      };
    case 'REMOVE_PASSWORD':
      return {
        ...state,
        password: null,
      };
    case 'SET_PIN':
      return {
        ...state,
        pin: action.payload,
      };
    case 'REMOVE_PIN':
      return {
        ...state,
        pin: null,
      };
    case 'DEAUTHORIZE':
    case 'CLEAR_DATA':
      return {
        ...state,
        privateKey: null,
        password: null,
        pin: null,
      };
    case 'SET_CURRENCIES':
      return {
        ...state,
        currencies: action.payload,
      };
    case 'SET_OCR_SCRIPTS':
      return {
        ...state,
        ocrScripts: action.payload,
      };

    default:
      return state;
  }
};
