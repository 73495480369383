import { useSelector, shallowEqual } from 'react-redux';
import { useRequestStateFor } from './useRequestStateFor';
import { RequestState } from '../models/RequestState';
import { useEffect, useMemo, useState } from 'react';

type RequestedOrderedCollection = {
  items: any[];
  state: RequestState;
};
export function useOrderedCollection(
  collection: string,
  options?: { [propName: string]: string },
): RequestedOrderedCollection {
  const requestState = useRequestStateFor(collection);
  const items = useSelector((state: any) => state.firestore.ordered[collection], shallowEqual);

  // memoize options
  const [hookOptions, setHookOptions] = useState(options);
  useEffect(() => {
    if (JSON.stringify(options) !== JSON.stringify(hookOptions)) {
      setHookOptions(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  // memoize state
  const state = useMemo(
    () => ({
      ...requestState,
      isNotFound: requestState.isLoaded && !items,
      isEmpty: requestState.isLoaded && !items.length,
    }),
    [requestState, items],
  );

  // memoize response
  const [orderedCollection, setOrderedCollection] = useState<RequestedOrderedCollection>({
    items: [],
    state,
  });

  useEffect(() => {
    setOrderedCollection({
      items: items?.filter((item: any) => {
        let result = true;
        if (hookOptions) {
          Object.keys(hookOptions).forEach(propName => {
            if (item[propName] !== hookOptions[propName]) {
              result = false;
            }
          });
        }
        return result;
      }),
      state,
    });
  }, [items, state, hookOptions]);

  return orderedCollection;
}
