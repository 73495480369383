// second link to Firebase to call the functions
import fb from 'firebase/app';

// hooks
import { FormEvent, useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';

// utils
import { str2key } from '../../utilities/encryption';

// components
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonList,
  IonModal,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToolbar,
  isPlatform,
} from '@ionic/react';
import { FormattedMessage, useIntl } from 'react-intl';
import TextField from '../../forms/TextField';
import PinCode from '../PinCode';

// icons
import { closeOutline } from 'ionicons/icons';

// types
import { TStoreState } from '../../store/store';
import { NativeBiometric } from 'capacitor-native-biometric';

type TAuthorizationModalProps = {
  isOpen: boolean;
  onDidDismiss: (success: boolean) => void;
  onAuthorized?: () => void;
  fieldName?: string;
};

const AuthorizationModal: React.FC<TAuthorizationModalProps> = (
  props: TAuthorizationModalProps,
) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const firebase = useFirebase();

  const routerRef = useSelector((state: TStoreState) => state.ui?.routerRef);

  const uid = useSelector((state: TStoreState) => state.firebase?.auth?.uid);
  const lastAuthUsed = useSelector((state: TStoreState) => state.firebase?.profile?.lastAuthUsed);

  const [buttonDisabled, setButtonDisabled] = useState(false);

  const passwordRef = useRef<any>(null);

  const password = useRef('');
  const pin = useRef('');

  const [currentAuthForm, setCurrentAuthForm] = useState<string | undefined>();
  const [error, setError] = useState<string | undefined>('');

  const storedPrivateKey = useSelector((state: TStoreState) => state.ui.privateKey);
  const storedPassword = useSelector((state: TStoreState) => state.ui.password);
  const storedPin = useSelector((state: TStoreState) => state.ui.pin);

  const profile = useSelector((state: TStoreState) => state.firebase?.profile, shallowEqual);
  const device = useSelector((state: TStoreState) => state.device, shallowEqual);

  const [thisDevice, setThisDevice] = useState<any>();
  useEffect(() => {
    if (device?.deviceId && profile?.devices?.[device.deviceId]) {
      setThisDevice(profile.devices[device.deviceId]);
    }
  }, [profile, device]);

  useEffect(() => {
    if (props.isOpen && thisDevice?.biometryKeyStored) {
      setCurrentAuthForm('biometrics');
      biometricsAction();
    }
  }, [props.isOpen, thisDevice]);

  const [repeatRequest, setRepeatRequest] = useState(false);
  useEffect(() => {
    if (storedPrivateKey && (storedPassword || storedPin)) {
      setRepeatRequest(true);
      setCurrentAuthForm(storedPassword ? 'password' : 'pin');
    } else {
      setRepeatRequest(false);
      pin.current = '';
      password.current = '';
    }
  }, [storedPrivateKey, storedPassword, storedPin]);

  const clearErrors = () => {
    setError('');
  };

  const processForm = (evt: FormEvent) => {
    evt.defaultPrevented = true;
    evt.preventDefault();
    evt.stopPropagation();
    authAction();
  };

  const updatePassword = (value: string) => {
    password.current = value;
    pin.current = '';
    clearErrors();
  };

  const updatePinCode = (value: string) => {
    password.current = '';
    pin.current = value;
    clearErrors();
  };

  const authAction = async () => {
    if (repeatRequest) {
      if (
        (storedPassword && storedPassword === password.current) ||
        (storedPin && storedPin === pin.current)
      ) {
        if (props.onAuthorized) props.onAuthorized();
        pin.current = '';
        password.current = '';
        props.onDidDismiss(true);
      } else {
        setError('invalid-password');
      }

      return;
    }

    setButtonDisabled(true);
    clearErrors();
    const getUserKeysFn = fb.app().functions('europe-west3').httpsCallable('users-getKey');
    const request: { uid: string; pin?: string; password?: string } = { uid };

    if (pin.current !== '') {
      request['pin'] = pin.current;
    }
    if (password.current !== '') {
      request['password'] = password.current;
    }

    await getUserKeysFn(request)
      .then(async key => {
        if (key?.data && typeof key?.data === 'string') {
          // we have the key, now we have to convert it and put it in the store
          const privateKey = await str2key(key.data);
          if (privateKey) {
            applyKey(key.data);
            props.onDidDismiss(true);
          } else {
            setError('invalid-key');
          }
        }

        setButtonDisabled(false);
      })
      .catch(err => {
        console.error('error getting key', err.code);
        setButtonDisabled(false);
        setError(err.code);
      });
  };

  /**
   * Biometrics auth
   */
  const biometricsAction = async () => {
    if (isPlatform('capacitor')) {
      NativeBiometric.verifyIdentity({
        reason: intl.formatMessage({
          id: 'ui.buttons.unlock-the-data',
          defaultMessage: 'Unlock the Data',
        }),
        title: intl.formatMessage({
          id: 'ui.biometric.title',
          defaultMessage: 'Verify your identity',
        }),
      }).then(() => {
        return NativeBiometric.getCredentials({
          server: 'https://thepapers.app',
        })
          .then(async value => {
            if (value.username === uid) {
              const privateKey = await str2key(value.password);
              if (privateKey) {
                applyKey(value.password);
                props.onDidDismiss(true);
              } else {
                setError('invalid-key');
              }
            } else {
              // in case that on this device another user key is stored
              // we have to delete the key and ask the user to re-setup the biometrics
              if (device?.deviceId) {
                firebase.updateProfile({
                  devices: {
                    ...(profile.devices || {}),
                    [device.deviceId]: {
                      ...(profile.devices?.[device.deviceId] || {}),
                      biometryKeyStored: false,
                    },
                  },
                });
              }

              setError('invalid-stored-credentials');
            }
          })
          .catch(err => {
            console.error('error getting stored credentials', err);
            setError('invalid-stored-credentials');
          });
      });
    }
  };

  const applyKey = (privateKey: string) => {
    // storing the key
    dispatch({
      type: 'SET_PRIVATE_KEY',
      payload: privateKey,
    });
    // storing the pin or password for future use
    // if we still have the private key – no need to go to the server every time
    if (pin.current !== '') {
      dispatch({
        type: 'SET_PIN',
        payload: pin.current,
      });
      firebase.updateProfile({ lastAuthUsed: 'pin' });
    }
    if (password.current !== '') {
      dispatch({
        type: 'SET_PASSWORD',
        payload: password.current,
      });
      firebase.updateProfile({ lastAuthUsed: 'password' });
    }
    pin.current = '';
    password.current = '';
  };

  return (
    <IonModal
      mode="md"
      isOpen={props.isOpen}
      onDidDismiss={() => props.onDidDismiss(false)}
      presentingElement={routerRef || undefined}
    >
      <IonHeader mode="md">
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => props.onDidDismiss(false)}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>
            <FormattedMessage
              id="modal.authorization.title"
              defaultMessage="Authorize to unlock the data"
            />
          </IonTitle>
        </IonToolbar>
        <IonToolbar className="segment-toolbar">
          <IonSegment
            value={currentAuthForm || lastAuthUsed || 'password'}
            onIonChange={evt => {
              clearErrors();
              setCurrentAuthForm(evt.detail.value);
            }}
            mode="ios"
          >
            {(!repeatRequest || storedPassword) && (
              <IonSegmentButton value="password">
                <IonLabel>
                  <FormattedMessage id="modal.auth.type.password" defaultMessage="Password" />
                </IonLabel>
              </IonSegmentButton>
            )}
            {(!repeatRequest || storedPin) && (
              <IonSegmentButton value="pin">
                <IonLabel>
                  <FormattedMessage id="modal.auth.type.pin" defaultMessage="PIN" />
                </IonLabel>
              </IonSegmentButton>
            )}
            <IonSegmentButton value="biometrics" disabled={!thisDevice?.biometryKeyStored}>
              <IonLabel>
                <FormattedMessage id="modal.auth.type.biometrics" defaultMessage="Biometrics" />
              </IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <form
          onSubmit={processForm}
          style={{
            display: 'flex',
            width: '100%',
            minHeight: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {(currentAuthForm || lastAuthUsed || 'password') === 'password' && (
            <IonList style={{ margin: '1rem 0 3rem', width: '80%' }}>
              <TextField
                ref={passwordRef}
                name="password"
                value={password.current}
                type="password"
                onValueUpdate={value => updatePassword(value.value?.toString() || '')}
                label={intl.formatMessage({ id: 'ui.field.account-password.label' })}
                autofocus={true}
                onNext={authAction}
                error={
                  error ? intl.formatMessage({ id: `auth.errors.password.${error}` }) : undefined
                }
              />
              <IonButton
                size="default"
                expand="block"
                mode="ios"
                color="secondary"
                style={{
                  margin: '1.6rem calc(var(--padding-start) + var(--ion-safe-area-left, 0px))',
                }}
                onClick={authAction}
                disabled={buttonDisabled}
              >
                <FormattedMessage
                  id="ui.buttons.unlock-the-data"
                  defaultMessage="Unlock the Data"
                />
              </IonButton>
            </IonList>
          )}
          {(currentAuthForm || lastAuthUsed) === 'pin' && (
            <PinCode
              value={pin.current}
              text={intl.formatMessage({
                id: 'modal.auth.pin.label',
                defaultMessage: 'Your PIN contains at least 4 digits',
              })}
              error={error ? `auth.errors.pin.${error}` : ''}
              onChange={updatePinCode}
              onEnter={authAction}
              disabled={buttonDisabled}
            />
          )}
          {(currentAuthForm || lastAuthUsed) === 'biometrics' && thisDevice?.biometryKeyStored && (
            <div>
              <div className="pin-text">
                Подтвърдете личноста си чрез биометрия или превключете за да използвате ПИН код или
                парола.
              </div>
              <IonList style={{ margin: '1rem auto 1rem', width: '80%' }}>
                <IonButton
                  size="default"
                  expand="block"
                  mode="ios"
                  color="secondary"
                  style={{
                    margin: '1.6rem calc(var(--padding-start) + var(--ion-safe-area-left, 0px))',
                  }}
                  onClick={biometricsAction}
                  disabled={buttonDisabled}
                >
                  <FormattedMessage
                    id="ui.buttons.unlock-the-data"
                    defaultMessage="Unlock the Data"
                  />
                </IonButton>
              </IonList>
              {error && (
                <div className="pin-error">
                  <FormattedMessage id={`auth.errors.biometrics.${error}`} />
                </div>
              )}
            </div>
          )}
        </form>
      </IonContent>
    </IonModal>
  );
};

export default AuthorizationModal;
