import {
  IonBackButton,
  IonButtons,
  IonItem,
  IonLabel,
  IonList,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { FormattedMessage, useIntl } from 'react-intl';
import AppPage from '../components/AppPage';

const CustomTypes: React.FC = () => {
  const intl = useIntl();

  return (
    <AppPage
      title={intl.formatMessage({ id: 'page.custom-types.title' })}
      header={
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/settings" />
          </IonButtons>
          <IonTitle>
            <FormattedMessage id="page.custom-types.title" defaultMessage="Custom Paper Types" />
          </IonTitle>
        </IonToolbar>
      }
    >
      <IonList>
        <IonItem>
          <IonLabel>I have no idea how this page should look</IonLabel>
        </IonItem>
      </IonList>
    </AppPage>
  );
};

export default CustomTypes;
