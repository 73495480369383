import { useRef, useState } from 'react';

// components
import {
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonPopover,
  IonList,
  IonItem,
  IonLabel,
  IonItemDivider,
} from '@ionic/react';

// icons
import { layersOutline, calendarOutline, filter } from 'ionicons/icons';

import './FiltersToolbar.scss';

type TPopoverProps = {
  isOpen: boolean;
  event: Event | undefined;
};

export type TFiltersItems = {
  // value sent to the parent component
  value:
    | 'none'
    | 'form'
    | 'types'
    | 'contacts'
    | 'all'
    | 'yearly'
    | 'monthly'
    | 'createdAt'
    | 'issuedOn'
    | 'servicePeriod'
    | 'expiresOn'
    | 'payUntil'
    | 'paidOn'
    | 'visible';
  title: string; // title for the item in the popover list
  label?: string; // title shown on the button
};

type TFiltersToolbarProps = {
  filterIcon?: string;
  groupingTitle?: string;
  groupingItems?: TFiltersItems[];
  groupingValue?: string;
  onGroupingChange?: Function;

  filterTitle?: string;
  filterItems?: TFiltersItems[];
  filterValue?: string;
  onFilterChange?: Function;

  periodTitle?: string;
  periodItems?: TFiltersItems[];
  periodValue?: string;
  onPeriodChange?: Function;

  periodIcon?: string;
  periodFieldTitle?: string;
  periodFieldItems?: TFiltersItems[];
  periodFieldValue?: string;
  onPeriodFieldChange?: Function;
};

const FiltersToolbar = (props: TFiltersToolbarProps) => {
  // filter popover
  const filterPopoverRef = useRef<any>(null);
  const [filterPopover, setFilterPopover] = useState<TPopoverProps>({
    isOpen: false,
    event: undefined,
  });
  const dismissFilterPopover = () => {
    filterPopoverRef.current?.dismiss();
    setFilterPopover({ isOpen: false, event: undefined });
  };
  const setFilterValue = (value: string) => {
    if (props.onFilterChange) {
      props.onFilterChange(value);
    }
    dismissFilterPopover();
  };
  const setGroupingValue = (value: string) => {
    if (props.onGroupingChange) {
      props.onGroupingChange(value);
    }
    dismissFilterPopover();
  };

  // period popover
  const periodPopoverRef = useRef<any>(null);
  const [periodPopover, setPeriodPopover] = useState<TPopoverProps>({
    isOpen: false,
    event: undefined,
  });
  const dismissPeriodPopover = () => {
    periodPopoverRef.current?.dismiss();
    setPeriodPopover({ isOpen: false, event: undefined });
  };
  const setPeriodValue = (value: string) => {
    if (props.onPeriodChange) {
      props.onPeriodChange(value);
    }
    dismissPeriodPopover();
  };

  const setPeriodFieldValue = (value: string) => {
    if (props.onPeriodFieldChange) {
      props.onPeriodFieldChange(value);
    }
    dismissPeriodPopover();
  };

  return (
    <IonToolbar className="filters-toolbar">
      {(props.groupingItems?.length || props.filterItems?.length) && (
        <IonButtons slot="start">
          <IonButton
            className="filters-toolbar--button"
            color="dark"
            size="small"
            onClick={e => setFilterPopover({ isOpen: true, event: e.nativeEvent })}
          >
            <IonIcon
              icon={props.filterIcon === 'sort' ? filter : layersOutline}
              slot="start"
              className="filters-toolbar--icon"
            />
            {
              props.groupingItems?.find(groupingItem => groupingItem.value === props.groupingValue)
                ?.label
            }
          </IonButton>
          <IonPopover {...filterPopover} ref={filterPopoverRef} onDidDismiss={dismissFilterPopover}>
            <IonList>
              {props.groupingTitle && (
                <IonItemDivider mode="md">{props.groupingTitle}</IonItemDivider>
              )}
              {props.groupingItems?.map((groupingItem, idx, arr) => (
                <IonItem
                  key={groupingItem.value}
                  button
                  detail={false}
                  onClick={() => setGroupingValue(groupingItem.value)}
                  lines={idx === arr.length - 1 ? 'none' : 'inset'}
                  className={
                    groupingItem.value === props.groupingValue
                      ? 'filters-toolbar--selected-item'
                      : ''
                  }
                >
                  <IonLabel>{groupingItem.title}</IonLabel>
                </IonItem>
              ))}
              {props.filterTitle && <IonItemDivider mode="md">{props.filterTitle}</IonItemDivider>}
              {props.filterItems?.map((filterItem, idx, arr) => (
                <IonItem
                  key={filterItem.value}
                  button
                  detail={false}
                  onClick={() => setFilterValue(filterItem.value)}
                  lines={idx === arr.length - 1 ? 'none' : 'inset'}
                  className={
                    filterItem.value === props.filterValue ? 'filters-toolbar--selected-item' : ''
                  }
                >
                  <IonLabel>{filterItem.title}</IonLabel>
                </IonItem>
              ))}
            </IonList>
          </IonPopover>
        </IonButtons>
      )}

      {props.periodItems && props.periodItems.length && (
        <IonButtons slot="end">
          <IonButton
            className="filters-toolbar--button"
            color="dark"
            size="small"
            onClick={e => setPeriodPopover({ isOpen: true, event: e.nativeEvent })}
          >
            <IonIcon
              icon={props.periodIcon || calendarOutline}
              slot="end"
              className="filters-toolbar--icon"
            />
            {props.periodItems.find(periodItem => periodItem.value === props.periodValue)?.label}
          </IonButton>
          <IonPopover {...periodPopover} ref={periodPopoverRef} onDidDismiss={dismissPeriodPopover}>
            <IonList>
              {props.periodTitle && <IonItemDivider mode="md">{props.periodTitle}</IonItemDivider>}
              {props.periodItems.map((periodItem, idx, arr) => (
                <IonItem
                  key={periodItem.value}
                  button
                  detail={false}
                  onClick={() => setPeriodValue(periodItem.value)}
                  lines={!props.periodFieldTitle && idx === arr.length - 1 ? 'none' : 'inset'}
                  className={
                    periodItem.value === props.periodValue ? 'filters-toolbar--selected-item' : ''
                  }
                >
                  <IonLabel>{periodItem.title}</IonLabel>
                </IonItem>
              ))}
              {props.periodFieldTitle && (
                <IonItemDivider mode="md">{props.periodFieldTitle}</IonItemDivider>
              )}
              {props.periodFieldItems?.map((periodFieldItem, idx, arr) => (
                <IonItem
                  key={periodFieldItem.value}
                  button
                  detail={false}
                  onClick={() => setPeriodFieldValue(periodFieldItem.value)}
                  lines={idx === arr.length - 1 ? 'none' : 'inset'}
                  className={
                    periodFieldItem.value === props.periodFieldValue
                      ? 'filters-toolbar--selected-item'
                      : ''
                  }
                >
                  <IonLabel>{periodFieldItem.title}</IonLabel>
                </IonItem>
              ))}
            </IonList>
          </IonPopover>
        </IonButtons>
      )}
    </IonToolbar>
  );
};

export default FiltersToolbar;
