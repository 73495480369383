// hooks
import { useState } from 'react';
import { useIntl } from 'react-intl';

// components
import ModalForm from '../components/ModalForm';
import StacksList from '../components/StacksList';
import { IonFab, IonFabButton, IonIcon } from '@ionic/react';
import StackModal from './StackModal';

// types
import { add } from 'ionicons/icons';

type TLinkContactModalProps = {
  opened: boolean;
  onDismiss: Function;
  setStackId?: (stackId: string) => void;
  stackId?: string | number | null;
  title?: string;
};

const LinkStackModal: React.FC<TLinkContactModalProps> = (props: TLinkContactModalProps) => {
  const intl = useIntl();

  const selectStack = (stackId: string) => {
    if (props.setStackId) {
      props.setStackId(stackId);
    }
    if (props.onDismiss) {
      props.onDismiss();
    }
  };

  /**
   * Create New Stack
   */
  const [formModalOpened, setFormModalOpened] = useState(false);

  const showCreateModal = () => {
    setFormModalOpened(true);
  };

  const hideCreateModal = () => {
    setFormModalOpened(false);
  };

  return (
    <ModalForm
      {...props}
      title={
        props.title ||
        intl.formatMessage({
          id: 'form.link-stack.title',
          defaultMessage: 'Choose a Stack to Link',
        })
      }
    >
      <StacksList searchText="" clickHandler={selectStack} />

      <IonFab vertical="bottom" horizontal="end" slot="fixed" className="modal-action">
        <IonFabButton mode="ios" onClick={showCreateModal} color="tertiary">
          <IonIcon icon={add} />
        </IonFabButton>
      </IonFab>

      <StackModal opened={formModalOpened} onDismiss={hideCreateModal} onCreateNew={selectStack} />
    </ModalForm>
  );
};

export default LinkStackModal;
