// hooks
import { useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { emitCustomEvent } from 'react-custom-events';
import { useFirestore } from 'react-redux-firebase';

// utils
import { updateCategoriesSettings, updateHiddenTips } from '../../thunks/settings';
import { nanoid } from 'nanoid';

// components
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonList,
  IonModal,
  IonReorder,
  IonReorderGroup,
  IonTitle,
  IonToolbar,
  useIonAlert,
} from '@ionic/react';
import LoadingScreen from '../LoadingScreen';

// icons
import { addOutline, closeOutline, removeCircleOutline } from 'ionicons/icons';
import swipeIcon from '../../assets/horizontal-swipe.svg';

// types
import { TStoreState } from '../../store/store';
import { Category } from '../../models/Category';

type TCategoriesModalProps = {
  isOpen: boolean;
  onDidDismiss: () => void;
};

const CategoriesModal: React.FC<TCategoriesModalProps> = (props: TCategoriesModalProps) => {
  const routerRef = useSelector((state: any) => state.ui?.routerRef);

  const intl = useIntl();
  const firestore = useFirestore();

  /**
   * Load custom categories from the profile
   */
  const profile = useSelector((state: TStoreState) => state.firebase?.profile, shallowEqual);
  const user = useSelector((state: any) => state.firebase?.auth);
  const categories = useSelector(
    (state: TStoreState) => state.settings?.categories || [],
    shallowEqual,
  );
  const isTipHidden = useSelector(
    (state: TStoreState) => state.settings?.hiddenTips?.categoriesModal || false,
  );

  const showAuthModal = () => {
    emitCustomEvent('showModal', 'registration');
  };

  /**
   * Reordering the categories
   *
   * @param event
   */
  const handleReorder = (event: any) => {
    const { from, to } = event.detail;
    // reorder the categories array and then save it to the settings
    const updatedCategories = [...categories];
    const [removed] = updatedCategories.splice(from, 1);
    updatedCategories.splice(to, 0, removed);
    updateCategoriesSettings(firestore, user.uid, updatedCategories);
    event.detail.complete(true);
  };

  /**
   * Adding a new category
   */
  const [addNewCategory, setAddNewCategory] = useState(false);
  const newCategoryName = useRef<string | null | undefined>(null);
  const updateNewCategoryName = (value: string | null | undefined) => {
    newCategoryName.current = value;
    // add new category to the top of the categories array and then save it to the settings
    const newCategory: Category = {
      value: makeValueFromName(value),
      label: value || 'Untitled category',
      system: false,
    };
    const updatedCategories = [newCategory, ...categories];
    updateCategoriesSettings(firestore, user.uid, updatedCategories);
  };

  const makeValueFromName = (name: string | null | undefined) => {
    return name?.toLowerCase().replace(/[^a-z0-9]/g, '-') || nanoid(8);
  };

  /**
   * Showing a category
   */
  const showCategory = (event: any, categoryValue: string) => {
    const updatedCategories = categories.map(category => {
      if (category.value === categoryValue) {
        return { ...category, hidden: false };
      }
      return category;
    });
    updateCategoriesSettings(firestore, user.uid, updatedCategories);
    // closing the sliding item
    event.target?.parentNode?.close?.();
    event.target?.parentNode?.parentNode?.close?.();
  };

  /**
   * Hiding a category
   */
  const hideCategory = (event: any, categoryValue: string) => {
    const updatedCategories = categories.map(category => {
      if (category.value === categoryValue) {
        return { ...category, hidden: true };
      }
      return category;
    });
    updateCategoriesSettings(firestore, user.uid, updatedCategories);
    // closing the sliding item
    event.target?.parentNode?.close?.();
    event.target?.parentNode?.parentNode?.close?.();
  };

  /**
   * Deleting a category
   */
  const [presentAlert] = useIonAlert();

  const askToDeleteCategory = (event: any, categoryValue: string) => {
    presentAlert({
      header: intl.formatMessage({ id: 'categories.delete-alert.title' }),
      message: intl.formatMessage({ id: 'categories.delete-alert.message' }),
      buttons: [
        intl.formatMessage({ id: 'ui.buttons.cancel' }),
        {
          text: intl.formatMessage({ id: 'ui.buttons.remove' }),
          handler: () => deleteCategory(event, categoryValue),
        },
      ],
    });
  };

  const deleteCategory = (event: any, categoryValue: string) => {
    const updatedCategories = categories.filter(category => category.value !== categoryValue);
    updateCategoriesSettings(firestore, user.uid, updatedCategories);
    // closing the sliding item
    event.target?.parentNode?.close?.();
    event.target?.parentNode?.parentNode?.close?.();
  };

  /**
   * Editing categories
   * there should be a possibility to edit more than one category at a time
   */
  const [renamingCategories, setRenamingCategories] = useState<{ [catId: string]: string }>({});
  const renameCategory = (value: string, label: string) => {
    const updatedCategories = categories.map(category => {
      if (category.value === value) {
        return { ...category, label };
      }
      return category;
    });
    updateCategoriesSettings(firestore, user.uid, updatedCategories);
    setRenamingCategories(values => {
      delete values[value];
      return { ...values };
    });
  };

  const renderItem = (category: Category) => {
    const label =
      category.label && category.label !== ''
        ? category.label
        : category.labelId
        ? intl.formatMessage({ id: category.labelId })
        : category.value;

    if (renamingCategories[category.value]) {
      return (
        <IonItem mode="md" detail={false} key={category.value}>
          <IonButton
            slot="start"
            fill="clear"
            color="dark"
            style={{ marginRight: '4px', marginLeft: '-6px' }}
            onClick={() => {
              setRenamingCategories(values => {
                delete values[category.value];
                return { ...values };
              });
            }}
          >
            <IonIcon icon={closeOutline} slot="icon-only" />
          </IonButton>
          <IonInput
            className="text-field"
            style={{ marginTop: 0 }}
            onIonChange={e => {
              setRenamingCategories(values => ({
                ...values,
                [category.value]: e.detail.value || '',
              }));
            }}
            value={renamingCategories[category.value] || label}
            inputmode="text"
            enterkeyhint="send"
            aria-label={intl.formatMessage(
              { id: 'modal.categories.rename.placeholder' },
              { label },
            )}
            placeholder={intl.formatMessage(
              { id: 'modal.categories.rename.placeholder' },
              { label },
            )}
            clearInput={true}
          ></IonInput>
          <IonButton
            slot="end"
            fill="outline"
            color="primary"
            style={{ marginRight: '-18px', marginLeft: '12px' }}
            onClick={() => {
              renameCategory(category.value, renamingCategories[category.value]);
            }}
          >
            <FormattedMessage id="ui.buttons.update" defaultMessage="Update" />
          </IonButton>
          <IonReorder slot="end"></IonReorder>
        </IonItem>
      );
    }
    return (
      <IonItemSliding key={category.value}>
        <IonItem detail={false}>
          <IonLabel color={category.hidden ? 'medium' : 'dark'}>{label}</IonLabel>
          <IonReorder slot="end"></IonReorder>
        </IonItem>
        <IonItemOptions side="start" onIonSwipe={evt => showCategory(evt, category.value)}>
          <IonItemOption
            color="tertiary"
            expandable
            disabled={!category.hidden}
            onClick={evt => showCategory(evt, category.value)}
          >
            <FormattedMessage id="ui.buttons.show" defaultMessage="Show" />
          </IonItemOption>
          <IonItemOption
            color="secondary"
            onClick={() =>
              setRenamingCategories(values => ({ ...values, [category.value]: label }))
            }
          >
            <FormattedMessage id="ui.buttons.rename" defaultMessage="Rename" />
          </IonItemOption>
        </IonItemOptions>
        <IonItemOptions side="end" onIonSwipe={evt => hideCategory(evt, category.value)}>
          {!category.system && (
            <IonItemOption color="danger" onClick={evt => askToDeleteCategory(evt, category.value)}>
              <FormattedMessage id="ui.buttons.remove" defaultMessage="Remove" />
            </IonItemOption>
          )}
          <IonItemOption
            color="tertiary"
            expandable
            disabled={!!category.hidden}
            onClick={evt => hideCategory(evt, category.value)}
          >
            <FormattedMessage id="ui.buttons.hide" defaultMessage="Hide" />
          </IonItemOption>
        </IonItemOptions>
      </IonItemSliding>
    );
  };

  return (
    <IonModal
      mode="md"
      isOpen={props.isOpen}
      onDidDismiss={props.onDidDismiss}
      presentingElement={routerRef}
    >
      <IonHeader mode="md">
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={props.onDidDismiss}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>
            <FormattedMessage id="modal.categories.title" defaultMessage="Categories" />
          </IonTitle>
          <IonButton
            slot="end"
            fill="outline"
            onClick={() => {
              setAddNewCategory(true);
            }}
            size="small"
            style={{ marginRight: '18px' }}
          >
            <IonIcon icon={addOutline} slot="start" />
            <FormattedMessage id="ui.buttons.add" defaultMessage="Add" />
          </IonButton>
        </IonToolbar>
      </IonHeader>
      {categories ? (
        <>
          {user?.isAnonymous ||
          !profile ||
          profile.isEmpty === true ||
          profile.account === 'anonymous' ? (
            <>
              <IonContent>
                <p className="modal-description-text">
                  <FormattedMessage
                    id="modal.currencies.need-account"
                    defaultMessage="You need to have an account to use this feature."
                  />
                </p>
              </IonContent>
              <IonFooter>
                <p className="modal-footer-button">
                  <IonButton
                    onClick={showAuthModal}
                    mode="md"
                    shape="round"
                    fill="outline"
                    className="ion-activated"
                  >
                    <IonLabel>
                      <FormattedMessage
                        id="ui.buttons.login-or-register"
                        defaultMessage="Register or Sign In"
                      />
                    </IonLabel>
                  </IonButton>
                </p>
              </IonFooter>
            </>
          ) : (
            <IonContent>
              {!isTipHidden && (
                <div className="tip tip--with-icon tip--with-close">
                  <IonButton
                    className="tip--close"
                    size="small"
                    fill="clear"
                    color="dark"
                    onClick={() => updateHiddenTips(firestore, user.uid, { categoriesModal: true })}
                  >
                    <IonIcon icon={closeOutline} slot="end" />
                    <FormattedMessage id="ui.buttons.hide-lowercase" defaultMessage="hide" />
                  </IonButton>
                  <IonIcon icon={swipeIcon} className="tip--icon" />
                  <div className="tip-text" style={{ flex: 1 }}>
                    <FormattedMessage
                      id="modal.categories.tip.1"
                      defaultMessage="To reorder categories, drag them vertically."
                    />
                    <br />
                    <FormattedMessage
                      id="modal.categories.tip.2"
                      defaultMessage="If you want to toggle their visibility, rename or delete — swipe left or right."
                    />
                  </div>
                </div>
              )}
              <IonList>
                {addNewCategory && (
                  <IonItem mode="md" detail={false}>
                    <IonButton
                      slot="start"
                      fill="clear"
                      color="danger"
                      style={{ marginRight: '4px', marginLeft: '-6px' }}
                      onClick={() => {
                        setAddNewCategory(false);
                      }}
                    >
                      <IonIcon icon={removeCircleOutline} slot="icon-only" />
                    </IonButton>
                    <IonInput
                      className="text-field"
                      style={{ marginTop: 0 }}
                      onIonChange={e => {
                        updateNewCategoryName(e.detail.value);
                      }}
                      value={newCategoryName.current}
                      inputmode="text"
                      enterkeyhint="send"
                      aria-label={intl.formatMessage({ id: 'modal.categories.new.placeholder' })}
                      placeholder={intl.formatMessage({ id: 'modal.categories.new.placeholder' })}
                      clearInput={true}
                    ></IonInput>
                    <IonButton slot="end" color="primary" fill="outline" className="ion-activated">
                      Add
                    </IonButton>
                  </IonItem>
                )}
                <IonReorderGroup disabled={false} onIonItemReorder={handleReorder}>
                  {categories.map(category => renderItem(category))}
                </IonReorderGroup>
              </IonList>
            </IonContent>
          )}
        </>
      ) : (
        <LoadingScreen />
      )}
    </IonModal>
  );
};

export default CategoriesModal;
