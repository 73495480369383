import { TFieldValue, TFieldValues, TFilePreview } from '../models/FieldValues';

/**
 * Generate a new crypto key pair (piblic and private) key
 *
 * @returns Promise<{ publicKey: CryptoKey; privateKey: CryptoKey }>
 */
export const generateKeyPair = async (): Promise<CryptoKeyPair> => {
  return window.crypto.subtle.generateKey(
    {
      name: 'RSA-OAEP',
      // Consider using a 4096-bit key
      modulusLength: 2048,
      publicExponent: new Uint8Array([1, 0, 1]),
      hash: 'SHA-256',
    },
    true,
    ['encrypt', 'decrypt'],
  );
};

/**
 * Generate a new crypto key for symmetric encriptions
 * used when the message is longer than 200 chars
 *
 * @returns Promise<{}>
 */
export const generateKey = async (): Promise<CryptoKey> => {
  return window.crypto.subtle.generateKey(
    {
      name: 'AES-GCM',
      length: 256,
    },
    true,
    ['encrypt', 'decrypt'],
  );
};

/**
 * Encode the message in a form we can use for the encryption
 *
 * @param message  string that needs to be encoded
 * @returns Uint8Array
 */
const encodeMessage = (message: string | number): Uint8Array => {
  let encoder = new TextEncoder();
  return encoder.encode(message.toString());
};

/**
 * Decodes the message to the expected string format
 *
 * @param message
 * @returns
 */
const decodeMessage = (message: Uint8Array | ArrayBuffer): string => {
  const decoder = new TextDecoder('utf8');
  return decoder.decode(message);
};

/**
 * Converts encoded data to a string we can put in the DB
 *
 * @param buffer
 * @returns string
 */
export const arrayBufferToBase64 = (buffer: ArrayBuffer): string => {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

/**
 * Converts a string to a message we can decrypt
 *
 * @param base64
 * @returns
 */
export const base64ToArrayBuffer = (base64: string): ArrayBuffer => {
  try {
    const binary = window.atob(base64);
    const len = binary.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary.charCodeAt(i);
    }
    return bytes.buffer;
  } catch (e) {
    console.error(e);
    return new ArrayBuffer(0);
  }
};

/**
 * Converts a crypto key to a string
 *
 * @param key
 * @returns
 */
export const key2str = async (key: CryptoKey | undefined): Promise<string> => {
  if (key) {
    const exported = await window.crypto.subtle.exportKey('jwk', key);
    return JSON.stringify(exported);
  }
  return '';
};

/**
 * Converts a string to a crypto key
 *
 * @param str
 * @returns
 */
export const str2key = async (str: string): Promise<CryptoKey | null> => {
  if (str) {
    const jwk = JSON.parse(str);
    return await window.crypto.subtle.importKey(
      'jwk',
      jwk,
      jwk.alg === 'A256GCM'
        ? { name: 'AES-GCM', length: 256 }
        : { name: 'RSA-OAEP', hash: 'SHA-256' },
      true,
      jwk.key_ops,
    );
  }
  return null;
};

/**
 * Encrypts a message using provided public key
 *
 * In case the message is longer than 200 chars,
 * a new symetric key is generated and returned
 * encrypted together with the encrypted message
 *
 * @param message   string
 * @param publicKey  the Public key from the KeyPair
 * @returns  Promise with a decrypted string
 */
export const encryptMessage = async (
  message: string | number,
  publicKey: CryptoKey | undefined,
): Promise<{ value: string; key?: string; IV?: string }> => {
  if (publicKey) {
    const encodedMessage = encodeMessage(message);
    if (encodedMessage.byteLength < 200) {
      // message is shorter than 200 chars
      const ciphertext = await window.crypto.subtle
        .encrypt(
          {
            name: 'RSA-OAEP',
          },
          publicKey,
          encodedMessage,
        )
        .catch(err => {
          console.error('encryption.js encryptMessage Error', err);
          throw err;
        });

      return {
        value: arrayBufferToBase64(ciphertext),
      };
    } else {
      // message is longer than 200 chars

      // generate new key
      const symmetricKey = await generateKey();

      // encrypt the message
      const iv = window.crypto.getRandomValues(new Uint8Array(12));
      const ciphertext = await window.crypto.subtle.encrypt(
        {
          name: 'AES-GCM',
          iv,
        },
        symmetricKey,
        encodedMessage,
      );

      // encode the key with the public key
      const stringKey = await key2str(symmetricKey);
      const encryptedKey = await encryptMessage(stringKey, publicKey);

      // return
      return {
        value: arrayBufferToBase64(ciphertext),
        key: encryptedKey.value,
        IV: arrayBufferToBase64(iv),
      };
    }
  }

  return { value: '' };
};

/**
 * Decrypts a message using the provided private key
 *
 * @param message  encrypted message
 * @param privateKey  the Private key from the KeyPair
 * @param symmetricKey  encrypted symmetric key in case of a long message
 * @param iv  vector
 * @returns  string
 */
export const decryptMessage = async (
  message: string,
  privateKey: CryptoKey | undefined,
  symmetricKey?: string,
  iv?: string,
): Promise<string> => {
  if (message.length < 64 || !base64RE.test(message)) {
    return message;
  }
  if (privateKey) {
    if (symmetricKey && iv) {
      const decryptedKey = await decryptMessage(symmetricKey, privateKey);
      const key = await str2key(decryptedKey);
      const ivByteArray = base64ToArrayBuffer(iv);
      if (key && ivByteArray) {
        let decrypted = await window.crypto.subtle.decrypt(
          {
            name: 'AES-GCM',
            iv: ivByteArray,
          },
          key,
          base64ToArrayBuffer(message),
        );
        return decodeMessage(decrypted);
      }
    } else {
      const decrypted = await window.crypto.subtle.decrypt(
        {
          name: 'RSA-OAEP',
        },
        privateKey,
        base64ToArrayBuffer(message),
      );
      return decodeMessage(decrypted);
    }
  }

  return message;
};

type TGenericItem = { [field: string]: string | TFieldValues | TFilePreview[] };

const base64RE = /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/;

/**
 * Decrypt field values using the private key
 * it's an async function because the decryption is done in parallel
 *
 * @param item  the field which value should be decrypted
 * @param privateKey  the private key
 * @returns  Promise with the decrypted field values
 */
export const decryptValues = async (
  item: TGenericItem,
  privateKey: CryptoKey | null | undefined,
): Promise<TGenericItem> => {
  const decrypted: TGenericItem = {};
  for (const key in item) {
    decrypted[key] = item[key];
    if (item[key] !== undefined && typeof item[key] === 'object') {
      if (Array.isArray(item[key])) {
        // array of values
        const field: any[] = [];
        for (let i = 0; i < (item[key] as TFieldValue[]).length; i++) {
          field[i] = { ...((item[key] as TFieldValue[])[i] || {}) };
          if (field[i].encrypt) {
            field[i].value = privateKey
              ? await decryptMessage(
                  field[i].value.toString(),
                  privateKey,
                  field[i].key,
                  field[i].IV,
                )
              : '• • • • • • •';

            if (field[i].label) {
              field[i].label = privateKey
                ? await decryptMessage(
                    field[i].label.toString(),
                    privateKey,
                    field[i].labelKey,
                    field[i].labelIV,
                  )
                : '• • • • • • •';
            }
          }
        }

        decrypted[key] = field;
      } else {
        const field = { ...(item[key] as TFieldValue) };
        if (field.encrypt) {
          if (field.value) {
            field.value = privateKey
              ? await decryptMessage(field.value.toString(), privateKey, field.key, field.IV)
              : '• • • • • • •';
          }

          decrypted[key] = field;
        }
      }
    }
  }
  return decrypted;
};
