import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

/**
 * Returns state of the requested collection as object with
 * predefined states:
 *
 * @param collection name of the collection to check
 */
type RequestState = {
  isNotLoaded: boolean;
  isLoading: boolean;
  isLoaded: boolean;
};
export function useRequestStateFor(collection: string, itemId: string = ''): RequestState {
  const [isNotLoaded, setIsNotLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const [state, setState] = useState<RequestState>({
    isNotLoaded,
    isLoading,
    isLoaded,
  });

  const dbStatus = useSelector((state: any) => state.firestore.status);
  const entity = itemId === '' ? collection : `${collection}/${itemId}`;

  if (dbStatus) {
    if (
      // entity was requested
      dbStatus.requested[entity] &&
      // and is not requesting anymore
      dbStatus.requesting[entity] === false
    ) {
      // entity is loaded
      if (isLoaded === false) {
        setIsLoaded(true);
        setIsLoading(false);
        setIsNotLoaded(false);
      }
    } else if (
      // entity was not requested
      dbStatus.requested[entity] === false &&
      // but for some reason is still requesting
      dbStatus.requesting[entity]
    ) {
      if (isLoading === false) {
        setIsLoaded(false);
        setIsLoading(true);
        setIsNotLoaded(false);
      }
    } else if (isNotLoaded === false) {
      // otherwise it is not yet loaded
      setIsNotLoaded(true);
    }
  } else if (isNotLoaded === false) {
    // otherwise it is not yet loaded
    setIsNotLoaded(true);
  }

  useEffect(() => {
    setState({
      isNotLoaded,
      isLoading,
      isLoaded,
    });
  }, [isNotLoaded, isLoading, isLoaded]);

  return state;
}
