export type TDeviceStore = {
  platform: string;
  isVirtual: boolean;
  operatingSystem: string;
  deviceId?: string;
  app?: {
    name?: string;
    id?: string;
    build?: string;
    version?: string;
  };
  canSetNotifications: boolean;
  canRecieveNotifications: boolean;
  biometryAvailable: boolean;
  byometryType?: any;
  theme?: TDeviceTheme;
  darkMode?: TDeviceDarkMode;
};

export type TDeviceTheme = 'modern' | 'classic';
export type TDeviceDarkMode = 'auto' | 'on' | 'off';

export const deviceInitialState = {
  platform: 'unnknown',
  isVirtual: false,
  operatingSystem: 'unknown',
  canSetNotifications: false,
  canRecieveNotifications: false,
  biometryAvailable: false,
  theme: (localStorage?.getItem('theme') || 'modern') as TDeviceTheme,
  darkMode: (localStorage?.getItem('darkMode') || 'auto') as TDeviceDarkMode,
};

const updateRootClass = (theme?: TDeviceTheme, darkMode?: TDeviceDarkMode) => {
  const root = document.documentElement;
  if (root) {
    if (theme) {
      root.classList.remove('theme-classic', 'theme-modern');
      root.classList.add(`theme-${theme}`);
    }
    if (darkMode) {
      root.classList.remove('dark-mode-on', 'dark-mode-off', 'dark-mode-auto');
      root.classList.add(`dark-mode-${darkMode}`);
    }
  }
};

updateRootClass(deviceInitialState.theme, deviceInitialState.darkMode);

export const deviceReducer = (state = {}, action: any) => {
  switch (action.type) {
    case 'SET_DEVICE':
      return {
        ...state,
        ...action.payload,
      };
    case 'SET_DEVICE_ID':
      return {
        ...state,
        deviceId: action.payload,
      };
    case 'SET_APP_INFO':
      return {
        ...state,
        app: action.payload,
      };
    case 'SET_CAN_SET_NOTIFICATIONS':
      return {
        ...state,
        canSetNotifications: action.payload,
      };
    case 'SET_CAN_RECIEVE_NOTIFICATIONS':
      return {
        ...state,
        canRecieveNotifications: action.payload,
      };
    case 'SET_BIOMETRY_AVAILABLE':
      return {
        ...state,
        biometryAvailable: action.payload,
      };
    case 'SET_BIOMETRY_TYPE':
      return {
        ...state,
        byometryType: action.payload,
      };
    case 'SET_THEME':
      updateRootClass(action.payload);
      return {
        ...state,
        theme: action.payload,
      };
    case 'SET_DARK_MODE':
      updateRootClass(undefined, action.payload);
      return {
        ...state,
        darkMode: action.payload,
      };
    case 'CLEAR_DATA':
      return deviceInitialState;
    default:
      return state;
  }
};
