import { move } from 'ionicons/icons';
import { useCallback, useLayoutEffect, useMemo, useRef } from 'react';

interface Options {
  threshold?: number;
  onStart?: (event: TouchEvent | MouseEvent) => void;
  onFinish?: (event: TouchEvent | MouseEvent) => void;
  onCancel?: (event: TouchEvent | MouseEvent) => void;
  onClick?: (event: TouchEvent | MouseEvent) => void;
}

function isMouseEvent(event: any) {
  return event.nativeEvent instanceof MouseEvent;
}

function isTouchEvent({ nativeEvent }: any) {
  return window.TouchEvent ? nativeEvent instanceof TouchEvent : 'touches' in nativeEvent;
}

export function useLongPress(
  callback: (e: TouchEvent | MouseEvent) => void,
  { threshold = 400, onStart, onFinish, onCancel, onClick }: Options = {},
) {
  const isLongPressActive = useRef<boolean>(false);
  const isPressed = useRef<boolean>(false);
  const timerId = useRef<ReturnType<typeof setTimeout>>();
  const cbRef = useRef(callback);

  useLayoutEffect(() => {
    cbRef.current = callback;
  });

  const start = useCallback(
    () => (event: TouchEvent | MouseEvent) => {
      // if (isPressed.current) return;

      if (!isMouseEvent(event) && !isTouchEvent(event)) return;

      if (onStart) {
        onStart(event);
      }

      isPressed.current = true;
      timerId.current = setTimeout(() => {
        cbRef.current(event);
        isLongPressActive.current = true;
      }, threshold);
    },
    [onStart, threshold],
  );

  const cancel = useCallback(
    () => (event: any) => {
      if (!isMouseEvent(event) && !isTouchEvent(event)) return;

      if (isLongPressActive.current) {
        if (onFinish) {
          onFinish(event);
        }
      } else if (isPressed.current) {
        if (onCancel) {
          onCancel(event);
        }
      }

      isLongPressActive.current = false;
      isPressed.current = false;

      if (timerId.current) {
        window.clearTimeout(timerId.current);
      }
    },
    [onFinish, onCancel, onClick],
  );

  const click = useCallback(
    () => (event: any) => {
      if (!isMouseEvent(event) && !isTouchEvent(event)) return;

      if (isLongPressActive.current) {
        if (onFinish) {
          onFinish(event);
        }
      } else if (isPressed.current) {
        if (onClick) {
          onClick(event);
        }
      }

      isLongPressActive.current = false;
      isPressed.current = false;

      if (timerId.current) {
        window.clearTimeout(timerId.current);
      }
    },
    [onFinish, onCancel, onClick],
  );

  const move = () => () => {
    // if mouse/pointer is moving, we have to cancel all press events
    isLongPressActive.current = false;
    isPressed.current = false;

    if (timerId.current) {
      window.clearTimeout(timerId.current);
    }
  };

  return useMemo(() => {
    if (callback === null) {
      return {};
    }

    const mouseHandlers = {
      onMouseDown: start(),
      onMouseUp: click(),
      onMouseLeave: cancel(),
    };

    const touchHandlers = {
      onTouchStart: start(),
      onTouchEnd: click(),
      onTouchMove: move(),
    };

    return {
      ...mouseHandlers,
      ...touchHandlers,
    };
  }, [callback, cancel, click, start]);
}
