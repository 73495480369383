// hooks
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

// components
import { IonCheckbox, IonItem, IonItemDivider, IonItemGroup, IonLabel } from '@ionic/react';
import TextField from './TextField';
import MultipleField from './MultipleField';
import TextareaField from './TextareaField';
import SelectField from './SelectField';
import { Fragment } from 'react';
import FileField from './FileField';
import TotalField from './TotalField';

// utils
import { _label } from '../utilities/strings';
import { getLabelId, getValue } from '../utilities/values';
import { fieldShouldBeDisplayed } from '../utilities/forms';

// types
import { TFormField, TFormFields } from '../models/Form';
import { TFieldValue, TFilePreview } from '../models/FieldValues';
import { TextFieldTypes } from '@ionic/core';
import { RecordTypeField } from '../models/RecordType';
import { Category } from '../models/Category';

type TModalFieldsProps = {
  fields: { [fieldId: string]: TFormField };
  form: TFormFields[];
  data: any;
  thumbnails?: TFilePreview[];
  avatars?: TFilePreview[];
  updateData: (
    field: string,
    value: undefined | TFieldValue | Array<TFieldValue | undefined>,
  ) => void;
};

const supportedFieldTypes = [
  'group',
  'text',
  'checkbox',
  'file',
  'select',
  'textarea',
  'date',
  'time',
  'total',
];

const ModalFields = ({
  fields,
  form,
  data,
  thumbnails,
  avatars,
  updateData,
}: TModalFieldsProps) => {
  const intl = useIntl();
  const categories = useSelector((state: any) => state.settings.categories);

  const checkSupportedFields = (field: TFormFields): boolean => {
    if (field.displayIfSupport) {
      if (Array.isArray(field.displayIfSupport)) {
        return field.displayIfSupport.every(type => supportedFieldTypes.includes(type));
      } else {
        return supportedFieldTypes.includes(field.displayIfSupport);
      }
    }
    return true;
  };

  return (
    <>
      {form
        ?.map((field: TFormFields | string) => {
          return typeof field === 'string' ? fields?.[field] : field;
        })
        .filter(
          (field: TFormFields) => !!field && fieldShouldBeDisplayed(field as RecordTypeField, data),
        )
        .map(
          (field: TFormFields) =>
            checkSupportedFields(field) && (
              <Fragment key={field.id}>
                {field.type === 'group' && (
                  <IonItemGroup>
                    <IonItemDivider mode="md">
                      {field.labelId ? (
                        <IonLabel>
                          <FormattedMessage id={field.labelId} />
                        </IonLabel>
                      ) : field.label ? (
                        <IonLabel>{_label(field.label, intl.locale)}</IonLabel>
                      ) : null}
                    </IonItemDivider>
                    {field.fields && (
                      <ModalFields
                        fields={fields}
                        form={field.fields}
                        data={data}
                        thumbnails={thumbnails}
                        avatars={avatars}
                        updateData={updateData}
                      />
                    )}
                  </IonItemGroup>
                )}
                {field.type === 'checkbox' && (
                  <IonItem mode="ios" className="text-field__container">
                    {field.labelId ? (
                      <IonLabel>
                        <FormattedMessage id={field.labelId} />
                      </IonLabel>
                    ) : field.label ? (
                      <IonLabel>{_label(field.label, intl.locale)}</IonLabel>
                    ) : null}
                    <IonCheckbox
                      slot="start"
                      name={field.id}
                      checked={data[field.id]?.value}
                      value={field.id}
                      onIonChange={e => updateData(field.id, { value: e.detail.checked })}
                      aria-label={
                        field.labelId
                          ? intl.formatMessage({ id: field.labelId })
                          : _label(field.label, intl.locale)
                      }
                    />
                  </IonItem>
                )}
                {field.type !== 'group' && field.type !== 'file' && field.multiple && (
                  <MultipleField
                    name={field.id}
                    value={data[field.id]}
                    onValueUpdate={value => updateData(field.id, value || [])}
                    type={field.type || 'text'}
                    label={_label(field.label, intl.locale)}
                    labelId={getLabelId(field as RecordTypeField, data[field.id])}
                    placeholder={_label(field.placeholder || '', intl.locale)}
                    placeholderId={field.placeholderId}
                    addMoreLabel={_label(field.addMoreLabel || '', intl.locale)}
                    addMoreLabelId={field.addMoreLabelId}
                    encrypt={field.encrypt}
                    unencrypt={field.unencrypt}
                  />
                )}
                {field.type === 'select' && (
                  <SelectField
                    name={field.id}
                    value={data[field.id] || 'none'}
                    options={
                      field.id === 'category'
                        ? categories.filter((cat: Category) => !cat.hidden)
                        : field.options || []
                    }
                    onValueUpdate={value => updateData(field.id, value)}
                    label={_label(field.label, intl.locale)}
                    labelId={getLabelId(field as RecordTypeField, data[field.id])}
                    multiple={field.multiple || field['multiple-select']}
                  />
                )}
                {field.type === 'textarea' && field.multiple !== true && (
                  <TextareaField
                    name={field.id}
                    value={data[field.id]?.value}
                    onValueUpdate={value => updateData(field.id, value)}
                    label={_label(field.label, intl.locale)}
                    labelId={field.labelId}
                    placeholder={_label(field.placeholder || '', intl.locale)}
                    placeholderId={field.placeholderId}
                    encrypt={field.encrypt}
                    unencrypt={field.unencrypt}
                  />
                )}
                {(field.type === 'text' ||
                  field.type === 'date' ||
                  field.type === 'month' ||
                  field.type === 'time') &&
                  field.multiple !== true && (
                    <TextField
                      name={field.id}
                      value={data[field.id]?.value}
                      type={
                        (getValue(data[field.dynamicType || '']) as TextFieldTypes) ||
                        field.type ||
                        'text'
                      }
                      onValueUpdate={value => updateData(field.id, value)}
                      label={_label(field.label, intl.locale)}
                      labelId={field.labelId}
                      placeholder={_label(field.placeholder || '', intl.locale)}
                      placeholderId={field.placeholderId}
                      encrypt={field.encrypt}
                      unencrypt={field.unencrypt}
                    />
                  )}
                {field.type === 'file' && (
                  <FileField
                    itemId={data.id}
                    name={field.id}
                    files={data[field.id]?.files}
                    thumbnails={thumbnails}
                    avatars={avatars}
                    onValueUpdate={files => updateData(field.id, { value: null, files })}
                    label={_label(field.label, intl.locale)}
                    labelId={field.labelId}
                    multiple={field.multiple}
                    addMoreLabel={_label(field.addMoreLabel || '', intl.locale)}
                    addMoreLabelId={field.addMoreLabelId}
                    width={field.placeholderWidth}
                    accept={field.accept}
                  />
                )}
                {field.type === 'total' && (
                  <TotalField
                    name={field.id}
                    value={data[field.id]}
                    label={_label(field.label, intl.locale)}
                    labelId={field.labelId}
                    hintId={field.hintId}
                    onValueUpdate={value => updateData(field.id, value)}
                    encrypt={field.encrypt}
                    unencrypt={field.unencrypt}
                    displayLarge={field.displayLarge}
                  />
                )}
              </Fragment>
            ),
        )}
    </>
  );
};

export default ModalFields;
