import { IonButton, IonButtons, IonIcon } from '@ionic/react';
import { ellipsisHorizontalCircleOutline, ellipsisVerticalCircleOutline } from 'ionicons/icons';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

type TActionsToolbarProps = {
  children?: ReactNode;
  presentMenu: (evt: any) => void;
  desktop?: boolean;
  open?: boolean;
  isEditMode: boolean;
  cancelButton?: string;
  updateButton: string;
  onSave: () => void;
  onCancel: () => void;
};

// render the actions toolbar
const ActionsToolbar = (props: TActionsToolbarProps) => (
  <IonButtons slot="end" className="toolbar-buttons">
    {props.isEditMode ? (
      <>
        {props.cancelButton && (
          <IonButton
            key="cancel"
            shape="round"
            fill="clear"
            className="cancel-button"
            onClick={evt => {
              evt?.stopPropagation();
              props.onCancel();
            }}
          >
            <FormattedMessage id={props.cancelButton} />
          </IonButton>
        )}
        <IonButton
          key="save"
          color="primary"
          shape="round"
          fill="outline"
          className="update-button"
          onClick={evt => {
            evt?.stopPropagation();
            props.onSave();
          }}
        >
          <FormattedMessage id={props.updateButton} />
        </IonButton>
      </>
    ) : (
      <IonButton
        key="menu"
        color="primary"
        shape="round"
        className="add-button"
        onClick={evt => {
          evt?.stopPropagation();
          props.presentMenu({
            event: evt.nativeEvent,
            side: props.open === false ? 'top' : 'bottom',
            alignment: 'right',
            arrow: false,
          });
        }}
      >
        <IonIcon
          className="add-button--icon"
          slot="icon-only"
          md={ellipsisVerticalCircleOutline}
          ios={ellipsisHorizontalCircleOutline}
        />
      </IonButton>
    )}
  </IonButtons>
);

export default ActionsToolbar;
