// hooks
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import { useHistory } from 'react-router';

// utils
import {
  createPaper,
  getFormattedFilesArray,
  getNewPaperData,
  updatePaper,
} from '../utilities/papers';
import { processData } from '../utilities/forms';

// components
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonTitle,
  IonContent,
  IonLabel,
  IonList,
  IonItem,
  IonItemGroup,
  IonItemDivider,
  useIonLoading,
  IonCheckbox,
} from '@ionic/react';
import { FormattedMessage, useIntl } from 'react-intl';

// icons and types
import {
  checkmarkDoneOutline,
  closeOutline,
  documentAttachOutline,
  documentOutline,
  documentsOutline,
  removeCircleOutline,
} from 'ionicons/icons';
import { TStoreState } from '../store/store';

export type FileToUpload = {
  name: string;
  type: string;
  size?: number;
  path?: string;
  objectUrl?: string;
  data64?: string;
};

type TDropModalProps = {
  isOpen: boolean;
  onDidDismiss: () => void;
  filesToUpload: FileToUpload[];
  currentPaperId?: string;
};

const DropModal: React.FC<TDropModalProps> = (props: TDropModalProps) => {
  const intl = useIntl();
  const routerRef = useSelector((state: TStoreState) => state.ui?.routerRef);
  const user = useSelector((state: TStoreState) => state.firebase.auth);
  const paper = useSelector(
    (state: TStoreState) => state.data?.papers?.items?.[props.currentPaperId || ''],
  ) as any;
  const firebase = useFirebase();
  const firestore = useFirestore();
  const history = useHistory();
  const selectedToUpload = useRef<boolean[]>(props.filesToUpload.map(() => true));

  // when the files to upload change, select all the papers again
  useEffect(() => {
    selectedToUpload.current = props.filesToUpload.map(() => true);
    updateSelection();
  }, [props.filesToUpload]);

  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const [allFilesSelected, setAllFilesSelected] = useState(true);
  const [numberFilesSelected, setNumberFilesSelected] = useState<number>(
    props.filesToUpload.length,
  );

  const updateSelection = (index?: number, value?: boolean) => {
    if (index !== undefined && value !== undefined) {
      selectedToUpload.current[index] = value;
    }

    // how many files are selected?
    const filesSelected = selectedToUpload.current.filter(v => v).length;
    if (filesSelected !== numberFilesSelected) {
      setNumberFilesSelected(filesSelected);
    }

    // allFilesSelected is about the control to select/deselect all the files
    if (filesSelected === props.filesToUpload.length && !allFilesSelected) {
      setAllFilesSelected(true);
    } else if (filesSelected < props.filesToUpload.length && allFilesSelected) {
      setAllFilesSelected(false);
    }

    // buttonsDisabled is about the control to upload the files
    if (buttonsDisabled && filesSelected > 0) {
      setButtonsDisabled(false);
    }
    if (!buttonsDisabled && filesSelected === 0) {
      setButtonsDisabled(true);
    }
  };

  const deselectAllFiles = () => {
    selectedToUpload.current = selectedToUpload.current.map(() => false);
    setNumberFilesSelected(0);
    setButtonsDisabled(true);
    setAllFilesSelected(false);
  };

  const selectAllFiles = () => {
    selectedToUpload.current = selectedToUpload.current.map(() => true);
    setNumberFilesSelected(props.filesToUpload.length);
    setButtonsDisabled(false);
    setAllFilesSelected(true);
  };

  const [presentLoading, dismissLoading] = useIonLoading();

  const updateCurrentPaper = async () => {
    if (props.currentPaperId === 'new') {
      const newPaperId = await createSinglePaper();
      history.replace('/papers/' + newPaperId);
    } else if (paper) {
      // show the spinner
      presentLoading({
        message: intl.formatMessage({
          id: 'form.paper.updating',
          defaultMessage: 'Updating Paper...',
        }),
      });

      const newFiles = await getFormattedFilesArray(props.filesToUpload);
      const formData = {
        files: {
          ...paper.files,
          files: [...(paper.files?.files || []), ...newFiles],
        },
      };
      const [docData, filesToUpload] = await processData(formData, { files: paper.files });

      // update document in the database
      await updatePaper(user, firebase, firestore, props.currentPaperId!, docData, filesToUpload)
        .then(async () => {
          // remove the message
          await dismissLoading();
          props.onDidDismiss();
        })
        .catch(err => {
          console.error('Update Paper Error', err);
          dismissLoading();
        });
    }
  };

  const createSinglePaper = async (): Promise<string> => {
    // show the spinner
    presentLoading({
      message: intl.formatMessage({
        id: 'form.paper.creating',
        defaultMessage: 'Creating New Paper...',
      }),
    });

    // get the files data out of the form
    const paperData = getNewPaperData(await getFormattedFilesArray(props.filesToUpload));
    const [docData, filesToUpload] = await processData(paperData, {});

    // create document in the database
    await createPaper(user, firebase, firestore, docData, filesToUpload)
      .then(async () => {
        // remove the message
        await dismissLoading();
        props.onDidDismiss();
      })
      .catch(err => {
        console.error('Create Paper Error', err);
        dismissLoading();
      });

    return docData.id as string;
  };

  const createMultiplePapers = async () => {
    // show the spinner
    presentLoading({
      message: intl.formatMessage(
        {
          id: 'form.paper.creating-many',
          defaultMessage: 'Creating New Papers...',
        },
        {
          count: numberFilesSelected || props.filesToUpload.length,
        },
      ),
    });

    // create all the documents in one promise
    Promise.all(
      props.filesToUpload
        .filter((_: any, idx: number) => selectedToUpload.current[idx])
        .map(async file => {
          // get the files data out of the form
          const paperData = getNewPaperData(await getFormattedFilesArray([file]));
          const [docData, filesToUpload] = await processData(paperData, {});

          // create document in the database
          return createPaper(user, firebase, firestore, docData, filesToUpload);
        }),
    )
      .then(async () => {
        // remove the message
        await dismissLoading();
        props.onDidDismiss();
      })
      .catch(err => {
        console.error('Create Papers Error', err);
        dismissLoading();
      });
  };

  // in case there is only one file and user is not looking at any paper - create new one
  useEffect(() => {
    if (
      props.isOpen &&
      props.filesToUpload.length === 1 &&
      !props.currentPaperId &&
      // user should be loaded already,
      // otherwise we will create a new user
      user.isLoaded
    ) {
      createSinglePaper();
    }
  }, [props.filesToUpload, props.isOpen, user]);

  return (
    <IonModal
      mode="md"
      isOpen={props.isOpen}
      onDidDismiss={props.onDidDismiss}
      presentingElement={routerRef || undefined}
    >
      <IonHeader mode="md">
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={props.onDidDismiss}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>
            <FormattedMessage
              id="modal.drop-modal.title"
              defaultMessage="Files Upload"
              values={{ count: props.filesToUpload.length }}
            />
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItemGroup>
            <IonItemDivider mode="md">
              <IonLabel>
                <FormattedMessage
                  id="modal.drop-modal.files-to-upload"
                  defaultMessage="Accepted files for upload"
                  values={{ count: props.filesToUpload.length }}
                />
              </IonLabel>
            </IonItemDivider>
            {props.filesToUpload.length > 1 && (
              <>
                {allFilesSelected ? (
                  <IonItem button onClick={deselectAllFiles} detail={false}>
                    <IonIcon slot="start" icon={removeCircleOutline} />
                    <IonLabel>
                      <FormattedMessage
                        id="modal.drop-modal.deselect-all"
                        defaultMessage="Deselect all files"
                      />
                    </IonLabel>
                  </IonItem>
                ) : (
                  <IonItem button onClick={selectAllFiles} detail={false}>
                    <IonIcon slot="start" icon={checkmarkDoneOutline} color="primary" />
                    <IonLabel>
                      <FormattedMessage
                        id="modal.drop-modal.select-all"
                        defaultMessage="Select all files"
                      />
                    </IonLabel>
                  </IonItem>
                )}
              </>
            )}
            {props.filesToUpload.map((file: any, idx: number) => (
              <IonItem key={file.name}>
                {props.filesToUpload.length > 1 && (
                  <IonCheckbox
                    mode="md"
                    slot="start"
                    checked={selectedToUpload.current[idx] === true}
                    onIonChange={e => {
                      updateSelection(idx, e.detail.checked);
                    }}
                    aria-label={file.name}
                  />
                )}
                <IonLabel>{file.name}</IonLabel>
              </IonItem>
            ))}
          </IonItemGroup>
          <br />
          <IonItemGroup>
            <IonItemDivider mode="md">
              <IonLabel>
                <FormattedMessage
                  id="modal.drop-modal.files-actions"
                  defaultMessage="What to do with the files?"
                  values={{ count: numberFilesSelected || props.filesToUpload.length }}
                />
              </IonLabel>
            </IonItemDivider>
            {props.currentPaperId && (
              <IonItem
                button
                onClick={updateCurrentPaper}
                detail={false}
                lines="inset"
                disabled={buttonsDisabled}
              >
                <IonIcon slot="start" icon={documentAttachOutline} />
                <IonLabel>
                  <FormattedMessage
                    id="modal.drop-modal.files-actions.add-to-current"
                    defaultMessage="Add the files to the current paper"
                    values={{ count: numberFilesSelected || props.filesToUpload.length }}
                  />
                </IonLabel>
              </IonItem>
            )}
            {numberFilesSelected > 1 || buttonsDisabled ? (
              <>
                <IonItem
                  button
                  onClick={createSinglePaper}
                  detail={false}
                  lines="inset"
                  disabled={buttonsDisabled}
                >
                  <IonIcon slot="start" icon={documentOutline} />
                  <IonLabel>
                    <FormattedMessage
                      id="modal.drop-modal.files-actions.all-in-one"
                      defaultMessage="Create a new paper with all the files"
                      values={{ count: numberFilesSelected || props.filesToUpload.length }}
                    />
                  </IonLabel>
                </IonItem>
                <IonItem
                  button
                  onClick={createMultiplePapers}
                  detail={false}
                  lines="none"
                  disabled={buttonsDisabled}
                >
                  <IonIcon slot="start" icon={documentsOutline} />
                  <IonLabel>
                    <FormattedMessage
                      id="modal.drop-modal.files-actions.separate"
                      defaultMessage="Create paper for each file"
                      values={{ count: numberFilesSelected || props.filesToUpload.length }}
                    />
                  </IonLabel>
                </IonItem>
              </>
            ) : (
              <IonItem
                button
                onClick={createSinglePaper}
                detail={false}
                lines="none"
                disabled={buttonsDisabled}
              >
                <IonIcon slot="start" icon={documentOutline} />
                <IonLabel>
                  <FormattedMessage
                    id="modal.drop-modal.files-actions.songle-one"
                    defaultMessage="Create a new paper with this file"
                  />
                </IonLabel>
              </IonItem>
            )}
          </IonItemGroup>
        </IonList>
      </IonContent>
    </IonModal>
  );
};

export default DropModal;
