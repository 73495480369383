import { Dispatch } from 'redux';
import { TFilePreview, TFileValue } from '../models/FieldValues';
import { Paper } from '../models/Paper';

export const uploadFiles = async (
  firebase: any,
  filesToUpload: TFileValue[],
  uid: string,
  collection: string,
  docId: string,
) => {
  for (let i = 0; i < filesToUpload.length; i++) {
    const file = filesToUpload[i];
    if (file.data) {
      await firebase.uploadFile(`/${uid}/${collection}/${docId}`, file.data, undefined, {
        name: `${file.id}.${file.extension?.toLowerCase()}`,
        metadata: {
          contentType: file.mimeType,
          contentDisposition: `attachment; filename="${file.name}"`,
        },
      });
    }
  }
};

export const deleteFiles = async (
  firebase: any,
  files: TFileValue[],
  uid: string,
  path: string,
  item: any,
) => {
  if (files?.length > 0) {
    // collect filenames to delete
    const filesToDelete: { path?: string; url?: string }[] = [];
    files.forEach(file => {
      // original file
      if (file.mimeType === 'application/pdf') {
        filesToDelete.push({ path: path + '/' + file.id + '.pdf' });
      }
      // delete all file's artifacts
      ['pages', 'preview', 'thumbnail', 'avatar'].forEach(artifact => {
        if (item[artifact]?.length > 0) {
          item[artifact].forEach((item: any) => {
            if (item.fileId === file.id && item.url) {
              filesToDelete.push({ url: item.url.replace('{uid}', uid) });
            }
          });
        }
      });
    });

    // delete files
    for (let i = 0; i < filesToDelete.length; i++) {
      let fileRef;
      if (filesToDelete[i].url && filesToDelete[i].url?.indexOf('http') === 0) {
        fileRef = firebase.storage().refFromURL(filesToDelete[i].url);
      }
      if (filesToDelete[i].url && filesToDelete[i].url?.indexOf('http') !== 0) {
        fileRef = firebase.storage().ref(filesToDelete[i].url);
      }
      if (filesToDelete[i].path) {
        fileRef = firebase.storage().ref(filesToDelete[i].path);
      }
      if (fileRef) {
        await fileRef.delete().catch((err: any) => {
          console.error('File not found to delete', err);
          return null;
        });
      }
    }
  }
};

/**
 * Make a XMLHttpRequest to get the file in from of a Promise
 *
 * @param opts  options for the request
 * @returns Promise
 */
export const makeRequest = async (opts: {
  url: string;
  method: string;
  headers?: { [header: string]: string };
  params?: { [param: string]: string };
}) => {
  return new Promise(function (resolve, reject) {
    var xhr = new XMLHttpRequest();
    xhr.open(opts.method, opts.url);
    xhr.responseType = 'blob';
    xhr.onload = function () {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr.response);
      } else {
        reject({
          status: xhr.status,
          statusText: xhr.statusText,
        });
      }
    };
    xhr.onerror = function () {
      reject({
        status: xhr.status,
        statusText: xhr.statusText,
      });
    };
    if (opts.headers) {
      Object.keys(opts.headers).forEach(function (key) {
        xhr.setRequestHeader(key, opts.headers?.[key] || '');
      });
    }
    const params = opts.params;
    let request: string | undefined = undefined;
    // We'll need to stringify if we've been given an object
    // If we have a string, this is skipped.
    if (params && typeof params === 'object') {
      request = Object.keys(params)
        .map(function (key) {
          return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
        })
        .join('&');
    }
    xhr.send(request);
  });
};

const mimeTypesByExt: { [ext: string]: string } = {
  pdf: 'application/pdf',
  png: 'image/png',
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  gif: 'image/gif',
  avif: 'image/avif',
  webp: 'image/webp',
};
export const getMimeTypeByFileName = (fileName: string) => {
  const ext = fileName.split('.').pop();
  if (ext && mimeTypesByExt[ext]) {
    return mimeTypesByExt[ext];
  }
  return null;
};

/**
 * Loads the download urls for the previews
 *
 * @param previews
 * @param storedUrls
 * @param firebase
 * @param dispatch
 * @returns
 */
export const extractUrls = async (
  previews: TFilePreview[],
  storedUrls: { [fileName: string]: { url: string; pageFileName?: string } } | undefined,
  firebase: any,
  dispatch: Dispatch<any>,
): Promise<{ url: string; pageFileName?: string }[]> => {
  let urls: { url: string; pageFileName?: string }[] = [];
  for (let i = 0; i < previews.length; i++) {
    if (!storedUrls?.[previews[i].fileName]) {
      const storageRef = await firebase.storage().refFromURL(previews[i].url);
      const url = await storageRef.getDownloadURL();
      if (url) {
        const pageFileName = previews[i].pageFileName || previews[i].fileName;
        urls.push({
          url,
          pageFileName,
        });
        dispatch({
          type: 'ADD_URL',
          payload: {
            fileName: previews[i].fileName,
            url,
            pageFileName,
          },
        });
      }
    } else {
      urls.push(storedUrls[previews[i].fileName]);
    }
  }
  return urls;
};

/**
 * Loads the download urls for the pdf files or previews (if there are no pdf files)
 * for multiple papers
 *
 * @param papers
 * @param storedUrls
 * @param firebase
 * @param dispatch
 * @returns
 */
export const extractDownloadUrlsFromPapers = async (
  papers: { [paperid: string]: Paper },
  storedUrls: { [fileName: string]: { url: string; pageFileName?: string } } | undefined,
  uid: string,
  firebase: any,
  dispatch: Dispatch<any>,
): Promise<
  { fileId: string; name: string; fileName: string; pageFileName: string; url: string }[]
> => {
  let urls: {
    fileId: string;
    name: string;
    fileName: string;
    pageFileName: string;
    url: string;
  }[] = [];
  for (const paperId in papers) {
    const paper = papers[paperId];

    // get all the PDF files to download
    const paperFiles = paper.files && !Array.isArray(paper.files) ? paper.files?.files || [] : [];
    const files: {
      fileId: string;
      name: string;
      fileName: string;
      pageFileName: string;
      url: string;
    }[] = [];
    const previewFiles: { [fileId: string]: string } = {};
    for (let i = 0; i < paperFiles.length; i++) {
      const file = paperFiles[i];
      if (file.mimeType === 'application/pdf') {
        const path = `/${uid}/paper/${paper.id}/${file.id}.pdf`;
        const url = await firebase.storage().ref(path).getDownloadURL();
        files.push({
          fileId: file.id,
          name: file.name,
          fileName: file.id + '.pdf',
          pageFileName: file.id + '.pdf',
          url,
        });
      } else {
        previewFiles[file.id] = file.name;
      }
    }

    // get all the preview files without pdf files
    const paperPreviews = paper.preview || [];
    const previews: {
      fileId: string;
      name: string;
      fileName: string;
      pageFileName: string;
      url: string;
    }[] = [];
    for (let i = 0; i < paperPreviews.length; i++) {
      const preview = paperPreviews[i];
      if (previewFiles[preview.fileId]) {
        const url = (preview.url || '').replace('{uid}', uid);
        previews.push({
          fileId: preview.fileId,
          name: previewFiles[preview.fileId],
          fileName: preview.fileName,
          pageFileName: preview.pageFileName || preview.fileName,
          url,
        });
      }
    }

    urls = urls.concat(files, previews);

    // save the urls to the store
    for (let i = 0; i < urls.length; i++) {
      if (!storedUrls?.[urls[i].fileName]) {
        dispatch({
          type: 'ADD_URL',
          payload: {
            fileId: urls[i].fileId,
            name: urls[i].name,
            fileName: urls[i].fileName,
            url: urls[i].url,
            pageFileName: urls[i].pageFileName,
          },
        });
      }
    }

    //
  }
  return urls;
};

// /**
//  * Load paper's file from the Firebase Storage
//  *
//  * @param user
//  * @param paper
//  * @param fileId
//  * @param callback
//  * @param loadPreview
//  * @param firebase
//  * @returns
//  */
// const loadFile = async (
//   user: any,
//   paper: Paper,
//   fileId: string | null,
//   callback: (response: any) => void,
//   loadPreview = false,
//   firebase,
// ) => {
//   if (fileId) {
//     const paperFile = (paper?.files as TFieldValue)?.files?.find(file => file.id === fileId);
//     const fileUrls = (paper?.files as TFieldValue)?.urls || {};

//     if (paperFile?.name) {
//       if (paperFile.mimeType === 'application/pdf' && !loadPreview) {
//         const fullPath = `/${user.uid}/paper/${paper.id}/${fileId}.pdf`;
//         firebase
//           .storage()
//           .ref(fullPath)
//           .getDownloadURL()
//           .then(async (url: string) => {
//             const file = await makeRequest({ url, method: 'GET' });
//             callback({
//               filename: paperFile?.name,
//               contentType: paperFile.mimeType,
//               data: file,
//             });
//           });
//       } else {
//         const url = fileUrls[activePreview].url;
//         const filename = paperFile.name.substring(0, paperFile.name.lastIndexOf('.')) + '.jpg';

//         if (url) {
//           const file = await makeRequest({ url, method: 'GET' });
//           callback({
//             filename,
//             contentType: 'image/jpeg',
//             data: file,
//           });
//         }
//       }
//     }
//   }
//   return null;
// };
