// hooks
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useIonViewWillLeave, useIonViewDidEnter } from '@ionic/react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';

// components
import { Helmet } from 'react-helmet';
import { IonContent, IonHeader, IonPage } from '@ionic/react';

// types
import { ReactElement } from 'react';
import { TStoreState } from '../store/store';

type TAppPageProps = {
  title?: string;
  header?: ReactElement;
  color?: string;
  contentClass?: string;
  path?: string;
  children?: ReactElement | Array<ReactElement | null | boolean | undefined>;
};

const AppPage = ({ title = '', header, color, contentClass, path, children }: TAppPageProps) => {
  const intl = useIntl();
  const lang = useSelector((state: TStoreState) => state.ui.lang);

  const appTitle = intl.formatMessage({ id: 'app.title' });
  const pageTitle = appTitle + (title !== '' && title !== appTitle ? ' · ' + title : '');

  const [showTitle, setShowTitle] = useState(false);

  useIonViewDidEnter(() => {
    setShowTitle(true);
  });

  useIonViewWillLeave(() => {
    setShowTitle(false);
  });

  const location = useLocation();
  const [currentPath, setPath] = useState<string | null>(null);
  const [level, setLevel] = useState<number>(0);
  const [pane, setPane] = useState<'left' | 'right' | null>(null);
  useEffect(() => {
    if (path) {
      if (location.pathname.indexOf(path) === 0) {
        const maxLevel = location.pathname.split('/').filter(item => item !== '').length;
        const level = path.split('/').filter(item => item !== '').length;
        setPath(path);
        setLevel(level);
        setPane(level > 1 && level === maxLevel ? 'right' : level + 1 === maxLevel ? 'left' : null);
      } else {
        setPath(null);
        setLevel(0);
        setPane(null);
      }
    }
  }, [location, path]);

  return (
    <IonPage data-path={currentPath} data-level={level} data-pane={pane}>
      {showTitle && (
        <Helmet>
          <html lang={lang} />
          <title>{pageTitle}</title>
        </Helmet>
      )}
      <IonHeader mode="md">{header}</IonHeader>
      <IonContent fullscreen color={color} className={contentClass || ''}>
        {children}
      </IonContent>
    </IonPage>
  );
};

export default AppPage;
