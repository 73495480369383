import { Contact, newContact } from '../models/Contact';

import { customAlphabet } from 'nanoid';
import { alphanumeric } from 'nanoid-dictionary';
import { TFieldValue, TFieldValues } from '../models/FieldValues';
import { filesizeFromBase64, mimeTypeFromBase64 } from './filesize';
const nanoid = customAlphabet(alphanumeric, 14);

export const generateContactFromDevice = (deviceContact: any, appContact?: Contact) => {
  // console.log('deviceContact', deviceContact);
  // console.log('appContact', appContact);
  if (appContact) {
    // updating existing contact
    const updatedContact: Contact = {
      ...appContact,
      // names

      // phones
      phone: updateFieldValues(
        getFieldValuesAsArray(appContact.phone),
        convertDevicePhones(deviceContact.phones),
      ),
      // emails
      email: updateFieldValues(
        getFieldValuesAsArray(appContact.email),
        convertDeviceEmails(deviceContact.emails),
      ),
      // addresses
      address: updateFieldValues(
        getFieldValuesAsArray(appContact.address),
        convertDeviceAddresses(deviceContact.postalAddresses),
      ),

      // other fields
      ...allTheOtherFields(deviceContact),
    };

    return updatedContact;
  } else {
    // creating a new contact
    const createdContact: Contact = {
      ...newContact,
      favorite: { value: false },
      id: nanoid(14),

      // names
      names: {
        // in case there is no family name -- it's Android
        value: deviceContact.name?.family
          ? [
              deviceContact.name?.given || '',
              deviceContact.name?.middle || '',
              deviceContact.name?.family || '',
            ]
              .filter(name => name !== '')
              .join(' ')
          : deviceContact.name?.display,
      },
      displayName: { value: deviceContact.name?.display },
      // phones
      phone: convertDevicePhones(deviceContact.phones),
      // emails
      email: convertDeviceEmails(deviceContact.emails),
      // addresses
      address: convertDeviceAddresses(deviceContact.postalAddresses),

      // other fields
      ...allTheOtherFields(deviceContact),
    };

    return createdContact;
  }
};

const allTheOtherFields = (contact: any): { [field: string]: TFieldValues } => {
  const fields: { [field: string]: TFieldValues } = {};
  // if (contact.birthday && contact.birthday !== '') {
  //   fields['birthdate'] = { value: contact.birthday };
  // }

  // company is saved as "other name", to be searched properly
  if (contact.organization?.company) {
    fields['otherNames'] = [
      {
        label: 'Company',
        value: contact.organization?.company,
      },
    ];
  }

  if (contact.image?.base64String) {
    fields['photo'] = {
      value: null,
      files: [
        {
          id: nanoid(14),
          name: 'photo',
          data64: contact.image?.base64String,
          size: filesizeFromBase64(contact.image?.base64String),
          mimeType: mimeTypeFromBase64(contact.image?.base64String),
        },
      ],
    };
  }

  return fields;
};

const updateFieldValues = (
  fieldValues: TFieldValue[],
  newFieldValues: TFieldValue[],
): TFieldValue[] => {
  const updatedFieldValues: TFieldValue[] = [...fieldValues];
  if ((newFieldValues?.length || 0) > 0) {
    newFieldValues.forEach((newFieldValue: TFieldValue) => {
      const exists = updatedFieldValues.find(
        (fieldValue: TFieldValue) => fieldValue.value === newFieldValue.value,
      );
      if (!exists) {
        updatedFieldValues.push(newFieldValue);
      }
    });
  }
  return updatedFieldValues;
};

const getFieldValuesAsArray = (fieldValue: TFieldValues): TFieldValue[] => {
  if (!fieldValue) {
    return [];
  }
  if (Array.isArray(fieldValue)) {
    return fieldValue;
  }
  return [fieldValue];
};

const convertDevicePhones = (devicePhones: { label?: string; number: number }[]): TFieldValue[] => {
  const phones: TFieldValues = [];
  if ((devicePhones?.length || 0) > 0) {
    devicePhones.forEach((phone: any) => {
      if (!phones.find((e: TFieldValue) => e.value === phone.number)) {
        const val: TFieldValue = {
          value: phone.number,
          encrypt: true,
          unencrypt: 'app',
        };
        if (phone.label && phone.label !== '') {
          val.label = phone.label;
        }
        phones.push(val);
      }
    });
  }
  return phones;
};

const convertDeviceEmails = (
  deviceEmails: { label?: string; address: string }[],
): TFieldValue[] => {
  const emails: TFieldValues = [];
  if ((deviceEmails?.length || 0) > 0) {
    deviceEmails.forEach((email: any) => {
      if (!emails.find((e: TFieldValue) => e.value === email.address)) {
        const val: TFieldValue = {
          value: email.address,
          encrypt: true,
          unencrypt: 'app',
        };
        if (email.label && email.label !== '') {
          val.label = email.label;
        }
        emails.push(val);
      }
    });
  }
  return emails;
};

const convertDeviceAddresses = (
  deviceAddresses: { label?: string; formatted: string }[],
): TFieldValue[] => {
  const addresses: TFieldValues = [];
  if ((deviceAddresses?.length || 0) > 0) {
    deviceAddresses.forEach((address: any) => {
      if (!addresses.find((e: TFieldValue) => e.value === address.formatted)) {
        const val: TFieldValue = {
          value: address.formatted,
          encrypt: true,
          unencrypt: 'app',
        };
        if (address.label && address.label !== '') {
          val.label = address.label;
        }
        addresses.push(val);
      }
    });
  }
  return addresses;
};
