// hooks
import { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation, useParams } from 'react-router';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import { useCollectionItem } from '../hooks/useCollectionItem';
import { useSelector, shallowEqual } from 'react-redux';
import { useOrderedCollection } from '../hooks/useOrderedCollection';

// utils
import { TStoreState } from '../store/store';
import { getValue } from '../utilities/values';
import { uploadFiles, deleteFiles } from '../utilities/files';
import { createFirestoreDocument, updateFirestoreDocument } from '../utilities/firestore';
import { processData } from '../utilities/forms';
import { str2key } from '../utilities/encryption';
import { emitCustomEvent } from 'react-custom-events';

// components
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonIcon,
  IonItem,
  IonList,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonAlert,
  useIonLoading,
  useIonPopover,
} from '@ionic/react';
import { Helmet } from 'react-helmet';
import LoadingScreen from '../components/LoadingScreen';
import PaperInfo from '../components/PaperInfo';
import SelectForm from '../forms/SelectForm';
import ModalFields from '../forms/ModalFields';
import PaperPreviews from '../components/PaperPreviews';

// types
import { newPaper, Paper } from '../models/Paper';
import { OrderedRecordTypesState, RecordType } from '../models/RecordType';
import { TForm, TFormField, TFormFields } from '../models/Form';
import {
  chevronDownOutline,
  chevronUpOutline,
  closeOutline,
  createOutline,
  duplicateOutline,
  lockOpenOutline,
  removeCircleOutline,
} from 'ionicons/icons';

import './PaperPage.scss';
import { TCommonDocument, TFileValue } from '../models/FieldValues';
import ActionsToolbar from '../components/ActionsToolbar';

import { customAlphabet } from 'nanoid';
import { alphanumeric } from 'nanoid-dictionary';
import { OCRResult } from '../models/OCR';
import { deletePaper } from '../utilities/papers';
const nanoid = customAlphabet(alphanumeric, 14);

const PaperPage: React.FC = () => {
  const intl = useIntl();
  const firebase = useFirebase();
  const firestore = useFirestore();
  const history = useHistory();

  const { paperId } = useParams<{ paperId: string }>();
  const location = useLocation();
  const [newPaperId] = useState<string>(nanoid(14));
  const [path, setPath] = useState<string | null>(location.pathname);

  const user = useSelector((state: TStoreState) => state.firebase.auth);
  const privateKey = useSelector((state: TStoreState) => state.ui.privateKey);
  const paper = useSelector((state: TStoreState) => state.data?.papers?.items?.[paperId]);
  const forms = useOrderedCollection('types', { record: 'paper' }) as OrderedRecordTypesState;
  const fields = useCollectionItem('types', 'fields');

  // encryption
  const profile = useSelector((state: TStoreState) => state.firebase.profile);
  const publicKey = useRef<CryptoKey | null>(null);
  useEffect(() => {
    if (profile?.publicKey) {
      str2key(profile.publicKey as string).then(key => {
        publicKey.current = key;
      });
    }
  }, [profile]);

  const [isLocked, setIsLocked] = useState(profile?.publicKey && !privateKey);
  useEffect(() => {
    setIsLocked(profile?.publicKey && !privateKey);
  }, [profile, privateKey]);

  const isDesktop = useSelector((state: TStoreState) => state.ui.isDesktop);

  const [presentLoading, dismissLoading] = useIonLoading();

  // info state
  const [isInfoOpen, setInfoOpen] = useState<boolean>(
    paperId === 'new' || location.search.indexOf('edit') >= 0,
  );

  /**
   * Request authorization
   */
  const requestAuthorization = async () => {
    emitCustomEvent('request-authorization');
  };

  /**
   * Paper (metadata) menu popover
   */
  const [presentMenu, closeMenu] = useIonPopover(() => {
    return (
      <IonList>
        {isLocked ? (
          <IonItem
            button
            onClick={() => {
              requestAuthorization();
              closeMenu();
            }}
            detail={false}
          >
            <IonIcon icon={lockOpenOutline} slot="start" />
            {intl.formatMessage({ id: 'ui.buttons.unlock-the-data' })}
          </IonItem>
        ) : (
          <>
            <IonItem
              button
              onClick={() => {
                editPaper();
                closeMenu();
              }}
              detail={false}
            >
              <IonIcon icon={createOutline} slot="start" />
              {intl.formatMessage({ id: 'ui.buttons.edit' })}
            </IonItem>
            <IonItem
              button
              onClick={() => {
                confirmDuplicatePaper();
                closeMenu();
              }}
              detail={false}
            >
              <IonIcon icon={duplicateOutline} slot="start" />
              {intl.formatMessage({ id: 'ui.buttons.duplicate-data' })}
            </IonItem>
          </>
        )}
        <IonItem
          button
          onClick={() => {
            confirmDeletePaper();
            closeMenu();
          }}
          lines="none"
          detail={false}
        >
          <IonIcon icon={removeCircleOutline} color="danger" slot="start" />
          {intl.formatMessage({ id: 'ui.buttons.delete-paper' })}
        </IonItem>
      </IonList>
    );
  });

  // menu actions
  const [presentAlert] = useIonAlert();

  const editPaper = () => {
    setEditMode(true);
  };

  const confirmDuplicatePaper = () => {
    presentAlert({
      header: intl.formatMessage({ id: 'page.paper.duplicate-alert.title' }),
      message: intl.formatMessage({ id: 'page.paper.duplicate-alert.message' }),
      buttons: [
        intl.formatMessage({ id: 'ui.buttons.cancel' }),
        {
          text: intl.formatMessage({ id: 'ui.buttons.ok' }),
          handler: () => duplicatePaper(),
        },
      ],
    });
  };

  const duplicatePaper = async () => {
    const newItem = { ...paper };
    // remove props that should not be copied
    delete newItem.files;
    delete newItem.pages;
    delete newItem.preview;
    delete newItem.thumbnail;
    delete newItem.updatedAt;
    // set new values
    newItem.id = newPaperId;

    // create the new paper
    await createFirestoreDocument(user, firebase, firestore, `papers/${newPaperId}`, newItem)
      .then(async res => {
        // redirect to the paper page
        history.replace('/papers/' + newPaperId);
      })
      .catch(err => {
        dismissLoading();
        console.error('Duplicate Paper Error', err);
      });
  };

  const confirmDeletePaper = () => {
    presentAlert({
      header: intl.formatMessage({ id: 'page.paper.delete-alert.title' }),
      message: intl.formatMessage({ id: 'page.paper.delete-alert.message' }),
      buttons: [
        intl.formatMessage({ id: 'ui.buttons.cancel' }),
        {
          text: intl.formatMessage({ id: 'ui.buttons.yes' }),
          handler: () => deleteThisPaper(),
        },
      ],
    });
  };

  const deleteThisPaper = async () => {
    presentLoading({
      message: intl.formatMessage({
        id: 'form.paper.deleting',
        defaultMessage: 'Deleting Paper...',
      }),
    });

    await deletePaper(paper, user, firebase, firestore)
      .then(() => {
        history.replace('/papers');
      })
      .catch(err => {
        console.error('Delete Paper Error', err);
      })
      .finally(async () => {
        await dismissLoading();
      });
  };

  /**
   * Paper edit and create functions
   */
  const [isEditMode, setEditMode] = useState<boolean>(
    paperId === 'new' || location.search.indexOf('edit') >= 0,
  );

  // data that will be updated in the form
  // starting with a new blank paper and then adding data that can come from the DB
  const returnNewFormData = (id: string, newData: Paper, defaultData?: TCommonDocument) => ({
    ...newData,
    ...(defaultData || {}),
    id,
  });

  const [formData, setFormData] = useState<{ [id: string]: TCommonDocument }>({
    [paperId]: returnNewFormData(paperId, newPaper, paper),
  });

  const updateField = (field: TFormField, value: any, add = false) => {
    if ((field as any).encrypt) {
      value.encrypt = (field as any).encrypt;
    }
    if ((field as any).unencrypt) {
      value.unencrypt = (field as any).unencrypt;
    }
    if (add && field.multiple) {
      // if it's multiple field, add the value instead of replace
      // check if the value is already in the array
      if (
        value?.value &&
        ((formData?.[paperId]?.[field.id] as any[]) || [])
          .map(item => item.value)
          .indexOf(value.value) < 0
      ) {
        updateData(field.id, [...((formData?.[paperId]?.[field.id] as any[]) || []), value]);
      } else if (
        !value?.value &&
        ((formData?.[paperId]?.[field.id] as any[]) || []).indexOf(value) < 0
      ) {
        updateData(field.id, [...((formData?.[paperId]?.[field.id] as any[]) || []), value]);
      }
    } else {
      updateData(field.id, value);
    }
  };

  const updateData = (property: string, value: any) => {
    if ((formData as any)[property] !== value) {
      setFormData(data => ({
        ...data,
        [paperId]: {
          ...data[paperId],
          [property]: value,
        },
      }));
      // update the data in the DB
      // if someone is changing not in edit mode
      if (paperId !== 'new' && !isEditMode) {
        setShouldSave(true);
      }
    }
  };

  // in case client's data in the DB is changed, we have to update the form too
  const [wasLocked, setWasLocked] = useState<boolean>(isLocked);
  useEffect(() => {
    const justUnlocked = !isLocked && wasLocked !== isLocked;
    if (justUnlocked) {
      setWasLocked(isLocked);
    }
    // set the form data
    if (
      (paper &&
        (paper.id !== paperId ||
          !formData[paperId] ||
          (paper.updatedAt || paper.createdAt!) >
            ((formData as any)[paperId]?.updatedAt || (formData as any)[paperId]?.createdAt) ||
          0)) ||
      justUnlocked
    ) {
      const unencryptedPaperData: Paper = Object.keys(paper).reduce((acc, key) => {
        const val = getValue(paper[key]) || '';
        if (val?.toString().indexOf('• • •') < 0) {
          acc[key] = paper[key];
        }
        return acc;
      }, {} as any) as Paper;
      setFormData(existingData => ({
        ...existingData,
        [paper.id as string]: {
          ...existingData[paper.id as string],
          ...unencryptedPaperData,
        },
      }));
    }
    // eslint-disable-next-line
  }, [paper, isEditMode, paperId]);

  const lastSelectedForm = useSelector(
    (state: TStoreState) => state.firebase.profile?.lastSelectedForm || '',
    shallowEqual,
  );

  const [selectedForm, setSelectedForm] = useState(-1);

  const updateSelectedForm = (newForm: number) => {
    if (selectedForm !== newForm) {
      setSelectedForm(newForm);
      setFormData(data => ({
        ...data,
        [paperId]: {
          ...data[paperId],
          form: getFormByNumber(selectedForm)?.id || 'document',
        },
      }));
    }
  };

  const getFormIndexById = (formId: string): number => {
    return (
      forms.items
        ?.sort((a, b) => ((a.order || 100) > (b.order || 100) ? 1 : -1))
        .findIndex(form => form.id === formId) || -1
    );
  };

  const getFormById = (formId: string): RecordType | undefined => {
    return forms.items?.find(form => form.id === formId);
  };

  const getFormByNumber = (formIndex: number): RecordType | undefined => {
    return forms.items?.sort((a, b) => ((a.order || 100) > (b.order || 100) ? 1 : -1))[formIndex];
  };

  /**
   * Update the selected form when the paper is updated or the forms are loaded
   *
   * @dependsOn paperId  id of the current paper (can change first, before the paper data comes)
   * @dependsOn paper  data of the current paper
   * @dependsOn forms.items  list of all the form for the types
   * @dependsOn lastSelectedForm  last used type saved in the profile
   */
  useEffect(() => {
    if (forms?.items) {
      let formIndex = -1;
      // we have the paper types loaded
      if (paper?.form) {
        // data for the current paper is loaded
        // we should use the data saved from there
        formIndex = getFormIndexById(paper.form as string);
        if (typeof formIndex === 'number') {
          updateSelectedForm(formIndex >= 0 ? formIndex : 0);
        }
      }
      if (formIndex < 0 && lastSelectedForm !== '') {
        // if we don't have the data for the current paper, we should use the last selected form
        formIndex = getFormIndexById(lastSelectedForm);
        if (typeof formIndex === 'number') {
          updateSelectedForm(formIndex >= 0 ? formIndex : 0);
        }
      }
      if (formIndex < 0) {
        // if we don't have any data, we should use the first form
        updateSelectedForm(0);
      }
    }
    // eslint-disable-next-line
  }, [forms.items, paper, lastSelectedForm, paperId]);

  /**
   * Upload file from the previews
   *
   * the difference is that we have to update/create paper doc right away,
   * and not wait for the save button to be pressed
   */
  const [shouldSave, setShouldSave] = useState(false);

  const uploadFile = (files: TFileValue[]) => {
    // check that files were updated
    if (files?.length > ((formData[paperId]?.files as any)?.files?.length || 0)) {
      updateData('files', {
        value: null,
        files,
      });

      setShouldSave(true);
    }
  };

  useEffect(() => {
    if (shouldSave) {
      setShouldSave(false);
      if (paperId === 'new') {
        // if there is no paper yet, have to create one first
        updateData('new', true);
        createItem(true);
      } else {
        // otherwise the paper should be updated
        updateItem();
      }
    }
    // eslint-disable-next-line
  }, [shouldSave]);

  // Save the data to the DB
  const createItem = async (redirectAfterImageUpload: boolean = false) => {
    // show loading
    presentLoading({
      message: intl.formatMessage({
        id: 'form.paper.creating',
        defaultMessage: 'Creating New Paper...',
      }),
    });

    // get the files data out of the form
    const [docData, filesToUpload] = await processData(
      {
        ...formData[paperId],
        id: newPaperId,
        form: getFormByNumber(selectedForm < 0 ? 0 : selectedForm)?.id,
      },
      paper,
    );

    // add new in case it's just created from scrat... file paper
    if (paperId === 'new' && redirectAfterImageUpload) {
      // @ts-ignore
      docData.new = true;
    }

    // save the data to the DB
    await createFirestoreDocument(user, firebase, firestore, `papers/${newPaperId}`, docData)
      .then(async res => {
        // upload the files to the storage
        const uid = user.uid || res.uid;
        await uploadFiles(firebase, filesToUpload, uid, 'paper', docData.id as string);

        // TODO: delete the removed files and their previews

        // remove the message
        await dismissLoading();
        // close the edit mode if it was not a redirect after image upload
        if (!redirectAfterImageUpload) {
          setEditMode(false);
        }

        // redirect to the paper page
        history.replace('/papers/' + newPaperId);
      })
      .catch(err => {
        dismissLoading();
        console.error('Create Paper Error', err);
      });
  };

  const updateItem = async () => {
    // show loading
    presentLoading({
      message: intl.formatMessage({
        id: 'form.paper.updating',
        defaultMessage: 'Updating Paper...',
      }),
    });

    // get the files data out of the form
    const [docData, filesToUpload, filesToDelete] = await processData(
      {
        ...formData[paperId],
        id: paperId,
        form: getFormByNumber(selectedForm < 0 ? 0 : selectedForm)?.id,
      },
      paper,
      publicKey.current || undefined,
    );

    // remove the new mark if there is one
    // @ts-ignore
    docData.new = false;

    // update the data in the DB
    await updateFirestoreDocument(user, firestore, `papers/${paperId}`, docData)
      .then(async () => {
        // upload new files to the storage
        await uploadFiles(firebase, filesToUpload, user.uid, 'paper', docData.id as string);

        // delete the removed files and their previews
        await deleteFiles(
          firebase,
          filesToDelete,
          user.uid,
          `/${user.uid}/paper/${docData.id}`,
          paper,
        );

        // remove the message and close the edit mode
        await dismissLoading();
        setEditMode(false);
      })
      .catch(err => {
        dismissLoading();
        console.error('Update Paper Error', err);
      });
  };

  /**
   * Text recognition results
   * returns parsed JSON, because in the DB it's stored as a string
   *
   * @dependsOn paper.ocr  text recognition results as a string
   */
  const ocr = useMemo<OCRResult[]>(
    () =>
      (paper?.ocr?.value as string)?.indexOf('• •') < 0
        ? JSON.parse((paper?.ocr?.value as string) || '[]')
        : [],
    [paper?.ocr],
  );

  /**
   * Flatten the form fields
   * to be used when selecting recognized text to fill the form
   *
   * @dependsOn selectedForm  currently selected paper type
   * @dependsOn forms  form fields for all paper types
   * @dependsOn fields  properties of all form fields
   */
  const flattedFormFields = useMemo((): TFormField[] => {
    const formFields = getFormByNumber(selectedForm < 0 ? 0 : selectedForm)
      ?.fields as TFormFields[];

    const getFields = (acc: TFormField[], item: TFormFields): TFormField[] => {
      if (typeof item === 'string') {
        if (fields.item?.[item]) {
          return [...acc, fields.item[item]];
        }
      } else if (item.type === 'group') {
        return [...acc, ...item.fields.reduce(getFields, [] as TFormField[])];
      } else {
        return [...acc, item];
      }
      return acc;
    };

    return formFields?.reduce(getFields, [] as TFormField[]);
    // eslint-disable-next-line
  }, [selectedForm, forms, fields]);

  const [pane, setPane] = useState<'left' | 'right' | null>(null);
  useEffect(() => {
    let currentPath = path;
    if ((path?.indexOf(paperId) || -1) < 0) {
      // replace the last piece of the path with the current paperId
      currentPath = path?.split('/').slice(0, -1).join('/') + '/' + paperId;
      setPath(currentPath);
    }
    if (currentPath) {
      if (location.pathname.indexOf(currentPath) === 0) {
        const maxLevel = location.pathname.split('/').filter(item => item !== '').length;
        const level = currentPath.split('/').filter(item => item !== '').length;
        setPane(level > 1 && level === maxLevel ? 'right' : level + 1 === maxLevel ? 'left' : null);
      } else {
        setPane(null);
      }
    }
  }, [location, paperId, path]);

  const closePage = () => {
    history.replace(path?.split('/').slice(0, -1).join('/') || '/papers');
  };

  return (
    <IonPage
      data-path={path}
      data-pathname={location.pathname}
      data-level={path?.split('/').filter(item => item !== '').length}
      data-pane={pane}
    >
      <Helmet>
        <title>
          {intl.formatMessage({ id: 'page.paper.title' }, { name: getValue(paper?.title) })}
        </title>
      </Helmet>
      <div className="paper-page--back-button">
        <IonBackButton mode="md" defaultHref="/papers" color="light" />
        <IonButton
          mode="md"
          onClick={() => closePage()}
          shape="round"
          className="paper-page--image-button"
        >
          <IonIcon icon={closeOutline} slot="icon-only" />
        </IonButton>
      </div>
      <div
        className={['paper-page--layout', isInfoOpen ? 'paper-page--layout-open' : ''].join(' ')}
      >
        <PaperPreviews
          paperId={paperId}
          paper={paper}
          formDataFiles={(formData[paperId] as any)?.files?.files}
          uploadFile={uploadFile}
          ocr={ocr}
          fields={flattedFormFields}
          updateField={updateField}
          isLocked={isLocked && !paper?.new}
          canUnlock={isLocked}
        />
        <div className="paper-page--info">
          <IonToolbar
            mode="md"
            className="paper-page--toggle-toolbar"
            onClick={() => {
              setInfoOpen(value => !value);
            }}
          >
            <IonButtons slot="start" className="toolbar-buttons">
              <IonButton>
                <IonIcon
                  slot="icon-only"
                  icon={isInfoOpen ? chevronDownOutline : chevronUpOutline}
                />
              </IonButton>
            </IonButtons>
            <IonTitle className="paper-page--title">
              {getValue(
                paper?.title,
                paper?.new
                  ? intl.formatMessage({ id: 'paper.new' })
                  : intl.formatMessage({ id: 'paper.untitled' }),
              )}
            </IonTitle>
            {!isDesktop && (
              <ActionsToolbar
                desktop={false}
                open={isInfoOpen}
                presentMenu={presentMenu}
                isEditMode={isEditMode}
                updateButton={paperId === 'new' ? 'ui.buttons.create' : 'ui.buttons.update'}
                onSave={paperId === 'new' ? createItem : updateItem}
                cancelButton={paperId === 'new' ? undefined : 'ui.buttons.cancel'}
                onCancel={() => {
                  setEditMode(false);
                }}
              />
            )}
          </IonToolbar>
          <div className="paper-page--info-container">
            {isDesktop && (
              <IonToolbar mode="md">
                <ActionsToolbar
                  desktop={true}
                  presentMenu={presentMenu}
                  isEditMode={isEditMode}
                  updateButton={paperId === 'new' ? 'ui.buttons.create' : 'ui.buttons.update'}
                  onSave={paperId === 'new' ? createItem : updateItem}
                  cancelButton={paperId === 'new' ? undefined : 'ui.buttons.cancel'}
                  onCancel={() => {
                    setEditMode(false);
                  }}
                />
              </IonToolbar>
            )}
            <IonContent className="paper-page--content">
              {forms.items && formData[paperId] && (paper || paperId === 'new') ? (
                <>
                  {isEditMode ? (
                    <IonList className="form-fields">
                      <SelectForm
                        forms={forms.items as TForm[]}
                        selectedForm={selectedForm < 0 ? 0 : selectedForm}
                        onSelection={updateSelectedForm}
                      />
                      <ModalFields
                        fields={fields.item}
                        form={
                          getFormByNumber(selectedForm < 0 ? 0 : selectedForm)
                            ?.fields as TFormFields[]
                        }
                        data={formData[paperId]}
                        thumbnails={paper?.thumbnail}
                        updateData={updateData}
                      />
                    </IonList>
                  ) : (
                    <PaperInfo
                      paper={paper}
                      typeFields={getFormById(paper?.form as string)?.fields}
                      isNotLocked={!isLocked || !!paper?.new}
                    />
                  )}
                </>
              ) : (
                <LoadingScreen />
              )}
            </IonContent>
          </div>
        </div>
      </div>
    </IonPage>
  );
};

export default PaperPage;
