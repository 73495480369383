import { TReminderData, TReminderRecord } from '../models/Reminder';

export const getReminderData = ({
  type,
  itemId,
  field,
  fieldNo = 0,
  offset = 0,
  value,
  image,
  repeat = false,

  title,
  body,
}: TReminderData): TReminderRecord | null => {
  // notifications will be sent at 8:38 am
  // we have to be sure that the user is setting the right time
  // to get the notification on the right day
  const valueDate = new Date(value + 'T08:38:00Z');

  // if the it is not a repeating reminder and it's
  // in the past, then don't create it
  if (!repeat && valueDate.getTime() < Date.now()) {
    return null;
  }

  // applying offset to the date
  if (offset) {
    valueDate.setDate(valueDate.getDate() - offset);

    // check again isn't that in the past
    if (!repeat && valueDate.getTime() < Date.now()) {
      return null;
    }
  }

  // set the next coming year if it's an anniversary
  if (repeat) {
    const nextValidYear = new Date().getFullYear();
    valueDate.setFullYear(nextValidYear);
    if (valueDate.getTime() < Date.now()) {
      valueDate.setFullYear(nextValidYear + 1);
    }
  }

  const reminderId = [type, itemId, field, fieldNo, offset].join('-');
  const timezoneOffset = valueDate.getTimezoneOffset();
  const date = valueDate.toISOString().substring(0, 10);
  const docName = date + (timezoneOffset >= 0 ? '+' : '') + timezoneOffset.toString();

  const record: TReminderRecord = {
    docName,
    reminderId,
    data: {
      title,
      body,
      type,
      itemId,
      field,
      repeat,
      offset,
      value,
      date,
    },
  };

  if (image !== undefined) {
    record.data.image = image;
  }

  return record;
};

export const maxReminderOffset = (date: string | undefined): number => {
  if (date) {
    const value = new Date(date);
    if (!isNaN(value.valueOf())) {
      return Math.floor((value.getTime() - Date.now()) / (1000 * 60 * 60 * 24));
    }
  }
  return 0;
};
