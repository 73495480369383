import { useEffect } from 'react';
import { store, rrfProps } from './store/store';
import { StatusBar, Style } from '@capacitor/status-bar';

// components
import { Redirect, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { setupIonicReact, IonApp, isPlatform } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Navigation from './components/Navigation';
import DataLoader from './components/DataLoader';
import Intl from './components/Intl';
import AppUrlListener from './components/AppUrlListener';

// pages
import PaperPage from './pages/PaperPage';
import PapersList from './pages/PapersList';
import Stacks from './pages/Stacks';
import Stack from './pages/Stack';
import Contacts from './pages/Contacts';
import Contact from './pages/Contact';
import Settings from './pages/Settings';
import CustomTypes from './pages/CustomTypes';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.scss';
import './theme/app.scss';

/* Swiper styles */
import 'swiper/css';
import '@ionic/react/css/ionic-swiper.css';

setupIonicReact({
  hardwareBackButton: true,
  statusTap: true,
  swipeBackEnabled: isPlatform('ios') ? true : false,
});

const App: React.FC = () => {
  /**
   * Setting status bar color to black on Android
   */
  const setStatusBarStyleDark = async () => {
    await StatusBar.setStyle({ style: Style.Dark });
    await StatusBar.setBackgroundColor({ color: '#000000' });
  };

  useEffect(() => {
    if (isPlatform('android') && isPlatform('capacitor')) {
      setStatusBarStyleDark();
    }
  }, []);

  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <Intl>
          <DataLoader />
          <IonApp>
            <IonReactRouter>
              <AppUrlListener />
              <Navigation>
                <Route exact path="/settings/types">
                  <CustomTypes />
                </Route>
                <Route exact path="/settings">
                  <Settings />
                </Route>
                <Route exact path="/papers/:paperId">
                  <PaperPage />
                </Route>
                <Route exact path="/papers">
                  <PapersList />
                </Route>
                <Route exact path="/stacks/:stackId/:paperId">
                  <PaperPage />
                </Route>
                <Route exact path="/stacks/:stackId">
                  <Stack />
                </Route>
                <Route exact path="/stacks">
                  <Stacks />
                </Route>
                <Route exact path="/contacts/:contactId/:paperId">
                  <PaperPage />
                </Route>
                <Route exact path="/contacts/:contactId">
                  <Contact />
                </Route>
                <Route exact path="/contacts">
                  <Contacts />
                </Route>
                <Route exact path="/">
                  <Redirect to="/papers" />
                </Route>
              </Navigation>
            </IonReactRouter>
          </IonApp>
        </Intl>
      </ReactReduxFirebaseProvider>
    </Provider>
  );
};

export default App;
