// second link to Firebase to call the functions
import fb from 'firebase/app';

// hooks
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useFirebase } from 'react-redux-firebase';

// utils
import { generateKeyPair, key2str } from '../utilities/encryption';
import { emitCustomEvent } from 'react-custom-events';

// components
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonTitle,
  IonContent,
  IonLabel,
  IonList,
  IonItem,
  IonRange,
  IonItemGroup,
  IonItemDivider,
  IonFooter,
} from '@ionic/react';
import { FormattedMessage, useIntl } from 'react-intl';
import PapersListItem from './PapersListItem';
import DetailsItem from './DetailsItem';
import PinSetupModal from './PinSetupModal';
import BiometricSetupModal from './BiometricSetupModal';

// icons and types
import { checkmarkSharp, closeOutline } from 'ionicons/icons';
import { TStoreState } from '../store/store';

type TEncryptionModalProps = {
  isOpen: boolean;
  onDidDismiss: () => void;
};

const EncryptionModal: React.FC<TEncryptionModalProps> = (props: TEncryptionModalProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const firebase = useFirebase();

  const routerRef = useSelector((state: TStoreState) => state.ui?.routerRef);
  const user = useSelector((state: any) => state.firebase?.auth);
  const profile = useSelector((state: TStoreState) => state.firebase?.profile, shallowEqual);
  const privateKey = useSelector((state: TStoreState) => state.ui?.privateKey, shallowEqual);
  const device = useSelector((state: TStoreState) => state.device, shallowEqual);

  const [thisDevice, setThisDevice] = useState<any>();
  useEffect(() => {
    if (device?.deviceId && profile?.devices?.[device.deviceId]) {
      setThisDevice(profile.devices[device.deviceId]);
    }
  }, [profile, device]);

  const uid = useSelector((state: TStoreState) => state.firebase?.auth?.uid);

  const [buttonDisabled, setButtonDisabled] = useState(false);

  /**
   * Generate new keyPair for the user and initiate the encryption process
   */
  const turnOnEncryption = async () => {
    setButtonDisabled(true);

    const keyPair = await generateKeyPair();

    if (keyPair.privateKey && keyPair.publicKey) {
      // save the keyPair to the profile using a function, since the private key goes to a separate db
      const privateKey = await key2str(keyPair.privateKey);
      const publicKey = await key2str(keyPair.publicKey);

      try {
        const storeKeysToUser = fb.app().functions('europe-west3').httpsCallable('users-storeKey');
        await storeKeysToUser({ uid, privateKey, publicKey });
      } catch (err) {
        setButtonDisabled(false);
        console.error('error calling users-storeKey function', err);
      }
    }
  };

  const [deauthTime, setDeauthTime] = useState(profile?.deauthTime || 15);
  useEffect(() => {
    if (profile?.deauthTime && profile.deauthTime !== deauthTime) {
      setDeauthTime(profile.deauthTime);
    }
  }, [profile]);

  const updateDeauthTime = (minutes: number) => {
    setDeauthTime(minutes);
    firebase.updateProfile({ deauthTime: minutes });
  };

  const requestAuthorization = async () => {
    emitCustomEvent('request-authorization');
  };

  const lockTheData = () => {
    dispatch({ type: 'DEAUTHORIZE' });
  };

  /**
   * PIN setup
   */
  const [isPinSetupModalOpen, setPinSetupModalOpen] = useState(false);

  /**
   * Biometry setup
   */
  const [isBiometrySetupModalOpen, setBiometrySetupModalOpen] = useState(false);

  const openBiometrySetup = () => {
    if (device?.biometryAvailable) {
      setBiometrySetupModalOpen(true);
    }
  };

  const showAuthModal = () => {
    emitCustomEvent('showModal', 'registration');
  };

  return (
    <IonModal
      mode="md"
      isOpen={props.isOpen}
      onDidDismiss={props.onDidDismiss}
      presentingElement={routerRef || undefined}
    >
      <IonHeader mode="md">
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={props.onDidDismiss}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>
            <FormattedMessage
              id="modal.encryption.title"
              defaultMessage="Data Encryption and Privacy"
            />
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      {user?.isAnonymous ||
      !profile ||
      profile.isEmpty === true ||
      profile.account === 'anonymous' ? (
        <>
          <IonContent>
            <p className="modal-description-text">
              <FormattedMessage
                id="modal.encryption.need-account"
                defaultMessage="You need to have an account to use this feature."
              />
            </p>
          </IonContent>
          <IonFooter>
            <p className="modal-footer-button">
              <IonButton
                onClick={showAuthModal}
                mode="md"
                shape="round"
                fill="outline"
                className="ion-activated"
              >
                <IonLabel>
                  <FormattedMessage
                    id="ui.buttons.login-or-register"
                    defaultMessage="Register or Sign In"
                  />
                </IonLabel>
              </IonButton>
            </p>
          </IonFooter>
        </>
      ) : profile && !profile.publicKey ? (
        <>
          <IonContent>
            <p className="modal-description-text">
              <FormattedMessage
                id="modal.encryption.not-all-data-encrypted"
                defaultMessage="Turning the encryption on will not encrypt all of your data, but only the fields with
                private data."
              />
            </p>
            <p className="modal-description-text">
              <FormattedMessage
                id="modal.encryption.encrypted-data-as-dots"
                defaultMessage="Encrypted data will be shown in the app as dots and the paper previews as a lock icon."
              />
            </p>
            <IonList>
              <PapersListItem
                paper={{
                  id: 'demo',
                  createdAt: '1234-12-12',
                  form: 'bill',
                  title: { value: intl.formatMessage({ id: 'modal.encryption.demo-paper.title' }) },
                  reference: {
                    value: intl.formatMessage({ id: 'modal.encryption.demo-paper.description' }),
                  },
                  total: { encrypt: true, value: '12.34' },
                  paid: { value: true },
                  privacy: { value: true },
                }}
              />
            </IonList>
            <p className="modal-description-text">
              <FormattedMessage
                id="modal.encryption.additional-privacy-fields"
                defaultMessage="And for the fields where additional privacy is needed, you will be asked for
                authorization even just to view the stored values:"
              />
            </p>
            <IonList>
              <DetailsItem
                id="demo-field"
                name="demo-field"
                type="text"
                itemId="demo"
                lines={'inset'}
                value={'• • • • • • •'}
                copyValue={''}
                label={intl.formatMessage({ id: 'modal.encryption.demo-field.label' })}
                actions={{
                  unlock: true,
                }}
              />
            </IonList>
            <p className="modal-description-text">
              <FormattedMessage
                id="modal.encryption.encrypton-is-forever"
                defaultMessage="Keep in mind that you will not be able to switch the encryption off."
              />
            </p>
          </IonContent>
          <IonFooter>
            <p className="modal-footer-button">
              <IonButton
                onClick={turnOnEncryption}
                mode="md"
                shape="round"
                fill="outline"
                className="ion-activated"
                disabled={buttonDisabled}
              >
                <IonLabel>
                  <FormattedMessage
                    id="modal.encryption.button.turn-on-encryption"
                    defaultMessage="Turn on Encryption"
                  />
                </IonLabel>
              </IonButton>
            </p>
          </IonFooter>
        </>
      ) : (
        <>
          <IonContent>
            <p className="modal-description-bold">
              <FormattedMessage
                id="modal.encryption.encryption-on"
                defaultMessage="All the personal data fields are being encrypted."
              />
            </p>
            <IonList mode="md">
              <IonItemGroup>
                <IonItemDivider mode="md">
                  <IonLabel>
                    <FormattedMessage
                      id="modal.encryption.auth-setup"
                      defaultMessage="Authorization setup"
                    />
                  </IonLabel>
                </IonItemDivider>
                <IonItem detail={false}>
                  <IonLabel>
                    <h2>
                      <FormattedMessage
                        id="modal.encryption.password.title"
                        defaultMessage="Account password"
                      />
                    </h2>
                    <p className="item--long-description">
                      <FormattedMessage
                        id="modal.encryption.password.description"
                        defaultMessage="Use your password to access encrypted data"
                      />
                    </p>
                  </IonLabel>
                  <IonIcon icon={checkmarkSharp} slot="end" color="primary" />
                </IonItem>
                <IonItem button detail={false} onClick={() => setPinSetupModalOpen(true)}>
                  <IonLabel>
                    <h2>
                      <FormattedMessage id="modal.encryption.pin.title" defaultMessage="Use PIN" />
                    </h2>
                    <p className="item--long-description">
                      {profile.pin ? (
                        <FormattedMessage
                          id="modal.encryption.pin.description"
                          defaultMessage="PIN is set. Tap to change"
                        />
                      ) : (
                        <FormattedMessage
                          id="modal.encryption.pin.no-pin-description"
                          defaultMessage="PIN is not set yet"
                        />
                      )}
                    </p>
                  </IonLabel>
                  {profile?.pin && <IonIcon icon={checkmarkSharp} slot="end" color="primary" />}
                </IonItem>
                <IonItem
                  button={true}
                  detail={false}
                  disabled={!device?.biometryAvailable}
                  onClick={() => openBiometrySetup()}
                >
                  <IonLabel>
                    <h2>
                      <FormattedMessage
                        id="modal.encryption.biometrics.title"
                        defaultMessage="Device Biometry"
                      />
                    </h2>
                    <p className="item--long-description">
                      {thisDevice?.biometryKeyStored ? (
                        <FormattedMessage
                          id="modal.encryption.biometrics.set"
                          defaultMessage="Biometric authorization is set"
                        />
                      ) : device?.biometryAvailable ? (
                        <FormattedMessage
                          id="modal.encryption.biometrics.available"
                          defaultMessage="Biometric authorization is available. Tap to set"
                        />
                      ) : (
                        <FormattedMessage
                          id="modal.encryption.biometrics.description"
                          defaultMessage="Biometric authorization is not available on this device"
                        />
                      )}
                    </p>
                  </IonLabel>
                  {thisDevice?.biometryKeyStored && (
                    <IonIcon icon={checkmarkSharp} slot="end" color="primary" />
                  )}
                </IonItem>
              </IonItemGroup>
            </IonList>

            <IonList mode="md">
              <IonItemGroup>
                <IonItemDivider mode="md">
                  <IonLabel className="item--long-description">
                    <FormattedMessage
                      id="modal.encryption.idle-time.label"
                      defaultMessage="Idle time before the personal data is locked:"
                    />
                    <br />
                    <span
                      style={{
                        color: 'var(--ion-color-dark, #000)',
                      }}
                    >
                      <b>{deauthTime}</b>{' '}
                      <FormattedMessage id="ui.labels.minutes" defaultMessage="minutes" />
                    </span>
                    .
                  </IonLabel>
                </IonItemDivider>
                <IonItem>
                  <IonRange
                    pin={true}
                    value={deauthTime}
                    min={5}
                    max={60}
                    snaps={true}
                    step={5}
                    onIonChange={e => updateDeauthTime(e.detail.value as number)}
                  >
                    <IonLabel slot="start">5</IonLabel>
                    <IonLabel slot="end">60</IonLabel>
                  </IonRange>
                </IonItem>
              </IonItemGroup>
            </IonList>
          </IonContent>
          <IonFooter>
            <p className="modal-footer-button">
              {privateKey ? (
                <IonButton
                  mode="md"
                  shape="round"
                  fill="outline"
                  className="ion-activated"
                  onClick={lockTheData}
                >
                  <FormattedMessage id="ui.buttons.lock-the-data" defaultMessage="Lock the Data" />
                </IonButton>
              ) : (
                <IonButton
                  mode="md"
                  shape="round"
                  fill="outline"
                  className="ion-activated"
                  onClick={requestAuthorization}
                >
                  <FormattedMessage
                    id="ui.buttons.unlock-the-data"
                    defaultMessage="Unlock the Data"
                  />
                </IonButton>
              )}
            </p>
          </IonFooter>
        </>
      )}

      <PinSetupModal
        isOpen={isPinSetupModalOpen}
        onDidDismiss={() => setPinSetupModalOpen(false)}
      />
      <BiometricSetupModal
        isOpen={isBiometrySetupModalOpen}
        onDidDismiss={() => setBiometrySetupModalOpen(false)}
      />
    </IonModal>
  );
};

export default EncryptionModal;
