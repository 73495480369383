import { Content } from '../models/Content';

export const _label = (label: Content = '', lang: string) => {
  if (typeof label === 'string') {
    return label;
  } else {
    return label[lang];
  }
};

export const classes = (obj: { [key: string]: boolean }): string => {
  return Object.keys(obj)
    .filter(key => obj[key])
    .join(' ');
};
