// hooks
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { useObjectCollection } from '../hooks/useObjectCollection';

// plugins
import { Clipboard } from '@capacitor/clipboard';
import { emitCustomEvent, useCustomEventListener } from 'react-custom-events';
import { maxReminderOffset } from '../utilities/reminders';
import { getURL } from '../utilities/values';

// components
import {
  IonBadge,
  IonButton,
  IonButtons,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonItemOptions,
  IonItemOption,
  IonItemSliding,
  IonLabel,
  IonList,
  useIonAlert,
  useIonPopover,
  useIonToast,
  IonReorderGroup,
  ItemReorderEventDetail,
  useIonViewWillLeave,
  isPlatform,
} from '@ionic/react';
import { PaperFrame } from './PaperFrames';
import PapersListItem from './PapersListItem';
import ContactsListItem from './ContactsListItem';
import { Link, useHistory, useLocation } from 'react-router-dom';
import LinkPaperModal from '../forms/LinkPaperModal';
import LinkStackModal from '../forms/LinkStackModal';
import StacksListItem from './StacksListItem';

// icons
import {
  addOutline,
  callOutline,
  checkmark,
  copyOutline,
  earthOutline,
  ellipsisHorizontalOutline,
  ellipsisVerticalOutline,
  keyOutline,
  mailOutline,
  navigateOutline,
  notificationsOutline,
} from 'ionicons/icons';

// types
import { TFilePreview, TFileValue } from '../models/FieldValues';
import { PapersState } from '../models/Paper';
import { ContactsState } from '../models/Contact';
import { TStoreState } from '../store/store';

type TDetailsItemParams = {
  id?: string;
  name: string;
  index?: number;
  type: string;
  itemId: string;
  label?: string;
  labelId?: string;
  value: string | number | boolean | null | undefined;
  copyValue: string | number | boolean | null | undefined;
  files?: TFileValue | TFileValue[];
  thumbnails?: TFilePreview[];
  previews?: TFilePreview[];
  avatars?: TFilePreview[];
  lines?: 'full' | 'inset' | 'none';
  actions?: {
    [action: string]: boolean | string;
  };

  reminders?: { [fieldIdAndItemNo: string]: string };
  repeat?: boolean;
  createReminder?: (
    field: string,
    fieldNo: number,
    offset: number,
    value: string,
    repeat?: boolean,
  ) => void;
  deleteReminder?: (docName: string, remiderId: string) => void;

  hidden?: boolean;
  options?: {
    value: string | number;
    labelId: string;
  }[];

  className?: string;
  displayLarge?: boolean;

  addPaper?: (paperid: string) => void;
  removePaper?: (paperid: string) => void;
  reorderPapers?: (from: number, to: number) => void;
  papersRouterLink?: string;

  addStack?: (stackid: string) => void;
  removeStack?: (stackid: string) => void;
  reorderStacks?: (from: number, to: number) => void;

  alwaysVisibleInDetails?: boolean;
};

const DetailsItem = ({
  id,
  name,
  index,
  type,
  itemId,
  label,
  labelId,
  value,
  copyValue,
  files,
  thumbnails,
  lines,
  actions,
  reminders,
  repeat,
  createReminder,
  deleteReminder,
  hidden = false,
  options,
  className,
  displayLarge = false,
  addPaper,
  removePaper,
  reorderPapers,
  papersRouterLink = '/papers/',
  addStack,
  removeStack,
  reorderStacks,
  alwaysVisibleInDetails = false,
}: TDetailsItemParams): React.ReactElement => {
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  const [presentToast] = useIonToast();

  const profile = useSelector((state: TStoreState) => state.firebase.profile);
  const papers = useSelector((state: TStoreState) => state.data.papers) as PapersState;
  const stacks = useObjectCollection('stacks');
  const contacts = useSelector(
    (state: TStoreState) => state.data?.contacts,
    shallowEqual,
  ) as ContactsState;
  const canSetNotifications = useSelector(
    (state: TStoreState) => state.device?.canSetNotifications,
  );

  const [isLocked, setIsLocked] = useState(false);
  useEffect(() => {
    setIsLocked((value?.toString().indexOf('•') || -1) >= 0);
  }, [value]);

  const copyToClipboard = async (value: any, type?: boolean | string) => {
    await Clipboard.write({
      string: type === 'no-spaces' ? value.replace(/\s/gi, '') : value,
    });
    presentToast({
      message: intl.formatMessage({ id: 'ui.toast.copied' }),
      duration: 2000,
      position: 'top',
      color: 'medium',
    });
  };

  const [presentAlert] = useIonAlert();

  const [reminderOffsets] = useState([
    { offset: 0, labelId: 'reminder.options.at-date' },
    { offset: 1, labelId: 'reminder.options.day-before' },
    // { offset: 2, labelId: 'reminder.options.2-days-before' },
    { offset: 3, labelId: 'reminder.options.3-days-before' },
    { offset: 7, labelId: 'reminder.options.week-before' },
    // { offset: 14, labelId: 'reminder.options.2-weeks-before' },
    // { offset: 21, labelId: 'reminder.options.3-weeks-before' },
    { offset: 30, labelId: 'reminder.options.month-before' },
  ]);

  const reminderCount = Object.keys(reminders || {}).filter(
    remId => remId.indexOf(`${name}-${index || 0}`) === 0,
  ).length;

  const [presentReminderMenu, closeReminderMenu] = useIonPopover(() => {
    return (
      <IonList>
        <IonItemDivider mode="md">
          <IonLabel>
            <FormattedMessage id="reminder.options.title" />
          </IonLabel>
        </IonItemDivider>
        {reminderOffsets
          .filter(item => item.offset <= reminderOffset)
          .map((item, idx, arr) => {
            const active = reminders?.[`${name}-${index || 0}-${item.offset}`] !== undefined;
            return (
              <IonItem
                key={item.offset}
                onClick={() => {
                  if (active && removeReminder) {
                    removeReminder(
                      reminders?.[`${name}-${index || 0}-${item.offset}`],
                      `${name}-${index || 0}-${item.offset}`,
                    );
                  }
                  if (!active && createReminder) {
                    setReminder(item.offset);
                  }
                  closeReminderMenu();
                }}
                detail={false}
                lines={idx < arr.length - 1 ? 'inset' : 'none'}
                button
              >
                {active && <IonIcon slot="end" icon={checkmark} size="small" />}
                <FormattedMessage id={item.labelId} />
              </IonItem>
            );
          })}
      </IonList>
    );
  });

  const reminderOffset = useMemo(
    () => (actions?.reminder && repeat ? 1000 : maxReminderOffset(copyValue?.toString())) || 0,
    [copyValue],
  );

  /**
   * Papers menu and actions
   */
  const [isReorder, setReorder] = useState(false);

  useIonViewWillLeave(() => {
    setReorder(false);
  });

  const [presentPapersMenu, closePapersMenu] = useIonPopover(() => {
    return (
      <IonList>
        <IonItem
          onClick={() => {
            setReorder(false);
            setIsModalOpen(true);
            closePapersMenu();
          }}
          detail={false}
          lines={'inset'}
          button
        >
          <FormattedMessage id="ui.buttons.add-paper" defaultMessage="Add Paper" />
        </IonItem>
        {reorderPapers && (
          <IonItem
            onClick={() => {
              setReorder(value => !value);
              closePapersMenu();
            }}
            detail={false}
            lines={'none'}
            button
          >
            {isReorder ? (
              <FormattedMessage
                id="ui.buttons.finish-reordering"
                defaultMessage="Finish reordering"
              />
            ) : (
              <FormattedMessage id="ui.buttons.reorder-papers" defaultMessage="Reorder Papers" />
            )}
          </IonItem>
        )}
      </IonList>
    );
  });

  const [presentStacksMenu, closeStacksMenu] = useIonPopover(() => {
    return (
      <IonList>
        <IonItem
          onClick={() => {
            setReorder(false);
            setIsModalOpen(true);
            closeStacksMenu();
          }}
          detail={false}
          lines={'inset'}
          button
        >
          <FormattedMessage id="ui.buttons.add-paper" defaultMessage="Add Paper" />
        </IonItem>
        {reorderPapers && (
          <IonItem
            onClick={() => {
              setReorder(value => !value);
              closeStacksMenu();
            }}
            detail={false}
            lines={'none'}
            button
          >
            {isReorder ? (
              <FormattedMessage
                id="ui.buttons.finish-reordering"
                defaultMessage="Finish reordering"
              />
            ) : (
              <FormattedMessage id="ui.buttons.reorder-papers" defaultMessage="Reorder Papers" />
            )}
          </IonItem>
        )}
      </IonList>
    );
  });

  const doReorderPapers = (event: CustomEvent<ItemReorderEventDetail>) => {
    reorderPapers?.(event.detail.from, event.detail.to);
    event.detail.complete();
  };

  const doReorderStacks = (event: CustomEvent<ItemReorderEventDetail>) => {
    reorderStacks?.(event.detail.from, event.detail.to);
    event.detail.complete();
  };

  /**
   * Toggling the visibility of the hidden values
   */
  const [hiddenVisible, setHiddenVisible] = useState(false);

  useCustomEventListener('grant-authorization', ({ fieldName }: { fieldName?: string }) => {
    if (fieldName === name) {
      setHiddenVisible(true);
    }
  });

  const unlockField = (): void => {
    if (hidden && !hiddenVisible) {
      // we have to request the auth popup and wait for the returned success
      if (profile.publicKey) {
        emitCustomEvent('request-authorization', { fieldName: name });
      } else {
        setHiddenVisible(true);
      }
    }
    if (hidden && hiddenVisible) {
      // hide th value again
      setHiddenVisible(false);
    }
  };

  // modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (
    !files &&
    !alwaysVisibleInDetails &&
    (!value || value === '' || (Array.isArray(value) && value.filter(v => !!v).length === 0))
  ) {
    return <></>;
  }

  const paperListItem = (item: string) =>
    removePaper ? (
      <IonItemSliding key={item}>
        <PapersListItem
          paper={papers.items?.[item]}
          routerLink={papersRouterLink}
          currentPath={location.pathname}
          thumbs={
            papers.items?.[item]?.thumbnail?.map((thumb: TFilePreview) => thumb.url || '') || []
          }
          reorder={isReorder}
        />
        <IonItemOptions side="end" onIonSwipe={() => removePaper(item)}>
          <IonItemOption color="danger" expandable onClick={() => removePaper(item)}>
            <FormattedMessage id="ui.buttons.remove" defaultMessage="Remove" />
          </IonItemOption>
        </IonItemOptions>
      </IonItemSliding>
    ) : (
      <PapersListItem
        key={item}
        paper={papers.items?.[item]}
        routerLink={papersRouterLink}
        reorder={isReorder}
        thumbs={
          papers.items?.[item]?.thumbnail?.map((thumb: TFilePreview) => thumb.url || '') || []
        }
      />
    );

  const stackListItem = (item: string) =>
    removeStack ? (
      <IonItemSliding key={item}>
        <StacksListItem stack={stacks.items?.[item]} routerLink={`/stacks/`} reorder={isReorder} />
        <IonItemOptions side="end" onIonSwipe={() => removeStack(item)}>
          <IonItemOption color="danger" expandable onClick={() => removeStack(item)}>
            <FormattedMessage id="ui.buttons.remove" defaultMessage="Remove" />
          </IonItemOption>
        </IonItemOptions>
      </IonItemSliding>
    ) : (
      <StacksListItem
        key={item}
        stack={stacks.items?.[item]}
        routerLink={`/stacks/`}
        reorder={isReorder}
      />
    );

  if (type === 'paper' && (alwaysVisibleInDetails || value)) {
    return (
      <>
        <IonItemDivider mode="md" className="details__divider details__divider_with-button">
          <IonLabel>{label || (labelId && <FormattedMessage id={labelId} />)}</IonLabel>
          {addPaper && (
            <IonButtons slot="end" className="toolbar-buttons">
              {isReorder ? (
                <IonButton
                  shape="round"
                  fill="outline"
                  size="small"
                  className="add-button"
                  onClick={() => {
                    setReorder(false);
                  }}
                >
                  <IonLabel>
                    <FormattedMessage id="ui.buttons.finish" defaultMessage="Finish" />
                  </IonLabel>
                </IonButton>
              ) : (
                <IonButton
                  shape="round"
                  fill="outline"
                  size="small"
                  className="add-button"
                  onClick={() => {
                    setReorder(false);
                    setIsModalOpen(true);
                  }}
                >
                  <IonIcon icon={addOutline} slot="icon-only" className="add-button--icon" />
                </IonButton>
              )}

              {reorderPapers && value && value.toString().indexOf(', ') > 0 && (
                <IonButton
                  shape="round"
                  className="add-button"
                  size="small"
                  onClick={evt => {
                    evt?.stopPropagation();
                    presentPapersMenu({
                      event: evt.nativeEvent,
                      side: 'bottom',
                      alignment: 'end',
                      arrow: false,
                    });
                  }}
                  style={{
                    width: '40px',
                    height: '40px',
                  }}
                >
                  <IonIcon
                    className="add-button--icon"
                    slot="icon-only"
                    md={ellipsisVerticalOutline}
                    ios={ellipsisHorizontalOutline}
                  />
                </IonButton>
              )}
            </IonButtons>
          )}
        </IonItemDivider>
        <IonReorderGroup disabled={!isReorder} onIonItemReorder={doReorderPapers}>
          {value && value.toString().indexOf(', ') > 0
            ? value
                .toString()
                .split(', ')
                .map((item: string) => paperListItem(item))
            : value && paperListItem(value.toString())}
        </IonReorderGroup>
        <LinkPaperModal
          opened={isModalOpen}
          onDismiss={() => setIsModalOpen(false)}
          setPaperId={(paperId: string) => {
            setIsModalOpen(false);
            addPaper?.(paperId);
          }}
        />
      </>
    );
  }

  if (type === 'stacks' && (alwaysVisibleInDetails || value)) {
    return (
      <>
        <IonItemDivider mode="md" className="details__divider details__divider_with-button">
          <IonLabel>{label || (labelId && <FormattedMessage id={labelId} />)}</IonLabel>
          {addStack && (
            <IonButtons slot="end" className="toolbar-buttons">
              <IonButton
                shape="round"
                fill="outline"
                size="small"
                className="add-button"
                onClick={() => {
                  setReorder(false);
                  setIsModalOpen(true);
                }}
              >
                <IonIcon icon={addOutline} slot="icon-only" className="add-button--icon" />
              </IonButton>
              {reorderStacks && value && value.toString().indexOf(', ') > 0 && (
                <IonButton
                  shape="round"
                  className="add-button"
                  size="small"
                  onClick={evt => {
                    evt?.stopPropagation();
                    presentStacksMenu({
                      event: evt.nativeEvent,
                      side: 'bottom',
                      alignment: 'end',
                      arrow: false,
                    });
                  }}
                  style={{
                    width: '40px',
                    height: '40px',
                  }}
                >
                  <IonIcon
                    className="add-button--icon"
                    slot="icon-only"
                    md={ellipsisVerticalOutline}
                    ios={ellipsisHorizontalOutline}
                  />
                </IonButton>
              )}
            </IonButtons>
          )}
        </IonItemDivider>
        <IonReorderGroup disabled={!isReorder} onIonItemReorder={doReorderStacks}>
          {value && value.toString().indexOf(', ') > 0
            ? value
                .toString()
                .split(', ')
                .map((item: string) => stackListItem(item))
            : value && stackListItem(value.toString())}
        </IonReorderGroup>
        <LinkStackModal
          opened={isModalOpen}
          onDismiss={() => setIsModalOpen(false)}
          setStackId={(stackId: string) => {
            setIsModalOpen(false);
            addStack?.(stackId);
          }}
        />
      </>
    );
  }

  if (value && type === 'contact') {
    const title = label || (labelId && <FormattedMessage id={labelId} />);

    const contactsList = value
      .toString()
      .split(', ')
      .map(contactId => contacts.items?.[contactId])
      .filter(contact => !!contact);

    if (contactsList.length === 0) {
      return <></>;
    }
    return (
      <>
        {title && (
          <IonItemDivider mode="md" className="details__divider">
            <IonLabel>{title}</IonLabel>
          </IonItemDivider>
        )}
        {contactsList.map(item => (
          <ContactsListItem key={item.id} contact={item} routerLink={`/contacts/`} />
        ))}
      </>
    );
  }

  /**
   * Adds reminder for the selected data with the required offset
   *
   * @param offset  how many days before the date should the notification be sent
   */
  const setReminder = (offset: number): void => {
    if (createReminder) {
      createReminder(name, index || 0, offset, copyValue?.toString() || '', repeat);
    }
  };

  const removeReminder = (docName: string, reminderId: string): void => {
    if (deleteReminder) {
      deleteReminder(docName, reminderId);
    }
  };

  const gotoSettings = (): void => {
    history.push(`/settings`);
  };

  /**
   * Returns the value from the options array if any
   */
  const getOptionsValue = (val: string | number | boolean) => {
    if (options) {
      const item = options.find(item => item.value === val);
      if (item) {
        return <FormattedMessage id={item.labelId} />;
      }
    }
    return val;
  };

  /**
   * Returns the item value class name
   */
  const getItemValueClassName = (): string => {
    let className = 'details__item-value';
    // add the field id
    className += ' details__item-value_' + id;
    // if it should be display large
    if (displayLarge) {
      className += ' details__item-value_large';
    }

    return className;
  };

  return (
    <IonItem lines={lines} className={`details__item details__item_${id}`}>
      {files ? (
        <div className="details__files">
          {Array.isArray(thumbnails) &&
            thumbnails.map((thumb: TFilePreview) => (
              <div key={thumb.url} className="details__files_preview-container">
                <Link to={`/papers/${itemId}/preview/${thumb.pageFileName || thumb.fileId}`}>
                  <img className="details__files_preview" src={thumb.url} />
                  <PaperFrame className="details__files_preview-frame" strokeWidth=".015" />
                </Link>
              </div>
            ))}
        </div>
      ) : (
        <IonLabel>
          {labelId ? <FormattedMessage id={labelId} /> : label ? <p>{label}</p> : null}
          {value &&
            (hidden && !hiddenVisible ? (
              <h2 className={getItemValueClassName()}>• • • • • • • •</h2>
            ) : type === 'select' ? (
              <h2 className={getItemValueClassName()}>{getOptionsValue(value)}</h2>
            ) : type === 'total' && value.toString().indexOf('•') < 0 ? (
              <h2 className={getItemValueClassName() + ' ' + className}>
                <FormattedNumber
                  value={parseFloat(value.toString().replace(',', '.').split('|')[0])}
                  currency={value.toString().split('|')[1]}
                  style="currency"
                />
              </h2>
            ) : (
              <h2 className={getItemValueClassName()}>{value.toString().split('|')[0]}</h2>
            ))}
        </IonLabel>
      )}
      {actions && !isLocked && (
        <div className="details__actions" slot="end">
          {actions.reminder && reminderOffset >= 0 && (
            <>
              {reminderCount > 0 && (
                <IonBadge
                  color="primary"
                  style={{
                    transform: 'translate(150%, 20%) scale(.7)',
                    opacity: 0.7,
                    fontWeight: 800,
                  }}
                >
                  {reminderCount}
                </IonBadge>
              )}
              <IonButton
                fill="clear"
                shape="round"
                className="details__action"
                size="small"
                color={reminderCount > 0 ? 'primary' : 'medium'}
                onClick={evt => {
                  if (canSetNotifications) {
                    presentReminderMenu({
                      event: evt.nativeEvent,
                      alignment: 'end',
                      side: 'top',
                      arrow: false,
                    });
                  } else {
                    presentAlert({
                      header: intl.formatMessage({ id: 'reminder.alert.title' }),
                      message: intl.formatMessage({ id: 'reminder.alert.message' }),
                      buttons: [
                        intl.formatMessage({ id: 'ui.buttons.cancel' }),
                        {
                          text: intl.formatMessage({ id: 'ui.buttons.settings' }),
                          handler: () => gotoSettings(),
                        },
                      ],
                    });
                  }
                }}
              >
                <IonIcon slot="icon-only" icon={notificationsOutline} />
              </IonButton>
            </>
          )}

          {actions.copy && !isLocked && (!hidden || hiddenVisible) && (
            <IonButton
              fill="clear"
              shape="round"
              className="details__action details__action-light"
              size="small"
              color="medium"
              onClick={() => copyToClipboard(copyValue, actions.copy)}
            >
              <IonIcon slot="icon-only" icon={copyOutline} />
            </IonButton>
          )}
          {actions.call && !isLocked && (
            <IonButton
              fill="clear"
              shape="round"
              className="details__action"
              size="small"
              color="primary"
              href={`tel:${value}`}
            >
              <IonIcon slot="icon-only" icon={callOutline} />
            </IonButton>
          )}
          {actions.email && !isLocked && (
            <IonButton
              fill="clear"
              shape="round"
              className="details__action"
              size="small"
              color="primary"
              href={`mailto:${value}`}
            >
              <IonIcon slot="icon-only" icon={mailOutline} />
            </IonButton>
          )}
          {actions.link && !isLocked && (
            <IonButton
              fill="clear"
              shape="round"
              className="details__action"
              size="small"
              color="primary"
              href={getURL(value as string)}
              target="_blank"
            >
              <IonIcon slot="icon-only" icon={earthOutline} />
            </IonButton>
          )}
          {actions.directions && (
            <IonButton
              fill="clear"
              shape="round"
              className="details__action"
              size="small"
              color="primary"
              href={
                isPlatform('android')
                  ? `geo:0,0?q=${encodeURIComponent(value?.toString() || '')}`
                  : isPlatform('ios')
                  ? `maps://?address=${encodeURIComponent(
                      value?.toString().replace(/\s/g, '+') || '',
                    )}`
                  : `https://maps.google.com/?q=${encodeURIComponent(value?.toString() || '')}`
              }
              target="_blank"
            >
              <IonIcon slot="icon-only" icon={navigateOutline} />
            </IonButton>
          )}
          {actions.unlock && (
            <IonButton
              fill="clear"
              shape="round"
              className="details__action"
              size="small"
              color="primary"
              onClick={unlockField}
            >
              <IonIcon slot="icon-only" icon={keyOutline} />
            </IonButton>
          )}
        </div>
      )}
    </IonItem>
  );
};

export default DetailsItem;
