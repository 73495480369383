// hooks
import React, { useEffect, useState } from 'react';
import { useObjectCollection } from '../hooks/useObjectCollection';
import { useFirebase } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

// components
import StacksListItem from './StacksListItem';
import { IonItem, IonLabel, IonList } from '@ionic/react';

// types
import { Stack, StacksState } from '../models/Stack';
import { chevronDown, chevronForward } from 'ionicons/icons';
import { FormattedMessage } from 'react-intl';

type TStacksListProps = {
  routerLink?: string;
  searchText: string;
  clickHandler?: (paperId: string) => void;
};

const StacksList = ({
  routerLink,
  searchText = '',
  clickHandler,
}: TStacksListProps): JSX.Element => {
  const firebase = useFirebase();
  const location = useLocation();

  /**
   * Data
   */
  const stacks = useObjectCollection('stacks') as StacksState;

  const [pinned, setPinned] = useState<Stack[]>([]);
  const [groups, setGroups] = useState<{ [groupId: string]: Stack[] }>({});
  const [groupsOrder, setGroupsOrder] = useState<string[]>([]);

  const stackSort = (a: Stack, b: Stack): number => {
    return (a.updatedAt || a.createdAt) > (b.updatedAt || b.createdAt) ? -1 : 1;
  };

  useEffect(() => {
    if (stacks.state.isLoaded && !stacks.state.isEmpty) {
      // temp data
      const pinnedStacks: Stack[] = [];
      const stackGroups: { [groupId: string]: Stack[] } = {};

      Object.keys(stacks.items)
        .filter(stackId => stacks.items[stackId])
        .forEach(stackId => {
          const stack = stacks.items[stackId];
          if (stack.pinned?.value === true) {
            pinnedStacks.push({ ...stack });
          } else {
            const groupId = stack['stack-type']?.value as string;
            if (groupId) {
              if (!stackGroups[groupId]) {
                stackGroups[groupId] = [];
              }
              stackGroups[groupId].push({ ...stack });
            }
          }

          // sort pinned stacks
          pinnedStacks.sort(stackSort);
          Object.keys(stackGroups).forEach(groupId => {
            stackGroups[groupId].sort(stackSort);
          });

          // set state
          setPinned(pinnedStacks);
          setGroups(stackGroups);
          setGroupsOrder(
            Object.keys(stackGroups).sort((a, b) => {
              const s1 = stackGroups[a][0];
              const s2 = stackGroups[b][0];
              return (s1.updatedAt || s1.createdAt) > (s2.updatedAt || s2.createdAt) ? -1 : 1;
            }),
          );
        });
    }
  }, [stacks.state]);

  // keep the groups open state
  const openState = useSelector((state: any) => state.firebase.profile?.stacksGroupsState || {});
  const toggleOpenState = (groupId: string) => {
    const newState = { ...openState, [groupId]: !openState[groupId] };
    firebase.updateProfile({ stacksGroupsState: newState });
  };

  return (
    <IonList className="leave-space-for-fab-at-the-bottom">
      {pinned.length > 0 &&
        pinned.map((stack: Stack) => (
          <StacksListItem
            key={stack.id}
            stack={stack}
            clickHandler={clickHandler}
            routerLink={routerLink}
            currentPath={location.pathname}
          />
        ))}
      {groupsOrder.map(groupId => (
        <React.Fragment key={groupId}>
          <IonItem
            button
            detail={true}
            detailIcon={openState[groupId] ? chevronDown : chevronForward}
            onClick={() => toggleOpenState(groupId)}
            className="group-item"
          >
            <IonLabel>
              <p className="list-data list-data--primary">
                <FormattedMessage id={`page.stacks.groups.${groupId}`} />
                <span className="list-data--count">{groups[groupId].length}</span>
              </p>
            </IonLabel>
          </IonItem>
          {openState[groupId] &&
            groups[groupId].map(stack => (
              <StacksListItem
                key={stack.id}
                stack={stack}
                routerLink={routerLink}
                clickHandler={clickHandler}
                currentPath={location.pathname}
              />
            ))}
        </React.Fragment>
      ))}
    </IonList>
  );
};

export default StacksList;
