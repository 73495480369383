import { readAsDataURL } from 'promise-file-reader';
import { TFieldValue, TFileValue } from '../models/FieldValues';
import { deleteFiles, uploadFiles } from './files';
import {
  createFirestoreDocument,
  deleteFirestoreDocument,
  updateFirestoreDocument,
} from './firestore';

import { customAlphabet } from 'nanoid';
import { alphanumeric } from 'nanoid-dictionary';
import { Paper } from '../models/Paper';
const nanoid = customAlphabet(alphanumeric, 14);

/**
 * Adds only the new values to the existing files array
 *
 * @param files array with files with the new values
 * @param currentFiles  array with files already processed
 * @returns final array with files
 */
export const updateFiles = (files: TFileValue[], currentFiles: TFileValue[]): TFileValue[] => {
  if (files && files.length !== currentFiles?.length) {
    const updatedFiles = [...currentFiles];
    files.forEach(incomingFile => {
      if (!currentFiles.find(currentFile => currentFile.id === incomingFile.id)) {
        updatedFiles.push({
          id: incomingFile.id,
          name: incomingFile.name,
          size: incomingFile.size,
          mimeType: incomingFile.mimeType,
          data64: incomingFile.data64,
        });
      }
    });
    return updatedFiles;
  }
  if (files?.length) {
    return files;
  }
  return currentFiles;
};

/**
 * Returns the file object in the format that the form is expecting
 *
 * @param file  file blob object from the input
 * @returns
 */
export const getFormattedFileObj = async (file: any): Promise<TFileValue> => {
  const fileId = nanoid(14);

  return {
    id: fileId,
    name: file.name,
    mimeType: file.type || file.mimeType,
    size: file.size,
    // decode file to base64
    data64: file.data64 || (file instanceof Blob && (await readAsDataURL(file))) || null,
    path: (!(file instanceof Blob) && file.path) || null,
  };
};

/**
 * Returns the array of file objects from the file blobs
 *
 * @param files  array of files from the input
 * @returns
 */
export const getFormattedFilesArray = async (files: any[]): Promise<TFileValue[]> => {
  const formattedFiles: TFileValue[] = [];
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    const fileObj = await getFormattedFileObj(file);
    formattedFiles.push(fileObj);
  }
  return formattedFiles;
};

/**
 * Returns object with the data to create a new empty paper with files
 *
 * @param files
 * @returns
 */
export const getNewPaperData = (files: TFileValue[]) => {
  return {
    id: nanoid(14),
    files: {
      value: null,
      files,
    },
    form: 'document',
    title: { value: '' },
    reference: { value: '' },
  };
};

export const createPaper = async (
  user: any,
  firebase: any,
  firestore: any,
  docData: any,
  filesToUpload: TFileValue[],
): Promise<any> => {
  // set the paper as a new one
  docData.new = true;
  if (filesToUpload?.[0]?.name) {
    docData.title = {
      value: filesToUpload?.[0]?.name?.replace(/\.[^/.]+$/, ''),
    };
  }

  return await createFirestoreDocument(user, firebase, firestore, `papers/${docData.id}`, docData)
    .then(async res => {
      // upload the files to the storage
      const uid = user.uid || res.uid;
      await uploadFiles(firebase, filesToUpload, uid, 'paper', docData.id as string);

      return Promise.resolve(res);
    })
    .catch(err => {
      return Promise.reject(err);
    });
};

export const updatePaper = async (
  user: any,
  firebase: any,
  firestore: any,
  paperId: string,
  docData: any,
  filesToUpload?: TFileValue[],
): Promise<any> => {
  return await updateFirestoreDocument(user, firestore, `papers/${paperId}`, docData)
    .then(async res => {
      if (filesToUpload?.length) {
        // upload new files to the storage
        await uploadFiles(firebase, filesToUpload, user.uid, 'paper', paperId);
      }

      return Promise.resolve(res);
    })
    .catch(err => {
      return Promise.reject(err);
    });
};

/**
 * Deletes a single paper with its files
 *
 * @param paper  data of the document to delete
 * @param user  user data
 * @param firebase  pointer to the firebase instance
 * @param firebase  pointer to the firestore instance
 *
 * TODO: deal with the linked papers
 */
export const deletePaper = async (
  paper: Paper,
  user: any,
  firebase: any,
  firestore: any,
): Promise<any> => {
  // files to delete are all the files we have]
  const filesToDelete = (paper?.files as TFieldValue)?.files;
  if (filesToDelete?.length) {
    await deleteFiles(firebase, filesToDelete, user.uid, `/${user.uid}/paper/${paper.id}`, paper);
  }

  // update the data in the DB
  return await deleteFirestoreDocument(user, firestore, `papers/${paper.id}`);
};

/**
 * Deletes an array of papers with their files
 *
 * @param papers  array of papers to delete
 * @param user  user data
 * @param firebase  pointer to the firebase instance
 * @param firestore  pointer to the firestore instance
 * @returns Promise with the result of the deletion
 */
export const deletePapers = async (
  papers: Paper[],
  user: any,
  firebase: any,
  firestore: any,
): Promise<any> => {
  const promises: Promise<any>[] = [];
  papers.forEach(paper => {
    promises.push(deletePaper(paper, user, firebase, firestore));
  });
  return await Promise.all(promises);
};
