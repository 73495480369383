import React from 'react';
import { IonSpinner } from '@ionic/react';

import './LoadingScreen.css';

type TLoadingScreenProps = {
  color?: string;
};

const LoadingScreen = ({ color }: TLoadingScreenProps) => {
  return (
    <div className="loading-screen">
      <IonSpinner color={color || 'dark'} />
    </div>
  );
};

export default LoadingScreen;
