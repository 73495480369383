// hooks
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useCollectionItem } from '../hooks/useCollectionItem';
import { useFirebase, useFirestore } from 'react-redux-firebase';

// components
import { IonList, useIonLoading } from '@ionic/react';
import ModalForm from '../components/ModalForm';
import ModalFields from './ModalFields';

// helper functions
import { createFirestoreDocument, updateFirestoreDocument } from '../utilities/firestore';
import { deleteFiles, uploadFiles } from '../utilities/files';
import { processData } from '../utilities/forms';
import { getValue } from '../utilities/values';

// types
import { TCommonDocument } from '../models/FieldValues';
import { newStack } from '../models/Stack';
import { useCollectionItems } from '../hooks/useCollectionItems';

import { customAlphabet } from 'nanoid';
import { alphanumeric } from 'nanoid-dictionary';
const nanoid = customAlphabet(alphanumeric, 14);

type TStackModalProps = {
  opened: boolean;
  onDismiss: Function;
  onCreateNew?: Function;
  setClientId?: Function;
  stackId?: string;
};

const StackModal = (props: TStackModalProps) => {
  const intl = useIntl();
  const user = useSelector((state: any) => state.firebase.auth);
  const stack = useCollectionItem('stacks', props.stackId);
  const [presentLoading, dismissLoading] = useIonLoading();

  const returnNewFormData = (newData: TCommonDocument) => ({
    ...newData,
    id: nanoid(14),
    ...stack.item,
  });

  // data that will be updated in the form
  // starting with a new blank contact and then adding data that can come from the DB
  const [formData, setFormData] = useState<TCommonDocument>(returnNewFormData(newStack));

  const clearFormData = () => {
    setFormData(returnNewFormData(newStack));
  };

  const updateData = (property: string, value: any) => {
    if ((formData as any)[property] !== value) {
      setFormData(data => ({ ...data, [property]: value }));
    }
  };

  // in case client's data in the DB is changed, we have to update the form too
  useEffect(() => {
    if (stack.item && stack.item.id !== formData.id) {
      setFormData(data => ({
        ...data,
        ...stack.item,
      }));
    }
    // eslint-disable-next-line
  }, [stack, props.opened]);

  // form type
  const fields = useCollectionItem('types', 'fields');
  const forms = useCollectionItems('types', { record: 'stack' });

  // Save the data to the DB
  const firebase = useFirebase();
  const firestore = useFirestore();

  const createItem = async () => {
    // show loading
    presentLoading({
      message: intl.formatMessage({
        id: 'form.stack.creating',
        defaultMessage: 'Creating Stack...',
      }),
    });

    // get the files data out of the form
    const [docData, filesToUpload] = await processData(formData, stack.item);

    // save the data to the DB
    await createFirestoreDocument(user, firebase, firestore, `stacks/${formData.id}`, docData)
      .then(async res => {
        // upload the files to the storage
        const uid = user.uid || res.uid;
        await uploadFiles(firebase, filesToUpload, uid, 'stack', docData.id as string);

        // close the modal
        dismissLoading();
        clearFormData();
        props.onDismiss();
        if (props.onCreateNew) {
          props.onCreateNew(docData.id);
        }
      })
      .catch(err => {
        dismissLoading();
        console.error('Create Stack Error', err);
      });
  };

  const updateItem = async () => {
    // show loading
    presentLoading({
      message: intl.formatMessage({
        id: 'form.stack.updating',
        defaultMessage: 'Updating Stack...',
      }),
    });

    // get the files data out of the form
    const [docData, filesToUpload, filesToDelete] = await processData(formData, stack.item);

    // update the data in the DB
    await updateFirestoreDocument(user, firestore, `stacks/${formData.id}`, docData)
      .then(async () => {
        // upload new files to the storage
        await uploadFiles(firebase, filesToUpload, user.uid, 'stack', docData.id as string);

        // delete the removed files and their previews
        await deleteFiles(
          firebase,
          filesToDelete,
          user.uid,
          `/${user.uid}/stack/${props.stackId}`,
          stack.item,
        );

        // close the modal
        dismissLoading();
        props.onDismiss();
      })
      .catch(err => {
        dismissLoading();
        console.error('Update Stack Error', err);
      });
  };

  return (
    <ModalForm
      {...props}
      title={
        props.stackId
          ? intl.formatMessage(
              {
                id: 'form.stack.edit.title',
                defaultMessage: 'Edit {name}',
              },
              {
                name: getValue(
                  formData.displayName,
                  intl.formatMessage({
                    id: 'form.stack.edit.untitled',
                    defaultMessage: 'Untitled',
                  }),
                ),
              },
            )
          : intl.formatMessage({
              id: 'form.stack.new.title',
              defaultMessage: 'Add New Stack',
            })
      }
      action={{
        title: props.stackId
          ? intl.formatMessage({ id: 'ui.buttons.update' })
          : intl.formatMessage({ id: 'ui.buttons.create' }),
        callback: props.stackId ? updateItem : createItem,
      }}
    >
      <IonList>
        {forms?.items && Array.isArray(forms?.items) && forms.items.length > 0 && (
          <ModalFields
            fields={fields?.item}
            form={forms.items[0].fields}
            data={formData}
            thumbnails={stack.item?.thumbnail}
            updateData={updateData}
          />
        )}
      </IonList>
    </ModalForm>
  );
};

export default StackModal;
