// hooks
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { useCollectionItem } from '../hooks/useCollectionItem';
import { useCollectionItems } from '../hooks/useCollectionItems';
import { useFirebase, useFirestore } from 'react-redux-firebase';

// components
import ModalForm from '../components/ModalForm';
import { Contact, newContact } from '../models/Contact';
import { IonList, useIonLoading } from '@ionic/react';
import ModalFields from './ModalFields';

// helper functions
import { createFirestoreDocument, updateFirestoreDocument } from '../utilities/firestore';
import { TCommonDocument } from '../models/FieldValues';
import { processData } from '../utilities/forms';
import { getValue } from '../utilities/values';
import { deleteFiles, uploadFiles } from '../utilities/files';
import { TStoreState } from '../store/store';
import { str2key } from '../utilities/encryption';

import { customAlphabet } from 'nanoid';
import { alphanumeric } from 'nanoid-dictionary';
const nanoid = customAlphabet(alphanumeric, 14);

// types
type TContactModalProps = {
  opened: boolean;
  onDismiss: Function;
  onCreateNew?: Function;
  setContactId?: Function;
  contactId?: string;
};

const ContactModal: React.FC<TContactModalProps> = (props: TContactModalProps) => {
  const intl = useIntl();
  const user = useSelector((state: any) => state.firebase.auth);
  const contact = useSelector(
    (state: TStoreState) => state.data.contacts?.items?.[props?.contactId || 'new'],
    shallowEqual,
  ) as Contact;
  const [presentLoading, dismissLoading] = useIonLoading();

  const returnNewFormData = (newData: TCommonDocument) => ({
    ...newData,
    ...(contact || {}),
    id: props.contactId === 'new' ? nanoid(14) : props.contactId,
  });

  // encryption
  const profile = useSelector((state: TStoreState) => state.firebase.profile);
  const publicKey = useRef<CryptoKey | null>(null);
  useEffect(() => {
    if (profile?.publicKey) {
      str2key(profile.publicKey as string).then(key => {
        publicKey.current = key;
      });
    }
  }, [profile]);

  // data that will be updated in the form
  // starting with a new blank contact and then adding data that can come from the DB
  const [formData, setFormData] = useState<TCommonDocument>(returnNewFormData(newContact));

  const clearFormData = () => {
    setFormData(returnNewFormData(newContact));
  };

  const updateData = (property: string, value: any) => {
    if ((formData as any)[property] !== value) {
      setFormData(data => ({ ...data, [property]: value }));
    }
  };

  // in case client's data in the DB is changed, we have to update the form too
  useEffect(() => {
    if (contact && contact.id !== formData.id) {
      setFormData(data => ({
        ...data,
        ...contact,
      }));
    }
    // eslint-disable-next-line
  }, [contact, props.opened]);

  // form type
  const fields = useCollectionItem('types', 'fields');
  const forms = useCollectionItems('types', { record: 'contact' });

  // Save the data to the DB
  const firebase = useFirebase();
  const firestore = useFirestore();

  const createItem = async () => {
    // show loading
    presentLoading({
      message: intl.formatMessage({
        id: 'form.contact.creating',
        defaultMessage: 'Creating Contact...',
      }),
    });

    // get the files data out of the form
    const [docData, filesToUpload] = await processData(
      formData,
      contact,
      publicKey.current || undefined,
    );

    // save the data to the DB
    await createFirestoreDocument(user, firebase, firestore, `contacts/${formData.id}`, docData)
      .then(async res => {
        // upload the files to the storage
        const uid = user.uid || res.uid;
        await uploadFiles(firebase, filesToUpload, uid, 'contact', docData.id as string);

        // close the modal
        dismissLoading();
        clearFormData();
        props.onDismiss();
        if (props.onCreateNew) {
          props.onCreateNew(docData.id);
        }
      })
      .catch(err => {
        dismissLoading();
        console.error('Create Contact Error', err);
      });
  };

  const updateItem = async () => {
    // show loading
    presentLoading({
      message: intl.formatMessage({
        id: 'form.contact.updating',
        defaultMessage: 'Updating Contact...',
      }),
    });

    // get the files data out of the form
    const [docData, filesToUpload, filesToDelete] = await processData(
      formData,
      contact,
      publicKey.current || undefined,
    );

    // update the data in the DB
    await updateFirestoreDocument(user, firestore, `contacts/${formData.id}`, docData)
      .then(async () => {
        // upload new files to the storage
        await uploadFiles(firebase, filesToUpload, user.uid, 'contact', docData.id as string);

        // delete the removed files and their previews
        await deleteFiles(
          firebase,
          filesToDelete,
          user.uid,
          `/${user.uid}/contact/${props.contactId}`,
          contact,
        );

        // close the modal
        dismissLoading();
        clearFormData();
        props.onDismiss();
      })
      .catch(err => {
        dismissLoading();
        console.error('Update Contact Error', err);
      });
  };

  return (
    <ModalForm
      {...props}
      title={
        props.contactId !== 'new'
          ? intl.formatMessage(
              {
                id: 'form.contact.edit.title',
                defaultMessage: 'Edit {name}',
              },
              {
                name: getValue(
                  formData.displayName,
                  intl.formatMessage({
                    id: 'form.contact.edit.untitled',
                    defaultMessage: 'Untitled',
                  }),
                ),
              },
            )
          : intl.formatMessage({
              id: 'form.contact.new.title',
              defaultMessage: 'Add New Contact',
            })
      }
      action={{
        title:
          props.contactId === 'new'
            ? intl.formatMessage({ id: 'ui.buttons.create' })
            : intl.formatMessage({ id: 'ui.buttons.update' }),
        callback: props.contactId === 'new' ? createItem : updateItem,
      }}
    >
      <IonList className="form-fields">
        {forms?.items && Array.isArray(forms?.items) && forms.items.length > 0 && (
          <ModalFields
            fields={fields?.item}
            form={forms.items[0].fields}
            data={formData}
            avatars={contact?.avatar || contact?.avatars}
            updateData={updateData}
          />
        )}
      </IonList>
    </ModalForm>
  );
};

export default ContactModal;
