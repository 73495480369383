import { RequestState } from './RequestState';
import { TCommonDocument, TFieldValue, TFieldValues, TFilePreview } from './FieldValues';

export type Contact = {
  id: string;
  names: TFieldValue;
  displayName?: TFieldValue;
  otherNames?: TFieldValues;
  birthdate?: TFieldValue;
  occupation?: TFieldValue;
  personalNumber?: TFieldValues;
  business?: TFieldValues;
  phone?: TFieldValues;
  email?: TFieldValues;
  address?: TFieldValues;
  notes?: TFieldValue;
  iban?: TFieldValues;

  favorite: TFieldValue;

  photo?: TFieldValue;
  avatar?: TFilePreview[];
  avatars?: TFilePreview[];

  createdAt?: string;
  createdBy?: string;
  updatedAt?: string;
  updatedBy?: string;
};

export type ContactState = {
  item: Contact;
  state: RequestState;
};

export type ContactsState = {
  items: { [id: string]: Contact };
  state: RequestState;
};

export type ContactsOrderedState = {
  items: Contact[];
  state: RequestState;
};

export const newContact: TCommonDocument = {
  id: '',
  names: { value: '' },
  favorite: { value: false },
  personalNumber: [{ value: '' }],
};
