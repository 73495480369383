import { useRef, useState } from 'react';
import { ReactZoomPanPinchRef, TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { useDoubleTap } from 'use-double-tap';

const transformStyle = { width: '100%', height: '100%' };

type TPinchZoomProps = {
  children: React.ReactNode;
  onZoomChange?: (zoom: number) => void;
  transformWrapperProps?: any;
};

const PinchZoom = ({ children, onZoomChange, ...transformWrapperProps }: TPinchZoomProps) => {
  const ref = useRef<ReactZoomPanPinchRef | null>(null);
  const [shouldPan, setShouldPan] = useState(false);

  const onDoubleTap = useDoubleTap(evt => {
    if (shouldPan) {
      ref.current?.resetTransform?.();
      setTimeout(() => {
        setShouldPan(false);
      }, 200);
      evt.stopPropagation?.();
      evt.preventDefault?.();
    }
  }, 300);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        padding: '4px',
      }}
    >
      <TransformWrapper
        ref={ref}
        pinch={{
          disabled: false,
        }}
        panning={{
          // Don't pan when the user didn't zoom into the child
          disabled: !shouldPan,
          velocityDisabled: true,
        }}
        doubleClick={{
          step: 1.5,
          mode: shouldPan ? 'reset' : 'zoomIn',
        }}
        onZoomStop={() => {
          const roundedScale = Math.round(ref?.current?.instance?.transformState?.scale || 1);
          if (onZoomChange) {
            onZoomChange(roundedScale);
          }
          setShouldPan(roundedScale > 1);
        }}
        {...transformWrapperProps}
        centerOnInit={true}
        centerZoomedOut={true}
        maxScale={12}
        limitToBounds={true}
      >
        <TransformComponent wrapperStyle={transformStyle} contentStyle={transformStyle}>
          <div {...onDoubleTap}>{children}</div>
        </TransformComponent>
      </TransformWrapper>
    </div>
  );
};

export default PinchZoom;
