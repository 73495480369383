// utilities
import { updateFirestoreDocument } from './firestore';

// types
import { ExtendedFirebaseInstance } from 'react-redux-firebase';

export const addPapersToStack = async (
  stackId: string,
  paperIds: { value: string }[],
  user: any,
  firebase: ExtendedFirebaseInstance,
  firestore: any,
): Promise<any> => {
  return await updateFirestoreDocument(user, firestore, `stacks/${stackId}`, {
    'stack-papers': firebase.firestore.FieldValue.arrayUnion(...paperIds),
  }).then(() => {
    // create backlinks
    const papers = paperIds.map(paper => paper.value);
    const promises = papers.map(paperId =>
      updateFirestoreDocument(user, firestore, `papers/${paperId}`, {
        stacks: firebase.firestore.FieldValue.arrayUnion({ value: stackId }),
      }),
    );
    return Promise.all(promises);
  });
};
