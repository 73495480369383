// second link to Firebase to call the functions
import fb from 'firebase/app';

// hooks
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { FormEvent, useRef, useState } from 'react';
import { useFirebase } from 'react-redux-firebase';

// plugins and utils
import { NativeBiometric } from 'capacitor-native-biometric';
import { key2str, str2key } from '../utilities/encryption';

// components
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonList,
  IonModal,
  IonTitle,
  IonToolbar,
  useIonToast,
} from '@ionic/react';
import { FormattedMessage, useIntl } from 'react-intl';
import TextField from '../forms/TextField';

// icons and types
import { closeOutline } from 'ionicons/icons';
import { TStoreState } from '../store/store';

type TBiometricSetupModalProps = {
  isOpen: boolean;
  onDidDismiss: () => void;
};

const BiometricSetupModal: React.FC<TBiometricSetupModalProps> = (
  props: TBiometricSetupModalProps,
) => {
  const intl = useIntl();
  const firebase = useFirebase();
  const dispatch = useDispatch();
  const [presentToast] = useIonToast();

  const routerRef = useSelector((state: TStoreState) => state.ui?.routerRef);
  const uid = useSelector((state: TStoreState) => state.firebase?.auth?.uid);

  const password = useRef('');

  const [step, setStep] = useState(0);

  const [buttonDisabled, setButtonDisabled] = useState(false);

  const [error, setError] = useState<string | undefined>('');

  const storedPrivateKey = useSelector((state: TStoreState) => state.ui.privateKey);
  const storedPassword = useSelector((state: TStoreState) => state.ui.password);
  const profile = useSelector((state: TStoreState) => state.firebase?.profile, shallowEqual);
  const device = useSelector((state: TStoreState) => state.device, shallowEqual);

  /**
   * Biometrics
   */
  const verifyIdentity = async () => {
    NativeBiometric.verifyIdentity({
      reason: intl.formatMessage({
        id: 'ui.biometric.reason',
        defaultMessage: 'For easier access to the encrypted papers',
      }),
      title: intl.formatMessage({
        id: 'ui.biometric.title',
        defaultMessage: 'Verify your identity',
      }),
    }).then(() => {
      setStep(1);
    });
  };

  /**
   * password field
   */
  const passwordRef = useRef<any>(null);

  const updatePassword = (value: string) => {
    password.current = value;
    setError('');
  };

  /**
   * What to do after the user enters their password
   * @param evt
   */
  const processForm = async (evt?: FormEvent) => {
    if (evt) {
      evt.defaultPrevented = true;
      evt.preventDefault();
      evt.stopPropagation();
    }
    setButtonDisabled(true);
    setError('');

    if (storedPrivateKey && storedPassword && storedPassword === password.current) {
      password.current = '';
      await storePrivateKey(storedPrivateKey);
      props.onDidDismiss();
      return;
    } else if (storedPassword && storedPassword === password.current) {
      setError('invalid-password');
      return;
    }

    const getUserKeysFn = fb.app().functions('europe-west3').httpsCallable('users-getKey');
    const request: { uid: string; password: string } = { uid, password: password.current };

    await getUserKeysFn(request)
      .then(async key => {
        if (key?.data && typeof key?.data === 'string') {
          // we have the key
          // convert it and put it in the store
          const privateKey = await str2key(key.data);
          if (privateKey) {
            // should set in the device native credentials storage
            await storePrivateKey(key.data);

            // storing the key
            dispatch({
              type: 'SET_PRIVATE_KEY',
              payload: key.data,
            });

            // storing the password for future use
            // if we still have the private key – no need to go to the server every time
            dispatch({
              type: 'SET_PASSWORD',
              payload: password.current,
            });

            // update the profile
            firebase.updateProfile({ lastAuthUsed: 'password' });

            // clear the password
            password.current = '';

            // close the popup
            props.onDidDismiss();
          } else {
            setError('invalid-key');
          }
        }

        setButtonDisabled(false);
      })
      .catch(err => {
        console.error('error getting key', err.code);
        setButtonDisabled(false);
        setError(err.code);
      });
  };

  const storePrivateKey = async (privateKey: string | CryptoKey, secondAttempt = false) => {
    if (typeof privateKey !== 'string') {
      privateKey = await key2str(privateKey);
    }

    return NativeBiometric.setCredentials({
      server: 'https://thepapers.app',
      username: uid,
      password: privateKey,
    })
      .then(() => {
        if (device?.deviceId) {
          firebase.updateProfile({
            devices: {
              ...(profile.devices || {}),
              [device.deviceId]: {
                ...(profile.devices?.[device.deviceId] || {}),
                biometryKeyStored: true,
              },
            },
          });
        }
      })
      .catch(async err => {
        console.error('error storing private key', err);
        // if we failed to store the key, unfortunatelly
        // we can try to delete all the old records
        if (!secondAttempt) {
          await NativeBiometric.deleteCredentials({
            server: 'https://thepapers.app',
          });
          storePrivateKey(privateKey, true);
        } else {
          presentToast({
            message: intl.formatMessage({ id: 'ui.toast.error' }),
            duration: 2000,
            position: 'top',
            color: 'danger',
          });
          props.onDidDismiss();
        }
      });
  };

  return (
    <IonModal
      mode="md"
      isOpen={props.isOpen}
      onDidDismiss={props.onDidDismiss}
      presentingElement={routerRef || undefined}
    >
      <IonHeader mode="md">
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={props.onDidDismiss}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>
            <FormattedMessage
              id="modal.biometric-setup.title"
              defaultMessage="Setup Biometic Authentication"
            />
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {step === 0 ? (
          <div>
            <p className="modal-description-text">
              <FormattedMessage
                id="modal.biometric-setup.step-0.description"
                defaultMessage="You have authenticate using biometrics of this device first."
              />
            </p>
            <p className="modal-description-text">
              <FormattedMessage
                id="modal.biometric-setup.step-1.description"
                defaultMessage="Then you will have to enter your password to authorize the device."
              />
            </p>
            <p className="modal-description-button">
              <IonButton
                onClick={verifyIdentity}
                mode="md"
                shape="round"
                fill="outline"
                className="ion-activated"
              >
                <IonLabel>
                  <FormattedMessage
                    id="ui.buttons.biometric-verify"
                    defaultMessage="Verify yourself with biometrics"
                  />
                </IonLabel>
              </IonButton>
            </p>
          </div>
        ) : (
          <form
            onSubmit={processForm}
            style={{
              display: 'flex',
              width: '100%',
              height: 'calc(100% - 130px)',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <IonList style={{ margin: '1rem 0 3rem', width: '80%' }}>
              <TextField
                ref={passwordRef}
                name="password"
                value={password.current}
                type="password"
                onValueUpdate={value => updatePassword(value.value?.toString() || '')}
                label={intl.formatMessage({ id: 'ui.field.account-password.label' })}
                autofocus={true}
                onNext={() => processForm()}
                error={
                  error ? intl.formatMessage({ id: `auth.errors.password.${error}` }) : undefined
                }
              />
              <IonButton
                size="default"
                expand="block"
                mode="ios"
                color="secondary"
                style={{
                  margin: '1.6rem calc(var(--padding-start) + var(--ion-safe-area-left, 0px))',
                }}
                onClick={processForm}
                disabled={buttonDisabled}
              >
                <FormattedMessage
                  id="ui.buttons.authorize-device"
                  defaultMessage="Authorize this device"
                />
              </IonButton>
            </IonList>
          </form>
        )}
      </IonContent>
    </IonModal>
  );
};

export default BiometricSetupModal;
