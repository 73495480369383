export const PaperFrame = ({
  className,
  strokeWidth,
}: {
  className?: string;
  strokeWidth?: string;
}) => (
  <svg
    viewBox="0 0 1 1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
    className={className || 'list-item__thumb-frame list-item__thumb-frame-0'}
  >
    <g fill="none" fillRule="evenodd">
      <path
        d="
        M0.53,0 C0.648,0,0.805,0.007,1,0.02 C0.991,0.24,0.987,0.408,0.986,0.524
        L0.986,0.524 L0.986,0.541 C0.986,0.656,0.991,0.809,1,1
        C0.764,0.994,0.585,0.99,0.463,0.99 L0.463,0.99 L0,0.99
        C0.009,0.737,0.013,0.542,0.013,0.406 C0.013,0.271,0.009,0.139,0,0.01
        C0.235,0.003,0.412,0,0.53,0 Z"
        strokeWidth={strokeWidth || '.03'}
        style={{ stroke: 'var(--ion-color-tertiary-shade, #800A0A)' }}
      ></path>
    </g>
  </svg>
);

export const PaperBackPage = (props: { index: number }) => (
  <svg
    viewBox="0 0 1 1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
    className={`list-item__thumb-frame list-item__thumb-frame-${props.index}`}
  >
    <g fill="none" fillRule="evenodd">
      <path
        d="
      M0.53,0 C0.648,0,0.805,0.007,1,0.02 C0.991,0.24,0.987,0.408,0.986,0.524
      L0.986,0.524 L0.986,0.541 C0.986,0.656,0.991,0.809,1,1
      C0.764,0.994,0.585,0.99,0.463,0.99 L0.463,0.99 L0,0.99
      C0.009,0.737,0.013,0.542,0.013,0.406 C0.013,0.271,0.009,0.139,0,0.01
      C0.235,0.003,0.412,0,0.53,0 Z"
        strokeWidth=".02"
        fill="#FFFFFF"
        style={{ stroke: 'var(--ion-color-tertiary-shade, #800A0A)' }}
      ></path>
    </g>
  </svg>
);
