// hooks
import { Fragment, useEffect, useState } from 'react';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { useCollectionItem } from '../hooks/useCollectionItem';

// helper functions
import { getLabel, getValue, getDateValue, getLabelId, reorderArray } from '../utilities/values';
import { _label } from '../utilities/strings';
import { deleteFirestoreDocument, updateFirestoreDocument } from '../utilities/firestore';
import { deleteFiles } from '../utilities/files';

// components
import AppPage from '../components/AppPage';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonIcon,
  IonImg,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonList,
  IonTitle,
  IonToolbar,
  useIonAlert,
  useIonLoading,
  useIonPopover,
} from '@ionic/react';
import LoadingScreen from '../components/LoadingScreen';
import DetailsItem from '../components/DetailsItem';
import StackModal from '../forms/StackModal';

// icons
import {
  createOutline,
  ellipsisHorizontalCircleOutline,
  ellipsisVerticalCircleOutline,
  pin,
  pinOutline,
  removeCircleOutline,
} from 'ionicons/icons';

// types
import { TStoreState } from '../store/store';
import { TFormFields } from '../models/Form';
import { TFieldValue } from '../models/FieldValues';
import { RecordTypeField } from '../models/RecordType';

const Stack: React.FC = () => {
  const intl = useIntl();
  const firebase = useFirebase();
  const firestore = useFirestore();
  const history = useHistory();

  const { stackId } = useParams<{ stackId: string }>();

  const stack = useCollectionItem('stacks', stackId);
  const fields = useCollectionItem('types', 'fields');
  const typeFields = useCollectionItem('types', 'stack');
  const user = useSelector((state: TStoreState) => state.firebase?.auth);

  /**
   * Favorite
   */
  const [isPinned, setPinned] = useState<boolean>(false);
  useEffect(() => {
    setPinned(getValue(stack.item?.pinned) === true);
  }, [stack]);

  const editStack = () => {
    showEditModal();
  };

  const [presentAlert] = useIonAlert();

  const confirmDeleteContact = () => {
    presentAlert({
      header: intl.formatMessage({ id: 'page.stack.delete-alert.title' }),
      message: intl.formatMessage({ id: 'page.stack.delete-alert.message' }),
      buttons: [
        intl.formatMessage({ id: 'ui.buttons.no' }),
        {
          text: intl.formatMessage({ id: 'ui.buttons.yes' }),
          handler: () => deleteStack(),
        },
      ],
    });
  };

  const [presentLoading, dismissLoading] = useIonLoading();

  const deleteStack = async () => {
    // show loading
    presentLoading({
      message: intl.formatMessage({
        id: 'form.stack.deleting',
        defaultMessage: 'Deleting Stack...',
      }),
    });

    // files to delete are all the files we have]
    const filesToDelete = (stack?.item?.photo as TFieldValue)?.files;
    if (filesToDelete?.length) {
      await deleteFiles(
        firebase,
        filesToDelete,
        user.uid,
        `/${user.uid}/stack/${stackId}`,
        stack.item,
      );
    }

    // update the data in the DB
    await deleteFirestoreDocument(user, firestore, `stacks/${stackId}`)
      .then(async () => {
        // remove the message
        await dismissLoading();

        // redirect to the papers list
        history.replace('/stacks');
      })
      .catch(err => {
        dismissLoading();
        console.error('Update Contact Error', err);
      });
  };

  /**
   * Change pinned status
   */
  const togglePinned = () => {
    firestore.doc(`/users/${user.uid}/stacks/${stackId}`).update({
      'pinned.value': !isPinned,
    });
  };

  /**
   * Menu popover
   */
  const [presentMenu, closeMenu] = useIonPopover(() => {
    return (
      <IonList>
        <IonItem
          button
          onClick={() => {
            editStack();
            closeMenu();
          }}
          detail={false}
        >
          <IonIcon icon={createOutline} slot="start" />
          {intl.formatMessage({ id: 'ui.buttons.edit' })}
        </IonItem>
        <IonItem
          button
          onClick={() => {
            togglePinned();
            closeMenu();
          }}
          detail={false}
        >
          <IonIcon
            icon={isPinned ? pin : pinOutline}
            color={isPinned ? 'medium' : 'secondary'}
            slot="start"
          />
          {intl.formatMessage({
            id: isPinned ? 'ui.buttons.remove-from-pinned' : 'ui.buttons.add-to-pinned',
          })}
        </IonItem>
        <IonItem
          button
          onClick={() => {
            confirmDeleteContact();
            closeMenu();
          }}
          lines="none"
          detail={false}
        >
          <IonIcon icon={removeCircleOutline} color="danger" slot="start" />
          {intl.formatMessage({ id: 'ui.buttons.delete' })}
        </IonItem>
      </IonList>
    );
  });

  /**
   * Edit modal
   */
  const [formModalOpened, setFormModalOpened] = useState(false);

  const showEditModal = () => {
    setFormModalOpened(true);
  };

  const hideEditModal = () => {
    setFormModalOpened(false);
  };

  /**
   * Add paper to the stack
   */
  const addPaper = (paperId: string) => {
    if (stack?.item) {
      if (stack.item['stack-papers']?.some((paper: any) => paper?.value === paperId)) {
        return;
      }

      // creating back link
      const backLinkField = fields?.item?.['stack-papers']?.backLinkField;
      if (backLinkField) {
        updateFirestoreDocument(user, firestore, `papers/${paperId}`, {
          [backLinkField]: firebase.firestore.FieldValue.arrayUnion({ value: stackId }),
        });
      }

      const updatedPapers: { value: string }[] = [
        {
          value: paperId,
        },
        ...(stack.item['stack-papers'] || []),
      ];
      updateFirestoreDocument(user, firestore, `stacks/${stackId}`, {
        'stack-papers': updatedPapers,
      });
    }
  };

  /**
   * Remove paper from stack
   */
  const removePaper = (paperId: string) => {
    if (stack?.item?.['stack-papers']?.some((paper: any) => paper?.value === paperId)) {
      const updatedPapers: { value: string }[] = stack.item['stack-papers'].filter(
        (paper: any) => paper?.value !== paperId,
      );

      // remove back link
      const backLinkField = fields?.item?.['stack-papers']?.backLinkField;
      if (backLinkField) {
        updateFirestoreDocument(user, firestore, `papers/${paperId}`, {
          [backLinkField]: firebase.firestore.FieldValue.arrayRemove({ value: stackId }),
        });
      }

      updateFirestoreDocument(user, firestore, `stacks/${stackId}`, {
        'stack-papers': updatedPapers,
      });
    }
  };

  /**
   * Reorder papers in the stack
   */
  const reorderPapers = (from: number, to: number) => {
    if (stack?.item && stack.item['stack-papers']) {
      const updatedPapers: { value: string }[] = reorderArray(stack.item['stack-papers'], from, to);
      updateFirestoreDocument(user, firestore, `stacks/${stackId}`, {
        'stack-papers': updatedPapers,
      });
    }
  };

  return (
    <AppPage
      title={intl.formatMessage(
        { id: 'page.stack.title' },
        {
          name: getValue(stack.item?.name, intl.formatMessage({ id: 'page.stack.title.untitled' })),
        },
      )}
      header={
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/stacks" />
          </IonButtons>
          <IonTitle>
            {stack.item
              ? getValue(stack.item?.name, intl.formatMessage({ id: 'page.stack.title.untitled' }))
              : intl.formatMessage({ id: 'page.stack.title.loading' })}
          </IonTitle>
          <IonButtons slot="end">
            <IonButton
              color="primary"
              shape="round"
              className="add-button"
              onClick={evt =>
                presentMenu({
                  event: evt.nativeEvent,
                  arrow: false,
                } as any)
              }
            >
              <IonIcon
                className="add-button--icon"
                slot="icon-only"
                md={ellipsisVerticalCircleOutline}
                ios={ellipsisHorizontalCircleOutline}
              />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      }
      path={`/stacks/${stackId}`}
    >
      {stack.item ? (
        <>
          {stack.item?.thumbnail?.[0] && (
            <div className="stack__image">
              <IonImg src={stack.item?.thumbnail[0].url.replace('{uid}', user.uid) || ''} />
            </div>
          )}
          <h1 className="stack__names">
            {getValue(stack.item?.name)}
            <IonIcon
              onClick={togglePinned}
              icon={isPinned ? pin : pinOutline}
              color={isPinned ? 'secondary' : 'medium'}
              className={`favorite-star${isPinned ? ' favorite-star__pinned' : ''}`}
            />
          </h1>

          <IonList>
            {typeFields.item?.fields
              ?.map((field: TFormFields | string) => {
                return typeof field === 'string' ? fields?.item?.[field] : field;
              })
              .filter((field: TFormFields) => !!field && !field.hideFromDetails)
              .map((field: TFormFields) => (
                <Fragment key={field.id}>
                  {field.type === 'group' ? (
                    field.fields
                      ?.map((subField: TFormFields | string) => {
                        return typeof subField === 'string' ? fields?.item?.[subField] : subField;
                      })
                      .filter(
                        (subField: TFormFields) =>
                          !!subField &&
                          !subField.hideFromDetails &&
                          (subField.alwaysVisibleInDetails ||
                            (stack.item[subField.id] &&
                              (!Array.isArray(stack.item[subField.id]) ||
                                stack.item[subField.id].filter((v: any) => !!v.value).length > 0))),
                      )
                      .map((subField: TFormFields) => (
                        <IonItemGroup key={subField.id}>
                          <IonItemDivider mode="md" className="details__divider">
                            <IonLabel>
                              {subField.label ? (
                                _label(
                                  (Array.isArray(stack.item[subField.id]) &&
                                    stack.item[subField.id].length > 1 &&
                                    subField.labelPlural) ||
                                    subField.label,
                                  intl.locale,
                                )
                              ) : (
                                <FormattedMessage
                                  id={getLabelId(
                                    subField as RecordTypeField,
                                    stack.item[subField.id],
                                  )}
                                />
                              )}
                            </IonLabel>
                          </IonItemDivider>
                          {Array.isArray(stack.item[subField.id]) ? (
                            stack.item[subField.id]
                              .filter((value: TFieldValue) => value.value)
                              .map((value: TFieldValue, idx: number, arr: TFieldValue[]) => (
                                <DetailsItem
                                  name={subField.id}
                                  itemId={stackId}
                                  type={subField.type}
                                  key={'value' + idx}
                                  lines={idx < arr.length - 1 ? 'inset' : 'none'}
                                  value={
                                    subField.type === 'date'
                                      ? getDateValue(value, undefined, undefined, intl.locale)
                                      : getValue(value)
                                  }
                                  copyValue={getValue(value)}
                                  label={getLabel(value)}
                                  actions={subField.actions}
                                  addPaper={addPaper}
                                  removePaper={removePaper}
                                  papersRouterLink={`/stacks/${stackId}/`}
                                  alwaysVisibleInDetails={subField.alwaysVisibleInDetails}
                                />
                              ))
                          ) : (
                            <DetailsItem
                              name={subField.id}
                              itemId={stackId}
                              type={subField.type}
                              lines="none"
                              value={
                                subField.type === 'date'
                                  ? getDateValue(
                                      stack.item[subField.id],
                                      undefined,
                                      undefined,
                                      intl.locale,
                                    )
                                  : getValue(stack.item[subField.id])
                              }
                              copyValue={getValue(stack.item[subField.id])}
                              label={getLabel(stack.item[subField.id])}
                              actions={subField.actions}
                              addPaper={addPaper}
                              removePaper={removePaper}
                              papersRouterLink={`/stacks/${stackId}/`}
                              alwaysVisibleInDetails={subField.alwaysVisibleInDetails}
                            />
                          )}
                        </IonItemGroup>
                      ))
                  ) : (
                    <DetailsItem
                      name={field.id}
                      itemId={stackId}
                      type={field.type}
                      lines="none"
                      value={
                        field.type === 'date'
                          ? getDateValue(stack.item[field.id], undefined, undefined, intl.locale)
                          : getValue(stack.item[field.id])
                      }
                      copyValue={getValue(stack.item[field.id])}
                      label={getLabel(stack.item[field.id])}
                      labelId={getLabelId(field as RecordTypeField, stack.item[field.id])}
                      actions={field.actions}
                      addPaper={addPaper}
                      removePaper={removePaper}
                      reorderPapers={reorderPapers}
                      papersRouterLink={`/stacks/${stackId}/`}
                      alwaysVisibleInDetails={field.alwaysVisibleInDetails}
                    />
                  )}
                </Fragment>
              ))}
          </IonList>
        </>
      ) : (
        <LoadingScreen />
      )}

      <StackModal
        key={stackId}
        opened={formModalOpened}
        onDismiss={hideEditModal}
        stackId={stackId}
      />
    </AppPage>
  );
};

export default Stack;
