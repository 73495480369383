// hooks
import { useEffect, useRef, useState } from 'react';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

// utils and plugins
import { Share } from '@capacitor/share';
import { FileSharer } from '@byteowls/capacitor-filesharer';
import { blobToBase64 } from '../utilities/forms';
import { deleteFiles, extractUrls, makeRequest } from '../utilities/files';
import { updateFirestoreDocument } from '../utilities/firestore';
import { Pagination } from 'swiper';
import { emitCustomEvent } from 'react-custom-events';

// components
import {
  IonButton,
  IonIcon,
  IonItem,
  IonList,
  isPlatform,
  useIonAlert,
  useIonPopover,
} from '@ionic/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import LoadingScreen from './LoadingScreen';
import FileField from '../forms/FileField';
import PaperPreview from './PaperPreview';

// styles
import 'swiper/css';
import 'swiper/css/pagination';
import '@ionic/react/css/ionic-swiper.css';

// types and icons
import { Paper } from '../models/Paper';
import { TFieldValue, TFilePreview, TFileValue } from '../models/FieldValues';
import { TStoreState } from '../store/store';
import {
  arrowBackOutline,
  arrowForwardOutline,
  chevronBackOutline,
  chevronForwardOutline,
  cloudDownloadOutline,
  cloudUploadOutline,
  ellipsisVertical,
  glassesOutline,
  removeCircleOutline,
  scanOutline,
  shareOutline,
  lockClosedOutline,
  textOutline,
  ellipsisHorizontal,
} from 'ionicons/icons';
import { TFormField, TFormFields } from '../models/Form';

type TPaperPreviewsProps = {
  paperId: string;
  paper: Paper;
  uploadFile: (value: TFileValue[]) => void;
  formDataFiles: TFileValue[];
  ocr?: any;
  fields: TFormFields[];
  updateField: (field: TFormField, value: any, add?: boolean) => void;
  isLocked: boolean;
  canUnlock: boolean;
};

const PaperPreviews = ({
  paperId,
  paper,
  uploadFile,
  formDataFiles,
  ocr,
  fields,
  updateField,
  isLocked,
  canUnlock,
}: TPaperPreviewsProps) => {
  const intl = useIntl();
  const firebase = useFirebase();
  const firestore = useFirestore();
  const dispatch = useDispatch();

  const user = useSelector((state: TStoreState) => state.firebase.auth);
  const isDesktop = useSelector((state: TStoreState) => state.ui.isDesktop);
  const storedUrls = useSelector((state: TStoreState) => state.data.urls, shallowEqual);
  const ocrPapers = useSelector(
    (state: TStoreState) => state.data.recognition.papers,
    shallowEqual,
  );

  const [fileUrls, setFileUrls] = useState<{ url: string; pageFileName?: string }[]>([]);

  const prepareUrls = async (
    previews: { fileId: string; fileName: string; pageFileName: string; url: string }[],
  ) => {
    let urls: { url: string; pageFileName?: string }[] = await extractUrls(
      previews,
      storedUrls,
      firebase,
      dispatch,
    );
    previewsNumber.current = urls.length + 1;
    setFileUrls(urls);
  };

  useEffect(() => {
    if (paper?.preview && diffPreviews(fileUrls, paper?.preview)) {
      prepareUrls(
        paper.preview
          .map(preview => ({
            fileId: preview.fileId,
            fileName: preview.fileName,
            pageFileName: preview.pageFileName || '',
            url: preview.url?.replace('{uid}', user.uid) || '',
          }))
          .filter(preview => preview.url !== ''),
      );
    }
  }, [paper]);

  const diffPreviews = (
    before: { url: string; pageFileName?: string }[],
    now: TFilePreview[],
  ): boolean => {
    if (before.length !== now.length) {
      return true;
    }
    return now.some(n => before.some(b => b.url.indexOf(n.fileId) < 0));
  };

  // settings for the swiper pagination
  const pagination = {
    clickable: true,
  };

  // check sharing permissions
  const [sharePermissions, setSharePermissions] = useState<boolean>(false);
  useEffect(() => {
    Share.canShare()
      .then(canShare => {
        setSharePermissions(canShare?.value ?? false);
      })
      .catch(err => {
        console.error('Checking sharing abilities', err);
      });
  }, []);

  const shareFile = async (fileId: string | null, preview = false) => {
    if (fileId) {
      await loadFile(
        fileId,
        async file => {
          const base64Data = await blobToBase64(file.data);
          FileSharer.share({
            filename: file.filename,
            contentType: file.contentType,
            base64Data: base64Data.indexOf(',') > 0 ? base64Data.split(',')[1] : base64Data,
          });
        },
        preview,
      );
    }
  };

  /**
   * Image menu popover
   */
  const [presentImageMenu, closeImageMenu] = useIonPopover(() => {
    const fileId = getFileId(activePreview, previewsNumber.current);
    const pageFileName = getPageFileName(activePreview, previewsNumber.current);

    return (
      <IonList>
        {fileId && !isLocked && fileIsAvailable(fileId) && isPlatform('desktop') && (
          <IonItem
            button
            onClick={() => {
              shareFile(fileId);
              closeImageMenu();
            }}
            detail={false}
            lines="inset"
          >
            <IonIcon icon={cloudDownloadOutline} slot="start" />
            {intl.formatMessage({ id: 'ui.buttons.download-file' })}
          </IonItem>
        )}
        {fileId && !isLocked && !fileIsAvailable(fileId) && isPlatform('desktop') && (
          <IonItem
            button
            onClick={() => {
              shareFile(fileId, true);
              closeImageMenu();
            }}
            detail={false}
            lines="inset"
          >
            <IonIcon icon={cloudDownloadOutline} slot="start" />
            {intl.formatMessage({ id: 'ui.buttons.download-preview' })}
          </IonItem>
        )}
        {fileId && !isLocked && !isPlatform('desktop') && sharePermissions && (
          <IonItem
            button
            onClick={() => {
              shareFile(fileId, true);
              closeImageMenu();
            }}
            detail={false}
            lines="inset"
          >
            <IonIcon icon={shareOutline} slot="start" />
            {intl.formatMessage({ id: 'ui.buttons.share-preview' })}
          </IonItem>
        )}
        {fileId &&
          !isLocked &&
          fileIsAvailable(fileId) &&
          !isPlatform('desktop') &&
          sharePermissions && (
            <IonItem
              button
              onClick={() => {
                shareFile(fileId);
                closeImageMenu();
              }}
              detail={false}
              lines="inset"
            >
              <IonIcon icon={shareOutline} slot="start" />
              {intl.formatMessage({ id: 'ui.buttons.share-file' })}
            </IonItem>
          )}
        {fileId && !isLocked && (
          <IonItem
            button
            onClick={() => {
              readPage();
              closeImageMenu();
            }}
            detail={false}
            lines="inset"
          >
            <IonIcon icon={glassesOutline} slot="start" />
            {pageFileName && ocr[pageFileName] ? (
              <FormattedMessage id="ui.buttons.read-page-again" defaultMessage="Read Page Again" />
            ) : (
              <FormattedMessage id="ui.buttons.read-page" defaultMessage="Read Page" />
            )}
          </IonItem>
        )}
        {fileId && !isLocked && (paper?.preview?.length || 1) > 1 && (
          <IonItem
            button
            onClick={() => {
              readPaper();
              closeImageMenu();
            }}
            detail={false}
            lines="inset"
          >
            <IonIcon icon={glassesOutline} slot="start" />
            {ocrPagesLength === paper?.preview?.length ? (
              <FormattedMessage
                id="ui.buttons.read-all-pages-again"
                defaultMessage="Read All Pages Again"
              />
            ) : (
              <FormattedMessage id="ui.buttons.read-all-pages" defaultMessage="Read All Pages" />
            )}
          </IonItem>
        )}
        <IonItem
          button
          onClick={() => {
            selectFileForUpload();
            closeImageMenu();
          }}
          lines={!fileId || isLocked ? 'none' : 'inset'}
          detail={false}
        >
          <IonIcon icon={cloudUploadOutline} slot="start" />
          {intl.formatMessage({
            id:
              (paper?.preview?.length || 0) > 0
                ? 'ui.buttons.upload-another-file'
                : 'ui.buttons.upload-file',
          })}
        </IonItem>
        {fileId && !isLocked && (paper?.preview?.length || 1) > 1 && (
          <IonItem detail={false} lines="inset">
            <IonButton
              fill="clear"
              shape="round"
              color="dark"
              disabled={previewsNumber.current < 1 || activePreview === 0}
              onClick={() => {
                movePreviewFirst(activePreview);
                closeImageMenu();
              }}
            >
              <IonIcon icon={chevronBackOutline} className="button-icon__prev" />
              <IonIcon icon={chevronBackOutline} className="button-icon__next" />
            </IonButton>
            <IonButton
              fill="clear"
              shape="round"
              color="dark"
              disabled={previewsNumber.current < 2 || activePreview === 0}
              onClick={() => {
                movePreviewUp(activePreview);
                closeImageMenu();
              }}
            >
              <IonIcon icon={chevronBackOutline} />
            </IonButton>
            <IonButton
              fill="clear"
              shape="round"
              color="dark"
              disabled={previewsNumber.current <= activePreview + 2}
              onClick={() => {
                movePreviewDown(activePreview);
                closeImageMenu();
              }}
            >
              <IonIcon icon={chevronForwardOutline} />
            </IonButton>
            <IonButton
              fill="clear"
              shape="round"
              color="dark"
              disabled={previewsNumber.current <= activePreview + 2}
              onClick={() => {
                movePreviewLast(activePreview);
                closeImageMenu();
              }}
            >
              <IonIcon icon={chevronForwardOutline} className="button-icon__prev" />
              <IonIcon icon={chevronForwardOutline} className="button-icon__next" />
            </IonButton>
          </IonItem>
        )}
        {fileId && !isLocked && !fileIsAvailable(fileId) && (
          <IonItem
            button
            onClick={() => {
              confirmPreviewDelete(fileId);
              closeImageMenu();
            }}
            detail={false}
            lines="none"
          >
            <IonIcon icon={removeCircleOutline} color="danger" slot="start" />
            {intl.formatMessage({ id: 'ui.buttons.delete-preview' })}
          </IonItem>
        )}
        {fileId && !isLocked && fileIsAvailable(fileId) && (
          <IonItem
            button
            onClick={() => {
              confirmFileDelete(fileId);
              closeImageMenu();
            }}
            detail={false}
            lines="none"
          >
            <IonIcon icon={removeCircleOutline} color="danger" slot="start" />
            {intl.formatMessage({ id: 'ui.buttons.delete-file' })}
          </IonItem>
        )}
      </IonList>
    );
  });

  const fileFieldRef = useRef<any>(null);
  const selectFileForUpload = () => {
    fileFieldRef.current?.selectFileForUpload();
  };

  const [activePreview, setActvePreview] = useState(0);
  const previewsNumber = useRef(1);

  // move preview to the start of the list
  const movePreviewFirst = (index: number) => {
    movePreview(index, 0);
  };

  // move preview one position up
  const movePreviewUp = (index: number) => {
    movePreview(index, index - 1);
  };

  // move preview one position down
  const movePreviewDown = (index: number) => {
    movePreview(index, index + 1);
  };

  // move preview to the last position
  const movePreviewLast = (index: number) => {
    movePreview(index, (paper?.preview?.length || 1) - 1);
  };

  const movePreview = (index: number, newIndex: number) => {
    let pages = [...(paper?.pages || [])];
    const selectedPage = pages.splice(index, 1);
    pages.splice(newIndex, 0, ...selectedPage);

    let preview = [...(paper?.preview || [])];
    const selectedPreview = preview.splice(index, 1);
    preview.splice(newIndex, 0, ...selectedPreview);

    let thumbnail = [...(paper?.thumbnail || [])];
    const selectedThumb = thumbnail.splice(index, 1);
    thumbnail.splice(newIndex, 0, ...selectedThumb);

    setFileUrls(urls => {
      const newUrls = [...urls];
      const selectedUrl = newUrls.splice(index, 1);
      newUrls.splice(newIndex, 0, ...selectedUrl);
      return newUrls;
    });

    updateFirestoreDocument(user, firestore, `papers/${paperId}`, { pages, preview, thumbnail });
  };

  const getFileId = (active: number, previews: number): string | null => {
    if (active < previews - 1) {
      // it is not the last frame with the upload button
      return paper?.preview?.[active]?.fileId || null;
    }
    return null;
  };

  const getPageFileName = (active: number, previews: number): string | null => {
    if (active < previews - 1) {
      // it is not the last frame with the upload button
      return paper?.preview?.[active]?.pageFileName || null;
    }
    return null;
  };

  const fileIsAvailable = (fileId: string | null): boolean => {
    if (fileId) {
      return (
        ((paper?.files as TFieldValue)?.files?.findIndex(
          file => file.id === fileId && file.mimeType === 'application/pdf',
        ) ?? -1) >= 0
      );
    }
    return false;
  };

  const loadFile = async (
    fileId: string | null,
    callback: (response: any) => void,
    loadPreview = false,
  ) => {
    if (fileId) {
      const paperFile = (paper?.files as TFieldValue)?.files?.find(file => file.id === fileId);

      if (paperFile?.name) {
        if (paperFile.mimeType === 'application/pdf' && !loadPreview) {
          const fullPath = `/${user.uid}/paper/${paperId}/${fileId}.pdf`;
          firebase
            .storage()
            .ref(fullPath)
            .getDownloadURL()
            .then(async url => {
              const file = await makeRequest({ url, method: 'GET' });
              callback({
                filename: paperFile?.name,
                contentType: paperFile.mimeType,
                data: file,
              });
            });
        } else {
          const url = fileUrls[activePreview].url;
          const filename = paperFile.name.substring(0, paperFile.name.lastIndexOf('.')) + '.jpg';

          if (url) {
            const file = await makeRequest({ url, method: 'GET' });
            callback({
              filename,
              contentType: 'image/jpeg',
              data: file,
            });
          }
        }
      }
    }
    return null;
  };

  const [presentAlert] = useIonAlert();

  const confirmPreviewDelete = (fileId: string | null) => {
    presentAlert({
      header: intl.formatMessage({ id: 'page.paper.delete-preview-alert.title' }),
      message: intl.formatMessage({ id: 'page.paper.delete-preview-alert.message' }),
      buttons: [
        intl.formatMessage({ id: 'ui.buttons.no' }),
        {
          text: intl.formatMessage({ id: 'ui.buttons.yes' }),
          handler: () => deleteFile(fileId),
        },
      ],
    });
  };

  const confirmFileDelete = (fileId: string | null) => {
    presentAlert({
      header: intl.formatMessage({ id: 'page.paper.delete-file-alert.title' }),
      message: intl.formatMessage({ id: 'page.paper.delete-file-alert.message' }),
      buttons: [
        intl.formatMessage({ id: 'ui.buttons.no' }),
        {
          text: intl.formatMessage({ id: 'ui.buttons.yes' }),
          handler: () => deleteFile(fileId),
        },
      ],
    });
  };

  // delete file
  const deleteFile = async (fileId: string | null) => {
    const filesToDelete = (paper?.files as TFieldValue)?.files?.find(file => file.id === fileId);
    if (filesToDelete) {
      deleteFiles(firebase, [filesToDelete], user.uid, `/${user.uid}/paper/${paperId}`, paper);
    }

    // four properties to update and to get the filenames from:
    // files
    const files = {
      ...paper?.files,
      files: (paper?.files as TFieldValue)?.files?.filter(file => file.id !== fileId),
    };
    const pages = (paper?.pages as TFilePreview[])?.filter(page => page.fileId !== fileId);
    const preview = (paper?.preview as TFilePreview[])?.filter(page => page.fileId !== fileId);
    const thumbnail = (paper?.thumbnail as TFilePreview[])?.filter(page => page.fileId !== fileId);

    const paperUpdate: any = {};
    if (files) paperUpdate.files = files;
    if (pages) paperUpdate.pages = pages;
    if (preview) paperUpdate.preview = preview;
    if (thumbnail) paperUpdate.thumbnail = thumbnail;

    // delete the record in the paper fdb
    await updateFirestoreDocument(user, firestore, `papers/${paperId}`, paperUpdate);
  };

  /**
   * Request authorization in case it's locked
   */
  const requestAuthorization = async () => {
    emitCustomEvent('request-authorization');
  };

  /**
   * Images navigation
   */
  const [swiper, setSwiper] = useState<any>(null);

  const prevPreview = () => {
    swiper?.slideTo(activePreview - 1);
  };
  const nextPreview = () => {
    swiper?.slideTo(activePreview + 1);
  };

  /**
   * Text recognition
   */
  const [showStrings, setShowStrings] = useState(false);

  const readPage = () => {
    emitCustomEvent('read-paper', [
      {
        pid: paperId,
        pageNo: activePreview,
        ...fileUrls[activePreview],
      },
    ]);
    setShowStrings(true);
  };

  const readPaper = () => {
    if (paper?.preview) {
      const pages = paper.preview
        .map((preview, idx: number) => ({
          pid: paper.id,
          pageNo: idx,
          ...storedUrls[preview.fileName],
        }))
        .filter(pageData => pageData.url);
      if (pages.length > 0) {
        emitCustomEvent('read-paper', pages);
      }
      setShowStrings(true);
    }
  };

  const [ocrPagesLength, setOcrPagesLength] = useState(0);
  useEffect(() => {
    if (Array.isArray(ocr)) {
      setOcrPagesLength(ocr.length);
    } else {
      setOcrPagesLength(Object.keys(ocr).length);
    }
  }, [ocr]);

  const [previewZoom, setPreviewZoom] = useState<number[]>([]);
  const onZoomChange = (zoom: number, idx: number) => {
    setPreviewZoom(prev => {
      const newZoom = [...prev];
      newZoom[idx] = zoom;
      return newZoom;
    });
  };

  return (
    <div className="paper-page--previews">
      <div className="paper-page--image-button-container">
        {!canUnlock && paper && (
          <IonButton
            mode="md"
            shape="round"
            className="paper-page--image-button"
            onClick={() => {
              ocrPagesLength === 0 ? readPaper() : setShowStrings(!showStrings);
            }}
            disabled={(ocrPapers[paper.id]?.state || 'done') !== 'done'}
            color={showStrings ? 'secondary' : ''}
          >
            {ocrPagesLength > 0 ? (
              <>
                <IonIcon icon={scanOutline} slot="icon-only" key="scan" />
                <IonIcon icon={textOutline} slot="icon-only" key="text" />
              </>
            ) : (
              <IonIcon icon={glassesOutline} slot="icon-only" key="glasses" />
            )}
          </IonButton>
        )}
        {canUnlock && (
          <IonButton
            mode="md"
            shape="round"
            className="paper-page--image-button"
            onClick={requestAuthorization}
          >
            <IonIcon icon={lockClosedOutline} slot="icon-only" key="unlock" />
          </IonButton>
        )}
        <IonButton
          mode="md"
          shape="round"
          className="paper-page--image-button"
          onClick={evt => {
            presentImageMenu({
              event: evt.nativeEvent,
              side: 'bottom',
              alignment: 'end',
              arrow: false,
            });
          }}
        >
          <IonIcon md={ellipsisVertical} ios={ellipsisHorizontal} slot="icon-only" />
        </IonButton>
      </div>
      {activePreview > 0 && (
        <div className="paper-page--prev-button-container">
          <IonButton
            mode="md"
            shape="round"
            className="paper-page--image-button"
            onClick={prevPreview}
          >
            <IonIcon icon={arrowBackOutline} slot="icon-only" />
          </IonButton>
        </div>
      )}
      {activePreview + 2 < previewsNumber.current && (
        <div className="paper-page--next-button-container">
          <IonButton
            mode="md"
            shape="round"
            className="paper-page--image-button"
            onClick={nextPreview}
            size="small"
          >
            <IonIcon icon={arrowForwardOutline} slot="icon-only" />
          </IonButton>
        </div>
      )}
      <Swiper
        slidesPerView={1}
        pagination={pagination}
        modules={[Pagination]}
        noSwiping={(previewZoom[activePreview] || 1) > 1}
        noSwipingClass="preview--container"
        onSwiper={setSwiper}
        onSlideChange={evt => {
          setActvePreview(evt.activeIndex);
          previewsNumber.current = evt.slides.length;
        }}
      >
        {paperId !== 'new' &&
          (paper ? (
            ((paper.preview?.length || 0) > 0 ||
              ((paper.files as TFieldValue)?.files?.length || 0) > 0) &&
            fileUrls.length === 0 ? (
              <SwiperSlide key="loading">
                <LoadingScreen color="light" />
              </SwiperSlide>
            ) : (
              paper.preview &&
              fileUrls.map((preview, idx) => (
                <SwiperSlide key={(preview.pageFileName || preview.url) + idx}>
                  {isLocked ? (
                    <div className="file-upload-button" onClick={requestAuthorization}>
                      <IonIcon icon={lockClosedOutline} className="file-upload-button__icon" />
                    </div>
                  ) : (
                    <PaperPreview
                      url={preview.url}
                      showStrings={showStrings}
                      ocr={
                        preview.pageFileName && ocr[preview.pageFileName]
                          ? ocr[preview.pageFileName]
                          : ocr[idx]
                      }
                      fields={fields}
                      updateField={updateField}
                      onZoomChange={(zoom: number) => onZoomChange(zoom, idx)}
                    />
                  )}
                </SwiperSlide>
              ))
            )
          ) : (
            <SwiperSlide key="loading">
              <LoadingScreen color="light" />
            </SwiperSlide>
          ))}
        <SwiperSlide key="new">
          <FileField
            itemId={paperId}
            ref={fileFieldRef}
            name="files"
            files={formDataFiles}
            onValueUpdate={uploadFile}
            addMoreLabel="add"
            uploadOnly={true}
            multiple={true}
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default PaperPreviews;
