import { getValue } from './values';

export type TEntitySearchResults = {
  [id: string]: number;
};

export const normString = (str: string): string => {
  return str
    .normalize('NFD')
    .replace(/\n|\s/g, ' ')
    .replace(/,/g, '.') // to match all kind of numbers
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^a-zа-яё0-9.| ]/gi, '')
    .toLowerCase();
};

export const getSearchStrings = (text: string) => {
  return text
    .split(/\s|, /)
    .map(str => normString(str))
    .filter(str => str !== '');
};

export const extactItemData = (item: any, fieldsToUse?: string[]): string => {
  if (item) {
    // extract the items that should not be used for searching
    // all the rest is in the `data` prop
    const {
      // system properties
      id,
      createdAt,
      createdBy,
      importedAt,
      importedBy,
      updatedAt,
      updatedby,
      avatar,
      thumbnail,
      preview,
      photo,
      form,

      // fields that should not be used for searching
      business,
      cardType,
      category,
      cvv,
      favorite,
      files,
      invoicingPeriod,
      linkedPapers,
      paid,
      password,
      paymentPaper,
      pin,
      reimbursable,
      reimbursementPaper,
      selectedPapers,
      ocr,

      ...data
    } = item;
    let ocrString = '';
    if (ocr?.value) {
      if (typeof ocr.value === 'string') {
        try {
          const parsed = JSON.parse(ocr.value);
          if (Array.isArray(parsed) && parsed.length > 0) {
            ocrString = normString(parsed.map((ocrItem: any) => ocrItem.text).join('|'));
          } else if (typeof parsed === 'object' && Object.keys(parsed).length > 0) {
            ocrString = normString(
              Object.values(parsed)
                .map((ocrItem: any) => ocrItem.text)
                .join('|'),
            );
          }
        } catch (e) {
          ocrString = normString(ocr.value);
        }
      } else if (Array.isArray(ocr.value) && ocr.value.length > 0) {
        ocrString = normString(ocr.value.map((ocrItem: any) => ocrItem.text).join('|'));
      }
    }
    return normString(
      Object.keys(data)
        .map((fieldName: any) => {
          if (fieldsToUse && !fieldsToUse.includes(fieldName)) {
            return null;
          }
          return getValue(data[fieldName]);
        })
        .filter(value => value && typeof value !== 'boolean')
        .join('|')
        .replace(', ', '|') +
        '|' +
        ocrString,
    );
  }
  return '';
};

export const getMatchingItems = (
  items: { [id: string]: any },
  searchStrings: string[],
  itemsData: { [id: string]: string } = {},
  fieldsToUse?: string[],
): [TEntitySearchResults, { [id: string]: string }] => {
  if (!items || Object.keys(items).length === 0) {
    return [{}, {}];
  }
  return [
    Object.keys(items)
      .map((itemId: string) => {
        // compress client data in one string
        if (!itemsData || !itemsData[itemId]) {
          itemsData = {
            ...itemsData,
            [itemId]: extactItemData(items[itemId], fieldsToUse),
          };
        }

        let searchWeight = 0;

        (searchStrings || []).forEach((str: string) => {
          if (itemsData[itemId]?.indexOf(str) >= 0) {
            searchWeight += str.length > 4 ? 2 : 1;
          } else if (itemsData[itemId]?.replace(/\s/g, '').indexOf(str) >= 0) {
            searchWeight += 1;
          }
        });

        // search the data
        return {
          id: itemId,
          searchWeight,
        };
      })
      .filter(result => {
        return result.searchWeight > 0;
      })
      .reduce((acc, item) => ({ ...acc, [item.id]: item.searchWeight }), {}),
    itemsData || {},
  ];
};
