import { ReactElement, useEffect, useState } from 'react';

// more hooks
import { useDispatch, useSelector } from 'react-redux';

// components
import { IntlProvider } from 'react-intl';
import LoadingScreen from './LoadingScreen';
import { Device } from '@capacitor/device';
import { TStoreState } from '../store/store';

export const locales = [
  { id: 'en', title: 'English', script: 'eng' },
  { id: 'es', title: 'Español', script: 'spa' },
  { id: 'de', title: 'Deutsch', script: 'deu' },
  { id: 'fr', title: 'Français', script: 'fra' },
  { id: 'it', title: 'Italiano', script: 'ita' },
  { id: 'bg', title: 'Български', script: 'bul' },
  { id: 'hu', title: 'Magyar', script: 'hun' },
  { id: 'pl', title: 'Polski', script: 'pol' },
  { id: 'lb', title: 'Lëtzebuergesch', script: 'ltz' },
  { id: 'ru', title: 'Русский', script: 'rus' },
];

type TIntlProps = {
  children: ReactElement | ReactElement[];
};

const Intl = ({ children }: TIntlProps) => {
  const dispatch = useDispatch();

  const profile = useSelector((state: TStoreState) => state.firebase?.profile);
  const uiLocale =
    useSelector((state: TStoreState) => state.ui?.lang) || localStorage.getItem('lang');

  const isMobile = useSelector((state: TStoreState) => state.device.platform !== 'web');

  const [browserLanguage, setBrowserLanguage] = useState<string | null>(null);

  useEffect(() => {
    getBrowserLanguage();
  }, [navigator.language, navigator.languages]);

  const getBrowserLanguage = async () => {
    if (!browserLanguage) {
      let langArr = navigator.languages || [navigator.language];
      if (isMobile) {
        langArr = [(await Device.getLanguageCode()).value];
      }
      const lang = langArr.find(l => locales.find(locale => locale.id === l));
      setBrowserLanguage(lang || locales[0].id);
    }
  };

  const [locale, setLocale] = useState(profile?.lang || browserLanguage);

  useEffect(() => {
    // set current language
    const newLocale = profile.lang || uiLocale || browserLanguage;
    if (newLocale && newLocale !== locale) {
      setLocale(newLocale);
      dispatch({ type: 'SET_LANG', payload: newLocale });
    }
    // eslint-disable-next-line
  }, [profile, uiLocale, browserLanguage]);

  const [messages, setMessages] = useState();

  useEffect(() => {
    if (locale) {
      import(`../../compiled-langs/${locale}.json`).then(res => {
        setMessages(res);
      });
    }
  }, [locale]);

  return messages !== undefined ? (
    <IntlProvider locale={locale} messages={messages} defaultLocale="en">
      {children}
    </IntlProvider>
  ) : (
    <LoadingScreen />
  );
};

export default Intl;
