import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/analytics';
import 'firebase/functions';

import { legacy_createStore as createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { firebaseReducer } from 'react-redux-firebase';
import { createFirestoreInstance, firestoreReducer } from 'redux-firestore';
import { firebaseConfig } from '../firebase-config';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

// additional reducers
import { TUiStore, uiInitialState, uiReducer } from './ui.reducer';
import { TDeviceStore, deviceInitialState, deviceReducer } from './device.reducer';
import { dataInitialState, dataReducer, TDataStore } from './data.reducer';
import { TSettingsStore, settingsInitialState, settingsReducer } from './settings.reducer';

// react-redux-firebase config
const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true,
};

// Initialize firebase instance
firebase.initializeApp(firebaseConfig);

// Initialize other services on firebase instance
firebase
  .firestore()
  .enablePersistence()
  .catch(err => {
    if (err.code === 'failed-precondition') {
      console.error(
        'Firestore Persinstence: multiple tabs open, persistence can only be enabled in one tab at a a time.',
      );
    } else if (err.code === 'unimplemented') {
      console.error(
        'Firestore Persinstence: the current browser does not support all of the features required to enable persistence.',
      );
    }
  });
firebase.storage();
firebase.analytics();
firebase.functions();

// Add firebase and firestore to reducers
const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  // Additional reducers
  data: dataReducer,
  ui: uiReducer,
  device: deviceReducer,
  settings: settingsReducer,
});

type TFirestoreState = {
  data?: {
    papers: any;
    tips: any;
    contacts: any;
    types: any;
    typesSettings: any;
    settings?: any;
  };
};

export type TStoreState = {
  firebase: any;
  firestore: TFirestoreState;
  data: TDataStore;
  ui: TUiStore;
  device: TDeviceStore;
  settings?: TSettingsStore;
};

// Create store with reducers and initial state
const initialState: TStoreState = {
  firebase: {},
  firestore: {},
  data: dataInitialState,
  ui: uiInitialState,
  device: deviceInitialState,
  settings: settingsInitialState,
};

// @ts-ignore
export const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(thunk)),
);

export const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};
