import React, { useEffect, useMemo, useRef, useState } from 'react';
import { emitCustomEvent, useCustomEventListener } from 'react-custom-events';
import { Filesystem } from '@capacitor/filesystem';
import { SendIntent } from 'send-intent';
import { filesizeFromBase64 } from '../utilities/filesize';
import { classes } from '../utilities/strings';
import { getMimeTypeByFileName } from '../utilities/files';

// hooks
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { useDropzone } from 'react-dropzone';
import { useObjectCollection } from '../hooks/useObjectCollection';
import { useFirebase } from 'react-redux-firebase';

// components
import {
  IonBackdrop,
  IonBadge,
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonRouterOutlet,
  IonSplitPane,
  IonTabBar,
  IonTabButton,
  IonTabs,
  isPlatform,
  useIonAlert,
} from '@ionic/react';
import { FormattedMessage, useIntl } from 'react-intl';
import AuthorizationModal from './popups/AuthorizationModal';
import DropModal from './DropModal';
import AuthModal from '../forms/AuthModal';
import EncryptionModal from './EncryptionModal';
import NotificationsModal from './NotificationsModal';
import ImportContactsModal from './ImportContactsModal';
import OCR from './OCR';
import WelcomeModal from './WelcomeModal';

// icons
import {
  chevronBackOutline,
  chevronForwardOutline,
  closeOutline,
  cloudUploadOutline,
} from 'ionicons/icons';
import allPapers from '../assets/all-papers.svg';
import stacks from '../assets/stacks2.svg';
import contacts from '../assets/contacts.svg';
import settings from '../assets/settings.svg';

// types
import { NavItem } from '../models/NavItem';
import { TStoreState } from '../store/store';
import { PapersState } from '../models/Paper';
import { File as PickedFile } from '@capawesome/capacitor-file-picker';
import { GalleryPhoto } from '@capacitor/camera';

import { customAlphabet } from 'nanoid';
import { numbers } from 'nanoid-dictionary';
const nanoid = customAlphabet(numbers, 8);

// import './Navigation.scss';

const NotificationTypePaths: { [type: string]: string } = {
  paper: '/papers/',
  contact: '/contacts/',
  stack: '/stacks/',
};

export const minDesktopScreenWidth = 760;
export const minOpenMenuScreenWidth = 1000;

const Navigation: React.FC = props => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const firebase = useFirebase();

  const user = useSelector((state: any) => state.firebase?.auth);

  const accounts = useObjectCollection('accounts');
  const profile = useSelector((state: TStoreState) => state.firebase?.profile, shallowEqual);
  const [currentAccount, setCurrentAccount] = useState<any>();
  useEffect(() => {
    if (accounts.items) {
      const account = profile.account || 'basic';
      if (accounts.items[account]) {
        setCurrentAccount(accounts.items[account]);
      }
    }
  }, [accounts, profile]);

  /**
   * Detect window sizes
   */
  const testWidth = (evt: any) => {
    dispatch({ type: !evt?.matches ? 'DESKTOP_MODE' : 'MOBILE_MODE' });
    setIsDesktop(!evt?.matches);
  };

  useEffect(() => {
    dispatch({
      type: window.innerWidth > minDesktopScreenWidth ? 'DESKTOP_MODE' : 'MOBILE_MODE',
    });
  }, []);

  const [isDesktop, setIsDesktop] = useState(window.innerWidth > minDesktopScreenWidth);

  const testOpenMenuWidth = (evt: any) => {
    if (!menuToggledManually) {
      toggleMenu(!evt?.matches);
    }
  };
  const [menuToggledManually, setMenuToggledManually] = useState(false);

  useEffect(() => {
    const mql = window.matchMedia('(max-width: ' + minDesktopScreenWidth + 'px)');
    if (mql.addListener) {
      mql.addListener(testWidth);
    } else {
      mql.addEventListener('change', testWidth);
    }
    testWidth(mql);

    const mql2 = window.matchMedia('(max-width: ' + minOpenMenuScreenWidth + 'px)');
    if (mql2.addListener) {
      mql2.addListener(testOpenMenuWidth);
    } else {
      mql2.addEventListener('change', testOpenMenuWidth);
    }
    testOpenMenuWidth(mql2);

    return () => {
      if (mql.removeListener) {
        mql.removeListener(testWidth);
      } else {
        mql.removeEventListener('change', testWidth);
      }

      if (mql2.removeListener) {
        mql2.removeListener(testOpenMenuWidth);
      } else {
        mql2.removeEventListener('change', testOpenMenuWidth);
      }
    };
  }, []);

  /**
   * Show or hide tabs when user is using the keyboard on a mobile
   */
  const showTabs = useSelector((state: any) => state.ui.showTabs);
  const shrinkMenu = useSelector((state: any) => state.ui.shrinkMenu);

  const toggleMenu = (isOpen?: boolean) => {
    if (isOpen === undefined) {
      setMenuToggledManually(true);
    }
    const expand = (isOpen !== undefined && isOpen) || (isOpen === undefined && shrinkMenu);
    dispatch({ type: expand ? 'EXPAND_MENU' : 'SHRINK_MENU' });
  };

  /**
   * Additional authorization to load the private key
   */
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [authModalField, setAuthModalField] = useState<string>();
  useCustomEventListener('request-authorization', (options?: { fieldName?: string }) => {
    if (options?.fieldName) {
      setAuthModalField(options.fieldName);
    }
    setShowAuthModal(true);
  });

  const hideAuthModal = (success: boolean) => {
    if (success) {
      emitCustomEvent('grant-authorization', { fieldName: authModalField });
    }
    setShowAuthModal(false);
    setAuthModalField(undefined);
  };

  /**
   * Welcome modal
   */
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);
  const [showEncryptionModal, setShowEncryptionModal] = useState(false);
  const [showImportContactsModal, setShowImportContactsModal] = useState(false);
  const [showNotificationsModal, setShowNotificationsModal] = useState(false);

  // show welcome screen only if user is not having a public key and did not close the modal manually
  useEffect(() => {
    if (
      !localStorage.getItem('hidden.welcomemodal') &&
      !profile.hidden?.welcomemodal &&
      profile.isLoaded
    ) {
      setShowWelcomeModal(true);
    }
  }, [profile]);

  /**
   * Opens modal depending on it's name
   *
   * @param modal modal name
   */
  useCustomEventListener('showModal', (modal: string) => {
    switch (modal) {
      case 'welcome':
        setShowWelcomeModal(true);
        break;
      case 'registration':
        setShowRegistrationModal(true);
        break;
      case 'encryption':
        setShowEncryptionModal(true);
        break;
      case 'contacts':
        setShowImportContactsModal(true);
        break;
      case 'reminders':
        setShowNotificationsModal(true);
        break;
    }
  });

  /**
   * New documents count
   */
  const papers = useSelector((state: TStoreState) => state.data.papers) as PapersState;
  const newPapers = useMemo(() => {
    if (papers.state.isLoaded && !papers.state.isEmpty) {
      return Object.keys(papers.items || {})
        .filter(paperId => papers.items?.[paperId])
        .reduce((acc, paperId) => {
          if (papers.items?.[paperId].new) {
            acc++;
          }
          return acc;
        }, 0);
    }
    return 0;
  }, [papers.items]);

  /**
   * Menu Items
   * items for both versions: desktop and mobile
   */
  const menuItems: NavItem[] = [
    // {
    //   link: '/all',
    //   tab: 'all',
    //   icon: allPapers,
    //   label: {
    //     id: 'navigation.all.title',
    //     description: 'Title of the All Papers navigation item',
    //     defaultMessage: 'All Papers',
    //   },
    //   badge: false,
    // },
    {
      link: '/papers',
      tab: 'papers',
      icon: allPapers,
      label: {
        id: 'navigation.all.title',
        description: 'Title of the All Papers navigation item',
        defaultMessage: 'Papers',
      },
      badge: newPapers || undefined,
    },
    {
      link: '/stacks',
      tab: 'stacks',
      icon: stacks,
      label: {
        id: 'navigation.stacks.title',
        description: 'Title of the Stacks navigation item',
        defaultMessage: 'Stacks',
      },
    },
    {
      link: '/contacts',
      tab: 'contacts',
      icon: contacts,
      label: {
        id: 'navigation.contacts.title',
        description: 'Title of the Contacts navigation item',
        defaultMessage: 'Contacts',
      },
    },
  ];

  const menuItemsEnd: NavItem[] = [
    {
      link: '/settings',
      tab: 'settings',
      icon: settings,
      label: {
        id: 'navigation.settings.title',
        description: 'Title of the Settings navigation item',
        defaultMessage: 'Settings',
      },
      badge: (user?.isEmpty || user.isAnonymous ? 1 : 0) + (profile?.publicKey ? 0 : 1),
      // menuLabel: {
      //   id: 'tabs.user-settings',
      //   description: 'User Settings Tab Label',
      //   defaultMessage: 'User Settings',
      // },
    },
  ];

  const gotoTab = (tab: string) => {
    (document.querySelector(`ion-tab-button[tab="${tab}"]`) as HTMLElement)?.click();
  };

  // ref for the modals
  const routerRef = useRef<HTMLIonRouterOutletElement | null>(null);
  const stateRouterRef = useSelector((state: any) => state.ui?.routerRef);

  useEffect(() => {
    if (stateRouterRef !== routerRef.current) {
      dispatch({ type: 'SET_ROUTER_REF', payload: routerRef.current });
    }
  }, [routerRef?.current]);

  /**
   * Files upload by custom event
   */
  useCustomEventListener('files-upload', (incomingFiles: File[]) => {
    if ((incomingFiles?.length || 0) > 0) {
      let files2Upload = [];
      for (let i = 0, len = incomingFiles.length; i < len; i++) {
        const file = incomingFiles[i];
        if (file) {
          Object.assign(file, {
            objectUrl: URL.createObjectURL(file),
          });
          files2Upload.push(file);
        }
      }
      if (files2Upload.length > 0) {
        setFiles(files2Upload);
      }
    }
  });

  useCustomEventListener('files-from-os-upload', (incomingFiles: PickedFile[]) => {
    if ((incomingFiles?.length || 0) > 0) {
      let files2Upload = [];
      for (let i = 0, len = incomingFiles.length; i < len; i++) {
        const file = incomingFiles[i];
        if (file) {
          files2Upload.push({
            name: file.name,
            mimeType: file.mimeType,
            size: file.size,
            path: file.path,
            data64: file.data,
          });
        }
      }
      if (files2Upload.length > 0) {
        setFiles(files2Upload);
      }
    }
  });

  useCustomEventListener('files-from-gallery-upload', async (incomingFiles: GalleryPhoto[]) => {
    if ((incomingFiles?.length || 0) > 0) {
      let files2Upload = [];
      for (let i = 0, len = incomingFiles.length; i < len; i++) {
        const file = incomingFiles[i];
        if (file?.path) {
          const name = file.path.split('/').pop();
          const fileInfo = await Filesystem.stat({ path: file.path || '' });

          files2Upload.push({
            name,
            size: fileInfo.size,
            mimeType: 'image/' + file.format,
            path: file.path,
          });
        }
      }
      if (files2Upload.length > 0) {
        setFiles(files2Upload);
      }
    }
  });

  useCustomEventListener('scans-upload', async (incomingFiles: string[]) => {
    if ((incomingFiles?.length || 0) > 0) {
      let files2Upload = [];
      for (let i = 0, len = incomingFiles.length; i < len; i++) {
        const data64 = incomingFiles[i];
        if (data64) {
          const name = intl.formatMessage(
            { id: 'ui.label.paper-scan', defaultMessage: 'Scanned paper {date} {id}' },
            {
              date: new Date().toLocaleDateString(intl.locale),
              id: nanoid(),
            },
          );

          files2Upload.push({
            name,
            size: filesizeFromBase64(data64),
            mimeType: 'image/jpeg',
            data64,
          });
        }
      }
      if (files2Upload.length > 0) {
        setFiles(files2Upload);
      }
    }
  });

  /**
   * File Drag and Drop
   */
  const [files, setFiles] = useState<any[]>([]);
  const { isDragActive, getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png'],
      'appication/pdf': ['.pdf'],
    },
    noKeyboard: true,
    noClick: true,
    onDrop: incomingFiles => {
      setFiles(
        incomingFiles.map(file =>
          Object.assign(file, {
            objectUrl: URL.createObjectURL(file),
          }),
        ),
      );
    },
  });

  const [showDragModal, setShowDragModal] = useState(false);
  const [currentPaperId, setCurrentPaperId] = useState<string | undefined>();
  useEffect(() => {
    if (papers.items && Object.keys(papers.items).length >= currentAccount?.limit) {
      presentAlert({
        header: intl.formatMessage({ id: 'page.papers.limit-alert.title' }),
        message: intl.formatMessage({ id: 'page.papers.limit-alert.message' }),
        buttons: [
          intl.formatMessage({ id: 'ui.buttons.cancel' }),
          {
            text: intl.formatMessage({ id: 'ui.buttons.settings' }),
            handler: () => gotoSettings(),
          },
        ],
      });
    } else {
      if (files.length > 0) {
        const path = location.pathname.split('/');
        if (path.length > 2 && path[1] === 'papers') {
          setCurrentPaperId(path[2]);
        } else {
          setCurrentPaperId(undefined);
        }
        setShowDragModal(true);
      }
    }
  }, [files]);

  const gotoSettings = (): void => {
    history.push(`/settings`);
  };

  const hideDragModal = () => {
    setShowDragModal(false);
    files2UploadCallback.current?.();
    setFiles([]);
  };

  /**
   * Notifications
   */
  const [presentAlert] = useIonAlert();

  useCustomEventListener('pushNotificationReceived', (notification: any) => {
    // notification received during the use of the app
    // have to show an alert with button to go to the item
    const title = notification.title;
    const body = notification.body;
    const type = notification.data?.type;
    if (type) {
      const path = NotificationTypePaths[type] + notification.data.itemId;
      presentAlert({
        header: title,
        message: body,
        buttons: [
          intl.formatMessage({ id: 'ui.buttons.cancel' }),
          {
            text: intl.formatMessage({ id: `ui.buttons.goto.${type}` }),
            handler: () => history.push(path),
          },
        ],
      });
    }
  });

  useCustomEventListener('pushNotificationActionPerformed', (notification: any) => {
    // user tapped on the notification
    // have to redirect to the item
    let path =
      NotificationTypePaths[notification.notification.data.type as string] +
      notification.notification.data.itemId;
    history.push(path);
  });

  const files2UploadCallback = useRef<() => void>();
  useCustomEventListener(
    'files2Upload',
    ({ files, callback }: { files: any[]; callback: () => void }) => {
      console.log('files2Upload event', files);
      // user shared a file from another app
      files2UploadCallback.current = callback;
      setFiles(files);
    },
  );

  // hide app links
  const setHidden = (type: string) => {
    if (!user?.isEmpty && !user.isAnonymous) {
      firebase.updateProfile({
        hidden: {
          ...(profile.hidden || {}),
          [type]: true,
        },
      });
    }
    localStorage.setItem(`hidden.${type}`, 'true');
  };

  return (
    <div {...getRootProps({ className: 'dropzone' })}>
      <input {...getInputProps()} title="Drop Files Input" />
      <OCR />
      <IonSplitPane
        contentId="main"
        className="desktop"
        when={`(min-width: ${minDesktopScreenWidth}px)`}
        disabled={!isDesktop}
      >
        <IonMenu
          contentId="main"
          className={`menu side-menu ${shrinkMenu ? 'side-menu__shrink' : ''}`}
          disabled={!isDesktop}
          swipeGesture={false}
        >
          <div className="menu-items">
            <div>
              {/* <div className="app-name">
                <h1 className="app-name__title">
                  <FormattedMessage id="app.title" defaultMessage="The Papers" />
                </h1>
              </div> */}
              {/* <IonImg src="/assets/logo.svg" /> */}
              <IonList mode="md" lines="none" className="menu__list">
                {menuItems.map(item => (
                  <IonItem
                    key={item.link}
                    button
                    onClick={() => gotoTab(item.tab)}
                    className={classes({
                      menu__item: true,
                      'menu__item-selected': location.pathname.indexOf(item.link) === 0,
                    })}
                  >
                    <IonIcon
                      icon={item.icon}
                      color={location.pathname.indexOf(item.link) === 0 ? 'primary' : 'medium'}
                      slot="start"
                      className="menu-icon"
                    />
                    {shrinkMenu === false && (
                      <IonLabel
                        color={location.pathname.indexOf(item.link) === 0 ? 'primary' : 'medium'}
                      >
                        <FormattedMessage
                          id={item.menuLabel?.id || item.label.id}
                          defaultMessage={
                            item.menuLabel?.defaultMessage || item.label.defaultMessage
                          }
                        />
                      </IonLabel>
                    )}
                    {shrinkMenu === false &&
                      item.badge !== undefined &&
                      (typeof item.badge !== 'number' || item.badge > 0) && (
                        <IonBadge color="danger" slot="end">
                          {item.badge}
                        </IonBadge>
                      )}
                  </IonItem>
                ))}
              </IonList>
            </div>
            <IonList mode="md" lines="none" className="menu__list">
              {shrinkMenu === false &&
                !isPlatform('capacitor') &&
                !localStorage.getItem('hidden.navapplinks') &&
                !profile.hidden?.navapplinks && (
                  <div
                    style={{
                      position: 'relative',
                      margin: '1rem 1.6rem',
                      padding: '1rem 3rem 0.9rem 0.9rem',
                      background: 'rgba(var(--ion-color-dark-rgb), 0.4)',
                      borderRadius: '1rem',
                    }}
                  >
                    <IonButton
                      fill="clear"
                      color="light"
                      shape="round"
                      size="small"
                      onClick={() => setHidden('navapplinks')}
                      style={{
                        position: 'absolute',
                        top: '0.3rem',
                        right: '0.3rem',
                        '--padding-start': 0,
                        '--padding-end': 0,
                      }}
                    >
                      <IonIcon icon={closeOutline} slot="icon-only" />
                    </IonButton>
                    <a
                      href="https://apps.apple.com/app/id1626640788"
                      style={{ display: 'block', marginBottom: '0.4rem' }}
                    >
                      <img src={`/assets/app-store/${intl.locale}.svg`} />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.leechylabs.papers">
                      <img src={`/assets/google-play/${intl.locale}.png`} />
                    </a>
                  </div>
                )}
              {menuItemsEnd.map(item => (
                <IonItem
                  key={item.link}
                  onClick={() => gotoTab(item.tab)}
                  button
                  className={classes({
                    menu__item: true,
                    'menu__item-selected': location.pathname.indexOf(item.link) === 0,
                  })}
                >
                  <IonIcon
                    icon={item.icon}
                    color={location.pathname.indexOf(item.link) === 0 ? 'primary' : 'medium'}
                    slot="start"
                    className="menu-icon"
                  />
                  {shrinkMenu === false && (
                    <IonLabel
                      color={location.pathname.indexOf(item.link) === 0 ? 'primary' : 'medium'}
                    >
                      <FormattedMessage
                        id={item.menuLabel?.id || item.label.id}
                        defaultMessage={item.menuLabel?.defaultMessage || item.label.defaultMessage}
                      />
                    </IonLabel>
                  )}
                  {shrinkMenu === false &&
                    item.badge !== undefined &&
                    (typeof item.badge !== 'number' || item.badge > 0) && (
                      <IonBadge color="danger" slot="end">
                        {item.badge}
                      </IonBadge>
                    )}
                </IonItem>
              ))}
              <IonItem className="menu__item" button detail={false} onClick={() => toggleMenu()}>
                <IonButton className="menu-shrinker" fill="clear" size="small" shape="round">
                  <IonIcon
                    icon={shrinkMenu ? chevronForwardOutline : chevronBackOutline}
                    slot="icon-only"
                  />
                </IonButton>
              </IonItem>
            </IonList>
          </div>
        </IonMenu>
        <div id="main">
          <IonTabs>
            <IonRouterOutlet ref={routerRef}>{props.children}</IonRouterOutlet>

            <IonTabBar slot="bottom" className={isDesktop || !showTabs ? 'tabs-hidden' : ''}>
              {[...menuItems, ...menuItemsEnd].map(item => (
                <IonTabButton tab={item.tab} href={item.link} key={item.link}>
                  <IonIcon icon={item.icon} className="tab-icon" />
                  <IonLabel>
                    <FormattedMessage
                      id={item.label.id}
                      defaultMessage={item.label.defaultMessage}
                    />
                  </IonLabel>
                  {item.badge !== undefined &&
                    (typeof item.badge !== 'number' || item.badge > 0) && (
                      <IonBadge color="danger">{item.badge}</IonBadge>
                    )}
                </IonTabButton>
              ))}
            </IonTabBar>
          </IonTabs>
        </div>
      </IonSplitPane>

      {/* Modals */}
      <AuthorizationModal
        isOpen={showAuthModal}
        onDidDismiss={success => hideAuthModal(success)}
        fieldName={authModalField}
      />
      <DropModal
        isOpen={showDragModal}
        onDidDismiss={hideDragModal}
        filesToUpload={files}
        currentPaperId={currentPaperId}
      />
      <WelcomeModal
        isOpen={showWelcomeModal}
        onDidDismiss={() => {
          setHidden('welcomemodal');
          setShowWelcomeModal(false);
        }}
      />
      <AuthModal
        isOpen={showRegistrationModal}
        onDidDismiss={() => setShowRegistrationModal(false)}
      />
      <EncryptionModal
        isOpen={showEncryptionModal}
        onDidDismiss={() => setShowEncryptionModal(false)}
      />
      <ImportContactsModal
        isOpen={showImportContactsModal}
        onDidDismiss={() => setShowImportContactsModal(false)}
      />
      <NotificationsModal
        isOpen={showNotificationsModal}
        onDidDismiss={() => setShowNotificationsModal(false)}
      />

      {isDragActive && !showDragModal && (
        <>
          <IonBackdrop className="dropzone--backdrop" />
          <div className="dropzone--icon-container">
            <IonIcon icon={cloudUploadOutline} className="dropzone--icon" size="large" />
          </div>
        </>
      )}
    </div>
  );
};

export default Navigation;
