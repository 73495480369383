// hooks
import { FormattedMessage, useIntl } from 'react-intl';

// components
import {
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';

// utils
import { _label } from '../utilities/strings';

// types
import { TForm } from '../models/Form';

type TModalFieldsProps = {
  forms: TForm[];
  selectedForm: number;
  onSelection: (value: number) => void;
};

const SelectForm = ({ forms, selectedForm, onSelection }: TModalFieldsProps) => {
  const intl = useIntl();

  if (forms.length < 2) {
    return null;
  }
  return (
    <>
      <IonItemGroup>
        <IonItemDivider mode="md">
          <IonLabel>
            <FormattedMessage
              id="form.paper.field.select-form.title"
              defaultMessage="Choose a template for the paper"
            />
          </IonLabel>
        </IonItemDivider>
      </IonItemGroup>
      <IonItem mode="ios" className="text-field__container">
        <IonLabel>
          <FormattedMessage id="form.paper.field.select-form.label" defaultMessage="Paper Type" />
        </IonLabel>
        <IonSelect
          value={selectedForm}
          onIonChange={evt => onSelection(evt.detail.value)}
          interface="popover"
          style={{ maxWidth: '66%' }}
          interfaceOptions={{
            cssClass: 'select-field__popover',
          }}
        >
          {forms
            .sort((a, b) => ((a.order || 100) > (b.order || 100) ? 1 : -1))
            .map((form, index) => (
              <IonSelectOption key={form.id} value={index}>
                {_label(form.name, intl.locale)}
              </IonSelectOption>
            ))}
        </IonSelect>
      </IonItem>
    </>
  );
};

export default SelectForm;
