// hooks
import { Fragment, useEffect, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useFirebase, useFirestore } from 'react-redux-firebase';

// utils
import { Contacts } from '@capacitor-community/contacts';
import { getValue } from '../utilities/values';
import classNames from 'classnames';
import { generateContactFromDevice } from '../utilities/contacts';
import { str2key } from '../utilities/encryption';
import { processData } from '../utilities/forms';
import { createFirestoreDocument } from '../utilities/firestore';
import { emitCustomEvent } from 'react-custom-events';
import { NativeSettings, AndroidSettings, IOSSettings } from 'capacitor-native-settings';
import { App } from '@capacitor/app';

// components
import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRadio,
  IonRadioGroup,
  IonTitle,
  IonToolbar,
  isPlatform,
  useIonLoading,
} from '@ionic/react';
import LoadingScreen from './LoadingScreen';
import { FormattedMessage, useIntl } from 'react-intl';
import MatchingContact from './MatchingContact';

// icons and types
import { checkmarkDoneOutline, closeOutline, removeCircleOutline } from 'ionicons/icons';
import { TStoreState } from '../store/store';
import { ContactsState } from '../models/Contact';
import { uploadFiles } from '../utilities/files';

type TImportContactsModalProps = {
  isOpen: boolean;
  onDidDismiss: () => void;
};

const ImportContactsModal = (props: TImportContactsModalProps) => {
  const routerRef = useSelector((state: TStoreState) => state.ui?.routerRef);
  const intl = useIntl();
  const firebase = useFirebase();
  const firestore = useFirestore();
  const user = useSelector((state: any) => state.firebase.auth);

  // check the permission status first
  const [permissionStatus, setPermissionStatus] = useState<
    PermissionState | 'restricted' | 'prompt-with-rationale'
  >('prompt');

  const profile = useSelector((state: TStoreState) => state.firebase.profile);
  const publicKey = useRef<CryptoKey | null>(null);
  useEffect(() => {
    if (profile?.publicKey) {
      str2key(profile.publicKey as string).then(key => {
        publicKey.current = key;
      });
    }
  }, [profile]);
  const privateKey = useSelector((state: TStoreState) => state.ui.privateKey);

  const [isLocked, setIsLocked] = useState(profile?.publicKey && !privateKey);
  useEffect(() => {
    setIsLocked(profile?.publicKey && !privateKey);
  }, [profile, privateKey]);

  const requestAuthorization = async () => {
    emitCustomEvent('request-authorization');
  };

  // check the permission only when the modal is open
  useEffect(() => {
    if (isPlatform('capacitor')) {
      if (props.isOpen) {
        checkPermissions();
      }
    } else {
      setPermissionStatus('restricted');
    }
    if (!props.isOpen) {
      setCntx([]);
    }
  }, [props.isOpen]);

  useEffect(() => {
    // attach app-wide event listener
    if (isPlatform('capacitor')) {
      App.addListener('appStateChange', ({ isActive }) => {
        if (isActive && props.isOpen) {
          checkPermissions();
        }
      });
    }
  }, []);

  const checkPermissions = async () => {
    const checkedPermissionStatus = await Contacts.checkPermissions();
    setPermissionStatus(checkedPermissionStatus.contacts);
  };

  const requestPermission = async () => {
    if (permissionStatus === 'prompt') {
      // if the status is still not determined, request permission
      const getPermissionStatus = await Contacts.requestPermissions();
      setPermissionStatus(getPermissionStatus.contacts);
    }
  };

  const contacts = useSelector(
    (state: TStoreState) => state.data?.contacts,
    shallowEqual,
  ) as ContactsState;

  // load contacts only when we know that we have the permission to do so
  const [deviceContacts, setDeviceContacts] = useState<any[] | null>(null);
  const [cntx, setCntx] = useState<any[] | null>(null);

  useEffect(() => {
    if (props.isOpen && permissionStatus === 'granted') {
      (async () => {
        const contacts = await Contacts.getContacts({
          projection: {
            name: true,
            organization: true,
            birthday: true,
            phones: true,
            emails: true,
            urls: true,
            postalAddresses: true,
            image: true,
          },
        });

        if (contacts?.contacts.length) {
          setDeviceContacts(contacts.contacts);
        } else {
          setDeviceContacts([]);
        }
      })();
    }
  }, [permissionStatus, props.isOpen]);

  // shows the unlock popup when we have rights but the data is locked. once
  useEffect(() => {
    if (props.isOpen && permissionStatus === 'granted' && isLocked) {
      requestAuthorization();
    }
  }, [props.isOpen, permissionStatus, isLocked]);

  /**
   * Update matching contacts when app or device contacts change
   */
  useEffect(() => {
    matchContacts();
  }, [contacts, deviceContacts]);

  /**
   * Matching device contacts with the ones already in the app
   */
  const normalizePhone = (phone: string): string => {
    if (!phone || typeof phone !== 'string') {
      return '';
    }
    return phone.replace(/[\D]/g, '').replace(/^(0|810|8|1800)/, '');
  };

  const matchContacts = () => {
    if (!isLocked && deviceContacts?.length) {
      let contactsUpdated = false;

      const updatedContacts = deviceContacts.map(deviceContact => {
        const updatedContact = {
          ...deviceContact,
          matchingContacts: [],
        };

        if (contacts.items) {
          Object.keys(contacts.items).forEach(contactId => {
            const contact = contacts.items[contactId];
            if (!contact) {
              return;
            }

            // check phones
            const appPhones = getValue(contact.phone, '')
              ?.toString()
              .split(',')
              .map(item => normalizePhone(item))
              .filter(item => item !== '');

            if (appPhones?.length && deviceContact.phones?.length) {
              const devicePhones = deviceContact.phones
                .map((devicePhone: any) => normalizePhone(devicePhone?.number))
                .filter((devicePhone: string) => devicePhone !== '');

              // looking for an intersection of the device and app phones arrays
              // using for to be able to cancel the loops
              for (let i = 0, len = appPhones.length; i < len; i++) {
                const appPhone = appPhones[i];
                for (let j = 0, ln = devicePhones.length; j < ln; j++) {
                  const devicePhone = devicePhones[j];
                  if (appPhone.indexOf(devicePhone) >= 0 || devicePhone.indexOf(appPhone) >= 0) {
                    if (!updatedContact.matchingContacts.includes(contactId)) {
                      updatedContact.matchingContacts = [
                        ...(updatedContact.matchingContacts || []),
                        contactId,
                      ];
                      contactsUpdated = true;
                    }
                    break;
                  }
                }
              }
            }

            // check names
            if (
              (deviceContact.name?.display || '').trim() !== '' &&
              (!updatedContact.matchingContacts ||
                !updatedContact.matchingContacts.includes(contactId))
            ) {
              const names = [
                getValue(contact.names, ''),
                getValue(contact.displayName, ''),
                getValue(contact.otherNames, ''),
              ]
                .map(item => item?.toString().trim())
                .join(',')
                .split(',')
                .filter(item => item !== '');

              if (
                names.length > 0 &&
                names.includes((deviceContact.name?.display || 'Untitled contact').trim())
              ) {
                updatedContact.matchingContacts = [
                  ...(updatedContact.matchingContacts || []),
                  contactId,
                ];
                contactsUpdated = true;
              }
            }
          });
        }

        return updatedContact;
      });

      setCntx(updatedContacts);
    }
  };

  const [selectedContacts, setSelectedContacts] = useState<{
    [contactId: string]: 'new' | 'update';
  }>({});
  const selectContact = (contact: any, type?: string) => {
    if (!type && selectedContacts[contact.contactId]) {
      const newSelectedContacts = { ...selectedContacts };
      delete newSelectedContacts[contact.contactId];
      setSelectedContacts(newSelectedContacts);
    } else {
      setSelectedContacts(state => ({
        ...state,
        [contact.contactId]:
          type || ((contact.matchingContacts?.length || 0) > 0 ? 'update' : 'new'),
      }));
    }
  };

  const selectAllContacts = () => {
    if (cntx) {
      setSelectedContacts(
        cntx.reduce((acc, contact) => {
          acc[contact.contactId] = (contact.matchingContacts?.length || 0) > 0 ? 'update' : 'new';
          return acc;
        }, {}),
      );
    }
  };

  const deselectAllContacts = () => {
    setSelectedContacts({});
  };

  const [presentLoading, dismissLoading] = useIonLoading();

  const importSelectedContacts = async () => {
    if (Object.keys(selectedContacts).length === 0) {
      props.onDidDismiss();
      return;
    }

    // show loading
    presentLoading({
      message: intl.formatMessage({
        id: 'modal.import-contacts.importing',
        defaultMessage: 'Importing Contacts...',
      }),
    });

    const contactIds = Object.keys(selectedContacts);
    for (let i = 0, len = contactIds.length; i < len; i++) {
      // create the contact
      const deviceContact = cntx?.find(item => item.contactId === contactIds[i]);
      const contactToUpdate =
        selectedContacts[contactIds[i]] === 'update'
          ? contacts.items[deviceContact.matchingContacts[0]]
          : undefined;
      const contact = generateContactFromDevice(deviceContact, contactToUpdate);

      // encrypt the data
      const [docData, filesToUpload] = await processData(
        contact,
        {},
        publicKey.current || undefined,
      );

      // save the document
      await createFirestoreDocument(
        user,
        firebase,
        firestore,
        `contacts/${docData.id}`,
        docData,
      ).then(async () => {
        // upload new files to the storage
        await uploadFiles(firebase, filesToUpload, user.uid, 'contact', docData.id as string);

        return true;
      });
    }

    // dismiss loading and close the modal
    dismissLoading();
    setSelectedContacts({});
    props.onDidDismiss();
  };

  const openAppSettings = () => {
    NativeSettings.open({
      optionAndroid: AndroidSettings.ApplicationDetails,
      optionIOS: IOSSettings.App,
    });
  };

  return (
    <IonModal
      mode="md"
      isOpen={props.isOpen}
      onDidDismiss={props.onDidDismiss}
      presentingElement={routerRef || undefined}
    >
      <IonHeader mode="md">
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={props.onDidDismiss}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>
            <FormattedMessage
              id="modal.import-contacts.title"
              defaultMessage="Contacts to import"
            />
          </IonTitle>
          <IonButtons slot="primary">
            <IonButton
              onClick={() => importSelectedContacts()}
              color="primary"
              shape="round"
              fill="outline"
              className="update-button"
            >
              <FormattedMessage id="ui.buttons.import" defaultMessage="Import" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {permissionStatus === 'restricted' ? (
          <p className="modal-description-bold">
            <FormattedMessage
              id="modal.import-contacts.restricted-access"
              defaultMessage="There is no access to the contacts on this device."
            />
          </p>
        ) : permissionStatus === 'denied' ? (
          <>
            <p className="modal-description-bold">
              <FormattedMessage
                id="modal.import-contacts.denied-access"
                defaultMessage="Access to the contacts on this device was denied. Please change this in the device settings."
              />
            </p>
            <div style={{ textAlign: 'center', margin: '2rem' }}>
              <IonButton
                mode="md"
                shape="round"
                fill="outline"
                className="ion-activated"
                onClick={openAppSettings}
              >
                <FormattedMessage
                  id="ui.buttons.open-app-settings"
                  defaultMessage="Open Application Settings"
                />
              </IonButton>
            </div>
          </>
        ) : permissionStatus === 'prompt' ? (
          <>
            <p className="modal-description-bold">
              <FormattedMessage
                id="modal.import-contacts.request-access"
                defaultMessage="To import the contacts, we need a permission to get access to your device."
              />
            </p>
            <div style={{ textAlign: 'center', margin: '2rem' }}>
              <IonButton
                mode="md"
                shape="round"
                fill="outline"
                className="ion-activated"
                onClick={requestPermission}
              >
                <FormattedMessage
                  id="ui.buttons.request-permission"
                  defaultMessage="Request Permission"
                />
              </IonButton>
            </div>
          </>
        ) : isLocked ? (
          <>
            <p className="modal-description-bold">
              <FormattedMessage
                id="modal.import-contacts.unlock-data"
                defaultMessage="To be able to match properly device's contacts with the already existing, please unlock the data first."
              />
            </p>
            <div style={{ textAlign: 'center', margin: '2rem' }}>
              <IonButton
                mode="md"
                shape="round"
                fill="outline"
                className="ion-activated"
                onClick={requestAuthorization}
              >
                <FormattedMessage
                  id="ui.buttons.unlock-the-data"
                  defaultMessage="Unlock the Data"
                />
              </IonButton>
            </div>
          </>
        ) : !cntx ? (
          <LoadingScreen />
        ) : cntx.length > 0 ? (
          <IonList>
            {Object.keys(selectedContacts).length > 0 ? (
              <IonItem button onClick={deselectAllContacts} detail={false}>
                <IonIcon slot="start" icon={removeCircleOutline} />
                <IonLabel>
                  <FormattedMessage
                    id="modal.import-contacts.deselect-all"
                    defaultMessage="Deselect all contacts"
                  />
                </IonLabel>
              </IonItem>
            ) : (
              <IonItem button onClick={selectAllContacts} detail={false}>
                <IonIcon slot="start" icon={checkmarkDoneOutline} color="primary" />
                <IonLabel>
                  <FormattedMessage
                    id="modal.import-contacts.select-all"
                    defaultMessage="Select all contacts"
                  />
                </IonLabel>
              </IonItem>
            )}
            {cntx.map(contact => (
              <Fragment key={contact.contactId}>
                <IonItem
                  className="list-item import-contact-item"
                  onClick={() => selectContact(contact)}
                >
                  <IonCheckbox
                    slot="start"
                    color="primary"
                    checked={!!selectedContacts[contact.contactId]}
                    className={classNames({
                      'matched-contact': (contact.matchingContacts?.length || 0) > 0,
                    })}
                    aria-label={contact.name?.display || ''}
                  />
                  <IonLabel>
                    <h2>
                      {contact.image?.base64String && (
                        <IonImg
                          src={contact.image.base64String}
                          style={{ objectFit: 'contain' }}
                          className="matched-contact--avatar"
                        />
                      )}
                      {contact.name?.display || ''}
                    </h2>
                    {(contact.matchingContacts?.length || 0) > 0 && (
                      <div>
                        <p className="matched-contact--title">
                          <FormattedMessage
                            id="modal.import-contacts.matching-contact"
                            defaultMessage="{count, plural, one {Matching contact} other {Matching contacts}}:"
                            values={{
                              count: contact.matchingContacts?.length,
                            }}
                          />
                        </p>
                        {contact.matchingContacts.map((contactId: string) => (
                          <MatchingContact key={contactId} contact={contacts.items[contactId]} />
                        ))}
                      </div>
                    )}
                  </IonLabel>
                  <IonLabel
                    slot="end"
                    className={classNames({
                      'list-item__end': true,
                      'import-contact-item--only-contact': !contact?.emails?.[0]?.address,
                    })}
                  >
                    {contact?.phones?.[0]?.number && <p>{contact?.phones?.[0]?.number}</p>}
                    {contact?.emails?.[0]?.address && <p>{contact?.emails?.[0]?.address}</p>}
                  </IonLabel>
                </IonItem>
                {(contact.matchingContacts?.length || 0) > 0 &&
                  selectedContacts[contact.contactId] && (
                    <IonRadioGroup
                      allowEmptySelection={false}
                      value={selectedContacts[contact.contactId]}
                      onIonChange={evt => selectContact(contact, evt.detail.value)}
                    >
                      <IonItem className="matched-contact--sub-item">
                        <IonRadio slot="start" value="update" color="primary"></IonRadio>
                        <IonLabel>
                          <FormattedMessage
                            id="modal.import-contacts.update-contact"
                            defaultMessage="Update the matching contact"
                          />
                        </IonLabel>
                      </IonItem>
                      <IonItem className="matched-contact--sub-item">
                        <IonRadio slot="start" value="new" color="primary"></IonRadio>
                        <IonLabel>
                          <FormattedMessage
                            id="modal.import-contacts.create-contact"
                            defaultMessage="Create new contact"
                          />
                        </IonLabel>
                      </IonItem>
                    </IonRadioGroup>
                  )}
              </Fragment>
            ))}
          </IonList>
        ) : (
          <p className="modal-description-bold">
            <FormattedMessage
              id="modal.import-contacts.no-contacts-found"
              defaultMessage="No contacts found on the device."
            />
          </p>
        )}
      </IonContent>
    </IonModal>
  );
};

export default ImportContactsModal;
