import { TFieldValue, TFieldValues, TFilePreview } from './FieldValues';
import { RequestState } from './RequestState';

export type Stack = {
  id: string;
  createdAt: string;
  updatedAt?: string;

  name: TFieldValue;
  pinned: TFieldValue;

  'stack-type': TFieldValue;
  'stack-papers'?: TFieldValues;

  thumbnail?: TFilePreview[];
};

export type StackState = {
  item: Stack;
  state: RequestState;
};

export type StacksState = {
  items: { [id: string]: Stack };
  state: RequestState;
};

export type StacksOrderedState = {
  items: Stack[];
  state: RequestState;
};

export const newStack: Stack = {
  id: '',
  createdAt: new Date().toISOString(),
  name: { value: '' },
  pinned: { value: false },
  'stack-type': { value: 'collection' },
};
