// hooks
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';

// components
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonTitle,
  IonToolbar,
} from '@ionic/react';

// icons
import { checkmarkSharp, closeOutline } from 'ionicons/icons';

// types
import { locales } from '../components/Intl';

type TLangModalProps = {
  isOpen: boolean;
  onDidDismiss: () => void;
};

const LangModal: React.FC<TLangModalProps> = (props: TLangModalProps) => {
  const routerRef = useSelector((state: any) => state.ui?.routerRef);

  const intl = useIntl();
  const dispatch = useDispatch();
  const uid = useSelector((state: any) => state.firebase?.auth?.uid);

  const firebase = useFirebase();

  const changeLanguage = (localeId: string) => {
    dispatch({ type: 'SET_LANG', payload: localeId });
    if (uid) firebase?.updateProfile({ lang: localeId });
    props.onDidDismiss();
    localStorage.setItem('lang', localeId);
  };

  return (
    <IonModal
      mode="md"
      isOpen={props.isOpen}
      onDidDismiss={props.onDidDismiss}
      presentingElement={routerRef}
    >
      <IonHeader mode="md">
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={props.onDidDismiss}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>
            <FormattedMessage
              id="modal.languages.title"
              defaultMessage="Select Interface Language"
            />
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonList>
          {locales.map(locale => (
            <IonItem
              key={locale.id}
              onClick={() => changeLanguage(locale.id)}
              button
              detail={false}
            >
              <IonLabel>{locale.title}</IonLabel>
              {intl.locale === locale.id && (
                <IonIcon icon={checkmarkSharp} slot="end" color="primary" />
              )}
            </IonItem>
          ))}
        </IonList>
      </IonContent>
    </IonModal>
  );
};

export default LangModal;
