// hooks
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useFirebase } from 'react-redux-firebase';
import { useObjectCollection } from '../hooks/useObjectCollection';
import { useHistory } from 'react-router-dom';

// utils
import { PushNotifications, Token } from '@capacitor/push-notifications';
import { getDateValue } from '../utilities/values';
import { emitCustomEvent } from 'react-custom-events';
import { NativeSettings, AndroidSettings, IOSSettings } from 'capacitor-native-settings';

// components
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonTitle,
  IonContent,
  IonLabel,
  IonList,
  IonItem,
  IonItemDivider,
  IonImg,
  IonAvatar,
  isPlatform,
  IonFooter,
} from '@ionic/react';
import { FormattedMessage, useIntl } from 'react-intl';

// icons and types
import { closeOutline } from 'ionicons/icons';
import { TStoreState } from '../store/store';
import { TReminder, TReminderRecordData, TRemindersState } from '../models/Reminder';

type TNotificationsModalProps = {
  isOpen: boolean;
  onDidDismiss: () => void;
};

const NotificationsModal: React.FC<TNotificationsModalProps> = (
  props: TNotificationsModalProps,
) => {
  const intl = useIntl();
  const firebase = useFirebase();

  const routerRef = useSelector((state: TStoreState) => state.ui?.routerRef);
  const profile = useSelector((state: TStoreState) => state.firebase?.profile);
  const user = useSelector((state: any) => state.firebase?.auth);

  const deviceId = useSelector((state: TStoreState) => state.device?.deviceId);
  const devices = useSelector((state: TStoreState) => state.firebase?.profile?.devices);

  const reminders = useObjectCollection('reminders') as TRemindersState;

  const [available, setAvailable] = useState<boolean | undefined>();
  const [permissions, setPermissions] = useState<string>('');

  const updateDevices = async (newDeviceInfo: { [prop: string]: any }) => {
    if (deviceId) {
      const devicesInfo = {
        ...(devices || {}),
        [deviceId]: {
          ...(devices?.[deviceId] || {}),
          ...newDeviceInfo,
        },
      };
      await firebase.updateProfile({ devices: devicesInfo });
    }
  };

  useEffect(() => {
    if (
      props.isOpen &&
      isPlatform('capacitor') &&
      !user?.isAnonymous &&
      !profile &&
      profile.isEmpty !== true &&
      profile.account !== 'anonymous'
    ) {
      PushNotifications.checkPermissions()
        .then(res => {
          setAvailable(true);
          setPermissions(res.receive);
          updateDevices({
            notificationsPermissions: res.receive,
          });
          if (res.receive === 'granted') {
            attachListeners();
            PushNotifications.register();
          }
        })
        .catch(err => {
          updateDevices({
            notificationsPermissions: 'not-available',
          });
          setAvailable(false);
          console.error('Check Notification Permissions Error', err);
        });
    }
  }, [props.isOpen]);

  const requestPermissions = () => {
    PushNotifications.requestPermissions().then(res => {
      setPermissions(res.receive);
      updateDevices({
        notificationsPermissions: res.receive,
      });
      if (res.receive === 'granted') {
        attachListeners();
        PushNotifications.register();
      }
    });
  };

  const attachListeners = () => {
    PushNotifications.addListener('registration', (token: Token) => {
      updateDevices({
        notificationsToken: token.value,
      });
    });

    PushNotifications.addListener('registrationError', (error: any) => {
      console.error('PushNotifications.addListener(registrationError)', error);
    });
  };

  const showAuthModal = () => {
    emitCustomEvent('showModal', 'registration');
  };

  const openAppSettings = () => {
    NativeSettings.open({
      optionAndroid: AndroidSettings.ApplicationDetails,
      optionIOS: IOSSettings.App,
    });
  };

  return (
    <IonModal
      mode="md"
      isOpen={props.isOpen}
      onDidDismiss={props.onDidDismiss}
      presentingElement={routerRef || undefined}
    >
      <IonHeader mode="md">
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={props.onDidDismiss}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>
            <FormattedMessage id="modal.notifications.title" defaultMessage="Notifications" />
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      {user?.isAnonymous ||
      !profile ||
      profile.isEmpty === true ||
      profile.account === 'anonymous' ? (
        <>
          <IonContent>
            <p className="modal-description-text">
              <FormattedMessage
                id="modal.notifications.need-account"
                defaultMessage="You need to have an account to use this feature."
              />
            </p>
          </IonContent>
          <IonFooter>
            <p className="modal-footer-button">
              <IonButton
                onClick={showAuthModal}
                mode="md"
                shape="round"
                fill="outline"
                className="ion-activated"
              >
                <IonLabel>
                  <FormattedMessage
                    id="ui.buttons.login-or-register"
                    defaultMessage="Register or Sign In"
                  />
                </IonLabel>
              </IonButton>
            </p>
          </IonFooter>
        </>
      ) : available ? (
        permissions === 'denied' ? (
          <>
            <IonContent>
              <p className="modal-description-bold">
                <FormattedMessage
                  id="modal.notifications.denied"
                  defaultMessage="Permissions to receive notifications on this device have been denied."
                />
              </p>
            </IonContent>
            <IonFooter>
              <p className="modal-footer-button">
                <IonButton
                  mode="md"
                  shape="round"
                  fill="outline"
                  className="ion-activated"
                  onClick={openAppSettings}
                >
                  <FormattedMessage
                    id="ui.buttons.open-app-settings"
                    defaultMessage="Open Application Settings"
                  />
                </IonButton>
              </p>
            </IonFooter>
          </>
        ) : permissions !== 'granted' ? (
          <>
            <IonContent>
              <p className="modal-description-bold">
                <FormattedMessage
                  id="modal.notifications.request-permissions"
                  defaultMessage="To start receiving notifications on this device, you have to grant permissions
                  to the app:"
                />
              </p>
            </IonContent>
            <IonFooter>
              <p className="modal-footer-button">
                <IonButton
                  onClick={requestPermissions}
                  mode="md"
                  shape="round"
                  fill="outline"
                  className="ion-activated"
                >
                  <IonLabel>
                    <FormattedMessage
                      id="ui.buttons.request-permission"
                      defaultMessage="Request Permission"
                    />
                  </IonLabel>
                </IonButton>
              </p>
            </IonFooter>
          </>
        ) : (
          <IonContent>
            <p className="modal-description-bold">
              <FormattedMessage
                id="modal.notifications.available"
                defaultMessage="You are currently receiving notifications on this device."
              />
            </p>
            <NotificationsList
              reminders={reminders.items}
              locale={intl.locale}
              onDidDismiss={props.onDidDismiss}
            />
          </IonContent>
        )
      ) : (
        <IonContent>
          <p className="modal-description-bold">
            <FormattedMessage
              id="modal.notifications.unavailable"
              defaultMessage="Sorry, notifications are not available on this device!"
            />
          </p>
          <NotificationsList
            reminders={reminders.items}
            locale={intl.locale}
            onDidDismiss={props.onDidDismiss}
          />
        </IonContent>
      )}
    </IonModal>
  );
};

export default NotificationsModal;

type NotificationsListProps = {
  reminders: { [id: string]: TReminder };
  locale: string;
  onDidDismiss?: () => void;
};

const NotificationsList = ({ reminders, locale, onDidDismiss }: NotificationsListProps) => {
  const history = useHistory();

  const goToEntity = (reminder: TReminderRecordData) => {
    const path = `/${reminder.type}s/${reminder.itemId}`;
    if (onDidDismiss) {
      onDidDismiss();
    }
    history.replace(path);
  };

  return (
    reminders && (
      <IonList>
        <IonItemDivider>
          <IonLabel>
            <FormattedMessage
              id="modal.notifications.reminders.title"
              defaultMessage="Pending Reminders"
            />
          </IonLabel>
        </IonItemDivider>
        {Object.keys(reminders)
          .sort((date1, date2) => (date1 > date2 ? 1 : -1))
          .map(date => {
            const displayDate = getDateValue(date.substring(0, 10), undefined, undefined, locale);
            return reminders[date]
              ? Object.keys(reminders[date])
                  .filter(key => key !== 'docName' && key !== 'uid')
                  .map((key: string) => {
                    const reminder = reminders[date][key];
                    if (reminder) {
                      return (
                        <IonItem key={key} button onClick={() => goToEntity(reminder)}>
                          {reminder.image && (
                            <IonAvatar
                              slot="end"
                              className="list-item__avatar"
                              style={{ width: 'auto' }}
                            >
                              <IonImg src={reminder.image} />
                            </IonAvatar>
                          )}
                          <IonLabel>
                            <p style={{ fontSize: '12px' }}>
                              <FormattedMessage
                                id="modal.notifications.reminders.scheduled"
                                defaultMessage="Scheduled for"
                              />{' '}
                              {displayDate}
                            </p>
                            <h2>{reminder.title}</h2>
                            <p>{reminder.body}</p>
                          </IonLabel>
                        </IonItem>
                      );
                    }
                    return null;
                  })
              : null;
          })}
      </IonList>
    )
  );
};
