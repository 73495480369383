// hooks
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { useObjectCollection } from '../hooks/useObjectCollection';

// helpers
import { emitCustomEvent } from 'react-custom-events';

// components
import { IonFab, IonFabButton, IonIcon } from '@ionic/react';
import AppPage from '../components/AppPage';
import ListHeader from '../components/ListHeader';
import StackModal from '../forms/StackModal';
import LoadingScreen from '../components/LoadingScreen';
import StacksList from '../components/StacksList';

// types and icons
import { TStoreState } from '../store/store';
import { add, lockClosedOutline } from 'ionicons/icons';

const Stacks: React.FC = () => {
  const intl = useIntl();

  // saved states
  const savedSearchText = useSelector(
    (state: any) => state.firebase.profile?.stacksSearch || '',
    shallowEqual,
  );

  // data
  const stacks = useObjectCollection('stacks');

  // tips
  const tips = useSelector((state: TStoreState) => state.firestore?.data?.tips?.[intl.locale]);

  /**
   * Create modal
   */
  const [formModalOpened, setFormModalOpened] = useState(false);

  const showCreateModal = () => {
    setFormModalOpened(true);
  };

  const hideCreateModal = () => {
    setFormModalOpened(false);
  };

  /**
   * Request authorization
   */
  const profile = useSelector((state: TStoreState) => state.firebase.profile, shallowEqual);
  const privateKey = useSelector((state: TStoreState) => state.ui?.privateKey);

  const [isLocked, setIsLocked] = useState(profile?.publicKey && !privateKey);

  useEffect(() => {
    setIsLocked(profile?.publicKey && !privateKey);
  }, [profile, privateKey]);

  const requestAuthorization = async () => {
    emitCustomEvent('request-authorization');
  };

  return (
    <AppPage
      title={intl.formatMessage({ id: 'page.stacks.title' })}
      header={
        <ListHeader
          title={intl.formatMessage({
            id: 'page.stacks.title',
            defaultMessage: 'Stacks',
          })}
          searchPlaceholder={intl.formatMessage({
            id: 'page.stacks.search.placeholder',
            defaultMessage: 'Filter Stacks',
          })}
          savedSearchText={savedSearchText}
          onSearchUpdate={null}
        >
          <></>
          {/* <FiltersToolbar
            filterIcon="sort"
            filterTitle={intl.formatMessage({
              id: 'stacks.sort.title',
              defaultMessage: 'Sort stacks by',
            })}
            filterItems={[
              {
                value: 'papers',
                title: intl.formatMessage({
                  id: 'stacks.sort.papers.title',
                  defaultMessage: 'Number of Papers',
                }),
                label: intl.formatMessage({
                  id: 'stacks.sort.papers.label',
                  defaultMessage: 'Sorted by Number of Papers',
                }),
              },
              {
                value: 'update',
                title: intl.formatMessage({
                  id: 'stacks.sort.update.title',
                  defaultMessage: 'Last Update',
                }),
                label: intl.formatMessage({
                  id: 'stacks.sort.update.label',
                  defaultMessage: 'Sorted by Last Update',
                }),
              },
            ]}
            filterValue={sortBy}
            onFilterChange={updateSort}
          /> */}
        </ListHeader>
      }
      path="/stacks"
    >
      {stacks.state.isLoading ? (
        <LoadingScreen />
      ) : stacks.state.isEmpty ? (
        stacks.state.isEmpty &&
        tips?.contacts && (
          <div className="tip" dangerouslySetInnerHTML={{ __html: tips.stacks }}></div>
        )
      ) : (
        <StacksList searchText="" routerLink="/stacks/" />
      )}

      {!stacks.state.isLoading && (
        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton mode="ios" onClick={showCreateModal} color="tertiary">
            <IonIcon icon={add} />
          </IonFabButton>
        </IonFab>
      )}

      {isLocked && (
        <IonFab vertical="bottom" horizontal="start" slot="fixed">
          <IonFabButton mode="ios" onClick={requestAuthorization}>
            <IonIcon icon={lockClosedOutline} />
          </IonFabButton>
        </IonFab>
      )}

      <StackModal opened={formModalOpened} onDismiss={hideCreateModal} />
    </AppPage>
  );
};

export default Stacks;
