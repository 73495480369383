import { useEffect, useRef } from 'react';

// hooks
import { useDispatch } from 'react-redux';

// components
import { isPlatform, IonSearchbar } from '@ionic/react';
import { backspaceOutline } from 'ionicons/icons';

type TSearchbar = {
  value?: string;
  placeholder: string;
  onChange?: Function;
};

const Searchbar = ({ value, placeholder, onChange }: TSearchbar) => {
  const dispatch = useDispatch();

  // search bar
  const searchBarRef = useRef<HTMLIonSearchbarElement>(null);

  const searchText = useRef<string>('');
  const updateSearch = (searchStr: string) => {
    searchText.current = searchStr;
    if (onChange) {
      onChange(searchStr);
    }
  };

  useEffect(() => {
    if (searchText.current === '') {
      searchText.current = value || '';
    }
  }, []);

  const onFocus = () => {
    if (isPlatform('mobile')) {
      dispatch({ type: 'HIDE_TABS' });
    }
  };
  const onBlur = () => {
    dispatch({ type: 'SHOW_TABS' });
  };

  useEffect(() => {
    setTimeout(() => {
      if (searchBarRef.current) {
        searchBarRef.current!.setFocus();

        // setting value this way, because otherwise
        // all the search mechanics are too glitchy
        if (value !== '') {
          searchBarRef.current.getInputElement().then(input => {
            if (input) {
              input.value = value || '';
              input.select();
            }
          });
        }
      }
    }, 100);
  }, []);

  return (
    <IonSearchbar
      ref={searchBarRef}
      onIonChange={e => updateSearch(e.detail.value!)}
      onIonInput={e => updateSearch(e.detail.value!)}
      onIonFocus={onFocus}
      onIonBlur={onBlur}
      searchIcon={undefined}
      clearIcon={backspaceOutline}
      mode="ios"
      className="toolbar-searchbar"
      placeholder={placeholder}
      debounce={0}
    />
  );
};

export default Searchbar;
