import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { IonAvatar, IonIcon, IonItem, IonLabel } from '@ionic/react';

import { getValue } from '../utilities/values';

import { businessOutline, personOutline, star } from 'ionicons/icons';
import { Contact } from '../models/Contact';
import { TStoreState } from '../store/store';
import { classes } from '../utilities/strings';

type TContactsListItemsProps = {
  contact: Contact;
  routerLink?: string;
  currentPath?: string;
  clickHandler?: (paperId: string) => void;
  style?: any;
};

const ContactsListItem = ({
  contact,
  routerLink,
  currentPath,
  clickHandler,
  style,
}: TContactsListItemsProps) => {
  const intl = useIntl();
  const uid = useSelector((state: TStoreState) => state.firebase.auth.uid);
  const avatar = (contact?.avatar?.[0]?.url || contact?.avatars?.[0]?.url)?.replace('{uid}', uid);

  const title =
    getValue(contact.displayName) ||
    getValue(contact.names, intl.formatMessage({ id: 'contact.untitled' }));

  const subtitle =
    getValue(contact.occupation) || (getValue(contact.displayName) && getValue(contact.names));

  return (
    <IonItem
      className={classes({
        'list-item': true,
        'contacts-list-item': true,
        'list-item--active': !!currentPath?.includes(`${routerLink}${contact?.id}` || '', 0),
      })}
      routerLink={routerLink && `${routerLink}${contact?.id}`}
      onClick={clickHandler && (() => clickHandler(contact?.id))}
      button={!!clickHandler}
      style={style}
    >
      {contact && (
        <>
          <IonAvatar slot="start" className="list-item__avatar">
            {avatar ? (
              // <IonImg src={avatar} style={{ objectFit: 'contain' }} />
              <img src={avatar} style={{ objectFit: 'contain' }} alt="avatar" />
            ) : (
              <IonIcon
                icon={getValue(contact.business) ? businessOutline : personOutline}
                color="medium"
                style={{ fontSize: '32px' }}
              />
            )}
          </IonAvatar>
          <IonLabel>
            <h2>
              {title}
              {getValue(contact.favorite) === true && (
                <IonIcon
                  icon={star}
                  color="secondary"
                  className="favorite-star favorite-star__list-item"
                />
              )}
            </h2>
            {subtitle && <p>{subtitle}</p>}
          </IonLabel>
          <IonLabel slot="end" className="list-item__end">
            <p>{getValue(contact.phone, '—', 1)}</p>
            <p>{getValue(contact.email, '—', 1)}</p>
          </IonLabel>
        </>
      )}
    </IonItem>
  );
};

export default ContactsListItem;
