// hooks
import { useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';
import { useHistory } from 'react-router';
import { useCollectionItem } from '../hooks/useCollectionItem';
import { useObjectCollection } from '../hooks/useObjectCollection';

// components
import AppPage from '../components/AppPage';
import ListHeader from '../components/ListHeader';
import FiltersToolbar, { TFiltersItems } from '../components/FiltersToolbar';
import LoadingScreen from '../components/LoadingScreen';
import PapersListComponent from '../components/PapersList';
import PeriodsToolbar from '../components/PeriodsToolbar';
import PapersListSelectionToolbar from '../components/PapersListSelectionToolbar';
import {
  IonChip,
  IonFab,
  IonFabButton,
  IonFabList,
  IonIcon,
  isPlatform,
  useIonAlert,
} from '@ionic/react';

// utils
import { calculateAvailablePeriods, getNewPeriod } from '../utilities/lists';
import { emitCustomEvent } from 'react-custom-events';
import classNames from 'classnames';
import { updatePapersGrouping, updatePapersPeriods, updatePapersSearch } from '../thunks/settings';

// plugins
import { FilePicker, PickFilesResult } from '@capawesome/capacitor-file-picker';
import { Camera, GalleryPhotos } from '@capacitor/camera';
import { DocumentScanner, ResponseType, ScanDocumentResponse } from 'capacitor-document-scanner';

// types
import { TStoreState } from '../store/store';
import { Paper, PapersState } from '../models/Paper';
import {
  add,
  cameraOutline,
  cloudUploadOutline,
  createOutline,
  documentsOutline,
  imagesOutline,
  lockClosedOutline,
} from 'ionicons/icons';
import { TPapersPeriodsShow } from '../store/settings.reducer';

const PapersList: React.FC = () => {
  const intl = useIntl();
  const firestore = useFirestore();
  const history = useHistory();

  // saved states
  const savedSearchText = useSelector(
    (state: TStoreState) => state.settings?.papers?.search || '',
    shallowEqual,
  );
  const grouping = useSelector((state: TStoreState) => state.settings?.papers?.grouping || 'none');
  const periods = useSelector(
    (state: TStoreState) =>
      state.settings?.papers?.periods || {
        show: 'all',
        field: 'createdAt',
        selected: 0,
      },
  );

  const showTabs = useSelector((state: TStoreState) => state.ui.showTabs);

  // data
  const uid = useSelector((state: TStoreState) => state.firebase.auth.uid);
  const papers = useSelector(
    (state: TStoreState) => state.data.papers,
    shallowEqual,
  ) as PapersState;
  const fields = useCollectionItem('types', 'fields');

  // check number of papers
  const accounts = useObjectCollection('accounts');
  const profile = useSelector((state: TStoreState) => state.firebase?.profile, shallowEqual);
  const [currentAccount, setCurrentAccount] = useState<any>();
  useEffect(() => {
    const account = profile.account || 'basic';
    if (accounts.items?.[account] && currentAccount !== accounts.items[account]) {
      setCurrentAccount(accounts.items[account]);
    }
    // eslint-disable-next-line
  }, [accounts, profile]);

  // tips
  const tips = useSelector((state: TStoreState) => state.firestore?.data?.tips?.[intl.locale]);

  // search
  const [searchText, setSearchText] = useState<string>('');
  const onSearchUpdate = (text: string) => {
    setSearchText(text);
    updatePapersSearch(firestore, uid, text);
  };

  // calculate available periods
  const availableYearsAndMonths = useMemo(() => {
    return calculateAvailablePeriods(papers.items, periods.field);
    // eslint-disable-next-line
  }, [papers.items, periods.field]);

  const availablePeriods: string[] = useMemo(() => {
    return availableYearsAndMonths[periods.show === 'yearly' ? 0 : 1];
  }, [periods, availableYearsAndMonths]);

  // toolbar items
  const groupingItems: TFiltersItems[] = useMemo(
    () => [
      {
        value: 'none',
        title: intl.formatMessage({
          id: 'papers.grouping.none.title',
          defaultMessage: 'No Grouping',
        }),
        label: intl.formatMessage({
          id: 'papers.grouping.none.label',
          defaultMessage: 'No Grouping',
        }),
      },
      {
        value: 'form',
        title: intl.formatMessage({
          id: 'papers.grouping.types.title',
          defaultMessage: 'Paper Type',
        }),
        label: intl.formatMessage({
          id: 'papers.grouping.types.label',
          defaultMessage: 'Group by Paper Type',
        }),
      },
      ...(((fields.item &&
        Object.keys(fields.item)
          ?.filter((fieldId: string) => fields.item[fieldId].grouping > 0)
          .sort((f1, f2) => (fields.item[f1].grouping > fields.item[f2].grouping ? 1 : -1))
          .map((fieldId: string) => ({
            value: fieldId,
            title: intl.formatMessage({
              id: fields.item[fieldId].groupingTitleId || 'papers.grouping.title',
            }),
            label: intl.formatMessage({
              id: fields.item[fieldId].groupingLabelId || 'papers.grouping.label',
            }),
          }))) ||
        []) as TFiltersItems[]),
    ],
    [fields.item, intl],
  );

  const filterItems: TFiltersItems[] = [];

  const periodItems: TFiltersItems[] = [
    {
      value: 'all',
      title: intl.formatMessage({
        id: 'periods.all.title',
        defaultMessage: 'For All Times',
      }),
      label: intl.formatMessage({
        id: 'periods.all.label',
        defaultMessage: 'All Times',
      }),
    },
    {
      value: 'yearly',
      title: intl.formatMessage({
        id: 'periods.yearly.title',
        defaultMessage: 'By Year',
      }),
      label: intl.formatMessage({
        id: 'periods.yearly.label',
        defaultMessage: 'By Year',
      }),
    },
    {
      value: 'monthly',
      title: intl.formatMessage({
        id: 'periods.monthly.title',
        defaultMessage: 'By Month',
      }),
      label: intl.formatMessage({
        id: 'periods.monthly.label',
        defaultMessage: 'By Month',
      }),
    },
  ];

  const periodFieldItems: TFiltersItems[] = [
    {
      value: 'createdAt',
      title: intl.formatMessage({
        id: 'form.paper.field.created-at.label',
        defaultMessage: 'Date added',
      }),
    },
    {
      value: 'issuedOn',
      title: intl.formatMessage({
        id: 'form.paper.field.issued-on.label',
        defaultMessage: 'Date of Issue',
      }),
    },
    {
      value: 'servicePeriod',
      title: intl.formatMessage({
        id: 'form.paper.field.service-period.label',
        defaultMessage: 'Service Date or Period',
      }),
    },
    {
      value: 'expiresOn',
      title: intl.formatMessage({
        id: 'form.paper.field.expires-on.label',
        defaultMessage: 'Date of Expiry',
      }),
    },
    {
      value: 'payUntil',
      title: intl.formatMessage({
        id: 'form.paper.field.pay-until.label',
        defaultMessage: 'Due Date',
      }),
    },
    {
      value: 'paidOn',
      title: intl.formatMessage({
        id: 'form.paper.field.paid-on.label',
        defaultMessage: 'Paid Date',
      }),
    },
  ];

  // grouping
  const updateGrouping = (newGrouping: string) => {
    updatePapersGrouping(firestore, uid, newGrouping);
  };

  // periods
  const updatePeriods = (newPeriods: TPapersPeriodsShow) => {
    updateSelectedPeriod(
      getNewPeriod(periods.show, periods.selected, availableYearsAndMonths, newPeriods),
      newPeriods,
    );
  };
  const updatePeriodField = (newPeriodField: string) => {
    updatePapersPeriods(firestore, uid, {
      ...periods,
      field: newPeriodField,
    });
  };
  const updateSelectedPeriod = (newSelectedPeriod: number, show?: TPapersPeriodsShow) => {
    updatePapersPeriods(firestore, uid, {
      ...periods,
      selected: newSelectedPeriod,
      show: show || periods.show,
    });
  };

  /**
   * Add paper menu actions
   */
  const [presentAlert] = useIonAlert();

  // Create modal
  const showCreateModal = () => {
    fabButtonDeactivated();
    fabButton.current?.close();
    if (papers.items && Object.keys(papers.items).length >= currentAccount.limit) {
      presentAlert({
        header: intl.formatMessage({ id: 'page.papers.limit-alert.title' }),
        message: intl.formatMessage({ id: 'page.papers.limit-alert.message' }),
        buttons: [
          intl.formatMessage({ id: 'ui.buttons.cancel' }),
          {
            text: intl.formatMessage({ id: 'ui.buttons.settings' }),
            handler: () => gotoSettings(),
          },
        ],
      });
    } else {
      history.push('/papers/new');
    }
  };

  // select files

  const gotoSettings = (): void => {
    history.push(`/settings`);
  };

  const selectFile = async () => {
    fabButtonDeactivated();
    fabButton.current?.close();
    if (isPlatform('capacitor')) {
      FilePicker.pickFiles({
        types: ['image/jpeg', 'image/png', 'application/pdf'],
        multiple: true,
      }).then((result: PickFilesResult) => {
        //  since Android cannot limit the filetypes to be picked, we need to filter the files here
        const files = result.files.filter(file =>
          ['image/jpeg', 'image/png', 'application/pdf'].includes(file.mimeType),
        );
        emitCustomEvent('files-from-os-upload', files);
      });
    } else if (fileInput?.current?.click) {
      fileInput.current.click();
    }
  };

  const selectImage = async () => {
    fabButtonDeactivated();
    fabButton.current?.close();
    if (isPlatform('capacitor')) {
      await Camera.pickImages({
        quality: 80,
        correctOrientation: true,
      }).then((result: GalleryPhotos) => {
        emitCustomEvent('files-from-gallery-upload', result.photos);
      });
    } else if (fileInput?.current?.click) {
      fileInput.current.click();
    }
  };

  const scanDocument = async () => {
    fabButtonDeactivated();
    fabButton.current?.close();
    if (isPlatform('capacitor')) {
      await DocumentScanner.scanDocument({
        letUserAdjustCrop: true,
        maxNumDocuments: 5,
        responseType: ResponseType.Base64,
      }).then((result: ScanDocumentResponse) => {
        if (result.status === 'success' && (result.scannedImages?.length || 0) > 0) {
          emitCustomEvent('scans-upload', result.scannedImages);
        }
      });
    } else if (fileInput?.current?.click) {
      fileInput.current.click();
    }
  };

  const fileInput = useRef<any>(null);

  const getFile = async () => {
    if (fileInput.current.files) {
      // send files to the dropzone popup
      emitCustomEvent('files-upload', fileInput.current.files);
      // clear the file input
      fileInput.current.value = null;
    }
  };

  // dimming the contents when the fab list is active
  const [fabListActive, setFabListActive] = useState(false);
  const fabButton = useRef<HTMLIonFabElement>(null);
  const fabButtonActivated = () => {
    // toggling in case the user clicks on the fab button again
    setFabListActive(value => !value);
  };
  const fabButtonDeactivated = () => {
    setFabListActive(false);
  };

  /**
   * Request authorization
   */
  const privateKey = useSelector((state: TStoreState) => state.ui?.privateKey);
  const [isLocked, setIsLocked] = useState(profile?.publicKey && !privateKey);

  useEffect(() => {
    const newLocked = profile?.publicKey && !privateKey;
    if (newLocked !== isLocked) {
      setIsLocked(profile?.publicKey && !privateKey);
    }
    // eslint-disable-next-line
  }, [profile, privateKey]);

  const requestAuthorization = async () => {
    emitCustomEvent('request-authorization');
  };

  /**
   * Multiple selection
   */
  const [selectedPapers, setSelectedPapers] = useState<{ [paperId: string]: Paper }>({});
  const selectHandler = (paperId: string) => {
    if (selectedPapers[paperId]) {
      setSelectedPapers(currentlySelected => {
        const newSelected = { ...currentlySelected };
        delete newSelected[paperId];
        return newSelected;
      });
    } else {
      setSelectedPapers(currentlySelected => ({
        ...currentlySelected,
        [paperId]: papers.items[paperId],
      }));
    }
  };

  // update selected papers, if the papers values has changed
  useEffect(() => {
    if (selectedPapers && Object.keys(selectedPapers).length > 0) {
      setSelectedPapers(sPapers =>
        Object.keys(sPapers).reduce((acc, itemId) => {
          acc[itemId] = papers.items[itemId];
          return acc;
        }, {} as { [paperId: string]: Paper }),
      );
    }
  }, [papers.items]);

  const deselectAll = () => {
    setSelectedPapers({});
  };

  console.log('papers list page render!');

  return (
    <AppPage
      title={intl.formatMessage({ id: 'page.all.title' })}
      header={
        <ListHeader
          title={intl.formatMessage({
            id: 'page.all.title',
            defaultMessage: 'All Papers',
          })}
          searchPlaceholder={intl.formatMessage({
            id: 'page.all.search.placeholder',
            defaultMessage: 'Filter Papers',
          })}
          savedSearchText={savedSearchText}
          onSearchUpdate={!papers.state.isLoading && !papers.state.isEmpty ? onSearchUpdate : null}
        >
          <>
            {!papers.state.isLoading && !papers.state.isEmpty ? (
              <FiltersToolbar
                groupingTitle={intl.formatMessage({
                  id: 'papers.grouping.title',
                  defaultMessage: 'Group papers by',
                })}
                groupingItems={groupingItems}
                groupingValue={grouping}
                onGroupingChange={updateGrouping}
                filterTitle={''}
                filterItems={filterItems}
                filterValue={grouping}
                onFilterChange={updateGrouping}
                periodFieldTitle={intl.formatMessage({
                  id: 'papers.period-field.title',
                  defaultMessage: 'According to',
                })}
                periodFieldItems={periodFieldItems}
                periodFieldValue={periods.field}
                onPeriodFieldChange={updatePeriodField}
                periodTitle={intl.formatMessage({
                  id: 'papers.periods.title',
                  defaultMessage: 'Show papers',
                })}
                periodItems={periodItems}
                periodValue={periods.show}
                onPeriodChange={updatePeriods}
              />
            ) : undefined}
            <PeriodsToolbar
              periods={periods.show}
              periodField={periods.field}
              availablePeriods={availablePeriods}
              selectedPeriod={periods.selected}
              onUpdate={updateSelectedPeriod}
            />
            <PapersListSelectionToolbar selectedPapers={selectedPapers} onDeselect={deselectAll} />
          </>
        </ListHeader>
      }
      path="/papers"
    >
      {papers.state.isLoading ? (
        <LoadingScreen />
      ) : papers.state.isEmpty ? (
        papers.state.isEmpty &&
        tips?.papers && (
          <div
            className={classNames({
              tip: true,
              'leave-space-for-fab-at-the-bottom': true,
              'list-transparent': fabListActive,
            })}
            dangerouslySetInnerHTML={{ __html: tips.papers }}
          ></div>
        )
      ) : (
        <PapersListComponent
          routerLink="/papers/"
          searchText={searchText}
          periods={periods.show}
          periodField={periods.field}
          selectedPeriod={periods.selected}
          grouping={grouping}
          fabListActive={fabListActive}
          selectHandler={selectHandler}
          selectedPapers={Object.keys(selectedPapers).reduce((acc, paperId) => {
            acc[paperId] = true;
            return acc;
          }, {} as { [paperId: string]: boolean })}
        />
      )}
      {!papers.state.isLoading && showTabs && (
        <IonFab vertical="bottom" horizontal="end" slot="fixed" ref={fabButton}>
          <IonFabButton mode="ios" onClick={fabButtonActivated} color="tertiary">
            <IonIcon icon={add} />
          </IonFabButton>
          <IonFabList side="top">
            <div className="fab-tooltip">
              <IonChip className="fab-tooltip--text" onClick={showCreateModal}>
                <FormattedMessage id="form.paper.new.title" defaultMessage="Create Empty Paper" />
              </IonChip>
            </div>
            <IonFabButton
              mode="ios"
              onClick={showCreateModal}
              color="secondary"
              title={intl.formatMessage({ id: 'form.paper.new.title' })}
            >
              <IonIcon
                icon={createOutline}
                title={intl.formatMessage({ id: 'form.paper.new.title' })}
                style={{ pointerEvents: 'none' }}
              />
            </IonFabButton>
            {isPlatform('capacitor') ? (
              <>
                <div className="fab-tooltip">
                  <IonChip className="fab-tooltip--text" onClick={selectFile}>
                    <FormattedMessage id="ui.buttons.select-files" defaultMessage="Select Files" />
                  </IonChip>
                </div>
                <IonFabButton mode="ios" onClick={selectFile} color="secondary">
                  <IonIcon icon={documentsOutline} />
                </IonFabButton>
                <div className="fab-tooltip">
                  <IonChip className="fab-tooltip--text" onClick={selectImage}>
                    <FormattedMessage
                      id="ui.buttons.select-multiple-from-gallery"
                      defaultMessage="Select Photos from Gallery"
                    />
                  </IonChip>
                </div>
                <IonFabButton mode="ios" onClick={selectImage} color="secondary">
                  <IonIcon icon={imagesOutline} />
                </IonFabButton>
                <div className="fab-tooltip">
                  <IonChip className="fab-tooltip--text" onClick={scanDocument}>
                    <FormattedMessage id="ui.buttons.scan-a-paper" defaultMessage="Scan a Paper" />
                  </IonChip>
                </div>
                <IonFabButton mode="ios" onClick={scanDocument} color="secondary">
                  <IonIcon icon={cameraOutline} />
                </IonFabButton>
              </>
            ) : (
              <>
                <div className="fab-tooltip">
                  <IonChip className="fab-tooltip--text" onClick={selectFile}>
                    <FormattedMessage id="ui.buttons.upload-files" defaultMessage="Upload Files" />
                  </IonChip>
                </div>
                <IonFabButton
                  mode="ios"
                  onClick={selectFile}
                  color="secondary"
                  title={intl.formatMessage({ id: 'ui.buttons.upload-files' })}
                >
                  <IonIcon
                    icon={cloudUploadOutline}
                    title={intl.formatMessage({ id: 'ui.buttons.upload-files' })}
                    style={{ pointerEvents: 'none' }}
                  />
                </IonFabButton>
              </>
            )}
          </IonFabList>
        </IonFab>
      )}

      {isLocked && (
        <IonFab vertical="bottom" horizontal="start" slot="fixed">
          <IonFabButton mode="ios" onClick={requestAuthorization}>
            <IonIcon icon={lockClosedOutline} />
          </IonFabButton>
        </IonFab>
      )}

      <input
        type="file"
        capture="environment"
        accept=".jpg,.gif,.jpeg,.png,image/*,.pdf,application/pdf"
        multiple={true}
        ref={fileInput}
        onChange={getFile}
        className="file-field__input"
      />
    </AppPage>
  );
};

export default PapersList;
