// hooks
import { Fragment, useEffect, useState } from 'react';
import {
  IonButton,
  IonIcon,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonList,
  useIonToast,
  useIonViewDidEnter,
} from '@ionic/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useCollectionItem } from '../hooks/useCollectionItem';
import { useSelector } from 'react-redux';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import { useObjectCollection } from '../hooks/useObjectCollection';

// utils
import {
  getDateValue,
  getLabel,
  getLabelId,
  getMonthValue,
  getTotalValue,
  getValue,
  reorderArray,
} from '../utilities/values';
import { fieldShouldBeDisplayed } from '../utilities/forms';
import { emitCustomEvent } from 'react-custom-events';
import { getReminderData } from '../utilities/reminders';
import { _label } from '../utilities/strings';
import { updateFirestoreDocument } from '../utilities/firestore';

// plugins
import { Clipboard } from '@capacitor/clipboard';

// components
import DetailsItem from './DetailsItem';

// types and icons
import { Paper } from '../models/Paper';
import { DefaultRecordFieldsState, RecordTypeField } from '../models/RecordType';
import { TFieldValue, TFileValue } from '../models/FieldValues';
import { TRemindersState } from '../models/Reminder';
import { TStoreState } from '../store/store';
import { copyOutline } from 'ionicons/icons';

export type TPaperInfoProps = {
  paper: Paper;
  typeFields?: RecordTypeField[];
  isNotLocked: boolean;
};

const PaperInfo = ({ paper, typeFields, isNotLocked }: TPaperInfoProps): JSX.Element => {
  const intl = useIntl();
  const firestore = useFirestore();
  const firebase = useFirebase();
  const user = useSelector((state: TStoreState) => state.firebase?.auth);
  const uid = useSelector((state: TStoreState) => state.firebase?.auth?.uid);

  const fields = useCollectionItem('types', 'fields') as DefaultRecordFieldsState;
  const dateFormat = useSelector(
    (state: TStoreState) => state.firebase.profile?.dateFormat || 'dd-mm-yyyy',
  );

  // copy reference to clipboard
  const [presentToast] = useIonToast();

  const copyToClipboard = async (value: any, type?: boolean | string) => {
    await Clipboard.write({
      string: type === 'no-spaces' ? value.replace(/\s/gi, '') : value,
    });
    presentToast({
      message: intl.formatMessage({ id: 'ui.toast.copied' }),
      duration: 2000,
      position: 'top',
      color: 'medium',
    });
  };

  /**
   * Request authorization
   */
  const requestAuthorization = async () => {
    emitCustomEvent('request-authorization');
  };

  useIonViewDidEnter(() => {
    if (!isNotLocked) {
      requestAuthorization();
    }
  }, [isNotLocked]);

  /**
   * Extracts the full record field body from the default types if needed
   *
   * @param field
   * @returns RecordTypeField
   */
  const returnField = (field: string | RecordTypeField): RecordTypeField => {
    return typeof field === 'string' ? fields?.item?.[field] : field;
  };

  /**
   * Checks should the field be displayed and returns a boolean
   *
   * @param field
   * @returns boolean
   */
  const returnShouldRootFieldBeDisplayed = (field: RecordTypeField): boolean => {
    return !!field && !field.hideFromDetails && fieldShouldBeDisplayed(field, paper.item);
  };

  const returnShouldSubFieldBeDisplayed = (field: RecordTypeField): boolean => {
    return (
      !!field &&
      !field.hideFromDetails &&
      !!paper[field.id as keyof Paper] &&
      fieldShouldBeDisplayed(field, paper.item) &&
      (!Array.isArray(paper[field.id]) ||
        (paper[field.id] as TFieldValue[])?.filter((v: any) => !!v.value).length > 0)
    );
  };

  const getFieldValues = (fieldType: RecordTypeField) => {
    const values: {
      field: TFieldValue;
      value: string | number | boolean | undefined | null | TFileValue[];
    }[] = Array.isArray(paper[fieldType.id])
      ? (paper[fieldType.id] as Array<TFieldValue>)
          .filter((value: TFieldValue) => value.value)
          .map((value: TFieldValue) => ({
            field: value,
            value:
              fieldType.type === 'file'
                ? paper[fieldType.id] && (paper[fieldType.id] as TFieldValue).files
                : fieldType.type === 'date'
                ? getDateValue(value, undefined, undefined, intl.locale, dateFormat)
                : fieldType.type === 'month'
                ? getMonthValue(value, undefined, undefined, intl.locale)
                : fieldType.type === 'total'
                ? getTotalValue(value)
                : getValue(value),
          }))
      : [
          {
            field: paper[fieldType.id] as TFieldValue,
            value:
              fieldType.type === 'file'
                ? paper[fieldType.id] && (paper[fieldType.id] as TFieldValue).files
                : fieldType.type === 'date'
                ? getDateValue(paper[fieldType.id], undefined, undefined, intl.locale, dateFormat)
                : fieldType.type === 'month'
                ? getMonthValue(paper[fieldType.id], undefined, undefined, intl.locale)
                : fieldType.type === 'total'
                ? getTotalValue(paper[fieldType.id])
                : getValue(paper[fieldType.id]),
          },
        ];

    return values;
  };

  const getFieldClass = (field: RecordTypeField) => {
    return field.id === 'total' || field.id === 'net'
      ? getValue(paper?.paid) === true
        ? 'amount-paid'
        : 'amount-notpaid'
      : '';
  };

  /**
   * Data fields
   */
  const categories = useSelector((state: TStoreState) => state.settings?.categories || null);
  const categoryField = getValue(paper?.category) || '';
  const paperCategories = Array.isArray(categoryField)
    ? categoryField
    : ((categoryField as string).split(', ') as string[]);
  const getCategoryLabel = (cat: string) => {
    if (categories) {
      const category = categories.find((category: any) => category.value === cat);
      if (category?.label) {
        return category.label;
      }
    }
    return <FormattedMessage id={`category.${cat}`} defaultMessage={cat} />;
  };
  const reference = getValue(
    paper?.reference,
    paper?.new ? intl.formatMessage({ id: 'paper.new.reference' }) : undefined,
  );
  const name = getValue(
    paper?.title,
    paper?.new
      ? intl.formatMessage({ id: 'paper.new' })
      : intl.formatMessage({ id: 'paper.untitled' }),
  );

  /**
   * Reminders
   */
  const reminders = useObjectCollection('reminders') as TRemindersState;
  const [contactReminders, setContactReminders] = useState<{ [field: string]: string }>({});
  useEffect(() => {
    if (paper && reminders.items) {
      const updatedContactReminders = Object.keys(reminders.items).reduce((acc, remindersDoc) => {
        if (reminders.items[remindersDoc]) {
          Object.keys(reminders.items[remindersDoc]).forEach(reminderId => {
            if (reminderId.indexOf(`paper-${paper.id}`) === 0) {
              const fieldId = reminderId.replace(`paper-${paper.id}-`, '');
              acc[fieldId] = remindersDoc;
            }
          });
        }
        return acc;
      }, {} as { [field: string]: string });

      if (JSON.stringify(contactReminders) !== JSON.stringify(updatedContactReminders)) {
        setContactReminders(updatedContactReminders);
      }
    } else {
      if (JSON.stringify(contactReminders) !== '{}') {
        setContactReminders({});
      }
    }
  }, [paper, reminders]);

  const createReminder = (
    field: string,
    fieldNo: number,
    offset: number,
    value: string,
    repeat: boolean = false,
  ) => {
    // create reminder object
    const reminder = getReminderData({
      type: 'paper',
      itemId: paper.id,
      field,
      fieldNo,
      offset,
      value,
      repeat,
      title: intl.formatMessage({ id: `reminder.paper.${field}.title` }),
      body: intl.formatMessage({ id: `reminder.paper.${field}.body` }, { name, offset }) as string,
    });

    if (paper?.thumbnail?.[0]?.url && reminder) {
      reminder.data.image = paper.thumbnail[0].url.replace('{uid}', user.uid);
    }

    // save the object to the DB
    if (reminder) {
      firestore.doc(`/users/${uid}/reminders/${reminder.docName}`).set(
        {
          docName: reminder.docName,
          uid,
          [reminder.reminderId]: reminder.data,
        },
        { merge: true },
      );
    }
  };

  const deleteReminder = (docName: string, reminderId: string) => {
    // get the document value
    const reminderDoc = { ...(reminders.items?.[docName] || {}) };
    delete reminderDoc[`paper-${paper.id}-${reminderId}`];

    if (Object.keys(reminderDoc).length < 3) {
      // if length is less than 3, means there are only docName and uid
      // delete the document
      firestore.doc(`/users/${uid}/reminders/${docName}`).delete();
    } else {
      // update the document
      firestore.doc(`/users/${uid}/reminders/${docName}`).update(reminderDoc);
    }
  };

  /**
   * Linked Paper actions
   */
  const addPaper = (fieldId: string, paperId: string) => {
    const field = paper[fieldId] as TFieldValue[];

    // check that the paper already exists
    if (field && field.some((paper: any) => paper?.value === paperId)) {
      return;
    }
    const updatedPapers: TFieldValue[] = [
      {
        value: paperId,
      },
      ...(field || []),
    ];

    // creating back link
    const backLinkField = fields?.item?.[fieldId]?.backLinkField;
    if (backLinkField) {
      updateFirestoreDocument(user, firestore, `papers/${paperId}`, {
        [backLinkField]: firebase.firestore.FieldValue.arrayUnion({ value: paper.id }),
      });
    }

    // update the paper
    updateFirestoreDocument(user, firestore, `papers/${paper.id}`, {
      [fieldId]: updatedPapers,
    });
  };

  const removePaper = (fieldId: string, paperId: string) => {
    const field = paper[fieldId] as TFieldValue[];

    if (field?.some((paper: any) => paper?.value === paperId)) {
      const updatedPapers: TFieldValue[] = field.filter((paper: any) => paper?.value !== paperId);

      // remove back link
      const backLinkField = fields?.item?.[fieldId]?.backLinkField;
      if (backLinkField) {
        updateFirestoreDocument(user, firestore, `papers/${paperId}`, {
          [backLinkField]: firebase.firestore.FieldValue.arrayRemove({ value: paper.id }),
        });
      }

      // update the paper
      updateFirestoreDocument(user, firestore, `papers/${paper.id}`, {
        [fieldId]: updatedPapers,
      });
    }
  };

  const reorderPaper = (fieldId: string, from: number, to: number) => {
    const field = paper[fieldId] as TFieldValue[];
    if (field) {
      const updatedPapers: { value: string }[] = reorderArray(field, from, to);
      updateFirestoreDocument(user, firestore, `papers/${paper.id}`, {
        [fieldId]: updatedPapers,
      });
    }
  };

  /**
   * Linked Stacks actions
   */
  const addStack = (fieldId: string, stackId: string) => {
    const field = paper[fieldId] as TFieldValue[];

    // check that the paper already exists
    if (field && field.some((paper: any) => paper?.value === stackId)) {
      return;
    }
    const updatedStacks: TFieldValue[] = [
      {
        value: stackId,
      },
      ...(field || []),
    ];

    // creating back link
    const backLinkField = fields?.item?.[fieldId]?.backLinkField;
    if (backLinkField) {
      updateFirestoreDocument(user, firestore, `stacks/${stackId}`, {
        [backLinkField]: firebase.firestore.FieldValue.arrayUnion({ value: paper.id }),
      });
    }

    // update the paper
    updateFirestoreDocument(user, firestore, `papers/${paper.id}`, {
      [fieldId]: updatedStacks,
    });
  };

  const removeStack = (fieldId: string, stackId: string) => {
    const field = paper[fieldId] as TFieldValue[];

    if (field?.some((stack: any) => stack?.value === stackId)) {
      const updatedStacks: TFieldValue[] = field.filter((stack: any) => stack?.value !== stackId);

      // remove back link
      const backLinkField = fields?.item?.[fieldId]?.backLinkField;
      if (backLinkField) {
        updateFirestoreDocument(user, firestore, `stacks/${stackId}`, {
          [backLinkField]: firebase.firestore.FieldValue.arrayRemove({ value: paper.id }),
        });
      }

      // update the paper
      updateFirestoreDocument(user, firestore, `papers/${paper.id}`, {
        [fieldId]: updatedStacks,
      });
    }
  };

  const reorderStacks = (fieldId: string, from: number, to: number) => {
    const field = paper[fieldId] as TFieldValue[];
    if (field) {
      const updatedStacks: { value: string }[] = reorderArray(field, from, to);
      updateFirestoreDocument(user, firestore, `papers/${paper.id}`, {
        [fieldId]: updatedStacks,
      });
    }
  };

  return (
    <>
      {paperCategories && (
        <p className={`paper__category paper__category-${paperCategories[0]}`}>
          {paperCategories
            .filter(cat => cat && cat !== '')
            .map((cat, idx) => (
              <Fragment key={`cat-${cat}-${idx}`}>
                {getCategoryLabel(cat)}
                {idx < paperCategories.length - 1 && ', '}
              </Fragment>
            ))}
        </p>
      )}
      <h1 className="paper__title" onClick={() => copyToClipboard(name)}>
        {name}
      </h1>
      {reference && (
        <p className="paper__reference" onClick={() => isNotLocked && copyToClipboard(reference)}>
          {reference}
          {isNotLocked && <IonIcon icon={copyOutline} className="paper__reference-copy" />}
        </p>
      )}
      {!isNotLocked && (
        <div style={{ textAlign: 'center', margin: '2rem' }}>
          <IonButton
            mode="md"
            shape="round"
            fill="outline"
            className="ion-activated"
            onClick={requestAuthorization}
          >
            <FormattedMessage id="ui.buttons.unlock-the-data" defaultMessage="Unlock the Data" />
          </IonButton>
        </div>
      )}
      <IonList>
        {typeFields
          ?.map(returnField)
          .filter(returnShouldRootFieldBeDisplayed)
          .map(field => (
            <Fragment key={field.id}>
              {field.type === 'group' ? (
                field?.fields
                  ?.map(returnField)
                  .filter(returnShouldSubFieldBeDisplayed)
                  .map(subField => {
                    const subFieldId = subField.id as keyof Paper;

                    if (!paper[subFieldId] && !subField?.alwaysVisibleInDetails) {
                      return null;
                    }

                    const values = getFieldValues(subField);

                    // check is there any value
                    if (
                      values?.length === 1 &&
                      ((Array.isArray(values[0].value) && values[0].value.length === 0) ||
                        !values[0].value ||
                        values[0].value === '')
                    ) {
                      return null;
                    }

                    return (
                      <IonItemGroup key={subField.id}>
                        <IonItemDivider mode="md" className="details__divider">
                          <IonLabel>
                            {subField.label ? (
                              _label(
                                (Array.isArray(paper[subField.id]) &&
                                  (paper[subField.id] as TFieldValue[]).length > 1 &&
                                  subField.labelPlural) ||
                                  subField.label,
                                intl.locale,
                              )
                            ) : (
                              <FormattedMessage
                                id={getLabelId(subField as RecordTypeField, paper[subFieldId])}
                              />
                            )}
                          </IonLabel>
                        </IonItemDivider>
                        {values.map((item, idx: number, arr: any) => (
                          <DetailsItem
                            id={subField.id}
                            name={subField.id}
                            index={idx}
                            type={subField.type}
                            itemId={paper.id}
                            key={'value' + idx}
                            lines={idx < arr.length - 1 ? 'inset' : 'none'}
                            value={item.value?.toString()}
                            files={item.field && (item.field as TFieldValue).files}
                            thumbnails={paper?.thumbnail}
                            previews={paper?.preview}
                            copyValue={getValue(item.field)}
                            label={getLabel(item.field)}
                            actions={subField.actions}
                            reminders={contactReminders}
                            repeat={subField.repeat}
                            createReminder={createReminder}
                            deleteReminder={deleteReminder}
                            hidden={subField.hidden}
                            options={subField.options}
                            className={getFieldClass(subField)}
                            displayLarge={subField.displayLarge}
                            alwaysVisibleInDetails={subField.alwaysVisibleInDetails}
                            addPaper={
                              subField.detailsActions?.add
                                ? (paperId: string) => addPaper(subField.id, paperId)
                                : undefined
                            }
                            removePaper={
                              subField.detailsActions?.remove
                                ? (paperId: string) => removePaper(subField.id, paperId)
                                : undefined
                            }
                            reorderPapers={
                              subField.detailsActions?.reorder
                                ? (from: number, to: number) => reorderPaper(subField.id, from, to)
                                : undefined
                            }
                            addStack={
                              subField.detailsActions?.add
                                ? (stackId: string) => addStack(subField.id, stackId)
                                : undefined
                            }
                            removeStack={
                              subField.detailsActions?.remove
                                ? (stackId: string) => removeStack(subField.id, stackId)
                                : undefined
                            }
                            reorderStacks={
                              subField.detailsActions?.reorder
                                ? (from: number, to: number) => reorderStacks(subField.id, from, to)
                                : undefined
                            }
                          />
                        ))}
                      </IonItemGroup>
                    );
                  })
              ) : (
                <DetailsItem
                  itemId={paper.id}
                  name={field.id}
                  type={field.type}
                  lines="none"
                  value={
                    field.type === 'date'
                      ? getDateValue(paper[field.id], undefined, undefined, intl.locale, dateFormat)
                      : field.type === 'month'
                      ? getMonthValue(paper[field.id], undefined, undefined, intl.locale)
                      : getValue(paper[field.id])
                  }
                  files={(paper[field.id] as TFieldValue)?.files}
                  thumbnails={paper?.thumbnail}
                  previews={paper?.preview}
                  copyValue={getValue(paper?.[field.id])}
                  label={getLabel(paper?.[field.id])}
                  labelId={getLabelId(field, paper?.[field.id])}
                  actions={field.actions}
                  reminders={contactReminders}
                  repeat={field.repeat}
                  createReminder={createReminder}
                  deleteReminder={deleteReminder}
                  hidden={field.hidden}
                  options={field.options}
                  className={getFieldClass(field)}
                  displayLarge={field.displayLarge}
                  alwaysVisibleInDetails={field.alwaysVisibleInDetails}
                  addPaper={
                    field.detailsActions?.add
                      ? (paperId: string) => addPaper(field.id, paperId)
                      : undefined
                  }
                  removePaper={
                    field.detailsActions?.remove
                      ? (paperId: string) => removePaper(field.id, paperId)
                      : undefined
                  }
                  reorderPapers={
                    field.detailsActions?.reorder
                      ? (from: number, to: number) => reorderPaper(field.id, from, to)
                      : undefined
                  }
                  addStack={
                    field.detailsActions?.add
                      ? (stackId: string) => addStack(field.id, stackId)
                      : undefined
                  }
                  removeStack={
                    field.detailsActions?.remove
                      ? (stackId: string) => removeStack(field.id, stackId)
                      : undefined
                  }
                  reorderStacks={
                    field.detailsActions?.reorder
                      ? (from: number, to: number) => reorderStacks(field.id, from, to)
                      : undefined
                  }
                />
              )}
            </Fragment>
          ))}
      </IonList>
      <p>
        <br />
      </p>
    </>
  );
};

export default PaperInfo;
