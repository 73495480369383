// hooks
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';
import { useFirestoreItemQuery } from '../hooks/useFirestoreItemQuery';
import { emitCustomEvent } from 'react-custom-events';

// components
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonModal,
  IonTitle,
  IonToggle,
  IonToolbar,
  isPlatform,
} from '@ionic/react';
import LoadingScreen from './LoadingScreen';

// icons
import { checkmarkSharp, closeOutline } from 'ionicons/icons';

// types
import { TStoreState } from '../store/store';

type TOCRSettngsModalProps = {
  isOpen: boolean;
  onDidDismiss: () => void;
};

const OCRSettngsModal: React.FC<TOCRSettngsModalProps> = (props: TOCRSettngsModalProps) => {
  const routerRef = useSelector((state: any) => state.ui?.routerRef);

  const intl = useIntl();
  const firebase = useFirebase();

  /**
   * Load selectted OCR scripts from the profile
   */
  const profile = useSelector((state: TStoreState) => state.firebase?.profile, shallowEqual);
  const user = useSelector((state: any) => state.firebase?.auth);
  const selected = useSelector((state: TStoreState) => state.ui.ocrScripts, shallowEqual);

  /**
   * Load full languages list
   */
  const languages = useFirestoreItemQuery('languages', intl.locale, {
    storeAs: 'ocrScripts',
  }) as { [code: string]: any };

  const toggleLanguage = (_: any, code: string) => {
    let updatedScripts: { [key: string]: any } = { ...selected };

    if (!!selected?.[code]) {
      // remove currency
      updatedScripts[code] = firebase.firestore.FieldValue.delete();
    } else {
      // add currency
      updatedScripts[code] = 1;
    }

    firebase.updateProfile({ ocrScripts: updatedScripts });
  };

  const showAuthModal = () => {
    emitCustomEvent('showModal', 'registration');
  };

  /**
   * Should all new Auto OCR
   *
   * @param value  true or false
   */
  const updateAutoOCR = (value: boolean) => {
    firebase.updateProfile({ autoOcr: value });
  };

  /**
   * Dates format
   */
  const updateDateFormat = (format: string) => {
    firebase.updateProfile({ dateFormat: format });
  };

  return (
    <IonModal
      mode="md"
      isOpen={props.isOpen}
      onDidDismiss={props.onDidDismiss}
      presentingElement={routerRef}
    >
      <IonHeader mode="md">
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={props.onDidDismiss}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>
            <FormattedMessage
              id="modal.recognition.settings.title"
              defaultMessage="Papers reading"
            />
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      {languages?.item ? (
        <>
          {user.isAnonymous ||
          !profile ||
          profile.isEmpty === true ||
          profile.account === 'anonymous' ? (
            <>
              <IonContent>
                <p className="modal-description-text">
                  <FormattedMessage
                    id="modal.select-country.need-account"
                    defaultMessage="You need to have an account to use this feature."
                  />
                </p>
              </IonContent>
              <IonFooter>
                <p className="modal-footer-button">
                  <IonButton
                    onClick={showAuthModal}
                    mode="md"
                    shape="round"
                    fill="outline"
                    className="ion-activated"
                  >
                    <IonLabel>
                      <FormattedMessage
                        id="ui.buttons.login-or-register"
                        defaultMessage="Register or Sign In"
                      />
                    </IonLabel>
                  </IonButton>
                </p>
              </IonFooter>
            </>
          ) : (
            <IonContent>
              <IonList>
                <IonItemDivider>
                  <IonLabel className="item--long-description">
                    <p>
                      <FormattedMessage
                        id="modal.recognition.settings.mode"
                        defaultMessage="Reading mode"
                      />
                    </p>
                  </IonLabel>
                </IonItemDivider>
                <IonItem detail={false}>
                  <IonLabel>
                    <FormattedMessage
                      id="modal.recognition.settings.auto"
                      defaultMessage="Automatically read all new papers"
                    />
                  </IonLabel>
                  <IonToggle
                    slot="end"
                    name="auto"
                    value="auto"
                    mode={isPlatform('ios') ? 'ios' : 'md'}
                    onIonChange={e => updateAutoOCR(e.detail.checked)}
                    checked={profile.autoOcr === true}
                    aria-label="Automatically read all new papers"
                  />
                </IonItem>
                <IonItem detail={false}>
                  <IonLabel>
                    <FormattedMessage
                      id="modal.recognition.settings.update"
                      defaultMessage="Update papers with recognized data"
                    />
                  </IonLabel>
                  <IonToggle
                    slot="end"
                    name="update"
                    value="update"
                    mode={isPlatform('ios') ? 'ios' : 'md'}
                    checked={false}
                    disabled={true}
                    aria-label="Update papers with recognized data"
                  />
                </IonItem>

                <IonItemDivider>
                  <IonLabel className="item--long-description">
                    <br />
                    <p>
                      <FormattedMessage
                        id="modal.recognition.date-format.title"
                        defaultMessage="Dates format"
                      />
                    </p>
                  </IonLabel>
                </IonItemDivider>
                <IonItem detail={false} button onClick={() => updateDateFormat('dd-mm-yyyy')}>
                  <IonLabel>
                    <FormattedMessage
                      id="modal.recognition.date-format.dd-mm-yyyy"
                      defaultMessage="Day, Month, Year"
                    />
                  </IonLabel>
                  {(!profile.dateFormat || profile.dateFormat === 'dd-mm-yyyy') && (
                    <IonIcon icon={checkmarkSharp} slot="end" color="primary" />
                  )}
                </IonItem>
                <IonItem detail={false} button onClick={() => updateDateFormat('mm-dd-yyyy')}>
                  <IonLabel>
                    <FormattedMessage
                      id="modal.recognition.date-format.mm-dd-yyyy"
                      defaultMessage="Month Day, Year"
                    />
                  </IonLabel>
                  {profile.dateFormat === 'mm-dd-yyyy' && (
                    <IonIcon icon={checkmarkSharp} slot="end" color="primary" />
                  )}
                </IonItem>

                <IonItemDivider>
                  <IonLabel className="item--long-description">
                    <br />
                    <p>
                      <FormattedMessage
                        id="modal.recognition.settings.languages.why"
                        defaultMessage="When papers are read, OCR system is recognizing any characters on the paper and it is much easier to know that everything is right if it knows what language is used."
                      />
                    </p>
                    <p>
                      <FormattedMessage
                        id="modal.recognition.settings.languages.less"
                        defaultMessage="Select languages used in your papers, but remember that the more languages are selected the slower they will be recognized."
                      />
                    </p>
                  </IonLabel>
                </IonItemDivider>
                <form>
                  {Object.keys(languages.item)
                    .map(langId => ({
                      id: langId,
                      name: languages.item[langId].name,
                      locale: languages.item[langId].locale,
                    }))
                    .sort((c1, c2) => {
                      // sorting by the name
                      const n1 = c1.name || '';
                      const n2 = c2.name || '';
                      return n1 > n2 ? 1 : n1 < n2 ? -1 : 0;
                    })
                    .map(language => (
                      <IonItem key={language.id} detail={false}>
                        <IonLabel>{language.name}</IonLabel>
                        <IonToggle
                          slot="end"
                          name={language.id}
                          value={language.id}
                          mode={isPlatform('ios') ? 'ios' : 'md'}
                          checked={!!selected?.[language.id] || profile.lang === language.locale}
                          disabled={profile.lang === language.locale}
                          onClick={evt => toggleLanguage(evt, language.id)}
                          aria-label={language.name}
                        />
                      </IonItem>
                    ))}
                </form>
              </IonList>
            </IonContent>
          )}
        </>
      ) : (
        <LoadingScreen />
      )}
    </IonModal>
  );
};

export default OCRSettngsModal;
