// hooks
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';
import { useFirestoreItemQuery } from '../hooks/useFirestoreItemQuery';
import { emitCustomEvent } from 'react-custom-events';

// components
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonText,
  IonTitle,
  IonToggle,
  IonToolbar,
  isPlatform,
} from '@ionic/react';
import LoadingScreen from './LoadingScreen';

// icons
import { closeOutline } from 'ionicons/icons';

// types
import { TStoreState } from '../store/store';

type TCurrenciesModalProps = {
  isOpen: boolean;
  onDidDismiss: () => void;
};

const CurrenciesModal: React.FC<TCurrenciesModalProps> = (props: TCurrenciesModalProps) => {
  const routerRef = useSelector((state: any) => state.ui?.routerRef);

  const intl = useIntl();
  const firebase = useFirebase();

  /**
   * Load selectted currencies from the profile
   */
  const profile = useSelector((state: TStoreState) => state.firebase?.profile, shallowEqual);
  const user = useSelector((state: any) => state.firebase?.auth);
  const selected = useSelector((state: TStoreState) => state.ui.currencies, shallowEqual);

  /**
   * Load full currencies list
   */
  const currencies = useFirestoreItemQuery('currencies', intl.locale) as { [code: string]: any };

  const toggleCurrency = (evt: any, code: string) => {
    let updatedCurrencies: { [key: string]: any } = { ...selected };

    if (!!selected?.[code]) {
      // remove currency
      updatedCurrencies[code] = firebase.firestore.FieldValue.delete();
    } else {
      // add currency
      updatedCurrencies[code] = currencies?.item?.[code]?.order || 1;
    }

    firebase.updateProfile({ currencies: updatedCurrencies });
  };

  const showAuthModal = () => {
    emitCustomEvent('showModal', 'registration');
  };

  return (
    <IonModal
      mode="md"
      isOpen={props.isOpen}
      onDidDismiss={props.onDidDismiss}
      presentingElement={routerRef}
    >
      <IonHeader mode="md">
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={props.onDidDismiss}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>
            <FormattedMessage
              id="modal.currencies.title"
              defaultMessage="Select Currencies to use in the app"
            />
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      {currencies?.item ? (
        <>
          {user?.isAnonymous ||
          !profile ||
          profile.isEmpty === true ||
          profile.account === 'anonymous' ? (
            <>
              <IonContent>
                <p className="modal-description-text">
                  <FormattedMessage
                    id="modal.currencies.need-account"
                    defaultMessage="You need to have an account to use this feature."
                  />
                </p>
              </IonContent>
              <IonFooter>
                <p className="modal-footer-button">
                  <IonButton
                    onClick={showAuthModal}
                    mode="md"
                    shape="round"
                    fill="outline"
                    className="ion-activated"
                  >
                    <IonLabel>
                      <FormattedMessage
                        id="ui.buttons.login-or-register"
                        defaultMessage="Register or Sign In"
                      />
                    </IonLabel>
                  </IonButton>
                </p>
              </IonFooter>
            </>
          ) : (
            <IonContent>
              <IonList>
                <form>
                  {Object.keys(currencies.item)
                    .map(cId => currencies.item[cId])
                    .sort((c1, c2) => {
                      // sorting by order
                      const o1 = c1.order || selected?.[c1.code] || 0;
                      const o2 = c2.order || selected?.[c2.code] || 0;
                      if (o1 || o2) {
                        return o1 === o2 ? 0 : o1 > o2 ? -1 : 1;
                      }

                      // sorting by the name
                      const n1 = c1.name || '';
                      const n2 = c2.name || '';
                      return n1 > n2 ? 1 : n1 < n2 ? -1 : 0;
                    })
                    .map(currency => (
                      <IonItem key={currency.code} detail={false}>
                        <IonLabel>
                          {currency.name} &nbsp; <IonText color="medium">{currency.code}</IonText>
                        </IonLabel>
                        <IonToggle
                          slot="end"
                          name={currency.code}
                          value={currency.code}
                          mode={isPlatform('ios') ? 'ios' : 'md'}
                          checked={!!selected?.[currency.code]}
                          onClick={evt => toggleCurrency(evt, currency.code)}
                          aria-label={currency.code}
                        />
                      </IonItem>
                    ))}
                </form>
              </IonList>
            </IonContent>
          )}
        </>
      ) : (
        <LoadingScreen />
      )}
    </IonModal>
  );
};

export default CurrenciesModal;
