// hooks
import { useEffect, useState } from 'react';

// components
import { IonButton, IonIcon } from '@ionic/react';
import { FormattedMessage } from 'react-intl';

// icons
import { backspaceOutline, ellipse } from 'ionicons/icons';

type TPinCodeProps = {
  onChange: (value: string) => void;
  onEnter: () => void;
  value: string;
  text: string;
  error: string;
  disabled?: boolean;
};

const PinCode: React.FC<TPinCodeProps> = ({
  onChange,
  onEnter,
  value,
  text,
  error,
  disabled,
}: TPinCodeProps) => {
  const [disabledOK, setDisabledOK] = useState(true);
  /**
   * PIN entry
   */
  const [pin, setPin] = useState(value);
  const addToPin = (value: string) => {
    const updatedPin = pin + value;
    setPin(updatedPin);
    onChange(updatedPin);
    if (updatedPin.length > 3) {
      setDisabledOK(false);
    }
  };

  const removeFromPin = () => {
    const updatedPin = pin.slice(0, -1);
    setPin(updatedPin);
    onChange(updatedPin);
  };

  const clearPin = () => {
    setPin('');
    onChange('');
  };

  const sendPin = () => {
    if (pin.length > 3) {
      onEnter();
    }
  };

  const handleKeyDown = (evt: KeyboardEvent): void => {
    if (disabled) {
      return;
    }
    // if user wants to reload the page or something else
    // we should not prevent the default behavior
    if (!evt.metaKey && !evt.ctrlKey) {
      evt.preventDefault();
    }

    // numbers
    if (/\d/.test(evt.key)) {
      addToPin(evt.key);
    }
    // delete key
    if (evt.key === 'Backspace' || evt.key === 'Delete') {
      removeFromPin();
    }
    // enter
    if (evt.key === 'Enter') {
      sendPin();
    }
  };

  // Event listeners
  useEffect(() => {
    const keyDownHandler = (evt: KeyboardEvent) => {
      handleKeyDown(evt);
    };
    window.addEventListener('keydown', keyDownHandler);
    return () => {
      window.removeEventListener('keydown', keyDownHandler);
    };
  }, [handleKeyDown]);

  return (
    <div className="pin-code">
      <div className="pin-text">
        {pin === ''
          ? text
          : pin.split('').map((_, index) => <IonIcon icon={ellipse} key={index} />)}
      </div>
      {error && (
        <div className="pin-error">
          <FormattedMessage id={error} />
        </div>
      )}
      <div className="pin-buttons">
        <IonButton
          shape="round"
          className="pin-button"
          fill="outline"
          onClick={() => addToPin('1')}
          disabled={disabled}
        >
          1
        </IonButton>
        <IonButton
          shape="round"
          className="pin-button"
          fill="outline"
          onClick={() => addToPin('2')}
          disabled={disabled}
        >
          2
        </IonButton>
        <IonButton
          shape="round"
          className="pin-button"
          fill="outline"
          onClick={() => addToPin('3')}
          disabled={disabled}
        >
          3
        </IonButton>
      </div>
      <div className="pin-buttons">
        <IonButton
          shape="round"
          className="pin-button"
          fill="outline"
          onClick={() => addToPin('4')}
          disabled={disabled}
        >
          4
        </IonButton>
        <IonButton
          shape="round"
          className="pin-button"
          fill="outline"
          onClick={() => addToPin('5')}
          disabled={disabled}
        >
          5
        </IonButton>
        <IonButton
          shape="round"
          className="pin-button"
          fill="outline"
          onClick={() => addToPin('6')}
          disabled={disabled}
        >
          6
        </IonButton>
      </div>
      <div className="pin-buttons">
        <IonButton
          shape="round"
          className="pin-button"
          fill="outline"
          onClick={() => addToPin('7')}
          disabled={disabled}
        >
          7
        </IonButton>
        <IonButton
          shape="round"
          className="pin-button"
          fill="outline"
          onClick={() => addToPin('8')}
          disabled={disabled}
        >
          8
        </IonButton>
        <IonButton
          shape="round"
          className="pin-button"
          fill="outline"
          onClick={() => addToPin('9')}
          disabled={disabled}
        >
          9
        </IonButton>
      </div>
      <div className="pin-buttons">
        <IonButton
          shape="round"
          className="pin-button"
          fill="clear"
          onClick={clearPin}
          disabled={disabled}
        >
          <IonIcon icon={backspaceOutline} slot="icon-only" />
        </IonButton>
        <IonButton
          shape="round"
          className="pin-button"
          fill="outline"
          onClick={() => addToPin('0')}
          disabled={disabled}
        >
          0
        </IonButton>
        <IonButton
          shape="round"
          className="pin-button"
          fill="clear"
          onClick={sendPin}
          disabled={disabledOK || disabled}
        >
          <FormattedMessage id="ui.buttons.ok" defaultMessage="OK" />
        </IonButton>
      </div>
    </div>
  );
};

export default PinCode;
