import { Filesystem } from '@capacitor/filesystem';
import { encryptMessage } from './encryption';

// types
import {
  TCommonDocument,
  TFieldValue,
  TFieldValues,
  TFilePreview,
  TFileValue,
} from '../models/FieldValues';
import { RecordTypeField } from '../models/RecordType';

/**
 * Compares document data with the form data per field
 * to see which files should be uploaded and which should be deleted
 * and adds the file data to the form data if needed
 *
 * @param formValue  usnaved form data
 * @param docValue  initial document data
 * @returns Promise<[updatedFormValue, filesToUpload, filesToDelete]>
 */
const processFiles = async (
  formValue: TFieldValue,
  docValue: string | TFieldValues | TFilePreview[],
): Promise<[TFieldValue, TFileValue[], TFileValue[]]> => {
  const { files, ...restValues } = formValue;
  const value: TFieldValue = { ...restValues };
  let toUpload: TFileValue[] = [];
  let toDelete: TFileValue[] = [];

  if (files && files.length > 0) {
    // if there are files in the form data

    // add the files array that was extracted before
    value.files = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      // check is the file an old one (it exists in the document data)
      const existingFile =
        (Array.isArray(docValue) &&
          (docValue as TFieldValue[]).find(item =>
            item.files?.some(fileItem => fileItem.id === file.id),
          )) ||
        (!Array.isArray(docValue) &&
          typeof docValue !== 'string' &&
          docValue?.files?.find(fileItem => fileItem.id === file.id));

      if (existingFile && !value.files.some(fileItem => fileItem.id === file.id)) {
        // if it's an old file - add it back to the form data
        value.files.push(existingFile as TFileValue);
      } else {
        // it is a newly added file

        // extract the file extension
        const extension =
          file.name.indexOf('.') > 0
            ? file.name.substring(file.name.lastIndexOf('.') + 1)
            : file.mimeType
            ? file.mimeType.split('/')[1]
            : 'jpeg';

        let fileData;
        if (file.data64) {
          fileData = await base64ToBlob(file.data64, file.mimeType);
          delete file.data64;
          delete file.objectUrl;
        }
        if (file.path) {
          // if there is a path — should retrieve the file data from the file system
          const fileData64 = await Filesystem.readFile({ path: file.path || '' });
          fileData = await base64ToBlob(fileData64.data, file.mimeType);
          delete file.path;
          delete file.data64;
          delete file.webPath;
        }
        if (fileData) {
          toUpload.push({
            ...file,
            extension,
            data: fileData,
          });
        }
        value.files.push(file);
      }
    }
    // check which files should be deleted
    if (Array.isArray(docValue)) {
      docValue.forEach(item => {
        const docItemFiles = (item as TFieldValue).files;
        if (docItemFiles) {
          docItemFiles.forEach(docFile => {
            if (!value.files!.some(newFile => newFile.id === docFile.id)) {
              toDelete.push(docFile);
            }
          });
        }
      });
    } else if (typeof docValue !== 'string' && docValue?.files) {
      docValue.files.forEach(docFile => {
        if (!value.files!.some(newFile => newFile.id === docFile.id)) {
          toDelete.push(docFile);
        }
      });
    }
  } else if (Array.isArray(docValue)) {
    // if the form data doesn't have files, but the document data does
    // then all the files should be deleted
    docValue.forEach(item => {
      if ((item as TFieldValue).files) {
        toDelete.push(...((item as TFieldValue).files || []));
      }
    });
  } else if (typeof docValue !== 'string' && docValue?.files) {
    // all the previous files should be deleted
    toDelete = docValue.files;
  }
  return [value, toUpload, toDelete];
};

const excludedFields: string[] = [
  'createdAt',
  'createdBy',
  'updatedAt',
  'updatedBy',
  'pages',
  'preview',
  'thumbnail',
];

const filesArtifactsFields: string[] = ['pages', 'preview', 'thumbnail', 'avatar'];

export const processData = async (
  formData: TCommonDocument,
  docData: TCommonDocument,
  publicKey?: CryptoKey | undefined,
): Promise<[TCommonDocument, TFileValue[], TFileValue[]]> => {
  let resultData: TCommonDocument = {};
  const filesToUpload: TFileValue[] = [];
  const filesToDelete: TFileValue[] = [];

  for (const fieldName in formData) {
    // exclude the external managed data from the form data
    if (!excludedFields.includes(fieldName)) {
      const fieldValue = formData[fieldName] as TFieldValue;
      const docValue = docData ? docData[fieldName] : '';

      if (Array.isArray(fieldValue)) {
        // if that's an array, we need to loop through each item
        resultData[fieldName] = fieldValue.filter((item: TFieldValue) => {
          if ((item.value === undefined || item.value === null) && !item.files) {
            return false;
          }
          return true;
        });

        // and arrays can contain files as well
        for (let i = 0; i < fieldValue.length; i++) {
          if ((fieldValue[i].files?.length || 0) > 0) {
            const [value, toUpload, toDelete] = await processFiles(fieldValue[i], docValue);
            (resultData[fieldName] as TFieldValue[])[i] = await encryptValue(value, publicKey);
            filesToUpload.push(...toUpload);
            filesToDelete.push(...toDelete);
          } else {
            (resultData[fieldName] as TFieldValue[])[i] = await encryptValue(
              fieldValue[i],
              publicKey,
            );
          }
        }
      } else if (typeof fieldValue !== 'string' && fieldValue?.files) {
        // it it's just files, then process it
        const [value, toUpload, toDelete] = await processFiles(fieldValue, docValue);
        resultData[fieldName] = await encryptValue(value, publicKey);
        filesToUpload.push(...toUpload);
        filesToDelete.push(...toDelete);
      } else if (typeof fieldValue !== 'string') {
        resultData[fieldName] = await encryptValue(fieldValue, publicKey);
      } else {
        resultData[fieldName] = fieldValue;
      }
    }
  }

  // if there are files to delete, then clean up the result data from artifacts
  if (filesToDelete.length > 0) {
    filesToDelete.forEach(file => {
      filesArtifactsFields.forEach(field => {
        if (Array.isArray(docData[field])) {
          resultData[field] = (docData[field] as TFilePreview[]).filter(
            item => item.fileId !== file.id,
          );
        }
      });
    });
  }

  return [resultData, filesToUpload, filesToDelete];
};

const encryptValue = async (
  origValue: TFieldValue,
  publicKey: CryptoKey | undefined,
): Promise<TFieldValue> => {
  let value = { ...origValue };
  if (value.encrypt && publicKey) {
    if (value.value && (typeof value.value === 'string' || typeof value.value === 'number')) {
      const encryptedValue = await encryptMessage(value.value, publicKey).catch(err => {
        console.error('encryptValue Error', err);
        return { value: origValue.value, key: null, IV: null };
      });
      value.value = encryptedValue.value;
      if (encryptedValue.key) {
        value.key = encryptedValue.key;
      }
      if (encryptedValue.IV) {
        value.IV = encryptedValue.IV;
      }
    }
    if (value.label && typeof value.label === 'string') {
      const encryptedLabel = await encryptMessage(value.label, publicKey).catch(err => {
        console.error('encryptValue Error', err);
        return { value: origValue.label, key: null, IV: null };
      });
      value.label = encryptedLabel.value;
      if (encryptedLabel.key) {
        value.labelKey = encryptedLabel.key;
      }
      if (encryptedLabel.IV) {
        value.labelIV = encryptedLabel.IV;
      }
    }
  }
  return value;
};

export const stringToBlob = (
  byteCharacters: string,
  contentType: string = 'image/jpeg',
  sliceSize: number = 512,
): Blob => {
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const base64ToBlob = async (
  base64: string,
  mimeType: string = 'image/jpeg',
): Promise<Blob> => {
  if (!/^data:[^;]*;base64,/.test(base64)) {
    base64 = 'data:' + mimeType + ';base64,' + base64;
  }
  const response = await fetch(base64);
  return await response.blob();
};

export const blobToBase64 = async (blob: any): Promise<string> => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.readAsDataURL(blob);
  });
};

export const fieldShouldBeDisplayed = (field: RecordTypeField, data: any): boolean => {
  if (data && field.showWhen) {
    return Object.keys(field.showWhen).some(fieldId => {
      if (field.showWhen?.[fieldId] === data?.[fieldId]?.value) {
        return true;
      }
      return false;
    });
  }
  if (field.showWhenNot) {
    if (data) {
      return !Object.keys(field.showWhenNot).some(fieldId => {
        if (!data?.[fieldId] || field.showWhenNot?.[fieldId] === data?.[fieldId].value) {
          return true;
        }
        return false;
      });
    }
    return false;
  }
  return true;
};
