// hooks
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

// components
import { IonInput, IonItem, IonLabel, isPlatform } from '@ionic/react';

// types
import { TextFieldTypes } from '@ionic/core';
import { TFieldValue } from '../models/FieldValues';

type TTextFieldProps = {
  name: string;
  value: string | null | undefined;
  onValueUpdate: (value: TFieldValue) => void;
  type?: TextFieldTypes;
  inputMode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search';
  label?: string;
  labelId?: string;
  placeholder?: string;
  placeholderId?: string;
  onNext?: () => void;
  autofocus?: boolean;
  error?: string;
  encrypt?: boolean;
  unencrypt?: string;
};

const TextField = (
  {
    name,
    value,
    onValueUpdate,
    type = 'text',
    inputMode,
    label,
    labelId,
    placeholder,
    placeholderId,
    onNext,
    autofocus,
    error,
    encrypt,
    unencrypt,
  }: TTextFieldProps,
  ref?: any,
) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const onKeyPress = (evt: any) => {
    if (onNext && evt.key === 'Enter') {
      onNext();
    }
  };

  const inputRef = useRef<HTMLIonInputElement>(null);
  useImperativeHandle(
    ref,
    () => ({
      setFocus() {
        inputRef.current?.setFocus();
      },
    }),
    [],
  );

  useEffect(() => {
    if (autofocus) {
      inputRef.current?.setFocus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRef.current, autofocus]);

  const onFocus = () => {
    if (isPlatform('mobile')) {
      dispatch({ type: 'HIDE_TABS' });
    }
  };
  const onBlur = () => {
    dispatch({ type: 'SHOW_TABS' });
  };

  const updateValue = (updatedValue: string | number | null | undefined) => {
    const newValue: TFieldValue = {
      value: updatedValue,
    };
    if (encrypt) newValue.encrypt = encrypt;
    if (unencrypt) newValue.unencrypt = unencrypt;

    onValueUpdate(newValue);
  };

  return (
    <IonItem mode="md" className="text-field__container">
      {(label || labelId) && (
        <IonLabel position="stacked">
          {labelId ? <FormattedMessage id={labelId} defaultMessage={label} /> : label}
        </IonLabel>
      )}
      <IonInput
        ref={inputRef}
        className="text-field"
        value={value}
        onIonChange={e => {
          updateValue(e.detail.value);
        }}
        name={name}
        type={type}
        inputmode={inputMode || (type === 'email' ? 'email' : 'text')}
        enterkeyhint="next"
        onKeyPress={onKeyPress}
        aria-label={label || (labelId && intl.formatMessage({ id: labelId }))}
        placeholder={
          (placeholderId &&
            intl.formatMessage({ id: placeholderId, defaultMessage: placeholder })) ||
          placeholder
        }
        clearInput={true}
        onIonFocus={onFocus}
        onIonBlur={onBlur}
      ></IonInput>
      {error !== '' && (
        <IonLabel slot="error" color="danger" position="stacked" className="text-field__error">
          {error}
        </IonLabel>
      )}
    </IonItem>
  );
};

export default forwardRef(TextField);
