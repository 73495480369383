// second link to Firebase to call the functions
import fb from 'firebase/app';

// hooks
import { shallowEqual, useSelector } from 'react-redux';
import { FormEvent, useRef, useState } from 'react';
import { useFirebase } from 'react-redux-firebase';

// components
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonList,
  IonModal,
  IonTitle,
  IonToolbar,
  useIonLoading,
} from '@ionic/react';
import { FormattedMessage, useIntl } from 'react-intl';
import TextField from '../forms/TextField';

// icons and types
import { closeOutline } from 'ionicons/icons';
import { TStoreState } from '../store/store';

type TBiometricSetupModalProps = {
  isOpen: boolean;
  onDidDismiss: () => void;
};

const DeleteAccountModal: React.FC<TBiometricSetupModalProps> = (
  props: TBiometricSetupModalProps,
) => {
  const intl = useIntl();
  const firebase = useFirebase();

  const routerRef = useSelector((state: TStoreState) => state.ui?.routerRef);
  const uid = useSelector((state: TStoreState) => state.firebase?.auth?.uid);

  const password = useRef('');

  const [buttonDisabled, setButtonDisabled] = useState(false);

  const [error, setError] = useState<string | undefined>('');

  const [presentLoading, dismissLoading] = useIonLoading();

  const profile = useSelector((state: TStoreState) => state.firebase?.profile, shallowEqual);

  /**
   * password field
   */
  const passwordRef = useRef<any>(null);

  const updatePassword = (value: string) => {
    password.current = value;
    setError('');
  };

  /**
   * What to do after the user enters their password
   * @param evt
   */
  const processForm = async (evt?: FormEvent) => {
    if (evt) {
      evt.defaultPrevented = true;
      evt.preventDefault();
      evt.stopPropagation();
    }
    setButtonDisabled(true);
    setError('');
    presentLoading({
      message: intl.formatMessage({
        id: 'modal.delete-account.deleting',
        defaultMessage: 'Deleting User Account...',
      }),
    });

    const deleteUserFn = fb.app().functions('europe-west3').httpsCallable('users-delete');
    const request: { uid: string; password: string } = { uid, password: password.current };

    await deleteUserFn(request)
      .then(async () => {
        // enable buttons
        setButtonDisabled(false);
        // dismiss loading indicator
        dismissLoading();
        // in case the delete was successful, we need to log the user out
        await firebase.logout();
        // and to close the modal
        props.onDidDismiss();
      })
      .catch(err => {
        console.error('error deleting user', err.code);
        setButtonDisabled(false);
        setError(err.code);
      });
  };

  return (
    <IonModal
      mode="md"
      isOpen={props.isOpen}
      onDidDismiss={props.onDidDismiss}
      presentingElement={routerRef || undefined}
    >
      <IonHeader mode="md">
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={props.onDidDismiss}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>
            <FormattedMessage id="modal.delete-account.title" defaultMessage="Delete Account" />
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <p className="modal-description-text">
          <FormattedMessage
            id="modal.delete-account.description"
            defaultMessage="Deleting the account will delete all the data associated with it."
          />
        </p>
        <p className="modal-description-text">
          <FormattedMessage
            id="modal.delete-account.password-required"
            defaultMessage="Please enter your password to delete the account."
          />
        </p>
        {profile?.account !== 'basic' && (
          <p className="modal-description-text">
            <FormattedMessage
              id="modal.delete-account.cancel-subscription"
              defaultMessage="Make sure to cancel your subscription"
            />
          </p>
        )}
        <form
          onSubmit={processForm}
          style={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <IonList style={{ margin: '1rem 0 3rem', width: '80%' }}>
            <TextField
              ref={passwordRef}
              name="password"
              value={password.current}
              type="password"
              onValueUpdate={value => updatePassword(value.value?.toString() || '')}
              label={intl.formatMessage({ id: 'ui.field.account-password.label' })}
              autofocus={true}
              onNext={() => processForm()}
              error={
                error ? intl.formatMessage({ id: `auth.errors.password.${error}` }) : undefined
              }
            />
            <IonButton
              size="default"
              expand="block"
              mode="ios"
              color="danger"
              style={{
                margin: '1.6rem calc(var(--padding-start) + var(--ion-safe-area-left, 0px))',
              }}
              onClick={processForm}
              disabled={buttonDisabled}
            >
              <FormattedMessage id="ui.buttons.delete-account" defaultMessage="Delete Account" />
            </IonButton>
          </IonList>
        </form>
      </IonContent>
    </IonModal>
  );
};

export default DeleteAccountModal;
