import { IonIcon, IonItem, IonLabel } from '@ionic/react';
import { helpBuoyOutline } from 'ionicons/icons';
import { FormattedMessage } from 'react-intl';

type TEmptySearchResultsProps = {
  searchText: string;
  isPeriod: boolean;
  type?: string;
};

const EmptySearchResults = ({ searchText, isPeriod, type = 'paper' }: TEmptySearchResultsProps) => {
  return (
    <IonItem>
      <IonIcon size="large" slot="start" icon={helpBuoyOutline} />
      <IonLabel className="empty-search-results">
        <h2>
          <FormattedMessage
            id={`search.${type}.no-results.title`}
            defaultMessage={`Unfortunattely, no ${type}s were found for “{searchText}”.`}
            values={{
              searchText: <b>{searchText}</b>,
            }}
          />
        </h2>
        <p>
          {isPeriod ? (
            <FormattedMessage
              id={`search.${type}.no-results.description-with-period`}
              defaultMessage="Try to search differently or take a look in another time period."
              values={{
                searchText,
              }}
            />
          ) : (
            <FormattedMessage
              id={`search.${type}.no-results.description`}
              defaultMessage={`Try to search differently to find the ${type} you are looking for.`}
              values={{
                searchText,
              }}
            />
          )}
        </p>
      </IonLabel>
    </IonItem>
  );
};

export default EmptySearchResults;
