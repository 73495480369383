// components
import { FormattedMessage } from 'react-intl';
import { IonItem, IonLabel, IonSelect, IonSelectOption, isPlatform } from '@ionic/react';

type TSelectFieldProps = {
  name: string;
  value: { value: string | null | undefined } | { value: string | null | undefined }[];
  options: { value: string; label?: string; labelId: string }[];
  multiple?: boolean;
  onValueUpdate: (
    value: { value: string | null | undefined } | { value: string | null | undefined }[],
  ) => void;
  label?: string;
  labelId?: string;
  onNext?: () => void;
  error?: string;
};

const SelectField = ({
  name,
  value,
  options,
  multiple = false,
  onValueUpdate,
  label,
  labelId,
}: TSelectFieldProps) => {
  const updateValue = (value: string[] | string | null | undefined) => {
    if (Array.isArray(value)) {
      const updatedValue = value.map(v => ({ value: v || 'none' }));
      onValueUpdate(updatedValue);
      return;
    }
    const updatedValue = { value: value || 'none' };
    onValueUpdate(updatedValue);
  };

  return (
    <IonItem mode="ios" className="text-field__container">
      <IonLabel>
        {labelId ? <FormattedMessage id={labelId} defaultMessage={label} /> : label}
      </IonLabel>
      <IonSelect
        name={name}
        value={Array.isArray(value) ? value.map(v => v.value) : value?.value || 'none'}
        onIonChange={evt => updateValue(evt.detail.value)}
        interface={multiple && isPlatform('mobile') ? 'alert' : 'popover'}
        multiple={multiple}
        style={{ maxWidth: '66%' }}
        interfaceOptions={{
          cssClass: 'select-field__popover',
        }}
        mode={isPlatform('ios') || !multiple ? 'ios' : 'md'}
      >
        {options.map((item, index) => (
          <IonSelectOption key={item.value} value={item.value}>
            {item.labelId ? (
              <FormattedMessage id={item.labelId} defaultMessage={item.label} />
            ) : (
              item.label
            )}
          </IonSelectOption>
        ))}
      </IonSelect>
    </IonItem>
  );
};

export default SelectField;
