import React, { RefObject } from 'react';

// hooks
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

// components
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonTitle,
  IonContent,
  IonModal,
} from '@ionic/react';
import Searchbar from './Searchbar';

// icons
import { closeOutline } from 'ionicons/icons';

type TModalFormProps = {
  opened: boolean;
  onDismiss: Function;
  title: string;
  children?: React.ReactNode;
  toolbar?: React.ReactNode;
  modalRef?: RefObject<HTMLIonModalElement>;
  setSearchStr?: Function;
  searchStr?: string;
  searchPlaceholder?: string;
  addAction?: Function;
  action?: { title: string; callback: Function; disabled?: boolean };

  initialBreakpoint?: number;
  breakpoints?: number[];
  backdropDismiss?: boolean;
  backdropBreakpoint?: number;
  className?: string;
  style?: React.CSSProperties;
};

const ModalForm = (props: TModalFormProps) => {
  const routerRef = useSelector((state: any) => state.ui?.routerRef);

  return (
    <IonModal
      mode="md"
      ref={props.modalRef ? props.modalRef : null}
      isOpen={props.opened}
      onDidDismiss={() => props.onDismiss()}
      presentingElement={routerRef}
      className={`modal-form ${props.className || ''}`}
      initialBreakpoint={props.initialBreakpoint}
      breakpoints={props.breakpoints}
      backdropDismiss={props.backdropDismiss}
      backdropBreakpoint={props.backdropBreakpoint}
      style={props.style}
    >
      <IonHeader mode="md">
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => props.onDismiss()}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>{props.title}</IonTitle>
          {(props.addAction || props.action) && (
            <IonButtons slot="primary">
              {props.addAction && (
                <IonButton
                  onClick={() => props.addAction && props.addAction()}
                  color="primary"
                  fill="outline"
                  className="modal-form__action-button"
                >
                  <FormattedMessage
                    id="ui.buttons.add"
                    description="UI Buttons: Add"
                    defaultMessage="Add"
                  />
                </IonButton>
              )}
              {props.action && (
                <IonButton
                  onClick={() => props.action?.callback && props.action.callback()}
                  disabled={props.action.disabled}
                  color="primary"
                  fill="outline"
                  className="modal-form__action-button"
                >
                  {props.action.title}
                </IonButton>
              )}
            </IonButtons>
          )}
        </IonToolbar>
        {props.setSearchStr && (
          <IonToolbar>
            <Searchbar
              value={props.searchStr}
              onChange={props.setSearchStr}
              placeholder={props.searchPlaceholder || ''}
            />
          </IonToolbar>
        )}
        {props.toolbar}
      </IonHeader>
      <IonContent style={{ height: 'calc(100% - 56px)', '--padding-bottom': '3rem' }}>
        {props.children}
      </IonContent>
    </IonModal>
  );
};

export default ModalForm;
