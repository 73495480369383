// hooks
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';
import { useHistory } from 'react-router';

// components
import {
  IonChip,
  IonFab,
  IonFabButton,
  IonFabList,
  IonIcon,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonToolbar,
  isPlatform,
} from '@ionic/react';
import AppPage from '../components/AppPage';
import ListHeader from '../components/ListHeader';
import LoadingScreen from '../components/LoadingScreen';
import ContactsList from '../components/ContactsList';

// types and icons
import { TStoreState } from '../store/store';
import { add, createOutline, idCardOutline, lockClosedOutline } from 'ionicons/icons';
import { ContactsState } from '../models/Contact';
import classNames from 'classnames';
import { emitCustomEvent } from 'react-custom-events';
import { updateContactsSearch, updateContactsShow } from '../thunks/settings';
import { TContactsShow } from '../store/settings.reducer';

const Contacts: React.FC = () => {
  const intl = useIntl();
  const firestore = useFirestore();
  const history = useHistory();

  const uid = useSelector((state: TStoreState) => state.firebase.auth.uid, shallowEqual);

  // saved states
  const savedSearchText = useSelector(
    (state: TStoreState) => state.settings?.contacts?.search || '',
    shallowEqual,
  );
  const contactsType = useSelector(
    (state: TStoreState) => state.settings?.contacts?.show || 'all',
    shallowEqual,
  );

  // data
  const contacts = useSelector(
    (state: TStoreState) => state.data?.contacts,
    shallowEqual,
  ) as ContactsState;

  const [thereAreContacts, setThereAreContacts] = useState(false);
  const [thereAreFavourites, setThereAreFavourites] = useState(false);

  useEffect(() => {
    if (contacts?.items) {
      setThereAreContacts(Object.keys(contacts.items).length > 0);
      let favorites = false;
      for (let itemId in contacts.items) {
        if (contacts.items[itemId]?.favorite?.value === true) {
          favorites = true;
          break;
        }
      }
      setThereAreFavourites(favorites);
    } else {
      setThereAreContacts(false);
      setThereAreFavourites(false);
    }
  }, [contacts]);

  // tips
  const tips = useSelector((state: TStoreState) => state.firestore?.data?.tips?.[intl.locale]);

  // search
  const [searchText, setSearchText] = useState<string>('');
  const onSearchUpdate = (text: string) => {
    setSearchText(text);
    updateContactsSearch(firestore, uid, text);
  };

  // type selector
  const [currentContactsType, setCurrentContactsType] = useState(contactsType);
  useEffect(() => {
    if (contactsType !== currentContactsType) {
      setCurrentContactsType(contactsType);
    }
  }, [contactsType]);

  const updateContactsType = (newContactsType: TContactsShow | undefined) => {
    if (
      newContactsType &&
      currentContactsType !== newContactsType &&
      contactsType !== newContactsType
    ) {
      updateContactsShow(firestore, uid, newContactsType);
    }
  };

  const showCreateModal = () => {
    fabButtonDeactivated();
    fabButton.current?.close();
    history.push('/contacts/new');
  };

  // import contacts modal
  const showImportContactsModal = () => {
    fabButtonDeactivated();
    fabButton.current?.close();
    emitCustomEvent('showModal', 'contacts');
  };

  // dimming the contents when the fab list is active
  const [fabListActive, setFabListActive] = useState(false);
  const fabButton = useRef<HTMLIonFabElement>(null);
  const fabButtonActivated = () => {
    // toggling in case the user clicks on the fab button again
    setFabListActive(value => !value);
  };
  const fabButtonDeactivated = () => {
    setFabListActive(false);
  };

  /**
   * Request authorization
   */
  const profile = useSelector((state: TStoreState) => state.firebase.profile, shallowEqual);
  const privateKey = useSelector((state: TStoreState) => state.ui?.privateKey);

  const [isLocked, setIsLocked] = useState(profile?.publicKey && !privateKey);

  useEffect(() => {
    const checkLocked = profile?.publicKey && !privateKey;
    if (checkLocked !== isLocked) {
      setIsLocked(profile?.publicKey && !privateKey);
    }
  }, [profile, privateKey]);

  const requestAuthorization = async () => {
    emitCustomEvent('request-authorization');
  };

  console.log('contacts page render');

  return (
    <AppPage
      title={intl.formatMessage({ id: 'page.contacts.title' })}
      header={
        <ListHeader
          title={intl.formatMessage({
            id: 'page.contacts.title',
            defaultMessage: 'Contacts',
          })}
          searchPlaceholder={intl.formatMessage({
            id: 'page.contacts.search.placeholder',
            defaultMessage: 'Filter Contacts',
          })}
          savedSearchText={savedSearchText}
          onSearchUpdate={thereAreContacts ? onSearchUpdate : null}
        >
          {thereAreFavourites ? (
            <IonToolbar className="segment-toolbar">
              <IonSegment
                value={currentContactsType}
                onIonChange={evt => updateContactsType(evt.detail.value as TContactsShow)}
                mode="ios"
              >
                <IonSegmentButton value="favorites">
                  <IonLabel>
                    <FormattedMessage
                      id="page.contacts.type.favorites"
                      defaultMessage="Favorites"
                    />
                  </IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="all">
                  <IonLabel>
                    <FormattedMessage id="page.contacts.type.all" defaultMessage="All" />
                  </IonLabel>
                </IonSegmentButton>
              </IonSegment>
            </IonToolbar>
          ) : undefined}
        </ListHeader>
      }
      path="/contacts"
    >
      {contacts?.state?.isLoading ? (
        <LoadingScreen />
      ) : contacts?.state?.isEmpty ? (
        tips?.contacts && (
          <div
            className={classNames({
              tip: true,
              'leave-space-for-fab-at-the-bottom': true,
              'list-transparent': fabListActive,
            })}
            dangerouslySetInnerHTML={{ __html: tips.contacts }}
          ></div>
        )
      ) : (
        <ContactsList
          routerLink="/contacts/"
          searchText={searchText}
          favoritesOnly={currentContactsType === 'favorites'}
          fabListActive={fabListActive}
        />
      )}

      {!contacts?.state?.isLoading && (
        <IonFab vertical="bottom" horizontal="end" slot="fixed" ref={fabButton}>
          {isPlatform('capacitor') ? (
            <>
              <IonFabButton mode="ios" onClick={fabButtonActivated} color="tertiary">
                <IonIcon icon={add} />
              </IonFabButton>
              <IonFabList side="top">
                <div className="fab-tooltip">
                  <IonChip className="fab-tooltip--text" onClick={showImportContactsModal}>
                    <FormattedMessage
                      id="form.contact.import.title"
                      defaultMessage="Import Contacts from your Device"
                    />
                  </IonChip>
                </div>
                <IonFabButton
                  mode="ios"
                  onClick={showImportContactsModal}
                  color="secondary"
                  title={intl.formatMessage({ id: 'form.contact.import.title' })}
                >
                  <IonIcon
                    icon={idCardOutline}
                    title={intl.formatMessage({ id: 'form.contact.import.title' })}
                    style={{
                      transform: 'rotate(-5deg) scale(1.1)',
                    }}
                  />
                </IonFabButton>

                <div className="fab-tooltip">
                  <IonChip className="fab-tooltip--text" onClick={showCreateModal}>
                    <FormattedMessage
                      id="form.contact.new.title"
                      defaultMessage="Add New Contact"
                    />
                  </IonChip>
                </div>
                <IonFabButton
                  mode="ios"
                  onClick={showCreateModal}
                  color="secondary"
                  title={intl.formatMessage({ id: 'form.contact.new.title' })}
                >
                  <IonIcon
                    icon={createOutline}
                    title={intl.formatMessage({ id: 'form.contact.new.title' })}
                    style={{ pointerEvents: 'none' }}
                  />
                </IonFabButton>
              </IonFabList>
            </>
          ) : (
            <IonFabButton mode="ios" onClick={showCreateModal} color="tertiary">
              <IonIcon icon={add} />
            </IonFabButton>
          )}
        </IonFab>
      )}

      {isLocked && (
        <IonFab vertical="bottom" horizontal="start" slot="fixed">
          <IonFabButton mode="ios" onClick={requestAuthorization}>
            <IonIcon icon={lockClosedOutline} />
          </IonFabButton>
        </IonFab>
      )}

      {/* <ContactModal opened={formModalOpened} onDismiss={hideCreateModal} /> */}
    </AppPage>
  );
};

export default Contacts;
