// hooks
import { useState } from 'react';
import { useIntl } from 'react-intl';

// components
import ModalForm from '../components/ModalForm';
import PapersList from '../components/PapersList';

type TLinkPaperModalProps = {
  opened: boolean;
  onDismiss: Function;
  setPaperId?: (paperId: string) => void;
  paperId?: string | number | null;
};

const LinkPaperModal: React.FC<TLinkPaperModalProps> = (props: TLinkPaperModalProps) => {
  const intl = useIntl();

  const selectPaper = (paperId: string) => {
    if (props.setPaperId) {
      props.setPaperId(paperId);
    }
    if (props.onDismiss) {
      props.onDismiss();
    }
  };

  const [searchText, setSearchText] = useState<string>('');
  const onSearchUpdate = (text: string) => {
    setSearchText(text);
  };

  return (
    <ModalForm
      {...props}
      title={intl.formatMessage({
        id: 'form.link-paper.title',
        defaultMessage: 'Choose a Paper to Link',
      })}
      searchStr={searchText}
      setSearchStr={onSearchUpdate}
      searchPlaceholder={intl.formatMessage({
        id: 'page.all.search.placeholder',
        defaultMessage: 'Filter Papers',
      })}
    >
      <PapersList
        searchText={searchText}
        disabledPapers={[props.paperId ? props.paperId.toString() : '']}
        clickHandler={selectPaper}
        periods="all"
        periodField="createdAt"
        selectedPeriod={0}
      />
    </ModalForm>
  );
};

export default LinkPaperModal;
