// second link to Firebase to create user in parallel
import fb from 'firebase/app';

// hooks
import { FormEvent, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';

// components
import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToolbar,
  isPlatform,
  useIonToast,
} from '@ionic/react';
import TextField from './TextField';

// icons
import { closeOutline } from 'ionicons/icons';

// types
import { TStoreState } from '../store/store';
import { useFirestoreItemQuery } from '../hooks/useFirestoreItemQuery';
import { Browser } from '@capacitor/browser';

type TAuthModalProps = {
  isOpen: boolean;
  onDidDismiss: () => void;
};

const AuthModal: React.FC<TAuthModalProps> = (props: TAuthModalProps) => {
  const routerRef = useSelector((state: any) => state.ui?.routerRef);
  const user = useSelector((state: TStoreState) => state.firebase?.auth, shallowEqual);

  const intl = useIntl();

  const firebase = useFirebase();

  const [presentToast] = useIonToast();

  const device = useSelector((state: TStoreState) => state.device);

  const [currentAuthForm, setCurrentAuthForm] = useState<string | undefined>(
    user?.isAnonymous ? 'login' : 'register',
  );

  const passwordRef = useRef<any>(null);
  const selectField = (fieldName: string) => {
    if (fieldName === 'password') {
      // if it's reset password — submit form
      if (currentAuthForm === 'reset') {
        authAction();
      }
      // set focus to the password field
      if (passwordRef.current) {
        passwordRef.current.setFocus();
      }
    }
  };

  const processForm = (evt: FormEvent) => {
    evt.defaultPrevented = true;
    evt.preventDefault();
    evt.stopPropagation();
    authAction();
  };

  // form data
  const email = useRef('');
  const password = useRef('');

  const authAction = () => {
    clearErrors();

    if (currentAuthForm === 'reset') {
      firebase
        .resetPassword(email.current)
        .then(() => {
          presentToast({
            message: intl.formatMessage({ id: 'ui.toast.reset-link-sent' }),
            duration: 2000,
            position: 'top',
            color: 'medium',
          });
          props.onDidDismiss();
        })
        .catch((error: any) => {
          errorsCheck(error);
        });
    } else {
      const credential = fb.auth.EmailAuthProvider.credential(email.current, password.current);

      // first we should try to login, even if the user is registering
      const auth = fb.auth();
      auth
        .signInWithCredential(credential)
        .then(result => {
          if (result.user) {
            // keeping the data of the anonymous user
            const from = user?.isAnonymous ? user.uid : null;
            const to = result.user.uid;

            // logging in as the requested user
            firebase.reloadAuth(result).then(() => {
              // if old user was anonymous — move the data to the real user
              moveAnonymousData(from, to, result.user?.email || '');

              // set anonymous ID if exists
              if (from) {
                firebase.updateProfile({ anonymousId: from });
              }

              // show the toast that everything is ok
              presentToast({
                message: intl.formatMessage(
                  { id: 'ui.toast.logged-in' },
                  { email: result.user?.email },
                ),
                duration: 2000,
                position: 'top',
                color: 'medium',
              });

              // close the popup
              props.onDidDismiss();
            });
          }
        })
        .catch((error: any) => {
          if (error.code === 'auth/user-not-found') {
            // if there is no such user — register a new one
            fb.auth()
              .createUserWithEmailAndPassword(email.current, password.current)
              .then(result => {
                if (result.user) {
                  // keeping the data of the anonymous user
                  const from = user?.isAnonymous ? user.uid : null;
                  const to = result.user.uid;

                  // logging in as the newly created user
                  firebase.reloadAuth(result).then(() => {
                    // if old user was anonymous — move the data to the real user
                    moveAnonymousData(from, to, result.user?.email || '');

                    // set the email as the only property for the user
                    firebase.updateProfile({
                      email: result.user?.email,
                      anonymousId: from,
                    });

                    // show the toast that the user was created
                    presentToast({
                      message: intl.formatMessage(
                        { id: 'ui.toast.user-created' },
                        { email: result.user?.email },
                      ),
                      duration: 2000,
                      position: 'top',
                      color: 'medium',
                    });
                    // close the popup
                    props.onDidDismiss();
                  });
                }
              })
              .catch(error => {
                errorsCheck(error);
              });
          } else {
            errorsCheck(error);
          }
        });
    }
  };

  const moveAnonymousData = (from: string, to: string, email: string) => {
    if (from && to) {
      const moveDataToUser = fb.app().functions('europe-west3').httpsCallable('users-moveData');
      moveDataToUser({ from, to })
        .then(() => {
          presentToast({
            message: intl.formatMessage({ id: 'ui.toast.anonymous-data-moved' }, { email }),
            duration: 2000,
            position: 'top',
            color: 'medium',
          });
        })
        .catch(() => {
          presentToast({
            message: intl.formatMessage({ id: 'ui.toast.anonymous-data-move-error' }, { email }),
            position: 'top',
            color: 'medium',
          });
        });
    }
  };

  // errors
  const [appError, setAppError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const errorsCheck = (error: any) => {
    if (error && error.code) {
      switch (error.code) {
        case 'auth/network-request-failed': {
          setAppError(
            intl.formatMessage({
              id: 'auth.errors.network',
              defaultMessage: 'Problems with the network connection. Try again later.',
              description: 'Auth error: the network request failed',
            }),
          );
          break;
        }
        case 'auth/too-many-requests': {
          setAppError(
            intl.formatMessage({
              id: 'auth.errors.manyrequests',
              defaultMessage: 'Too many requests from your computer. Try again in an hour or more.',
              description: 'Auth error: too many requests',
            }),
          );
          break;
        }
        case 'auth/email-already-in-use': {
          setEmailError(
            intl.formatMessage({
              id: 'auth.errors.emailinuse',
              defaultMessage: 'The email address is already registered. You should sign in.',
              description: 'Auth error: email is already in use',
            }),
          );
          break;
        }
        case 'auth/user-not-found': {
          setEmailError(
            intl.formatMessage({
              id: 'auth.errors.emailnotfound',
              defaultMessage: 'The user with this email is not found.',
              description: 'Auth error: email not registered yet',
            }),
          );
          break;
        }
        case 'auth/invalid-email': {
          setEmailError(
            intl.formatMessage({
              id: 'auth.errors.invalidemail',
              defaultMessage: 'The email address is badly formatted.',
              description: 'Auth error: invalid email format',
            }),
          );
          break;
        }
        case 'auth/wrong-password': {
          setPasswordError(
            intl.formatMessage({
              id: 'auth.errors.invalidpassword',
              defaultMessage: 'Wrong password. Correct and try again.',
              description: 'Auth error: invalid password',
            }),
          );
          break;
        }
        case 'auth/weak-password': {
          setPasswordError(
            intl.formatMessage({
              id: 'auth.errors.weakpassword',
              defaultMessage: 'The password must be 6 characters long or more.',
              description: 'Auth error: weak password, must be 6 chars or more',
            }),
          );
          break;
        }
        default: {
          setAppError(error.message);
        }
      }
    } else {
      console.error('Unexpected error', error);
    }
  };

  const clearErrors = (
    opts: { app?: boolean; email?: boolean; password?: boolean } = {
      app: true,
      email: true,
      password: true,
    },
  ) => {
    if (opts.app) {
      setAppError('');
    }
    if (opts.email) {
      setEmailError('');
    }
    if (opts.password) {
      setPasswordError('');
    }
  };

  const updateEmail = (value: string) => {
    email.current = value;
    clearErrors({ app: true, email: true });
  };

  const updatePassword = (value: string) => {
    password.current = value;
    clearErrors({ app: true, password: true });
  };

  const [agree, setAgree] = useState(false);

  const urls = useFirestoreItemQuery('settings', 'urls') as { [term: string]: any };

  const openUrlFromSite = async (path = '/') => {
    let url = urls?.landing
      ? `${urls.landing}/${intl.locale}${path}`
      : `https://mypapersapp.com/${intl.locale}${path}`;

    // if we are in a mobile app, we need to add a query param
    // to open the site without the navigation
    if (isPlatform('capacitor')) {
      url += `?app=true`;
    }

    await Browser.open({
      url,
      toolbarColor: device.theme === 'classic' ? '#fdd985' : '#b5dfd7',
      windowName: 'thepapershelp',
    });
  };

  const openTermsOfUse = (evt: any) => {
    evt.stopPropagation();
    evt.preventDefault();
    openUrlFromSite('/terms/');
  };

  const openPrivacyPolicy = (evt: any) => {
    evt.stopPropagation();
    evt.preventDefault();
    openUrlFromSite('/privacy/');
  };

  return (
    <IonModal
      mode="md"
      ref={routerRef || null}
      isOpen={props.isOpen}
      onDidDismiss={() => props.onDidDismiss()}
      presentingElement={routerRef}
    >
      <IonHeader mode="md">
        <IonToolbar mode="md">
          <IonButtons slot="start">
            <IonButton onClick={() => props.onDidDismiss()}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>
            <FormattedMessage id="modal.auth.title" defaultMessage="Register or Sign In" />
          </IonTitle>
        </IonToolbar>
        <IonToolbar mode="md" className="segment-toolbar">
          <IonSegment
            value={currentAuthForm}
            onIonChange={evt => {
              clearErrors();
              setCurrentAuthForm(evt.detail.value);
            }}
            mode="ios"
          >
            <IonSegmentButton value="register">
              <IonLabel>
                <FormattedMessage id="modal.auth.type.register" defaultMessage="Register" />
              </IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="login">
              <IonLabel>
                <FormattedMessage id="modal.auth.type.login" defaultMessage="Sign In" />
              </IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="reset">
              <IonLabel>
                <FormattedMessage id="modal.auth.type.reset" defaultMessage="Reset Password" />
              </IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <form onSubmit={processForm}>
          <IonList style={{ margin: '1rem 2rem' }}>
            <TextField
              name="email"
              value={email.current}
              type="email"
              onValueUpdate={value => updateEmail(value.value?.toString() || '')}
              label={intl.formatMessage({ id: 'ui.field.email.label' })}
              onNext={() => selectField('password')}
              error={emailError}
            />
            {currentAuthForm !== 'reset' && (
              <TextField
                ref={passwordRef}
                name="password"
                value={password.current}
                type="password"
                onValueUpdate={value => updatePassword(value.value?.toString() || '')}
                label={intl.formatMessage({ id: 'ui.field.password.label' })}
                placeholder={
                  currentAuthForm === 'register'
                    ? intl.formatMessage({ id: 'ui.field.password.description' })
                    : ''
                }
                onNext={authAction}
                error={passwordError}
              />
            )}
            {currentAuthForm === 'register' && (
              <IonItem mode="ios" className="text-field__container auth-checkbox">
                <IonCheckbox
                  slot="start"
                  mode="md"
                  className="auth-checkbox"
                  id="agree"
                  checked={agree}
                  onIonChange={e => setAgree(e.detail.checked)}
                  aria-label={intl.formatMessage(
                    { id: 'ui.field.agree.label' },
                    { terms: '', privacy: '' },
                  )}
                />
                <IonLabel className="auth-checkbox">
                  <FormattedMessage
                    id="ui.field.agree.label"
                    defaultMessage="I agree that the images and data I upload will be stored on the server, and
                      available only to me. I understand that I can completely delete my
                      account and my data at any time. I agree with the {terms} and {privacy}."
                    values={{
                      terms: (
                        <a
                          href="https://mypapersapp.com/en/terms/"
                          target="_blank"
                          rel="noreferrer"
                          onClick={e => openTermsOfUse(e)}
                        >
                          <FormattedMessage
                            id="ui.buttons.terms-and-conditions"
                            defaultMessage="Terms and Conditions"
                          />
                        </a>
                      ),
                      privacy: (
                        <a
                          href="https://mypapersapp.com/en/privacy/"
                          target="_blank"
                          rel="noreferrer"
                          onClick={e => openPrivacyPolicy(e)}
                        >
                          <FormattedMessage
                            id="ui.buttons.privacy-policy"
                            defaultMessage="Privacy policy"
                          />
                        </a>
                      ),
                    }}
                  />
                </IonLabel>
              </IonItem>
            )}
            {appError !== '' && (
              <div className="ion-padding">
                <IonLabel color="danger" position="stacked" className="error-label">
                  {appError}
                </IonLabel>
              </div>
            )}
            <IonButton
              size="default"
              expand="block"
              mode="ios"
              color="secondary"
              style={{
                margin: '1.6rem calc(var(--padding-start) + var(--ion-safe-area-left, 0px))',
              }}
              disabled={currentAuthForm === 'register' && agree === false}
              onClick={authAction}
            >
              <FormattedMessage id={`ui.buttons.${currentAuthForm}`} />
            </IonButton>
          </IonList>
        </form>
      </IonContent>
    </IonModal>
  );
};

export default AuthModal;
