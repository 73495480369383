import { ReactElement, useEffect, useState } from 'react';

// components
import { IonButton, IonButtons, IonIcon, IonTitle, IonToolbar } from '@ionic/react';
import Searchbar from './Searchbar';
import { closeOutline, searchOutline, addCircleOutline } from 'ionicons/icons';

type TListHeaderProps = {
  title: string;
  searchPlaceholder: string;
  savedSearchText?: string;
  onSearchUpdate: null | Function;
  onAdd?: Function;
  children?: ReactElement;
};

const ListHeader = ({
  title,
  searchPlaceholder,
  savedSearchText,
  onSearchUpdate,
  onAdd,
  children,
}: TListHeaderProps) => {
  // content of the search box
  const [searchText, setSearchText] = useState<string>(savedSearchText || '');
  useEffect(() => {
    if (savedSearchText && savedSearchText !== '' && searchText !== savedSearchText) {
      setSearchText(savedSearchText);
    }
    // eslint-disable-next-line
  }, [savedSearchText]);

  // search bar
  const [showSearchBar, setShowSearchBar] = useState(false);

  const updateSearchText = (text: string) => {
    setSearchText(text);
    if (onSearchUpdate) {
      onSearchUpdate(text);
    }
  };

  const toggleSearchBar = () => {
    if (showSearchBar) {
      if (onSearchUpdate) {
        onSearchUpdate('');
      }
    } else {
      if (onSearchUpdate) {
        onSearchUpdate(searchText);
      }
    }
    setShowSearchBar(!showSearchBar);
  };

  return (
    <>
      <IonToolbar className="search-toolbar">
        {showSearchBar ? (
          <Searchbar
            value={searchText}
            onChange={updateSearchText}
            placeholder={searchPlaceholder}
          />
        ) : (
          <IonTitle className="home">{title}</IonTitle>
        )}
        <IonButtons slot="primary" className="toolbar-buttons">
          {onSearchUpdate && (
            <IonButton
              onClick={toggleSearchBar}
              color={showSearchBar ? 'primary' : 'dark'}
              shape="round"
              className="search-button"
            >
              <IonIcon
                icon={showSearchBar ? closeOutline : searchOutline}
                slot="icon-only"
                className="search-button--icon"
                color="medium"
              />
            </IonButton>
          )}
          {onAdd && (
            <IonButton
              onClick={() => onAdd()}
              color="primary"
              shape="round"
              className="add-button"
              size="large"
            >
              <IonIcon icon={addCircleOutline} slot="icon-only" className="add-button--icon" />
            </IonButton>
          )}
        </IonButtons>
      </IonToolbar>
      {children}
    </>
  );
};

export default ListHeader;
