import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

// components
import { IonItem, IonLabel, IonTextarea, isPlatform } from '@ionic/react';
import { TFieldValue } from '../models/FieldValues';

type TTextareaFieldProps = {
  name: string;
  value: string | null | undefined;
  onValueUpdate: (value: TFieldValue) => void;
  label?: string;
  labelId?: string;
  placeholder?: string;
  placeholderId?: string;
  encrypt?: boolean;
  unencrypt?: string;
};

const TextareaField = ({
  name,
  value,
  onValueUpdate,
  label,
  labelId,
  placeholder,
  placeholderId,
  encrypt,
  unencrypt,
}: TTextareaFieldProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const onFocus = () => {
    if (isPlatform('mobile')) {
      dispatch({ type: 'HIDE_TABS' });
    }
  };
  const onBlur = () => {
    dispatch({ type: 'SHOW_TABS' });
  };

  const updateValue = (updatedValue: string | null | undefined) => {
    const newValue: TFieldValue = {
      value: updatedValue,
    };
    if (encrypt) newValue.encrypt = encrypt;
    if (unencrypt) newValue.unencrypt = unencrypt;

    onValueUpdate(newValue);
  };

  return (
    <IonItem mode="md" className="text-field__container">
      {(label || labelId) && (
        <IonLabel position="stacked">
          {labelId ? <FormattedMessage id={labelId} defaultMessage={label} /> : label}
        </IonLabel>
      )}
      <IonTextarea
        className="text-field"
        aria-label={label || (labelId && intl.formatMessage({ id: labelId }))}
        value={value}
        onIonChange={e => updateValue(e.detail.value)}
        name={name}
        inputmode="text"
        enterkeyhint="next"
        autoGrow={true}
        placeholder={
          (placeholderId &&
            intl.formatMessage({ id: placeholderId, defaultMessage: placeholder })) ||
          placeholder
        }
        onIonFocus={onFocus}
        onIonBlur={onBlur}
      ></IonTextarea>
    </IonItem>
  );
};

export default TextareaField;
