// hooks
import { useSelector } from 'react-redux';
import { emitCustomEvent } from 'react-custom-events';
import { useHistory } from 'react-router-dom';

// components
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonModal,
  IonTitle,
  IonToolbar,
  isPlatform,
} from '@ionic/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { FormattedMessage } from 'react-intl';

// types
import { TStoreState } from '../store/store';
import {
  closeOutline,
  fingerPrintOutline,
  idCardOutline,
  notificationsOutline,
  pricetagsOutline,
} from 'ionicons/icons';

type TWelcomeModalProps = {
  isOpen: boolean;
  onDidDismiss: () => void;
};

const WelcomeModal = (props: TWelcomeModalProps) => {
  const routerRef = useSelector((state: TStoreState) => state.ui?.routerRef);
  const profile = useSelector((state: TStoreState) => state.firebase?.profile);
  const history = useHistory();

  // settings for the swiper pagination
  const pagination = {
    clickable: true,
  };

  const showEncryptionModal = () => {
    emitCustomEvent('showModal', 'encryption');
  };

  const showContactsModal = () => {
    emitCustomEvent('showModal', 'contacts');
  };

  const showRemindersModal = () => {
    emitCustomEvent('showModal', 'reminders');
  };

  const showAuthModal = () => {
    emitCustomEvent('showModal', 'registration');
  };

  const toThePapers = () => {
    props.onDidDismiss();
    history.push('/papers');
  };

  return (
    <IonModal
      mode="md"
      isOpen={props.isOpen}
      onDidDismiss={props.onDidDismiss}
      presentingElement={routerRef || undefined}
    >
      <IonHeader mode="md">
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={props.onDidDismiss}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>
            <FormattedMessage id="modal.setup.title" defaultMessage="The Papers Setup" />
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent color="secondary" className="welcome-modal">
        <Swiper
          slidesPerView={1}
          pagination={pagination}
          modules={[Pagination]}
          className="welcome-modal--swiper"
        >
          {(!profile ||
            profile.isEmpty ||
            profile?.isAnonymous ||
            !profile.hidden?.welcomemodal) && (
            <SwiperSlide key="register">
              <div className="welcome-modal--slide">
                <p className="welcome-modal--lead">
                  <b>
                    <i>
                      <FormattedMessage id="modal.setup.account.lead" defaultMessage="Welcome to" />
                    </i>
                  </b>
                </p>
                <h1>
                  <FormattedMessage id="modal.setup.account.title" defaultMessage="The Papers 2" />
                </h1>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <p style={{ textAlign: 'center' }}>
                    <FormattedMessage
                      id="modal.setup.account.what-is-new"
                      defaultMessage="With this version we have greatly improved our on-device text recognition, search, and multipage PDF support."
                    />
                  </p>
                  <IonIcon
                    icon={pricetagsOutline}
                    style={{
                      fontSize: '4rem',
                      flex: '0 0 4rem',
                      margin: '0 0 0 0.6rem',
                      transform: 'translateY(2rem) rotate(-20deg)',
                    }}
                  />
                </div>
                <p>
                  <FormattedMessage
                    id="modal.setup.account.features"
                    defaultMessage="Use an account to choose multiple languages for the recognition and get access to your documents from everywhere."
                  />
                </p>
                {(!profile || profile.isEmpty || profile?.isAnonymous) && (
                  <>
                    <p>
                      <IonButton
                        onClick={showAuthModal}
                        mode="md"
                        shape="round"
                        fill="solid"
                        style={{ marginBottom: '0.2rem' }}
                      >
                        <IonLabel>
                          <FormattedMessage
                            id="ui.buttons.login-or-register"
                            defaultMessage="Sign In or Register a new Account"
                          />
                        </IonLabel>
                      </IonButton>
                    </p>
                    <p>
                      <FormattedMessage
                        id="modal.setup.account.additional"
                        defaultMessage="Account will give you access to the encryption, notifications and currency settings too."
                      />
                    </p>
                  </>
                )}
              </div>
            </SwiperSlide>
          )}
          {!profile?.publicKey && (
            <SwiperSlide key="encryption">
              <div className="welcome-modal--slide">
                <p className="welcome-modal--lead">
                  <b>
                    <i>
                      <FormattedMessage
                        id="modal.setup.encryption.lead"
                        defaultMessage="First things first:"
                      />
                    </i>
                  </b>
                </p>
                <h1>
                  <FormattedMessage
                    id="modal.setup.encryption.title"
                    defaultMessage="Protect your data!"
                  />
                </h1>
                <p>
                  <FormattedMessage
                    id="modal.setup.encryption.offer"
                    defaultMessage="We can encrypt all of your confidential data, and no one will be able to see it without your permission."
                  />
                </p>
                <p className="welcome-modal--note">
                  <IonButton
                    onClick={showEncryptionModal}
                    mode="md"
                    shape="round"
                    fill="solid"
                    style={{ marginBottom: '0.2rem' }}
                  >
                    <IonLabel>
                      <FormattedMessage
                        id="modal.encryption.button.turn-on-encryption"
                        defaultMessage="Turn on Encryption"
                      />
                    </IonLabel>
                  </IonButton>
                  <br />
                  <FormattedMessage
                    id="modal.setup.needs-registration"
                    defaultMessage="You will have to register first"
                  />
                </p>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <IonIcon
                    icon={fingerPrintOutline}
                    style={{ fontSize: '5rem', flex: '0 0 5rem', margin: '1rem 1rem 0 0' }}
                  />
                  <p style={{ textAlign: 'start' }}>
                    <FormattedMessage
                      id="modal.setup.encryption.biometrics"
                      defaultMessage="And yes, we can use biometric authentication on your device, so you won't have to type your password or PIN all the time."
                    />
                  </p>
                </div>
              </div>
            </SwiperSlide>
          )}
          {isPlatform('capacitor') && (
            <>
              <SwiperSlide>
                <div className="welcome-modal--slide">
                  <p className="welcome-modal--lead">
                    <b>
                      <i>
                        <FormattedMessage
                          id="modal.setup.contacts.lead"
                          defaultMessage="Linking papers:"
                        />
                      </i>
                    </b>
                  </p>
                  <h1>
                    <FormattedMessage
                      id="modal.setup.contacts.title"
                      defaultMessage="People and organizations"
                    />
                  </h1>
                  <p>
                    <FormattedMessage
                      id="modal.setup.contacts.description"
                      defaultMessage="Connecting papers is essential for your best experience. And the most helpful connections are with the contacts mentioned in it."
                    />
                  </p>
                  <div style={{ display: 'flex', flexDirection: 'row', maxWidth: '25rem' }}>
                    <p style={{ textAlign: 'end' }}>
                      <FormattedMessage
                        id="modal.setup.contacts.offer"
                        defaultMessage="The easiest way to create your contacts list is to import it from your device."
                      />
                    </p>
                    <IonIcon
                      icon={idCardOutline}
                      style={{
                        fontSize: '4rem',
                        flex: '0 0 4rem',
                        margin: '1rem 0 0 1rem',
                        transform: 'rotate(10deg)',
                      }}
                    />
                  </div>
                  <p>
                    <IonButton
                      onClick={showContactsModal}
                      mode="md"
                      shape="round"
                      fill="solid"
                      style={{ marginBottom: '0.2rem' }}
                    >
                      <IonLabel>
                        <FormattedMessage
                          id="modal.setup.button.import-contacts"
                          defaultMessage="Import Contacts"
                        />
                      </IonLabel>
                    </IonButton>
                  </p>
                  <p>
                    <FormattedMessage
                      id="modal.setup.contacts.later"
                      defaultMessage="You can also import the contacts later from the Settings tab."
                    />
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="welcome-modal--slide">
                  <p className="welcome-modal--lead">
                    <b>
                      <i>
                        <FormattedMessage
                          id="modal.setup.reminders.lead"
                          defaultMessage="Never miss the deadline:"
                        />
                      </i>
                    </b>
                  </p>
                  <h1>
                    <FormattedMessage id="modal.setup.reminders.title" defaultMessage="Reminders" />
                  </h1>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <IonIcon
                      icon={notificationsOutline}
                      style={{
                        fontSize: '4rem',
                        flex: '0 0 4rem',
                        margin: '1rem 0.5rem 0 0',
                        transform: 'rotate(-10deg)',
                      }}
                    />
                    <p style={{ textAlign: 'start' }}>
                      <FormattedMessage
                        id="modal.setup.reminders.offer"
                        defaultMessage="Papers can have all kinds of dates: issue date, due date, expiry date, etc. For all the dates in the future, you can get reminder notifications."
                      />
                    </p>
                  </div>
                  <p>
                    <IonButton
                      onClick={showRemindersModal}
                      mode="md"
                      shape="round"
                      fill="solid"
                      style={{ marginBottom: '0.2rem' }}
                    >
                      <IonLabel>
                        <FormattedMessage
                          id="modal.setup.button.turn-on-notifications"
                          defaultMessage="Turn on Notifications"
                        />
                      </IonLabel>
                    </IonButton>
                  </p>
                  <p>
                    <FormattedMessage
                      id="modal.setup.reminders.repeating"
                      defaultMessage="And for contacts' birthdays or anniversaries, the reminders will repeat each year."
                    />
                  </p>
                </div>
              </SwiperSlide>
            </>
          )}
          {!profile?.isAnonymous && profile?.publicKey && !isPlatform('capacitor') && (
            <SwiperSlide>
              <div className="welcome-modal--slide">
                <p style={{ margin: '2rem' }}>
                  <FormattedMessage
                    id="modal.setup.finished"
                    defaultMessage="The app seems to be set up properly!"
                  />
                </p>
                <p>
                  <IonButton
                    onClick={toThePapers}
                    mode="md"
                    shape="round"
                    fill="solid"
                    style={{ marginBottom: '0.2rem' }}
                  >
                    <IonLabel>
                      <FormattedMessage
                        id="ui.buttons.goto.papers"
                        defaultMessage="To the Papers"
                      />
                    </IonLabel>
                  </IonButton>
                </p>
              </div>
            </SwiperSlide>
          )}
        </Swiper>
      </IonContent>
    </IonModal>
  );
};

export default WelcomeModal;
