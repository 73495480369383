// hooks
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import useResizeObserver from '@react-hook/resize-observer';
import { shallowEqual, useSelector } from 'react-redux';

// utils
import { Clipboard } from '@capacitor/clipboard';
import {
  getAmountFromString,
  getContactFromString,
  getCurrencyFromString,
  getDateFromString,
} from '../utilities/text-analysis';

// components
import {
  IonAvatar,
  IonIcon,
  IonImg,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  useIonPopover,
  useIonToast,
} from '@ionic/react';
import PinchZoom from './PinchZoom';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';

// icons and types
import { businessOutline, copyOutline, createOutline, personOutline } from 'ionicons/icons';
import { OCRResult, OCRResultBbox, OCRResultLine } from '../models/OCR';
import { TFormField, TFormFields } from '../models/Form';
import { TStoreState } from '../store/store';
import { getDateValue, getValue } from '../utilities/values';
import { ContactsState } from '../models/Contact';

type TPaperPreviewProps = {
  url: string;
  showStrings: boolean;
  ocr: OCRResult;
  fields: TFormFields[];
  updateField: (field: TFormField, value: any, add?: boolean) => void;
  onZoomChange?: (zoom: number) => void;
};

const PaperPreview = ({
  url,
  showStrings,
  ocr,
  fields,
  updateField,
  onZoomChange,
}: TPaperPreviewProps) => {
  const intl = useIntl();
  const [presentToast] = useIonToast();

  const [fullWidth, setFullWidth] = useState(0);
  const [fullHeight, setFullHeight] = useState(0);
  const containerRef = useRef<HTMLImageElement>(null);
  const [size, setSize] = useState<{ width?: number; height?: number }>({});

  const uid = useSelector((state: TStoreState) => state.firebase.auth.uid);
  const lastUsedCurrency = useSelector(
    (state: TStoreState) => state.firebase.profile?.lastUsedCurrency || 'EUR',
  );
  const dateFormat = useSelector(
    (state: TStoreState) => state.firebase.profile?.dateFormat || 'dd-mm-yyyy',
  );
  const currencies = useSelector((state: TStoreState) => state.ui.currencies, shallowEqual);

  const contacts = useSelector(
    (state: TStoreState) => state.data?.contacts,
    shallowEqual,
  ) as ContactsState;

  useLayoutEffect(() => {
    if (containerRef.current) {
      setSize(containerRef.current.getBoundingClientRect());
    }
  }, [containerRef, fullWidth]);

  // Where the magic happens
  useResizeObserver(containerRef, (entry: any) => setSize(entry.contentRect));

  const getStringStyles = (bbox: OCRResultBbox) => {
    const k = (size?.width || 0) / fullWidth;
    const stringWidth = (bbox.x1 - bbox.x0 + 4) * k;
    const stringHeight = (bbox.y1 - bbox.y0 + 4) * k;
    const offsetX = (-2 - fullWidth / 2 + bbox.x0) * k;
    const offsetY = (-2 - fullHeight / 2 + bbox.y0) * k;
    return {
      width: `${stringWidth}px`,
      height: `${stringHeight}px`,
      transform: `translate(${offsetX}px, ${offsetY}px)`,
    };
  };

  // line splitter
  const [lines, setLines] = useState<OCRResultLine[]>([]);
  useEffect(() => {
    if ((ocr?.lines?.length || 0) > 0) {
      const lines = ocr.lines.reduce((acc: OCRResultLine[], line: OCRResultLine) => {
        const lines: OCRResultLine[] = [];
        let currentLine: OCRResultLine = {
          words: [],
          text: line.words?.[0]?.text || line.text,
          bbox: line.words?.[0]?.bbox || line.bbox,
          confidence: line.confidence,
          baseline: line.baseline,
        };
        if ((line.words?.length || 0) > 1) {
          currentLine.words.push(line.words[0]);
          for (let i = 1; i < line.words.length; i++) {
            const currentWord = line.words[i];
            // check how far the current word is from the previous one
            if (currentWord.bbox.x0 - currentLine.bbox.x1 < 20) {
              // if the word is close enough, add it to the current line
              currentLine.words.push(currentWord);
              currentLine.text += ` ${currentWord.text}`;
              currentLine.bbox.x1 = currentWord.bbox.x1;
              currentLine.bbox.y0 = Math.min(currentLine.bbox.y0, currentWord.bbox.y0);
              currentLine.bbox.y1 = Math.max(currentLine.bbox.y1, currentWord.bbox.y1);
            } else {
              // othrwise create a new line
              lines.push(currentLine);
              currentLine = {
                words: [currentWord],
                text: currentWord.text,
                bbox: currentWord.bbox,
                confidence: line.confidence,
                baseline: line.baseline,
              };
            }
          }
        }
        lines.push(currentLine);

        return [...acc, ...lines];
      }, []);

      setLines(lines);
    }
  }, [ocr]);

  const copyToClipboard = async (value: any, type?: boolean | string) => {
    await Clipboard.write({
      string: type === 'no-spaces' ? value.replace(/\s/gi, '') : value,
    });
    presentToast({
      message: intl.formatMessage({ id: 'ui.toast.copied' }),
      duration: 2000,
      position: 'top',
      color: 'medium',
    });
  };

  /**
   * Popover when clicking on the recognized string
   */
  const popoverString = useRef<string>('');
  const [prersentRecognizedPopover, closeRecognizedPopover] = useIonPopover(() => {
    // analyze the string before showing the popover
    let fields2show: TFormField[];
    let currency = 'EUR';
    let amount = 0;
    let contact: any = null;
    let avatar: string | undefined = undefined;

    // check for dates
    const date = getDateFromString(popoverString.current, dateFormat);
    if (date) {
      fields2show = fields.filter(field => field.type === 'date') as TFormField[];
    } else {
      // check for numbers
      amount = getAmountFromString(popoverString.current);
      if (amount > 0) {
        currency = getCurrencyFromString(
          popoverString.current,
          lastUsedCurrency,
          Object.keys(currencies || {}),
        );
        fields2show = fields.filter(field => field.type === 'total') as TFormField[];
      } else {
        // check for contacts
        contact = getContactFromString(popoverString.current, contacts.items);
        if (contact) {
          avatar = (
            contacts.items[contact]?.avatar?.[0]?.url || contacts.items[contact]?.avatars?.[0]?.url
          )?.replace('{uid}', uid);
          fields2show = fields.filter(field => field.type === 'contact') as TFormField[];
        } else {
          fields2show = fields.filter(field => field.type === 'text') as TFormField[];
        }
      }
    }

    return (
      <IonList>
        <IonItem>
          <IonLabel className="preview--recognized">{popoverString.current || ''}</IonLabel>
        </IonItem>
        <IonItem
          button
          onClick={() => {
            copyToClipboard(popoverString.current);
            closeRecognizedPopover();
          }}
          detail={false}
          lines="none"
        >
          <IonIcon icon={copyOutline} slot="start" />
          <FormattedMessage id="ui.buttons.copy-to-clipboard" defaultMessage="Copy to Clipboard" />
        </IonItem>
        {fields2show.length > 0 && (
          <>
            {date ? (
              <IonItem>
                <IonLabel className="preview--recognized">
                  <FormattedMessage
                    id="paper.ocr.popover.date"
                    defaultMessage="Set { date } to"
                    values={{
                      date: getDateValue(
                        date.toISOString(),
                        undefined,
                        undefined,
                        intl.locale,
                        dateFormat,
                      ),
                    }}
                  />
                </IonLabel>
              </IonItem>
            ) : amount > 0 ? (
              <IonItem>
                <IonLabel className="preview--recognized">
                  <FormattedMessage
                    id="paper.ocr.popover.amount"
                    defaultMessage="Use { amount } as"
                    values={{
                      amount: (
                        <FormattedNumber value={amount} currency={currency} style="currency" />
                      ),
                    }}
                  />
                </IonLabel>
              </IonItem>
            ) : contact ? (
              <IonItem>
                <IonAvatar slot="start" className="list-item__avatar" style={{ width: '28px' }}>
                  {avatar ? (
                    <IonImg src={avatar} style={{ objectFit: 'contain' }} />
                  ) : (
                    <IonIcon
                      icon={getValue(contact.business) ? businessOutline : personOutline}
                      color="medium"
                      style={{ fontSize: '24px' }}
                    />
                  )}
                </IonAvatar>
                <IonLabel className="preview--recognized">
                  {getValue(contacts.items[contact].displayName) ||
                    getValue(
                      contacts.items[contact].names,
                      intl.formatMessage({ id: 'contact.untitled' }),
                    )}
                  {'...'}
                </IonLabel>
              </IonItem>
            ) : (
              <IonItemDivider mode="md">
                <IonLabel>
                  <FormattedMessage id="paper.ocr.popover.use-as" defaultMessage="Use it as" />
                </IonLabel>
              </IonItemDivider>
            )}
            {fields2show.map((field, idx) => (
              <IonItem
                key={`field${idx}`}
                button
                onClick={() => {
                  if (date) {
                    updateField(field, { value: date.toISOString().substring(0, 10) }, true);
                  } else if (amount > 0) {
                    updateField(field, { value: amount, currency });
                  } else if (contact) {
                    updateField(field, { value: contact }, true);
                  } else {
                    updateField(field, { value: popoverString.current }, true);
                  }
                  closeRecognizedPopover();
                }}
                detail={false}
                lines="none"
              >
                <IonIcon icon={createOutline} slot="start" />
                <IonLabel>
                  {field.label || (field.labelId && <FormattedMessage id={field.labelId} />)}
                </IonLabel>
              </IonItem>
            ))}
          </>
        )}
      </IonList>
    );
  });

  return (
    <div className="preview--container">
      <PinchZoom onZoomChange={onZoomChange}>
        <div className="preview--image-container">
          <img
            ref={containerRef}
            className="preview--image"
            src={url}
            onLoad={evt => {
              let targetImage: any = evt.target;
              if (targetImage) {
                setFullWidth(targetImage.naturalWidth);
                setFullHeight(targetImage.naturalHeight);
              }
            }}
          />
          {showStrings &&
            (lines.length || 0) > 0 &&
            lines.map((line, idx) => (
              <div
                key={`line${idx}`}
                className="preview--string"
                style={getStringStyles(line.bbox)}
                onClick={evt => {
                  popoverString.current = line.text;
                  prersentRecognizedPopover({
                    event: evt.nativeEvent,
                    arrow: false,
                  });
                }}
              ></div>
            ))}
        </div>
      </PinchZoom>
    </div>
  );
};

export default PaperPreview;
