import { useSelector, shallowEqual } from 'react-redux';
import { useRequestStateFor } from './useRequestStateFor';
import { RequestState } from '../models/RequestState';
import { useEffect, useMemo, useState } from 'react';

type RequestedObjectCollection = {
  items: { [id: string]: any };
  state: RequestState;
};

export function useObjectCollection(collection: string): RequestedObjectCollection {
  const requestState = useRequestStateFor(collection);
  const items = useSelector((state: any) => {
    return state.firestore.data[collection];
  }, shallowEqual);

  // memoize state
  const state = useMemo(
    () => ({
      ...requestState,
      isNotFound: requestState.isLoaded && !items,
      isEmpty: requestState.isLoaded && (!items || !Object.keys(items).length),
    }),
    [requestState, items],
  );

  // memoize response
  const [objectCollection, setObjectCollection] = useState<RequestedObjectCollection>({
    items: [],
    state,
  });

  useEffect(() => {
    setObjectCollection({
      items,
      state,
    });
  }, [items, state]);

  return objectCollection;
}
