import { Category } from '../models/Category';

export type TPapersPeriodsShow = 'all' | 'yearly' | 'monthly';
type TPapersPeriodsField =
  | 'createdAt'
  | 'issuedOn'
  | 'servicePeriod'
  | 'expiresOn'
  | 'payUntil'
  | 'paidOn';

export type TPapersPeriods = {
  show: TPapersPeriodsShow;
  field: TPapersPeriodsField;
  selected: number;
};

export type TContactsShow = 'all' | 'favorites';

export type TSettingsStore = {
  papers: {
    search: string;
    linkSearch?: string;
    grouping: string;
    groupsState: { [groupId: string]: boolean };
    periods: TPapersPeriods;
  };
  contacts: {
    search: string;
    linkSearch?: string;
    show: TContactsShow;
  };
  stacks: {
    search: string;
    linkSearch?: string;
    groupsState: { [groupId: string]: boolean };
  };
  categories: Category[];
  hiddenTips: { [tip: string]: boolean };
};

export const settingsInitialState: TSettingsStore = {
  papers: {
    search: '',
    grouping: 'none',
    groupsState: {},
    periods: {
      show: 'all' as TPapersPeriodsShow,
      field: 'createdAt' as TPapersPeriodsField,
      selected: 0,
    },
  },
  contacts: {
    search: '',
    show: 'all' as TContactsShow,
  },
  stacks: {
    search: '',
    groupsState: {},
  },
  categories: [
    {
      labelId: 'category.medical',
      system: true,
      value: 'medical',
    },
    {
      labelId: 'category.household',
      system: true,
      value: 'household',
    },
    {
      labelId: 'category.education',
      system: true,
      value: 'education',
    },
    {
      labelId: 'category.sport',
      system: true,
      value: 'sport',
    },
    {
      labelId: 'category.trip',
      system: true,
      value: 'trip',
    },
    {
      labelId: 'category.vehicle',
      system: true,
      value: 'vehicle',
    },
    {
      labelId: 'category.communication',
      system: true,
      value: 'communication',
    },
    {
      labelId: 'category.taxes',
      system: true,
      value: 'taxes',
    },
    {
      labelId: 'category.food',
      system: true,
      value: 'food',
    },
    {
      labelId: 'category.work',
      system: true,
      value: 'work',
    },
    {
      labelId: 'category.other',
      system: true,
      value: 'other',
    },
  ],
  hiddenTips: {},
};

export const settingsReducer = (state = {} as TSettingsStore, action: any) => {
  switch (action.type) {
    // update the whole store when the collection is loaded/updated
    case 'UPDATE_SETTINGS':
      const updatedSettings = { ...action.payload };
      if (updatedSettings.categories) {
        updatedSettings.categories = updatedSettings.categories.categories;
      }
      return {
        ...state,
        ...updatedSettings,
      };

    case 'SET_PAPERS_SEARCH':
      return {
        ...state,
        papers: {
          ...state.papers,
          search: action.payload,
        },
      };
    case 'SET_PAPERS_LINK_SEARCH':
      return {
        ...state,
        papers: {
          ...state.papers,
          linkSearch: action.payload,
        },
      };
    case 'SET_PAPERS_GROUPING':
      return {
        ...state,
        papers: {
          ...state.papers,
          grouping: action.payload,
        },
      };
    case 'CHANGE_PAPERS_GROUPS_STATE':
      return {
        ...state,
        papers: {
          ...state.papers,
          groupsState: {
            ...state.papers.groupsState,
            [action.payload]: !state.papers.groupsState[action.payload],
          },
        },
      };
    case 'SET_PAPERS_PERIODS_SHOW':
      return {
        ...state,
        papers: {
          ...state.papers,
          periods: {
            ...state.papers.periods,
            show: action.payload as 'all' | 'yearly' | 'monthly',
          },
        },
      };

    case 'SET_CATEGORIES':
      return {
        ...state,
        categories: action.payload,
      };
    case 'ADD_CATEGORY':
      return {
        ...state,
        categories: [action.payload, ...state.categories],
      };
    case 'REMOVE_CATEGORY':
      return {
        ...state,
        categories: state.categories.filter(category => category.value !== action.payload),
      };
    case 'UPDATE_CATEGORY':
      return {
        ...state,
        categories: state.categories.map(category => {
          if (category.value === action.payload.value) {
            return action.payload;
          }
          return category;
        }),
      };

    default:
      return state;
  }
};
