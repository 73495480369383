// hooks
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';

// components
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonModal,
  IonTitle,
  IonToolbar,
} from '@ionic/react';

// icons
import { checkmarkSharp, closeOutline } from 'ionicons/icons';

// types
import { TStoreState } from '../store/store';
const themes = ['modern', 'classic'];
const darkModeSettings = ['auto', 'on', 'off'];

type TThemeModalProps = {
  isOpen: boolean;
  onDidDismiss: () => void;
};

const ThemeModal: React.FC<TThemeModalProps> = (props: TThemeModalProps) => {
  const routerRef = useSelector((state: any) => state.ui?.routerRef);
  const dispatch = useDispatch();
  const firebase = useFirebase();

  const device = useSelector((state: TStoreState) => state.device);
  const devices = useSelector((state: TStoreState) => state.firebase?.profile?.devices);

  const updateDeviceSettings = async (newDeviceInfo: { [prop: string]: any }) => {
    if (device?.deviceId) {
      const devicesInfo = {
        ...(devices || {}),
        [device.deviceId]: {
          ...(devices?.[device.deviceId] || {}),
          ...newDeviceInfo,
        },
      };
      await firebase.updateProfile({ devices: devicesInfo });
    }
  };

  const changeTheme = (theme: string) => {
    dispatch({ type: 'SET_THEME', payload: theme });
    localStorage.setItem('theme', theme);
    if (devices) {
      updateDeviceSettings({ theme });
    }
  };

  const changeDarkModeSettings = (darkMode: string) => {
    dispatch({ type: 'SET_DARK_MODE', payload: darkMode });
    localStorage.setItem('darkMode', darkMode);
    if (device) {
      if (devices) {
        updateDeviceSettings({ darkMode });
      }
    }
  };

  return (
    <IonModal
      mode="md"
      isOpen={props.isOpen}
      onDidDismiss={props.onDidDismiss}
      presentingElement={routerRef}
    >
      <IonHeader mode="md">
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={props.onDidDismiss}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>
            <FormattedMessage
              id="modal.themes.title"
              defaultMessage="Change the look and feel of the app"
            />
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonList>
          <IonItemDivider>
            <IonLabel className="item--long-description">
              <p>
                <FormattedMessage id="modal.themes.theme.title" defaultMessage="Select theme" />
              </p>
            </IonLabel>
          </IonItemDivider>
          {themes.map(theme => (
            <IonItem key={theme} onClick={() => changeTheme(theme)} button detail={false}>
              <IonLabel>
                <FormattedMessage id={`modal.themes.theme.${theme}`} defaultMessage={theme} />
              </IonLabel>
              {device.theme === theme && (
                <IonIcon icon={checkmarkSharp} slot="end" color="primary" />
              )}
            </IonItem>
          ))}
          <IonItemDivider>
            <IonLabel className="item--long-description">
              <p>
                <FormattedMessage
                  id="modal.themes.dark-mode.title"
                  defaultMessage="Select when to apply dark mode"
                />
              </p>
            </IonLabel>
          </IonItemDivider>
          {darkModeSettings.map(setting => (
            <IonItem
              key={setting}
              onClick={() => changeDarkModeSettings(setting)}
              button
              detail={false}
            >
              <IonLabel>
                <FormattedMessage
                  id={`modal.themes.dark-mode.${setting}`}
                  defaultMessage={setting}
                />
              </IonLabel>
              {device.darkMode === setting && (
                <IonIcon icon={checkmarkSharp} slot="end" color="primary" />
              )}
            </IonItem>
          ))}
        </IonList>
      </IonContent>
    </IonModal>
  );
};

export default ThemeModal;
