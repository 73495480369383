// hooks
import { useIntl } from 'react-intl';

// components
import {
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonTitle,
  useIonPopover,
  IonList,
  IonItem,
} from '@ionic/react';

// icons
import { arrowBack, arrowForward } from 'ionicons/icons';

import './PeriodsToolbar.scss';

type TPeriodsToolbarProps = {
  selectedPeriod: number;
  periodField: string;
  availablePeriods: string[];
  periods: string;
  onUpdate: Function;
};

const PeriodsToolbar = ({
  selectedPeriod,
  periodField,
  availablePeriods,
  periods,
  onUpdate,
}: TPeriodsToolbarProps) => {
  const intl = useIntl();

  const prevPeriod = () => {
    if (availablePeriods[selectedPeriod + 1]) {
      onUpdate(selectedPeriod + 1);
    }
  };

  const nextPeriod = () => {
    if (periods !== 'all' && selectedPeriod > 0) {
      onUpdate(selectedPeriod - 1);
    }
  };

  const getPeriodsPickerColumns = () => {
    return [
      {
        name: 'period',
        options: availablePeriods.map((period: string, idx: number) => ({
          text: renderPeriod(period, periodField),
          value: idx,
        })),
        selectedIndex: selectedPeriod,
      },
    ];
  };

  const renderPeriod = (period: string, periodField: string) => {
    if (period === 'other') {
      return intl.formatMessage({ id: 'period.other.' + periodField });
    } else if (period && period.length > 4) {
      return intl.formatDate(period, { year: 'numeric', month: 'long' });
    } else {
      return period;
    }
  };

  const onPeriodPickerChange = (newValue: any) => {
    if (typeof newValue === 'number') {
      onUpdate(newValue);
    } else {
      onUpdate(newValue.period.value);
    }
  };

  /**
   * periods popover
   */
  const [presentPopover, closePopover] = useIonPopover(
    () => {
      return (
        <IonList>
          {getPeriodsPickerColumns()?.[0].options?.map((column: any, idx, arr) => (
            <IonItem
              key={column.value}
              button
              onClick={() => {
                onPeriodPickerChange(column.value);
                closePopover();
              }}
              lines={idx === arr.length - 1 ? 'none' : 'full'}
              className={selectedPeriod === column.value ? 'selected-period' : ''}
              detail={false}
            >
              {column.text}
            </IonItem>
          ))}
        </IonList>
      );
    },
    {
      side: 'bottom',
      alignment: 'center',
      arrow: false,
    },
  );

  if (periods === 'all') {
    return null;
  } else {
    return (
      <IonToolbar className="period-toolbar">
        {/* <IonPicker
          isOpen={isPeriodPickerOpen}
          columns={getPeriodsPickerColumns()}
          onDidDismiss={() => setPeriodPickerOpen(false)}
          buttons={[
            {
              text: intl.formatMessage({
                id: 'ui.buttons.cancel',
                defaultMessage: 'Cancel',
                description: 'UI Buttons: Cancel',
              }),
              role: 'cancel',
            },
            {
              text: intl.formatMessage({
                id: 'ui.buttons.show',
                defaultMessage: 'Show',
                description: 'UI Buttons: Show',
              }),
              handler: value => {
                onPeriodPickerChange(value);
              },
            },
          ]}
        /> */}
        <IonButtons slot="start">
          <IonButton
            shape="round"
            onClick={prevPeriod}
            disabled={availablePeriods.length <= selectedPeriod + 1}
            color="dark"
          >
            <IonIcon icon={arrowBack} slot="icon-only" />
          </IonButton>
        </IonButtons>
        <IonTitle className="period-toolbar--title">
          <IonButton
            fill="clear"
            color="dark"
            className="filters-toolbar--button"
            onClick={evt => {
              // setPeriodPickerOpen(true)
              presentPopover({
                event: evt.nativeEvent,
                arrow: false,
                side: 'bottom',
                alignment: 'center',
              } as any);
            }}
          >
            {renderPeriod(availablePeriods[selectedPeriod], periodField)}
          </IonButton>
        </IonTitle>
        <IonButtons slot="end">
          <IonButton shape="round" onClick={nextPeriod} disabled={selectedPeriod <= 0} color="dark">
            <IonIcon icon={arrowForward} slot="icon-only" />
          </IonButton>
        </IonButtons>
      </IonToolbar>
    );
  }
};

export default PeriodsToolbar;
