// hooks
import { IonIcon, isPlatform, useIonToast } from '@ionic/react';
import { glassesOutline } from 'ionicons/icons';
import { useEffect, useRef, useState } from 'react';
import { useCustomEventListener } from 'react-custom-events';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useFirebase, useFirestore } from 'react-redux-firebase';

// utils
import { createWorker } from 'tesseract.js';
import { updateFirestoreDocument } from '../utilities/firestore';
import { extractLines } from '../utilities/ocr';
import { blobToBase64, processData } from '../utilities/forms';
import { str2key } from '../utilities/encryption';
import { CapacitorOCR } from 'capacitor-cyrillic-ocr';

// types
import { OCRQueueItem, OCRResult, OCRResults } from '../models/OCR';
import { TStoreState } from '../store/store';
import { locales } from './Intl';
import { makeRequest } from '../utilities/files';
import { useFirestoreCollectionQuery } from '../hooks/useFirestoreCollectionQuery';

const OCR = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const firestore = useFirestore();
  const firebase = useFirebase();

  const user = useSelector((state: TStoreState) => state.firebase?.auth);
  // encryption
  const profile = useSelector((state: TStoreState) => state.firebase.profile);
  const publicKey = useRef<CryptoKey | null>(null);
  useEffect(() => {
    if (profile?.publicKey) {
      str2key(profile.publicKey as string).then(key => {
        publicKey.current = key;
      });
    }
  }, [profile]);
  const deviceId = useSelector((state: TStoreState) => state.device?.deviceId, shallowEqual);

  // scripts to use
  const ocrScripts = useSelector((state: TStoreState) => state.ui.ocrScripts);
  const langScript = locales.find(l => l.id === intl.locale)?.script;

  const [presentToast] = useIonToast();

  const isActive = useSelector((state: TStoreState) => state.data.recognition.active);

  const papers = useSelector((state: TStoreState) => state.data.papers.items);

  /**
   * Keep pointer to the worker in a ref
   */
  const worker = useRef<Tesseract.Worker>();

  /**
   * Create worker on mount
   * and destroy it on unmount
   */
  useEffect(() => {
    if (!isPlatform('capacitor') || (!isPlatform('ios') && !isPlatform('android'))) {
      initWorker();
      return () => {
        killWorker();
      };
    }
  }, []);

  /**
   * Initialize worker
   */
  const initWorker = async () => {
    worker.current = await createWorker({
      errorHandler: err => console.error(err),
    });
    // Params are not working :-((
    //
    // worker.current.setParameters({
    //   preserve_interword_spaces: '1',
    //   tessjs_create_hocr: '0',
    //   tessjs_create_tsv: '0',
    // });
  };

  /**
   * Destroy the worker (shouldn't happen)
   */
  const killWorker = async () => {
    await worker.current?.terminate();
  };

  /**
   * Queue
   */
  const queue = useSelector((state: TStoreState) => state.data.recognition.queue) as OCRQueueItem[];
  const [firestoreQueueUpdate, setFirestoreQueueUpdate] = useState({});

  // update queue using a custom event
  useCustomEventListener('read-paper', (newItems: OCRQueueItem[]) => {
    dispatch({ type: 'ADD_TO_OCR_QUEUE', payload: newItems });
  });

  // subsribe to a queue collecttion in the Firestore
  const firestoreQueue = useFirestoreCollectionQuery(`users/${user.uid}/ocr-queue`, false, {
    storeAs: 'ocrQueue',
  }) as { items: { [key: string]: OCRQueueItem } };
  useEffect(() => {
    if (
      profile.autoOcr !== false &&
      firestoreQueue.items &&
      Object.keys(firestoreQueue.items).length > 0
    ) {
      // looking for items that are having current deviceId
      const itemsWithCurrentDeviceId = Object.values(firestoreQueue.items).filter(
        item => item?.deviceId === deviceId,
      );
      if (itemsWithCurrentDeviceId.length > 0) {
        // if there are items with the same device id -- add them to the queue
        itemsWithCurrentDeviceId.forEach(item => {
          addPreviewToQueue(item);
        });
      } else {
        // if there are no items with the same device id
        // try to set the device id to the first item without device id
        const firstItemWithoutDeviceId = Object.keys(firestoreQueue.items).filter(
          key => firestoreQueue.items[key] && !firestoreQueue.items[key].deviceId,
        )[0];

        // crerate transaction to set the divice id to the item without device id
        setDeviceIdToFirestoreQueueItem(firstItemWithoutDeviceId);
      }
    }
  }, [profile.autoOcr, firestoreQueue.items, firestoreQueueUpdate]);

  // get the preview file url and add it to the queue
  const addPreviewToQueue = async (queueItem: OCRQueueItem) => {
    const storageRef = await firebase
      .storage()
      .refFromURL(queueItem.url.replace('{uid}', user.uid));
    const url = await storageRef.getDownloadURL();
    dispatch({ type: 'ADD_TO_OCR_QUEUE', payload: [{ ...queueItem, url }] });
  };

  // try to the divice id to the item without device id
  const setDeviceIdToFirestoreQueueItem = async (id: string) => {
    firestore.runTransaction(async transaction => {
      const doc = await transaction.get(firestore.doc(`users/${user.uid}/ocr-queue/${id}`));
      if (doc.exists && !doc.data()?.deviceId) {
        transaction.update(firestore.doc(`users/${user.uid}/ocr-queue/${id}`), {
          deviceId,
        });
      }
    });
  };

  // process images from the queue
  useEffect(() => {
    if (queue.length > 0) {
      extractText(queue[0]);
    }
  }, [queue]);

  const getLanguages = (langScript?: string, ocrScripts?: { [key: string]: number }) => {
    let languages = [];
    if (langScript) {
      languages.push(langScript);
    }
    languages.push(...Object.keys(ocrScripts || {}).filter(script => script !== langScript));
    return languages;
  };

  const extractText = async (queueItem: OCRQueueItem) => {
    // start the blinking of the icon in the corner
    dispatch({
      type: 'SET_OCR_ACTIVE',
      payload: {
        active: true,
        ...queueItem,
      },
    });

    if (isPlatform('capacitor') && (isPlatform('ios') || isPlatform('android'))) {
      // in case it's a device with a OCR plugin
      const file = await makeRequest({ url: queueItem.url, method: 'GET' });
      const base64Data = await blobToBase64(file);

      let languages = getLanguages(langScript, ocrScripts);

      await CapacitorOCR.recognize({
        base64Image: base64Data.indexOf(',') > 0 ? base64Data.split(',')[1] : base64Data,
        orientation: 'up',
        languages,
      }).then(data => {
        // update the document
        setPaperUpdate(val => [...val, { item: queueItem, result: data as unknown as OCRResult }]);
      });
    } else if (worker.current) {
      // it case it's the browser
      await worker.current.load();
      let scripts = getLanguages(langScript, ocrScripts).join('+');

      await worker.current.loadLanguage(scripts);
      await worker.current.initialize(scripts);
      const { data } = await worker.current.recognize(queueItem.url);

      // update the document
      setPaperUpdate(val => [...val, { item: queueItem, result: extractLines(data) }]);
    } else {
      presentToast({
        message: intl.formatMessage({
          id: 'ui.toast.ocr-not-available',
          defaultMessage: 'Recognition is not available!',
        }),
        duration: 2000,
        position: 'top',
        color: 'warning',
      });
    }
    // stop the blinking of the icon in the corner
    dispatch({
      type: 'SET_OCR_ACTIVE',
      payload: {
        active: false,
        ...queueItem,
      },
    });

    // remove the item from the firestore queue collection
    if (firestoreQueue.items && Object.keys(firestoreQueue.items).length > 0) {
      const item = Object.keys(firestoreQueue.items).filter(
        key =>
          firestoreQueue.items[key] &&
          firestoreQueue.items[key].pid === queueItem.pid &&
          firestoreQueue.items[key].pageFileName === queueItem.pageFileName,
      )[0];

      if (item) {
        firestore.doc(`users/${user.uid}/ocr-queue/${item}`).delete();
      }
    }
    setFirestoreQueueUpdate({});

    // move to the next document
    dispatch({ type: 'REMOVE_FROM_OCR_QUEUE', payload: queueItem });
  };

  /**
   * Save extracted text to the papers
   */
  const [paperUpdate, setPaperUpdate] = useState<{ item: OCRQueueItem; result: OCRResult }[]>([]);
  const paperCache = useRef<{ [paperId: string]: { [page: string]: OCRResult } }>({});

  useEffect(() => {
    if (paperUpdate.length > 0) {
      const update = paperUpdate[0];
      const paper = papers[update.item.pid];

      // cache the data in case the document is not updated yet
      paperCache.current[update.item.pid] = {
        ...(paperCache.current[update.item.pid] || {}),
        [update.item.pageFileName || update.item.pageNo!]: update.result,
      };

      if (paper) {
        const currentOcr =
          (paper.ocr?.value as string)?.indexOf('•') >= 0
            ? {}
            : (JSON.parse((paper.ocr?.value as string) || '{}') as OCRResults);
        const ocr = { ...currentOcr };

        if (paperCache.current[update.item.pid]) {
          const cached = paperCache.current[update.item.pid];
          Object.keys(cached).forEach(page => {
            ocr[page] = cached[page];
          });
        }

        updatePaper(ocr, paper.id);
      }
      setPaperUpdate(val => val.slice(1));
    }
  }, [paperUpdate]);

  const updatePaper = async (ocr: OCRResults, paperId: string) => {
    const update = {
      ocr: {
        value: JSON.stringify(ocr),
        unencrypt: 'app',
        encrypt: true,
      },
    };
    const [docData] = await processData(update, {}, publicKey.current || undefined);

    updateFirestoreDocument(user, firestore, `papers/${paperId}`, docData);
  };

  return (
    <IonIcon icon={glassesOutline} className={isActive ? 'ocr ocr-working' : 'ocr ocr-stopped'} />
  );
};

export default OCR;
