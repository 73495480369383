// hooks
import { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import { FormattedMessage, useIntl } from 'react-intl';

// plugins
import { Share } from '@capacitor/share';

// components
import {
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonTitle,
  IonList,
  useIonPopover,
  IonItem,
  useIonAlert,
  useIonLoading,
} from '@ionic/react';
import LinkStackModal from '../forms/LinkStackModal';
import UpdpateCategoriesModal from '../forms/UpdateCategoriesModal';

// utilities
import { deletePapers, updatePaper } from '../utilities/papers';
import { addPapersToStack } from '../utilities/stacks';
import { extractDownloadUrlsFromPapers } from '../utilities/files';
import { getValue } from '../utilities/values';

// icons
import {
  close,
  shareOutline,
  pricetagsOutline,
  ellipsisVertical,
  ellipsisHorizontal,
  removeCircleOutline,
  folderOpenOutline,
} from 'ionicons/icons';

// types
import { Paper } from '../models/Paper';
import { TStoreState } from '../store/store';

// styles
import './PapersListSelectionToolbar.scss';

type PapersListSelectionToolbarProps = {
  selectedPapers?: { [paperId: string]: Paper };
  onDeselect: () => void;
};

const PapersListSelectionToolbar = ({
  selectedPapers,
  onDeselect,
}: PapersListSelectionToolbarProps) => {
  const intl = useIntl();
  const firebase = useFirebase();
  const firestore = useFirestore();
  const dispatch = useDispatch();

  const user = useSelector((state: TStoreState) => state.firebase.auth);
  const storedUrls = useSelector((state: TStoreState) => state.data.urls, shallowEqual);

  const num = useMemo(() => Object.keys(selectedPapers || {}).length, [selectedPapers]);

  const [presentAlert] = useIonAlert();
  const [presentLoading, dismissLoading] = useIonLoading();

  /**
   * Share papers' files
   */
  const [sharePermissions, setSharePermissions] = useState<boolean>(false);
  useEffect(() => {
    Share.canShare()
      .then(canShare => {
        setSharePermissions(canShare?.value ?? false);
      })
      .catch(err => {
        console.error('Checking sharing abilities', err);
      });
  }, []);

  const shareFiles = async () => {
    presentLoading({
      message: intl.formatMessage({
        id: 'page.papers.preparing-files-to-share',
        defaultMessage: 'Preparing Files to Share...',
      }),
    });

    // collect file paths
    const urls = await extractDownloadUrlsFromPapers(
      selectedPapers || {},
      storedUrls,
      user.uid,
      firebase,
      dispatch,
    );

    console.log('uris', urls);

    // download files

    // save files to temp directory

    // share files

    await dismissLoading();
  };

  /**
   * Change categories
   */
  const [isCategoriesModalOpen, setIsCategoriesModalOpen] = useState(false);
  const categories = useSelector((state: any) => state.settings.categories || []);

  const getSelectedCategories = (selectedPapers?: { [paperId: string]: Paper }) => {
    if (selectedPapers) {
      const selectedCategories: { [catId: string]: boolean | 'mix' } = {};
      categories.forEach((cat: any) => {
        const catId = cat.value;
        const catPapers = Object.values(selectedPapers).filter(paper => {
          const paperCats = (getValue(paper.category) as string).split(', ');
          return paperCats.includes(catId);
        });
        if (catPapers.length === 0) {
          selectedCategories[catId] = false;
        } else if (catPapers.length === Object.keys(selectedPapers).length) {
          selectedCategories[catId] = true;
        } else {
          selectedCategories[catId] = 'mix';
        }
      });
      return selectedCategories;
    }

    return {};
  };

  const updateCategoriesToSelectedPapers = async (selectedCategories: {
    [catId: string]: boolean;
  }) => {
    console.log('updateCategoriesToSelectedPapers', selectedCategories, selectedPapers);
    if (selectedPapers && Object.keys(selectedPapers).length > 0) {
      Object.keys(selectedPapers).forEach(async paperId => {
        const categories = (getValue(selectedPapers[paperId].category) as string).split(', ');
        console.log('paper', paperId, 'initial categories', categories);
        Object.keys(selectedCategories).forEach(catId => {
          if (selectedCategories[catId] === false) {
            categories.splice(categories.indexOf(catId), 1);
          } else if (!categories.includes(catId)) {
            categories.push(catId);
          }
        });

        console.log('updating paper', paperId, 'with categories', categories);

        await updatePaper(user, firebase, firestore, paperId, {
          category: categories.map(cat => ({ value: cat })),
        });
      });
    }
  };

  /**
   * Add to stack
   */
  const [isStackModalOpen, setIsStackModalOpen] = useState(false);

  const addSelectedPapersToStack = async (stackId: string) => {
    presentLoading({
      message: intl.formatMessage({
        id: 'page.papers.adding-selected-to-stack',
        defaultMessage: 'Adding Papers to Stack...',
      }),
    });

    await addPapersToStack(
      stackId,
      Object.keys(selectedPapers || {}).reduce((acc, paperId) => {
        acc.push({ value: paperId });
        return acc;
      }, [] as { value: string }[]),
      user,
      firebase,
      firestore,
    )
      .catch(err => {
        console.error('Delete Selected Papers Error', err);
      })
      .finally(async () => {
        await dismissLoading();
        setIsStackModalOpen(false);
        onDeselect();
      });
  };

  /**
   * Delete selected papers
   */
  const confirmSelectedPapersDelete = () => {
    presentAlert({
      header: intl.formatMessage({
        id: 'toolbar.selection.modal.delete.title',
        defaultMessage: 'Delete Selected Papers',
      }),
      message: intl.formatMessage(
        {
          id: 'toolbar.selection.modal.delete.message',
          defaultMessage: 'Are you sure you want to delete {num} papers?',
        },
        { num },
      ),
      buttons: [
        intl.formatMessage({ id: 'ui.buttons.no' }),
        {
          text: intl.formatMessage({ id: 'ui.buttons.yes' }),
          handler: () => deleteSelectedPapers(),
        },
      ],
    });
  };

  const deleteSelectedPapers = async () => {
    presentLoading({
      message: intl.formatMessage({
        id: 'toolbar.selection.loading.deleting',
        defaultMessage: 'Deleting Selected Papers...',
      }),
    });

    const papersToDelete: Paper[] = Object.values(selectedPapers || {});

    await deletePapers(papersToDelete, user, firebase, firestore)
      .then(() => {
        onDeselect();
      })
      .catch(err => {
        console.error('Delete Selected Papers Error', err);
      })
      .finally(async () => {
        await dismissLoading();
      });
  };

  const [presentPopover, closePopover] = useIonPopover(
    () => {
      return (
        <IonList>
          <IonItem
            button
            onClick={() => {
              shareFiles();
              closePopover();
            }}
            lines="inset"
            detail={false}
          >
            <IonIcon icon={shareOutline} slot="start" />
            <FormattedMessage
              id="papers.selection.share-files"
              defaultMessage="Share PDFs and images"
            />
          </IonItem>
          <IonItem
            button
            onClick={() => {
              setIsStackModalOpen(true);
              closePopover();
            }}
            lines="inset"
            detail={false}
          >
            <IonIcon
              icon={folderOpenOutline}
              slot="start"
              className="papers-selection-toolbar--stack-icon"
            />
            <FormattedMessage
              id="papers.selection.add-to-stack"
              defaultMessage="Add papers to stack"
            />
          </IonItem>
          <IonItem
            button
            onClick={() => {
              setIsCategoriesModalOpen(true);
              closePopover();
            }}
            lines="inset"
            detail={false}
          >
            <IonIcon icon={pricetagsOutline} slot="start" />
            <FormattedMessage
              id="papers.selection.change-categories"
              defaultMessage="Change papers categories"
            />
          </IonItem>
          {/* <IonItem button onClick={() => closePopover()} lines="inset" detail={false}>
            <IonIcon icon={trailSignOutline} slot="start" />
            <FormattedMessage
              id="papers.selection.change-categories"
              defaultMessage="Change papers categories"
            />
            Change papers type
          </IonItem> */}
          {/* num > 1 && (
            <IonItem button onClick={() => closePopover()} lines="inset" detail={false}>
              <IonIcon icon={documentAttachOutline} slot="start" />
              Merge papers into one
            </IonItem>
          ) */}
          <IonItem
            button
            onClick={() => {
              confirmSelectedPapersDelete();
              closePopover();
            }}
            lines="none"
            detail={false}
          >
            <IonIcon icon={removeCircleOutline} slot="start" color="danger" />
            <FormattedMessage id="papers.selection.delete" defaultMessage="Delete papers" />
          </IonItem>
        </IonList>
      );
    },
    {
      side: 'bottom',
      alignment: 'right',
      arrow: false,
    },
  );

  if (selectedPapers && Object.keys(selectedPapers).length === 0) {
    return null;
  }

  return (
    <IonToolbar className="papers-selection-toolbar">
      <IonButtons slot="start">
        <IonButton shape="round" onClick={onDeselect} color="dark">
          <IonIcon icon={close} slot="icon-only" />
        </IonButton>
      </IonButtons>
      <IonTitle className="papers-selection-toolbar--title">
        <FormattedMessage
          id="papers.selection.title"
          values={{ num }}
          defaultMessage="{num} papers selected"
        />
      </IonTitle>
      <IonButtons slot="end">
        <IonButton shape="round" onClick={shareFiles} color="dark">
          <IonIcon icon={shareOutline} slot="icon-only" />
        </IonButton>
        <IonButton
          shape="round"
          onClick={() => {
            setIsCategoriesModalOpen(true);
          }}
          color="dark"
        >
          <IonIcon icon={pricetagsOutline} slot="icon-only" />
        </IonButton>
        <IonButton
          shape="round"
          onClick={evt => {
            presentPopover({
              event: evt.nativeEvent,
              arrow: false,
              side: 'bottom',
              alignment: 'right',
            } as any);
          }}
          color="medium"
          size="small"
        >
          <IonIcon md={ellipsisVertical} ios={ellipsisHorizontal} slot="icon-only" />
        </IonButton>
      </IonButtons>
      <LinkStackModal
        opened={isStackModalOpen}
        onDismiss={() => setIsStackModalOpen(false)}
        setStackId={(stackId: string) => {
          setIsStackModalOpen(false);
          addSelectedPapersToStack(stackId);
        }}
      />
      <UpdpateCategoriesModal
        opened={isCategoriesModalOpen}
        onDismiss={() => setIsCategoriesModalOpen(false)}
        setCategories={updatedCategories => updateCategoriesToSelectedPapers(updatedCategories)}
        selectedCategories={getSelectedCategories(selectedPapers)}
      />
    </IonToolbar>
  );
};

export default PapersListSelectionToolbar;
