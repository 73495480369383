import { TFieldValue, TFieldValues, TFilePreview } from '../models/FieldValues';
import { RecordTypeField } from '../models/RecordType';

/**
 * Returns the value of the field
 *
 * @param field
 * @param defaultValue  value to be returned if the field doesn't have a value
 * @param num  if the value is an array how many records to return
 * @returns field value
 */
export const getValue = (
  field: string | TFieldValues | TFilePreview[] | undefined,
  defaultValue?: string,
  num?: number,
): string | number | boolean | null | undefined => {
  if (field === undefined) {
    return undefined;
  }
  if (typeof field === 'string') {
    return field || defaultValue;
  }
  if (Array.isArray(field)) {
    if (num && num > 0) {
      let result = '';
      for (let i = 0; i < Math.min(num, field.length); i++) {
        const theField = field[i] as TFieldValue;
        if (theField.value && theField.value !== undefined && theField.value !== null) {
          result += result ? ', ' : '' + theField.value;
        }
      }
      return result;
    } else {
      return field.map(item => (item as TFieldValue).value).join(', ') || defaultValue;
    }
  }
  return field.value || defaultValue;
};

export const getCurrency = (field: string | TFieldValues | TFilePreview[] | undefined): string => {
  if (field === undefined || typeof field === 'string') {
    return '';
  }
  if (Array.isArray(field)) {
    return field.map(item => (item as TFieldValue).currency).join(', ');
  }
  return field.currency || '';
};

export const hasValueOf = (
  field: string | TFieldValues | TFilePreview[] | undefined,
  value: string | number,
): boolean => {
  if (field === undefined) {
    return false;
  }
  if (typeof field === 'string' || typeof field === 'number') {
    return field === value;
  }
  if (Array.isArray(field)) {
    return field.some(item => (item as TFieldValue).value === value);
  }
  return field.value === value;
};

export const getTotalValue = (
  field: string | TFieldValues | TFilePreview[] | undefined,
  defaultValue: string = '',
  num?: number,
) => {
  let result = getValue(field, defaultValue, num);
  const currency = typeof field !== 'string' && !Array.isArray(field) && field?.currency;

  if (result && currency) {
    result += '|' + currency;
  }
  return result || defaultValue;
};

export const getDateValue = (
  field: string | TFieldValues | TFilePreview[] | undefined,
  defaultValue: string = '',
  num?: number,
  lang: string = 'en',
  dateFormat = 'dd-mm-yyyy',
): string | number | boolean | null | undefined => {
  const result = getValue(field, defaultValue, num);
  if (result && typeof result === 'string') {
    const date = new Date(result);
    const language = dateFormat === 'dd-mm-yyyy' ? lang + '-GB' : lang;
    if (!isNaN(date.getTime())) {
      return date.toLocaleDateString(language, {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
      });
    }
  }
  return result;
};

export const getMonthValue = (
  field: string | TFieldValues | TFilePreview[] | undefined,
  defaultValue: string = '',
  num?: number,
  lang: string = 'en',
): string | number | boolean | null | undefined => {
  const result = getValue(field, defaultValue, num);
  if (result && typeof result === 'string') {
    const date = new Date(result);
    if (!isNaN(date.getTime())) {
      return date.toLocaleDateString(lang + '-GB', { year: 'numeric', month: 'long' });
    }
  }
  return result;
};

export const getLabel = (field: string | TFieldValues | TFilePreview[]): string => {
  if (field && typeof field !== 'string' && !Array.isArray(field) && field?.label) {
    return field.label;
  }
  return '';
};

export const getLabelId = (
  field: RecordTypeField,
  value: string | TFieldValues | TFilePreview[],
): string | undefined => {
  if (value && Array.isArray(value) && value.length > 1 && field.labelPluralId) {
    return field.labelPluralId;
  } else {
    return field.labelId || undefined;
  }
};

/**
 * Moves an array item from one position to another
 *
 * @param array
 * @param {number} from
 * @param {number} to
 * @returns reordered array
 */
export const reorderArray = (array: any[], from: number, to: number) => {
  if (from === to) {
    return array;
  }
  return array.reduce((acc, item, index) => {
    if (index === from) {
      return acc;
    } else if (index === to) {
      if (from < to) {
        acc.push(item, array[from]);
      } else {
        acc.push(array[from], item);
      }
    } else {
      acc.push(item);
    }
    return acc;
  }, []);
};

/**
 * Returns the value of the field as an URL with protocol
 *
 * @param field
 */
export const getURL = (url: string): string => {
  if (url && url.startsWith('http')) {
    return url;
  }
  return 'http://' + url;
};
