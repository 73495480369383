// plugins
import { FilePicker } from '@capawesome/capacitor-file-picker';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { DocumentScanner, ResponseType } from 'capacitor-document-scanner';

// hooks
import { forwardRef, useEffect, useRef, useState, useImperativeHandle } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

// components
import {
  IonButton,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  isPlatform,
  useIonActionSheet,
  useIonToast,
} from '@ionic/react';

// utils
import { getFormattedFileObj, updateFiles } from '../utilities/papers';
import { filesizeFromBase64 } from '../utilities/filesize';

// icons
import {
  cameraOutline,
  closeOutline,
  cloudUploadOutline,
  documentsOutline,
  imagesOutline,
  scanOutline,
  trashOutline,
} from 'ionicons/icons';

// types
import { TStoreState } from '../store/store';
import { TFilePreview, TFileValue } from '../models/FieldValues';

import { customAlphabet } from 'nanoid';
import { alphanumeric } from 'nanoid-dictionary';
const nanoid = customAlphabet(alphanumeric, 14);

type TFileFieldProps = {
  itemId: string;
  name: string;
  files: TFileValue[];
  thumbnails?: TFilePreview[];
  avatars?: TFilePreview[];
  onValueUpdate: (value: TFileValue[]) => void;
  label?: string;
  labelId?: string;
  addMoreLabel: string;
  addMoreLabelId?: string;
  width?: number;
  multiple?: boolean;
  accept?: string;
  uploadOnly?: boolean;
};

const FileField = forwardRef(
  (
    {
      itemId,
      name,
      files,
      thumbnails,
      avatars,
      onValueUpdate,
      label,
      labelId,
      addMoreLabel,
      addMoreLabelId,
      width = 100,
      multiple = false,
      accept = '.jpg,.gif,.jpeg,.png,image/*,.pdf,application/pdf',
      uploadOnly = false,
    }: TFileFieldProps,
    ref,
  ) => {
    const intl = useIntl();
    const fileInput = useRef<any>(null);
    const uid = useSelector((state: TStoreState) => state.firebase.auth.uid);

    const [storedItemId, setStoredItemId] = useState<string>('');
    const [tmpFiles, setTmpFiles] = useState<TFileValue[]>(updateFiles(files, []));

    useEffect(() => {
      const updatedFiles = updateFiles(files, itemId !== storedItemId ? [] : tmpFiles);
      // if the files, compared by id's are different, we have to update tmpFiles
      if (
        itemId !== storedItemId ||
        updatedFiles.some((file, idx) => file.id !== tmpFiles[idx]?.id)
      ) {
        setTmpFiles(updatedFiles);
        setStoredItemId(itemId);
      }
    }, [files, itemId]);

    const [present, dismiss] = useIonActionSheet();

    const [presentToast] = useIonToast();

    const scanPaper = async () => {
      const photo = await DocumentScanner.scanDocument({
        letUserAdjustCrop: true,
        maxNumDocuments: 1,
        responseType: ResponseType.Base64,
      }).catch(error => {
        presentToast({
          message: error?.message || intl.formatMessage({ id: 'ui.toast.error' }),
          duration: 2000,
          position: 'top',
          color: 'danger',
        });
        console.error('Document Scan error', error?.message);
      });

      if (photo?.status === 'success' && (photo.scannedImages?.length || 0) > 0) {
        const newImages: TFileValue[] = [];
        photo.scannedImages?.forEach(image => {
          const fileId = nanoid(14);

          newImages.push({
            id: fileId,
            name: fileId,
            mimeType: 'image/jpeg',
            size: filesizeFromBase64(image),
            data64: image,
          });
        });

        if (newImages.length > 0) {
          setTmpFiles(currentFiles => [...currentFiles, ...newImages]);
        }
      }
    };

    const takeAPhoto = async () => {
      const photo = await Camera.getPhoto({
        quality: 80,
        allowEditing: false,
        source: CameraSource.Camera,
        resultType: CameraResultType.Uri,
      }).catch(error => {
        presentToast({
          message: error?.message || intl.formatMessage({ id: 'ui.toast.error' }),
          duration: 2000,
          position: 'top',
          color: 'danger',
        });
        console.error('Take Photo error', error?.message);
      });

      if (photo) {
        const fileId = nanoid(14);

        setTmpFiles(currentFiles => [
          ...currentFiles,
          {
            id: fileId,
            name: fileId,
            mimeType: 'image/' + photo.format,
            webPath: photo.webPath,
            path: photo.path,
          },
        ]);
      }
    };

    const selectImagesFromGallery = async () => {
      const images = await Camera.pickImages({
        quality: 80,
        correctOrientation: true,
        limit: multiple ? undefined : 1,
      }).catch(error => {
        presentToast({
          message: error?.message || intl.formatMessage({ id: 'ui.toast.error' }),
          duration: 2000,
          position: 'top',
          color: 'danger',
        });
        console.error('Select Images from Gallery error', error?.message);
      });

      if (images?.photos && images.photos.length > 0) {
        const newImages: TFileValue[] = [];
        for (let i = 0; i < images.photos.length; i++) {
          const image = images.photos[i];
          if (image) {
            const fileId = nanoid(14);

            newImages.push({
              id: fileId,
              name: fileId,
              mimeType: 'image/' + image.format,
              webPath: image.webPath,
              path: image.path,
            });
          }
        }
        if (newImages.length > 0) {
          setTmpFiles(currentFiles => [...currentFiles, ...newImages]);
        }
      }
    };

    const selectFileFromOS = async () => {
      // request file(s) from the file picker
      const pickedFiles = await FilePicker.pickFiles({
        types: ['image/jpeg', 'image/png', 'application/pdf'],
        multiple,
      });

      if (pickedFiles?.files?.length > 0) {
        const newImages: TFileValue[] = [];
        for (let i = 0; i < pickedFiles.files.length; i++) {
          const file = pickedFiles.files[i];
          if (file && ['image/jpeg', 'image/png', 'application/pdf'].includes(file.mimeType)) {
            const fileId = nanoid(14);

            newImages.push({
              id: fileId,
              name: file.name,
              mimeType: file.mimeType,
              size: file.size,
              data64: file.data,
            });
          }
        }
        if (newImages.length > 0) {
          setTmpFiles(currentFiles => [...currentFiles, ...newImages]);
        }
      }
    };

    const selectFile = () => {
      if (isPlatform('capacitor')) {
        // on mobile, we use the action sheet to choose between native file pickers
        present({
          backdropDismiss: true,
          keyboardClose: true,
          cssClass: 'choose-file-source',
          buttons: [
            multiple
              ? {
                  text: intl.formatMessage({ id: 'ui.buttons.scan-a-paper' }),
                  icon: scanOutline,
                  handler: () => {
                    dismiss();
                    scanPaper();
                  },
                }
              : {},
            {
              text: intl.formatMessage({ id: 'ui.buttons.take-a-photo' }),
              icon: cameraOutline,
              handler: () => {
                dismiss();
                takeAPhoto();
              },
            },
            {
              text: intl.formatMessage({ id: 'ui.buttons.select-from-gallery' }),
              icon: imagesOutline,
              handler: () => {
                dismiss();
                selectImagesFromGallery();
              },
            },
            {
              text: intl.formatMessage({ id: 'ui.buttons.select-file' }),
              icon: documentsOutline,
              handler: () => {
                dismiss();
                selectFileFromOS();
              },
            },
            {
              text: intl.formatMessage({ id: 'ui.buttons.cancel' }),
              icon: closeOutline,
              role: 'cancel',
              handler: dismiss,
            },
          ].filter(item => item.text),
        });
      } else if (fileInput?.current?.click) {
        fileInput.current.click();
      }
    };

    const getFile = async () => {
      const updatedFiles = [...tmpFiles];
      if (fileInput.current.files) {
        for (let i = 0, len = fileInput.current.files.length; i < len; i++) {
          const file = fileInput.current.files[i];
          if (file) {
            // add new file to the files array
            updatedFiles.push(await getFormattedFileObj(file));
          }
        }
        // update the temp files list
        setTmpFiles(updatedFiles);
        // clear the file input
        fileInput.current.value = null;
      }
    };

    const removeFile = (fileId: string) => {
      setTmpFiles(currentFiles =>
        currentFiles.filter(file => {
          if (file.id === fileId) {
            // remove the file object url when deleting temp file
            if (file.objectUrl) {
              URL.revokeObjectURL(file.objectUrl);
            }
            return false;
          }
          return true;
        }),
      );
    };

    // update the form data when files are changed
    useEffect(() => {
      if (tmpFiles.some(file => (file.objectUrl?.length || 0) > 0)) {
        return;
      }
      if (files && !tmpFiles.some(newFile => files.some(oldFile => newFile.id === oldFile.id))) {
        const updatedFiles = tmpFiles.map(newFile => {
          const oldFile = files.find(oldFile => newFile.id === oldFile.id);
          return oldFile || newFile;
        });
        onValueUpdate(updatedFiles);
      } else {
        onValueUpdate(tmpFiles);
      }
    }, [tmpFiles]);

    // clean the temp files object urls
    useEffect(() => {
      return () => {
        tmpFiles.forEach(file => {
          if (file.objectUrl) {
            URL.revokeObjectURL(file.objectUrl);
          }
        });
      };
    }, []);

    const getFileUrl = (file: TFileValue) => {
      // if we have a preview of any sort: use it.
      // better if it is a thumbnail or an avatar
      if (thumbnails) {
        const thumb = thumbnails.find(thumb => thumb.fileId === file.id)?.url;
        if (thumb) {
          return thumb.replace('{uid}', uid);
        }
      }
      if (avatars) {
        const thumb = avatars.find(thumb => thumb.fileId === file.id)?.url;
        if (thumb) {
          return thumb.replace('{uid}', uid);
        }
      }
      // otherwise check is it a fresh not loaded yet file
      if (file.webPath) {
        return file.webPath;
      }
      const start = 'data:' + file.mimeType + ';base64,';
      if (file.data64?.indexOf(start) === 0) {
        return file.data64;
      } else {
        return start + file.data64;
      }
    };

    useImperativeHandle(ref, () => ({
      selectFileForUpload() {
        selectFile();
      },
    }));

    if (uploadOnly) {
      return (
        <div className="file-upload-button" onClick={selectFile}>
          <input
            type="file"
            capture="environment"
            accept={accept}
            multiple={multiple}
            ref={fileInput}
            onChange={getFile}
            className="file-field__input"
          />
          <IonIcon icon={cloudUploadOutline} className="file-upload-button__icon" />
        </div>
      );
    }

    return (
      <IonItem mode="md" className="text-field__container">
        {(label || labelId) && (
          <IonLabel position="stacked">
            {labelId ? <FormattedMessage id={labelId} defaultMessage={label} /> : label}
          </IonLabel>
        )}
        <div className="file-field__list">
          <input
            type="file"
            capture="environment"
            accept={accept}
            multiple={multiple}
            ref={fileInput}
            onChange={getFile}
            className="file-field__input"
          />
          {tmpFiles.map(file => (
            <div
              key={file.id}
              style={{ width: width + 'px' }}
              className="file-field__preview file-field__preview-container"
            >
              {file.name?.indexOf('.pdf') === -1 ? (
                <IonImg
                  className="file-field__preview"
                  style={{ width: width + 'px' }}
                  src={getFileUrl(file)}
                />
              ) : (
                <div
                  className="file-field__preview file-field__no-preview"
                  style={{ width: width + 'px' }}
                >
                  <div className="file-field__no-preview_filename">{file.name}</div>
                </div>
              )}
              <IonButton
                size="small"
                fill="solid"
                shape="round"
                color="light"
                className="file-field__delete"
                onClick={() => removeFile(file.id)}
              >
                <IonIcon icon={trashOutline} />
              </IonButton>
            </div>
          ))}
          {(multiple || tmpFiles.length === 0) && (
            <IonButton
              className="file-field__button"
              style={{ width: width + 'px' }}
              type="button"
              color="medium"
              fill="outline"
              size="default"
              expand="block"
              onClick={selectFile}
            >
              {addMoreLabelId ? (
                <FormattedMessage id={addMoreLabelId} defaultMessage={addMoreLabel} />
              ) : (
                addMoreLabel
              )}
            </IonButton>
          )}
        </div>
      </IonItem>
    );
  },
);

export default FileField;
