// hooks
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// components
import { IonAvatar, IonIcon, IonImg, IonItem, IonLabel, IonReorder } from '@ionic/react';

// utilities
import { getCurrency, getValue } from '../utilities/values';

// icons and types
import { airplaneOutline, briefcaseOutline, folderOutline, golfOutline } from 'ionicons/icons';
import { Stack } from '../models/Stack';
import { TFieldValue } from '../models/FieldValues';
import { TStoreState } from '../store/store';
import { PapersState } from '../models/Paper';
import { DisplayAmounts } from './PapersList';
import { classes } from '../utilities/strings';

type TStacksListItemsProps = {
  stack: Stack;
  routerLink?: string;
  clickHandler?: (paperId: string) => void;
  currentPath?: string;
  reorder?: boolean;
};

const StacksListItem = ({
  stack,
  routerLink,
  clickHandler,
  currentPath,
  reorder,
}: TStacksListItemsProps) => {
  const uid = useSelector((state: TStoreState) => state.firebase.auth.uid);
  const image = stack?.thumbnail?.[0]?.url?.replace('{uid}', uid);

  const papers = useSelector((state: TStoreState) => state.data.papers) as PapersState;

  const getIcon = (stack: Stack) => {
    const type = getValue(stack?.['stack-type']);
    if (type === 'location') {
      return golfOutline;
    } else if (type === 'project') {
      return folderOutline;
    } else if (type === 'trip') {
      return airplaneOutline;
    }
    return briefcaseOutline;
  };

  const [totals, setTotals] = useState<{ [currency: string]: number }>({});
  useEffect(() => {
    const stackPapers = stack?.['stack-papers'] as TFieldValue[];
    if (stackPapers?.length > 0) {
      setTotals(
        stackPapers.reduce((acc: { [currency: string]: number }, paperValue: TFieldValue) => {
          const paper = papers?.items?.[paperValue.value as string];
          if (paper) {
            const displayTotalValue = getValue(paper.displayTotal);
            const totalField = displayTotalValue ? paper.displayTotal : paper.total;
            const currency: string = getCurrency(
              displayTotalValue ? paper.displayTotal : paper.total,
            );
            const total = parseFloat((getValue(totalField, '0') || 0).toString().replace(',', '.'));

            if (total > 0 && currency !== '') {
              acc[currency] = (acc[currency] || 0) + total;
            }
          }
          return acc;
        }, {}),
      );
    } else setTotals({});
  }, [stack, papers]);

  return (
    <IonItem
      className={classes({
        'list-item': true,
        'list-item--active': !!currentPath?.includes(`${routerLink}${stack?.id}` || '', 0),
      })}
      routerLink={routerLink && `${routerLink}${stack?.id}`}
      onClick={clickHandler && (() => clickHandler(stack?.id))}
      button={!!clickHandler}
    >
      {stack && (
        <>
          {image ? (
            <IonAvatar slot="start" className="list-item__avatar">
              <IonImg src={image} style={{ objectFit: 'contain' }} />
            </IonAvatar>
          ) : (
            <IonIcon icon={getIcon(stack)} slot="start" className="list-item__avatar" />
          )}
          <IonLabel>
            {getValue(stack.name, 'Untitled Stack')}
            <span className="list-data--count">
              {(stack?.['stack-papers'] as TFieldValue[])?.length || 0}
            </span>
          </IonLabel>
          {reorder ? (
            <IonReorder slot="end" />
          ) : (
            Object.keys(totals).length > 0 && (
              <DisplayAmounts
                groupId={stack?.id}
                total={totals}
                income={{}}
                unpaid={{}}
                reimbursed={{}}
              />
            )
          )}
        </>
      )}
    </IonItem>
  );
};

export default StacksListItem;
