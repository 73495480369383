import { useSelector, shallowEqual } from 'react-redux';
import { RequestState } from '../models/RequestState';

type RequestedCollectionItems = {
  items: { [prop: string]: any }[];
  state: RequestState;
};

type RequestedUnorderedCollectionItems = {
  items: { [itemId: string]: any };
  state: RequestState;
};

export function useCollectionItems(
  collection: string,
  options: { [propName: string]: string },
): RequestedCollectionItems {
  const items = useSelector((state: any) => {
    if (state.firestore.ordered[collection]) {
      return Object.keys(state.firestore.ordered[collection])
        .filter(itemId => {
          let result = true;
          Object.keys(options).forEach(propName => {
            if (state.firestore.ordered[collection][itemId][propName] !== options[propName]) {
              result = false;
            }
          });
          return result;
        })
        .map(itemId => state.firestore.ordered[collection][itemId]);
    }
    return [];
  }, shallowEqual);

  return {
    items,
    state: {
      isNotFound: !items.length,
      isEmpty: !items.length,
      isNotLoaded: false,
      isLoading: false,
      isLoaded: true,
    },
  };
}

export function useUnorderedCollectionItems(
  collection: string,
  options: { [propName: string]: string },
): RequestedUnorderedCollectionItems {
  const items = useSelector((state: any) => {
    if (state.firestore.data[collection]) {
      return Object.keys(state.firestore.data[collection])
        .filter(itemId => {
          let result = true;
          Object.keys(options).forEach(propName => {
            if (state.firestore.data[collection][itemId][propName] !== options[propName]) {
              result = false;
            }
          });
          return result;
        })
        .reduce((acc, itemId: string) => {
          acc[itemId] = state.firestore.data[collection][itemId];
          return acc;
        }, {} as { [itemId: string]: any });
    }
    return [];
  }, shallowEqual);

  return {
    items,
    state: {
      isNotFound: !items.length,
      isEmpty: !items.length,
      isNotLoaded: false,
      isLoading: false,
      isLoaded: true,
    },
  };
}
