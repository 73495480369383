// types
import { ExtendedFirestoreInstance } from 'react-redux-firebase';
import { TContactsShow } from '../store/settings.reducer';
import { Category } from '../models/Category';

export const updatePapersSearch = (
  firestore: ExtendedFirestoreInstance,
  uid: string,
  searchString: string,
) => {
  return updatePapersSettings(firestore, uid, { search: searchString });
};

export const updatePapersGrouping = (
  firestore: ExtendedFirestoreInstance,
  uid: string,
  grouping: string,
) => {
  return updatePapersSettings(firestore, uid, { grouping });
};

export const updatePapersPeriods = (
  firestore: ExtendedFirestoreInstance,
  uid: string,
  periods: any,
) => {
  return updatePapersSettings(firestore, uid, { periods });
};

export const updatePapersGroupsState = (
  firestore: ExtendedFirestoreInstance,
  uid: string,
  groupsState: any,
) => {
  console.log('updatePapersGroupsState', { groupsState });
  return updatePapersSettings(firestore, uid, { groupsState });
};

const updatePapersSettings = (firestore: ExtendedFirestoreInstance, uid: string, settings: any) => {
  return firestore
    .doc(`users/${uid}/settings/papers`)
    .set(settings, { merge: true })
    .catch(err => {
      console.error('updatePapersSettings error', err, settings);
    });
};

export const updateContactsSearch = (
  firestore: ExtendedFirestoreInstance,
  uid: string,
  searchString: string,
) => {
  return updateContactsSettings(firestore, uid, { search: searchString });
};

export const updateContactsLinkSearch = (
  firestore: ExtendedFirestoreInstance,
  uid: string,
  searchString: string,
) => {
  return updateContactsSettings(firestore, uid, { linkSearch: searchString });
};

export const updateContactsShow = (
  firestore: ExtendedFirestoreInstance,
  uid: string,
  show: TContactsShow,
) => {
  return updateContactsSettings(firestore, uid, { show });
};

const updateContactsSettings = (
  firestore: ExtendedFirestoreInstance,
  uid: string,
  settings: any,
) => {
  return firestore
    .doc(`users/${uid}/settings/contacts`)
    .set(settings, { merge: true })
    .catch(err => {
      console.error('updateContactsSettings error', err, settings);
    });
};

export const updateCategoriesSettings = (
  firestore: ExtendedFirestoreInstance,
  uid: string,
  categories: Category[],
) => {
  return firestore
    .doc(`users/${uid}/settings/categories`)
    .set({ categories }, { merge: true })
    .catch(err => {
      console.error('updateCategoriesSettings error', err, categories);
    });
};

export const updateHiddenTips = (
  firestore: ExtendedFirestoreInstance,
  uid: string,
  hiddenTips: { [tip: string]: boolean },
) => {
  return firestore
    .doc(`users/${uid}/settings/hiddenTips`)
    .set({ ...hiddenTips }, { merge: true })
    .catch(err => {
      console.error('updateHiddenTips error', err, hiddenTips);
    });
};
