import { RequestState } from './RequestState';
import { TFieldValue, TFieldValues, TFilePreview } from './FieldValues';

export type Paper = {
  id: string;
  createdAt: string;
  updatedAt?: string;

  form: string;
  title: TFieldValue;
  reference: TFieldValue;
  total?: TFieldValue;

  files?: TFieldValues;
  linkedPapers?: TFieldValues;

  pages?: TFilePreview[];
  thumbnail?: TFilePreview[];
  preview?: TFilePreview[];

  ocr?: TFieldValue;

  [key: string]: TFieldValues | TFilePreview[] | string;
};

export type PaperState = {
  item: Paper;
  state: RequestState;
};

export type PapersState = {
  items: { [id: string]: Paper };
  state: RequestState;
};

export type PapersOrderedState = {
  items: Paper[];
  state: RequestState;
};

export const newPaper: Paper = {
  id: '',
  createdAt: new Date(0).toISOString(),
  form: 'default',
  title: { value: '' },
  reference: { value: '' },
};
