// hooks
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFirestoreCollectionQuery } from '../hooks/useFirestoreCollectionQuery';
import { useFirestoreItemQuery } from '../hooks/useFirestoreItemQuery';
import { useIntl } from 'react-intl';
import { useObjectCollection } from '../hooks/useObjectCollection';
import { useIdleTimer } from 'react-idle-timer';
import { useFirebase } from 'react-redux-firebase';

// plugins
import { Device } from '@capacitor/device';
import { PushNotifications } from '@capacitor/push-notifications';
import { App } from '@capacitor/app';
import { isPlatform } from '@ionic/react';
import { AvailableResult, NativeBiometric } from 'capacitor-native-biometric';
import { Glassfy } from 'capacitor-plugin-glassfy';
import { NavigationBar } from '@hugotomazi/capacitor-navigation-bar';
import { Directory, Filesystem, ReaddirResult } from '@capacitor/filesystem';
import write_blob from 'capacitor-blob-writer';

// utils
import { emitCustomEvent, useCustomEventListener } from 'react-custom-events';
import { decryptValues, str2key } from '../utilities/encryption';

// types and data
import { Paper, PapersState } from '../models/Paper';
import { TStoreState } from '../store/store';
import Package from '../../package.json';
import { Contact, ContactsState } from '../models/Contact';
import { locales } from './Intl';
import { SendIntent } from 'send-intent';
import { getMimeTypeByFileName } from '../utilities/files';

type TDataLoaderParams = {
  children?: React.ReactElement;
};

const DataLoader = ({ children }: TDataLoaderParams): React.ReactElement | null => {
  const user = useSelector((state: any) => state.firebase?.auth);
  const intl = useIntl();
  const dispatch = useDispatch();
  const firebase = useFirebase();

  const privateKey = useSelector((state: TStoreState) => state.ui.privateKey);
  const profile = useSelector((state: TStoreState) => state.firebase?.profile);

  /**
   * Notifications
   *
   * Get device data and set the listeners for the notifications
   */
  const devices = useSelector((state: any) => state.firebase?.profile?.devices);
  const deviceId = useRef<string | undefined>();

  useEffect(() => {
    Device.getInfo().then(info => {
      dispatch({ type: 'SET_DEVICE', payload: info });
    });
    Device.getId().then(deviceIdContainer => {
      deviceId.current = deviceIdContainer.uuid;
      dispatch({ type: 'SET_DEVICE_ID', payload: deviceIdContainer.uuid });
    });
    if (isPlatform('capacitor')) {
      PushNotifications.addListener('pushNotificationReceived', notification => {
        emitCustomEvent('pushNotificationReceived', notification);
      });

      PushNotifications.addListener('pushNotificationActionPerformed', notification => {
        emitCustomEvent('pushNotificationActionPerformed', notification);
      });

      App.getInfo().then(info => {
        dispatch({ type: 'SET_APP_INFO', payload: info });
      });
    } else {
      dispatch({
        type: 'SET_APP_INFO',
        payload: {
          id: 'com.leechylabs.papers',
          name: 'The Papers',
          version: Package.version,
          build: Package.bundle,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (
      devices &&
      Object.keys(devices).some(device => {
        return devices[device].notificationsPermissions === 'granted';
      })
    ) {
      // if the user have at least one device with notifications enabled
      // then we can let him set notifications from any device
      dispatch({ type: 'SET_CAN_SET_NOTIFICATIONS', payload: true });
    }
    if (deviceId.current && devices?.[deviceId.current]?.notificationsPermissions === 'granted') {
      // All things that can be done with the device info here
      // starting with the notifications registration
      dispatch({ type: 'SET_CAN_RECIEVE_NOTIFICATIONS', payload: true });
    }
  }, [deviceId.current, devices]);

  /**
   * Intents -- files shared from other apps
   */
  const processingIntent = useRef(false);

  const checkIntent = () => {
    if (isPlatform('capacitor') && !processingIntent.current) {
      SendIntent.checkSendIntentReceived()
        .then(async (result: any) => {
          console.log('Send Intent Received: ', result);
          if (result?.url) {
            processingIntent.current = true;

            const files2Upload = [];
            const receivedFiles = [result, ...(result.additionalItems || [])];
            for (let i = 0, len = receivedFiles.length; i < len; i++) {
              const file = receivedFiles[i];
              const fileInfo = await Filesystem.stat({ path: file.url || '' });

              files2Upload.push({
                name: file.title,
                size: fileInfo.size,
                mimeType: getMimeTypeByFileName(file.title),
                path: file.url,
              });
            }

            if (files2Upload.length > 0) {
              emitCustomEvent('files2Upload', {
                files: files2Upload,
                callback: () => {
                  finishIntent();
                },
              });
            }
          } else {
            finishIntent();
          }
        })
        .catch((err: any) => {
          if (err && Object.keys(err).length > 0) {
            console.error(err);
            finishIntent();
          }
        });
    }
  };

  const finishIntent = () => {
    if (processingIntent.current) {
      processingIntent.current = false;
      SendIntent.finish();
    }
  };

  // run the function on the first render
  useEffect(() => {
    checkIntent();
  }, []);

  useCustomEventListener('sendIntentReceived', (notification: any) => {
    console.log('sendIntentReceived event', notification);
    // user shared a file from another app
    checkIntent();
  });

  /**
   * User settings
   *
   * Subscribe to the documents with the settings and update the store with the data
   * later the thunks will be used to update the data in the firestore
   */
  const settings = useFirestoreCollectionQuery(`users/${user.uid}/settings`, false, {
    storeAs: 'settings',
  });
  useEffect(() => {
    console.log('settings', settings?.items);
    if (settings?.items && !Array.isArray(settings?.items)) {
      dispatch({ type: 'UPDATE_SETTINGS', payload: settings.items });
    }
  }, [settings]);

  /**
   * Biometrics
   *
   * have to check it when the app is started
   * check every time app becomes active again (user can change the settings)
   * and by request from the settings
   */
  const checkBiometry = () => {
    if (isPlatform('capacitor')) {
      NativeBiometric.isAvailable().then((result: AvailableResult) => {
        dispatch({ type: 'SET_BIOMETRY_AVAILABLE', payload: result.isAvailable });
        dispatch({ type: 'SET_BIOMETRY_TYPE', payload: result.biometryType });
      });
    }
  };

  useEffect(() => {
    checkBiometry();

    // attach app-wide event listener
    App.addListener('appStateChange', ({ isActive }) => {
      if (isActive) {
        checkBiometry();
      }
    });
  }, []);

  useCustomEventListener('check-biometry', () => {
    checkBiometry();
  });

  /**
   * Load App Settings
   */
  useFirestoreItemQuery('settings', 'accounts', {
    storeAs: 'accounts',
  });

  /**
   * Load System and Custom Types
   */
  useFirestoreCollectionQuery('types', true, {
    orderBy: 'order',
  });
  useFirestoreCollectionQuery(`users/${user.uid}/types`, false, {
    storeAs: 'customTypes',
  });

  /**
   * Load Pages tips
   */
  useFirestoreItemQuery('tips', intl.locale);

  /**
   * Load papers with (potentially) encrypted data
   */
  useFirestoreCollectionQuery(`users/${user.uid}/papers`, false, {
    storeAs: 'papersRaw',
  });

  /**
   * Load contacts with (potentially) encrypted data
   */
  useFirestoreCollectionQuery(`users/${user.uid}/contacts`, false, {
    storeAs: 'contactsRaw',
  });

  /**
   * Update currencies for the user
   */
  useEffect(() => {
    if (profile?.currencies) {
      dispatch({ type: 'SET_CURRENCIES', payload: profile.currencies });
    }
  }, [profile?.currencies]);

  /**
   * Update OCR scripts for the user
   */
  useEffect(() => {
    if (profile?.ocrScripts) {
      dispatch({ type: 'SET_OCR_SCRIPTS', payload: profile.ocrScripts });
    }
  }, [profile?.ocrScripts]);

  /**
   * Idle timer
   * remove the private key after some timeout to lock the data
   */
  const onIdle = () => {
    dispatch({ type: 'REMOVE_PRIVATE_KEY' });
  };

  const idleTimer = useIdleTimer({
    onIdle,
    timeout: 1000 * 60 * (profile?.deauthTime || 60),
    startManually: true,
    stopOnIdle: true,
  });

  /**
   * Unencrypt papers and put them to the store unencrypted
   */
  const papersRaw = useObjectCollection('papersRaw') as PapersState;

  const decryptPapers = async () => {
    const decryptedItems: { [paperId: string]: Paper } = {};
    const paperIds = papersRaw?.items ? Object.keys(papersRaw.items) : [];
    for (let i = 0; i < paperIds.length; i++) {
      const decryptedValues = await decryptValues(
        papersRaw.items[paperIds[i]],
        typeof privateKey === 'string' ? await str2key(privateKey) : privateKey,
      );
      decryptedItems[paperIds[i]] = {
        ...papersRaw.items[paperIds[i]],
        ...(decryptedValues || {}),
      };
    }
    dispatch({
      type: 'SET_PAPERS',
      payload: {
        state: papersRaw.state,
        items: decryptedItems,
      },
    });
  };

  useEffect(() => {
    if (papersRaw.state.isLoaded) {
      if (profile.publicKey) {
        decryptPapers();

        // if we have the private key, then at the moment we
        // decrypt the papers, start the countdown
        if (privateKey) {
          idleTimer.start();
        }
      } else if (profile?.isLoaded) {
        dispatch({
          type: 'SET_PAPERS',
          payload: papersRaw,
        });
      }
    }
  }, [papersRaw.state, profile, privateKey]);

  /**
   * Unencrypt contacts and put them to the store unencrypted
   */
  const contactsRaw = useObjectCollection('contactsRaw') as ContactsState;

  const decryptContacts = async () => {
    const decryptedItems: { [paperId: string]: Contact } = {};
    const contactIds = contactsRaw?.items ? Object.keys(contactsRaw.items) : [];
    for (let i = 0; i < contactIds.length; i++) {
      const decryptedValues = await decryptValues(
        contactsRaw.items[contactIds[i]],
        typeof privateKey === 'string' ? await str2key(privateKey) : privateKey,
      );
      decryptedItems[contactIds[i]] = {
        ...contactsRaw.items[contactIds[i]],
        ...(decryptedValues || {}),
      };
    }
    dispatch({
      type: 'SET_CONTACTS',
      payload: {
        state: contactsRaw.state,
        items: decryptedItems,
      },
    });
  };

  useEffect(() => {
    if (contactsRaw.state.isLoaded) {
      if (profile.publicKey) {
        decryptContacts();
      } else if (profile?.isLoaded) {
        dispatch({
          type: 'SET_CONTACTS',
          payload: contactsRaw,
        });
      }
    }
  }, [contactsRaw.state, profile, privateKey]);

  /**
   * Load stacks
   */
  useFirestoreCollectionQuery(`users/${user.uid}/stacks`, false, {
    storeAs: 'stacks',
  });

  /**
   * Load reminders
   */
  useFirestoreCollectionQuery(`users/${user.uid}/reminders`, false, {
    storeAs: 'reminders',
  });

  /**
   * Setup purchases
   */
  useEffect(() => {
    if (isPlatform('capacitor') && user?.uid) {
      Glassfy.initialize({ apiKey: '9de748e9425a489da6e1d65309f2c8cd', watcherMode: false })
        .then(() => {
          initPurchases(user.uid);
        })
        .catch(err => {
          console.error('error initialising glassfy', err);
        });
    }
  }, [user?.uid]);

  const initPurchases = async (uid: string) => {
    try {
      await Glassfy.connectCustomSubscriber({ subscriberId: uid });
      // console.log('user is changed, initialising with uid', user.uid, subscriber);
      Glassfy.permissions()
        .then(permissions => {
          const validPermission = permissions.all.find(permission => permission.isValid);
          if (validPermission) {
            // console.log('valid permission', validPermission);
            updateSubscriptionInProfile(validPermission.permissionId);
          } else {
            // console.log('setting basic account', permissions.all);
            updateSubscriptionInProfile('basic');
          }
        })
        .catch(error => {
          console.error('Glassfy permissions error', error);
        });
    } catch (error) {
      // initialization error
      console.error('Glassfy initialization error', error);
    }
  };

  const updateSubscriptionInProfile = (activeSubscription: string) => {
    if (profile?.isLoaded && profile.account !== activeSubscription && !profile.manualAccount) {
      firebase.updateProfile({
        account: activeSubscription,
      });
    }
  };

  /**
   * Change the color of the navigation bar on android
   */
  const device = useSelector((state: TStoreState) => state.device);
  const theme = useRef<string>(null);

  const setNavigationBarStyleDark = () => {
    if (theme.current === null || theme.current !== device?.theme) {
      NavigationBar.setColor({
        color: (device?.theme || 'modern') === 'modern' ? '#182f2d' : '#2f2918',
        darkButtons: false,
      });
    }
  };

  useEffect(() => {
    if (isPlatform('android')) {
      setNavigationBarStyleDark();
    }
  }, [device]);

  /**
   * Check the tessdata files on Android and download them if needed
   */
  const ocrScripts = useSelector((state: TStoreState) => state.ui.ocrScripts);
  const langScript = locales.find(l => l.id === intl.locale)?.script;

  const manageTessdata = async (languages: string[]) => {
    // check if tessdata folder exists
    let tessdataDir: ReaddirResult = { files: [] };
    try {
      await Filesystem.mkdir({ path: '/tessdata', directory: Directory.Data, recursive: false });
    } catch (e) {
      tessdataDir = await Filesystem.readdir({ path: '/tessdata', directory: Directory.Data });
    }

    // check which files have to be downloaded
    const filesToDownload = languages
      .filter(lang => !tessdataDir.files.find(file => file.name === `${lang}.traineddata`))
      .map(lang => `${lang}.traineddata`);

    // download the files
    for (let i = 0; i < filesToDownload.length; i++) {
      const file = await fetch(
        `https://raw.githubusercontent.com/tesseract-ocr/tessdata/master/${filesToDownload[i]}`,
      );
      const data = await file.blob();
      if (file) {
        await write_blob({
          path: `/tessdata/${filesToDownload[i]}`,
          blob: data,
          directory: Directory.Data,
          fast_mode: true,
        });
      } else {
        alert('Could not download OCR library file ' + filesToDownload[i]);
      }
    }
  };

  useEffect(() => {
    if (isPlatform('android') && langScript) {
      manageTessdata([langScript, ...Object.keys(ocrScripts || {})]);
      // manageTessdata([langScript]);
    }
  }, [langScript]);

  return children || null;
};

export default DataLoader;
