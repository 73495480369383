import { forwardRef, useImperativeHandle, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useIMask } from 'react-imask';

// components
import {
  IonIcon,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  isPlatform,
  useIonAlert,
} from '@ionic/react';

// types
import { TFieldValue } from '../models/FieldValues';
import { TStoreState } from '../store/store';
import { useFirebase } from 'react-redux-firebase';
import { helpCircleOutline } from 'ionicons/icons';

type TTotalFieldProps = {
  name: string;
  value: TFieldValue;
  onValueUpdate: (value: TFieldValue) => void;
  label?: string;
  labelId?: string;
  placeholder?: string;
  placeholderId?: string;
  hintId?: string;
  onNext?: () => void;
  error?: string;
  encrypt?: boolean;
  unencrypt?: string;
  displayLarge?: boolean;
};

const TotalField = (
  {
    name,
    value,
    onValueUpdate,
    label,
    labelId,
    placeholder,
    placeholderId,
    hintId,
    onNext,
    error,
    encrypt,
    unencrypt,
    displayLarge = false,
  }: TTotalFieldProps,
  ref?: any,
) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { ref: imaskRef } = useIMask(
    {
      mask: Number,
      scale: 2,
      signed: false,
      normalizeZeros: true,
      padFractionalZeros: true,
    },
    {
      onAccept: (result: any) => updateValue(result.toString()),
    },
  );

  const onKeyPress = (evt: any) => {
    if (onNext && evt.key === 'Enter') {
      onNext();
    }
  };

  const inputRef = useRef<HTMLIonInputElement>(null);
  useImperativeHandle(
    ref,
    () => ({
      setFocus() {
        inputRef.current?.setFocus();
      },
    }),
    [],
  );

  const onFocus = () => {
    if (isPlatform('mobile')) {
      dispatch({ type: 'HIDE_TABS' });
    }
  };
  const onBlur = () => {
    dispatch({ type: 'SHOW_TABS' });
  };

  const updateValue = (total: string | null | undefined) => {
    const newValue: TFieldValue = {
      value: total,
      currency: value?.currency || 'EUR',
    };
    if (encrypt) newValue.encrypt = encrypt;
    if (unencrypt) newValue.unencrypt = unencrypt;

    onValueUpdate(newValue);
  };

  /**
   * Currency
   */
  const firebase = useFirebase();
  const lastUsedCurrency = useSelector(
    (state: TStoreState) => state.firebase.profile?.lastUsedCurrency || 'EUR',
  );
  const currencies = useSelector((state: TStoreState) => state.ui.currencies, shallowEqual);

  const updateCurrency = (currency: string) => {
    const newValue: TFieldValue = {
      value: value?.value || 0,
      currency: currency,
    };
    if (encrypt) newValue.encrypt = encrypt;
    if (unencrypt) newValue.unencrypt = unencrypt;

    firebase.updateProfile({
      lastUsedCurrency: currency,
    });

    onValueUpdate(newValue);
  };

  /**
   * Hint alert
   */
  const [presentAlert] = useIonAlert();

  const showHint = (evt: any) => {
    evt.stopPropagation();
    evt.preventDefault();

    presentAlert({
      header: intl.formatMessage({ id: labelId }),
      message: intl.formatMessage({
        id: hintId || 'missing hint id',
        defaultMessage: 'Field hint is missing',
      }),
      buttons: [intl.formatMessage({ id: 'ui.buttons.ok' })],
    });
  };

  return (
    <IonItem mode="md" className="text-field__container total-field__container">
      {(label || labelId) && (
        <IonLabel position="stacked">
          {labelId ? <FormattedMessage id={labelId} defaultMessage={label} /> : label}
          {hintId && (
            <IonIcon
              icon={helpCircleOutline}
              style={{
                fontSize: '2rem',
                verticalAlign: '-0.7rem',
                marginLeft: '0.5rem',
              }}
              onClick={evt => showHint(evt)}
            />
          )}
        </IonLabel>
      )}
      <input
        ref={imaskRef}
        name={name}
        inputMode="decimal"
        className={`text-field total-field ${displayLarge ? 'total-field-large' : ''}`}
        placeholder={
          (placeholderId &&
            intl.formatMessage({ id: placeholderId, defaultMessage: placeholder })) ||
          placeholder
        }
        defaultValue={
          typeof value?.value === 'boolean' || value?.value === null
            ? '0'
            : value?.value?.toString()
        }
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyPress={onKeyPress}
      />
      <IonItem
        mode="ios"
        className={`text-field__container total-field__currency-container ${
          displayLarge ? '' : 'total-field__currency-container-small'
        }`}
        slot="end"
      >
        <IonSelect
          value={value?.currency || lastUsedCurrency}
          interface="popover"
          className="total-field__currency"
          mode="ios"
          onIonChange={evt => updateCurrency(evt.detail.value)}
        >
          {currencies &&
            Object.keys(currencies)
              .sort((c1: string, c2: string) =>
                currencies[c1] < currencies[c2] ? 1 : currencies[c1] > currencies[c2] ? -1 : 0,
              )
              .map((currency: string) => (
                <IonSelectOption key={currency} value={currency}>
                  {currency}
                </IonSelectOption>
              ))}
          {!currencies?.[value?.currency || lastUsedCurrency] && (
            <IonSelectOption disabled value={value?.currency || lastUsedCurrency}>
              {value?.currency || lastUsedCurrency}
            </IonSelectOption>
          )}
          <IonSelectOption disabled className="option-hint">
            <FormattedMessage
              id="ui.field.total.currency-hint"
              defaultMessage="The list of the currencies can be tuned in the Settings"
            />
          </IonSelectOption>
        </IonSelect>
      </IonItem>
      {error !== '' && (
        <IonLabel slot="error" color="danger" position="stacked" className="text-field__error">
          {error}
        </IonLabel>
      )}
    </IonItem>
  );
};

export default forwardRef(TotalField);
