import { getValue } from './values';

/**
 * Returns two arrays with values for the period filter – years and months
 * based on the given items
 *
 * @param items      set of objects with a date field as a ISO string
 * @param dateField  name of the field where we'll look for a date
 */
export const calculateAvailablePeriods = (
  items: { [id: string]: any },
  dateField: string = 'createdAt',
): [string[], string[]] => {
  if (items) {
    // get unique years and months
    const { years, months } = Object.keys(items).reduce(
      (acc, id) => {
        const date =
          dateField === 'servicePeriod' && (items[id]?.periodEnd || items[id]?.periodStart)
            ? (getValue(items[id].periodEnd) as string) ||
              (getValue(items[id].periodStart) as string)
            : (getValue(items[id]?.[dateField]) as string);
        if (date) {
          const year = date.substring(0, 4);
          const month = date.substring(0, 7);
          return {
            years: { ...acc.years, [year]: true },
            months: { ...acc.months, [month]: true },
          };
        } else
          return {
            years: { ...acc.years, other: true },
            months: { ...acc.months, other: true },
          };
      },
      { years: {}, months: {} },
    );

    return [
      Object.keys(years).sort((i1, i2) => (i1.replace(/\D/g, '') > i2.replace(/\D/g, '') ? -1 : 1)),
      Object.keys(months).sort((i1, i2) =>
        i1.replace(/\D/g, '') > i2.replace(/\D/g, '') ? -1 : 1,
      ),
    ];
  } else {
    return [[], []];
  }
};

/**
 * Calculates new selected period when filtering is changed (between all, yearly and monthly)
 *
 * @param periods                 current state of the filtering (all, yearly or monthly)
 * @param selectedPeriod          selected period — index of the coresponding array
 * @param availableYearsAndMonths arrays with all available years and months
 * @param newPeriods              new state of the filtering
 */
export const getNewPeriod = (
  periods: string,
  selectedPeriod: number,
  availableYearsAndMonths: [string[], string[]],
  newPeriods: string,
) => {
  if (newPeriods === 'yearly') {
    if (periods === 'monthly' && selectedPeriod >= 0) {
      const year = availableYearsAndMonths[1][selectedPeriod].substr(0, 4);
      return availableYearsAndMonths[0].indexOf(year);
    } else if (availableYearsAndMonths[0].length) {
      return 0;
    }
  } else if (newPeriods === 'monthly') {
    if (periods === 'yearly' && selectedPeriod >= 0) {
      const year = availableYearsAndMonths[0][selectedPeriod];
      const month = availableYearsAndMonths[1].findIndex(month => month.indexOf(year) === 0);
      return month;
    } else if (availableYearsAndMonths[1].length) {
      return 0;
    }
  }
  return 0;
};

export const getSortDate = (item: any, dateField: string = 'issuedOn'): string => {
  if (!item) return '';
  const servicePeriod =
    (getValue(item.periodEnd) as string) || (getValue(item.periodStart) as string) || null;
  if (dateField === 'servicePeriod' && servicePeriod) {
    return servicePeriod;
  }
  return (
    (getValue(item[dateField]) as string) ||
    (getValue(item.issuedOn) as string) ||
    (getValue(item.expiresOn) as string) ||
    servicePeriod ||
    item.updatedAt ||
    item.createdAt
  );
};

/**
 * Returns the name of the contact
 *
 * @param contact  contact object
 * @param contactId  contact id (if name is not found, the id will be returned)
 * @returns string
 */
export const getContactName = (contact: any, contactId: string, defaultMessage?: string) => {
  if (contact) {
    return (
      getValue(contact?.displayName) ||
      getValue(contact?.names) ||
      getValue(contact?.occupation) ||
      getValue(contact?.email) ||
      contactId ||
      ''
    );
  }
  return defaultMessage || '';
};

export const getContactFullName = (contact: any, contactId: string, defaultMessage?: string) => {
  if (contact) {
    return (
      getValue(contact?.names) ||
      getValue(contact?.displayName) ||
      getValue(contact?.occupation) ||
      getValue(contact?.email) ||
      contactId ||
      ''
    );
  }
  return defaultMessage || '';
};
