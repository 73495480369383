export const createFirestoreDocument = async (
  user: any,
  firebase: any,
  firestore: any,
  docPath: string,
  data: any,
  userCreated?: string,
): Promise<any> => {
  if (userCreated || (user?.isLoaded && !user.isEmpty)) {
    // if the user is already logged in, create a document
    const userPath = `users/${userCreated || user.uid}/` + docPath;
    return firestore
      .doc(userPath)
      .set({
        ...data,
        createdAt: new Date()
          .toLocaleString('en-GB')
          .replace(/(\d+)\/(\d+)\/(\d+), (\d+):(\d+)/, '$3-$2-$1T$4:$5'),
      })
      .then(() => ({ uid: userCreated || user.uid }))
      .catch((err: any) => {
        console.error('Document creation error', err);
      });
  } else if (user.isEmpty) {
    // if the user is not logged in, we have to create an anonymous user
    let newUser: any;
    await firebase
      .auth()
      .signInAnonymously()
      .then((res: any) => (newUser = res.user))
      .catch((err: any) => {
        console.error('Anonymous sign up error', err);
      });
    // create a document for the anonymous user
    await firestore.doc(`users/${newUser.uid}`).set({
      uid: newUser.uid,
      account: 'basic',
    });
    // retry to create the document, passing the new uid
    return createFirestoreDocument(user, firebase, firestore, docPath, data, newUser.uid);
  } else {
    return Promise.reject(new Error('User is not logged in'));
  }
};

export const updateFirestoreDocument = async (
  user: any,
  firestore: any,
  docPath: string,
  data: any,
): Promise<any> => {
  const userPath = `users/${user.uid}/` + docPath;
  return firestore
    .doc(userPath)
    .update({
      ...data,
      updatedAt: new Date()
        .toLocaleString('en-GB')
        .replace(/(\d+)\/(\d+)\/(\d+), (\d+):(\d+)/, '$3-$2-$1T$4:$5'),
    })
    .then((res: any) => res)
    .catch((err: any) => {
      console.error('Document update error', err);
    });
};

export const deleteFirestoreDocument = async (
  user: any,
  firestore: any,
  docPath: string,
): Promise<any> => {
  const userPath = `users/${user.uid}/` + docPath;

  return firestore
    .doc(userPath)
    .delete()
    .then((res: any) => res)
    .catch((err: any) => {
      console.error('Document deletion error', err);
    });
};
