import { locales } from '../components/Intl';
import { TStoreState } from '../store/store';
import { actionTypes } from 'redux-firestore';
import { Browser } from '@capacitor/browser';
import { AppUpdate } from '@capawesome/capacitor-app-update';
import { emitCustomEvent } from 'react-custom-events';

// hooks
import { useEffect, useMemo, useState } from 'react';
import { FormattedList, FormattedMessage, useIntl } from 'react-intl';
import { useFirebase } from 'react-redux-firebase';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useObjectCollection } from '../hooks/useObjectCollection';
import { useFirestoreItemQuery } from '../hooks/useFirestoreItemQuery';

// components
import {
  IonBadge,
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonTitle,
  IonToolbar,
  isPlatform,
  useIonPopover,
} from '@ionic/react';
import AppPage from '../components/AppPage';
import LinkContactModal from '../forms/LinkContactModal';
import LangModal from '../components/LangModal';
import ContactsListItem from '../components/ContactsListItem';
import InAppPurchasesModal from '../components/InAppPurchasesModal';
import CurrenciesModal from '../components/CurrenciesModal';
import DeleteAccountModal from '../components/DeleteAccountModal';
import OCRSettngsModal from '../components/OCRSettngsModal';
import ThemeModal from '../components/ThemeModal';

import {
  brushOutline,
  cashOutline,
  ellipsisHorizontal,
  ellipsisVertical,
  exitOutline,
  fingerPrintOutline,
  glassesOutline,
  helpBuoyOutline,
  language,
  notificationsOutline,
  personCircleOutline,
  pricetagsOutline,
  starHalfOutline,
  trashOutline,
} from 'ionicons/icons';

import { PapersState } from '../models/Paper';
import { TRemindersState } from '../models/Reminder';

import './Settings.scss';
import CategoriesModal from '../components/popups/CategoriesModal';

const Settings: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  // let listFormat = useMemo(() => {
  //   return new Intl.ListFormat(intl.locale, { style: 'long', type: 'conjunction' });
  // }, [intl.locale]);

  const accounts = useObjectCollection('accounts');
  const papers = useSelector((state: TStoreState) => state.data.papers) as PapersState;
  const reminders = useObjectCollection('reminders') as TRemindersState;
  const user = useSelector((state: TStoreState) => state.firebase?.auth, shallowEqual);
  const profile = useSelector((state: TStoreState) => state.firebase?.profile);
  const shrinkMenu = useSelector((state: TStoreState) => state.ui.shrinkMenu);

  const [currentAccount, setCurrentAccount] = useState<any>();
  useEffect(() => {
    if (accounts.items) {
      const account = profile.account || 'basic';
      if (accounts.items[account]) {
        setCurrentAccount(accounts.items[account]);
      }
    }
  }, [accounts, profile]);

  // auth modal
  const mainContact = useSelector(
    (state: TStoreState) => state.data?.contacts?.items?.[profile?.mainContact],
    shallowEqual,
  );
  const privateKey = useSelector((state: TStoreState) => state.ui.privateKey, shallowEqual);
  const showAuthModal = () => {
    emitCustomEvent('showModal', 'registration');
  };

  // lang modal
  const [showLangModal, setShowLangModal] = useState(false);

  // encryption modal
  const showEncryptModal = () => {
    emitCustomEvent('showModal', 'encryption');
  };

  // notifications modal
  const showNotificationsModal = () => {
    emitCustomEvent('showModal', 'reminders');
  };

  // current device info
  const device = useSelector((state: TStoreState) => state.device);

  // in-app purchases modal
  const [showIAPModal, setShowIAPModal] = useState(false);

  // currencies modal
  const currencies = useFirestoreItemQuery('currencies', intl.locale) as { [code: string]: any };
  const currenciesList = useMemo(() => {
    // get selected currencies from profile
    if (profile?.currencies) {
      return Object.keys(profile.currencies)
        .sort((a, b) => (profile.currencies[a] > profile.currencies[b] ? -1 : 1))
        .map(code => currencies.item?.[code]?.name || code);
    } else {
      return [];
    }
  }, [currencies, profile.currencies]);
  const [showCurrenciesModal, setShowCurrenciesModal] = useState(false);

  // countries modal
  const [showOCRModal, setShowOCRModal] = useState(false);

  // app info
  const app = useSelector((state: TStoreState) => state.device?.app);

  // delete account modal
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // sign out
  const firebase = useFirebase();
  const signOut = async () => {
    await firebase.logout();
    dispatch({ type: actionTypes.CLEAR_DATA });
  };

  /**
   * User dropdown
   */
  const [presentUserMenu, closeUserMenu] = useIonPopover(() => {
    return (
      <IonList>
        <IonItem
          onClick={() => {
            setContactModalOpen(true);
            closeUserMenu();
          }}
          detail={false}
          lines="inset"
          button
        >
          <IonIcon slot="start" icon={personCircleOutline} color="secondary" />
          <FormattedMessage id="page.settings.choose-main-contact" />
        </IonItem>
        <IonItem
          onClick={() => {
            signOut();
            closeUserMenu();
          }}
          detail={false}
          button
        >
          <IonIcon slot="start" icon={exitOutline} color="danger" />
          <FormattedMessage id="ui.buttons.logout" />
        </IonItem>
        <IonItem
          onClick={() => {
            setShowDeleteModal(true);
            closeUserMenu();
          }}
          detail={false}
          lines="none"
          button
        >
          <IonIcon slot="start" icon={trashOutline} color="danger" />
          <FormattedMessage id="ui.buttons.delete-account" defaultMessage="Delete Account" />
        </IonItem>
      </IonList>
    );
  });

  /**
   * Setting main contact
   */
  const [isContactModalOpen, setContactModalOpen] = useState(false);

  const setMainContact = (contactId: string) => {
    firebase?.updateProfile({ mainContact: contactId });
    setContactModalOpen(false);
  };

  /**
   * Open a window with the FAQ section from the website in appropriate language
   */
  const urls = useFirestoreItemQuery('settings', 'urls') as { [term: string]: any };

  const openFAQFromSite = async (path = '/faq') => {
    let url = urls?.landing
      ? `${urls.landing}/${intl.locale}${path}`
      : `https://mypapersapp.com/${intl.locale}${path}`;

    // if we are in a mobile app, we need to add a query param
    // to open the site without the navigation
    if (isPlatform('capacitor')) {
      url += `?app=true`;
    }

    await Browser.open({
      url,
      toolbarColor: device.theme === 'classic' ? '#fdd985' : '#b5dfd7',
      windowName: 'thepapershelp',
    });
  };

  const remindersCount = useMemo(() => {
    const count = reminders.items
      ? Object.keys(reminders.items).reduce((acc, item) => {
          return acc + (reminders.items[item] ? Object.keys(reminders.items[item]).length - 2 : 0);
        }, 0)
      : 0;

    return count;
  }, [reminders.items]);

  /**
   * Theme and Dark Mode
   */
  const [isThemesModalOpen, setThemesModalOpen] = useState(false);

  /**
   * Categories
   */
  const categories = useSelector(
    (state: TStoreState) => state.settings?.categories || [],
    shallowEqual,
  );
  const [isCategoriesModalOpen, setCategoriesModalOpen] = useState(false);
  const customCategories = useMemo(
    () => categories.filter(cat => !cat.system).length || 0,
    [categories],
  );

  /**
   * Rating in the App Stores
   */
  const openAppStore = async () => {
    await AppUpdate.openAppStore();
  };

  return (
    <AppPage
      title={intl.formatMessage({ id: 'page.settings.title' })}
      header={
        <IonToolbar>
          <IonTitle>
            <FormattedMessage id="page.settings.title" defaultMessage="Settings" />
          </IonTitle>
        </IonToolbar>
      }
      contentClass="settings-page"
    >
      <IonGrid className="settings-grid">
        <IonRow>
          <IonCol size="12">
            <IonCard className="settings-card">
              <IonList mode="md" className="settings--username">
                {(!user.uid || user?.isAnonymous) && (
                  <IonBadge color="danger" className="settings--badge" mode="ios">
                    !
                  </IonBadge>
                )}
                {user?.isEmpty === true ? (
                  <ContactsListItem
                    contact={{
                      id: 'new',
                      favorite: { value: false },
                      names: { value: intl.formatMessage({ id: 'page.settings.user.empty' }) },
                    }}
                    clickHandler={showAuthModal}
                  />
                ) : user?.isAnonymous === true ? (
                  <ContactsListItem
                    contact={{
                      id: 'new',
                      favorite: { value: false },
                      names: { value: intl.formatMessage({ id: 'page.settings.user.anonymous' }) },
                    }}
                    clickHandler={showAuthModal}
                  />
                ) : mainContact ? (
                  <ContactsListItem contact={mainContact} routerLink="/contacts/" />
                ) : (
                  <ContactsListItem
                    contact={{
                      id: 'new',
                      favorite: { value: false },
                      names: { value: profile?.displayName || user?.displayName || user?.email },
                    }}
                    clickHandler={() => setContactModalOpen(true)}
                  />
                )}
                <div style={{ textAlign: 'right', margin: '8px 12px 0 12px' }}>
                  <div style={{ display: 'inline-block', float: 'left', margin: '10px 0 6px' }}>
                    {currentAccount && (
                      <>
                        <FormattedMessage id={currentAccount.labelId} />
                        {': '}
                        <FormattedMessage
                          id="account.type.counter"
                          values={{
                            papers: (papers?.items && Object.keys(papers.items).length) || 0,
                            limit: currentAccount.limit,
                          }}
                        />
                      </>
                    )}
                  </div>
                  {user?.isEmpty === true || user?.isAnonymous === true ? (
                    <IonButton
                      onClick={showAuthModal}
                      mode="md"
                      shape="round"
                      fill="outline"
                      className="ion-activated settings--login-button"
                    >
                      <IonLabel className="settings--login-button-label">
                        <FormattedMessage
                          id="ui.buttons.login-or-register"
                          defaultMessage="Sign In or Register a new Account"
                        />
                      </IonLabel>
                    </IonButton>
                  ) : (
                    <div
                      style={{ display: 'inline-block', whiteSpace: 'nowrap', marginLeft: '12px' }}
                    >
                      <IonButton
                        mode="md"
                        shape="round"
                        fill="outline"
                        className="ion-activated"
                        size="small"
                        onClick={() => setShowIAPModal(true)}
                      >
                        <IonLabel>
                          <FormattedMessage id="ui.buttons.upgrade" defaultMessage="Upgrade" />
                        </IonLabel>
                      </IonButton>
                      <IonButton
                        onClick={evt => {
                          presentUserMenu({
                            event: evt.nativeEvent,
                            alignment: 'end',
                            side: 'bottom',
                            arrow: false,
                          });
                        }}
                        mode="md"
                        shape="round"
                        fill="outline"
                        className="ion-activated settings--button-ellipsis"
                        color="medium"
                        size="small"
                      >
                        <IonIcon md={ellipsisVertical} ios={ellipsisHorizontal} slot="icon-only" />
                      </IonButton>
                    </div>
                  )}
                </div>
              </IonList>

              <LinkContactModal
                opened={isContactModalOpen}
                onDismiss={() => setContactModalOpen(false)}
                contactId={profile?.mainContact}
                setContactId={(contactId: string) => setMainContact(contactId)}
                title={intl.formatMessage({ id: 'page.settings.choose-main-contact' })}
              />
            </IonCard>
          </IonCol>
          <IonCol size="6" size-md="6" size-sm="6" size-xs="6" size-xxs="12">
            <IonCard
              className="settings-card settings-card_clickable"
              onClick={() => showNotificationsModal()}
            >
              <IonCardContent>
                <h1 className="settings-card--title">
                  <IonIcon
                    icon={notificationsOutline}
                    className="settings-card--icon"
                    color="primary"
                  />
                  <FormattedMessage
                    id="page.settings.reminders.title"
                    defaultMessage="No pending reminders"
                    values={{ count: remindersCount }}
                  />
                </h1>
                <p className="settings-card--description">
                  {device.canRecieveNotifications ? (
                    <FormattedMessage
                      id="page.settings.reminders.this-device"
                      defaultMessage="Notifiactions received on this device"
                    />
                  ) : device.canSetNotifications ? (
                    <FormattedMessage
                      id="page.settings.reminders.other-device"
                      defaultMessage="Notifiactions received on another device"
                    />
                  ) : (
                    <FormattedMessage
                      id="page.settings.reminders.no-permissions"
                      defaultMessage="To start receiving reminders, have to set up notifications first"
                    />
                  )}
                </p>
              </IonCardContent>
            </IonCard>
            <IonCard
              className="settings-card settings-card_clickable"
              onClick={() => setCategoriesModalOpen(true)}
            >
              <IonCardContent>
                <h1 className="settings-card--title">
                  <IonIcon
                    icon={pricetagsOutline}
                    className="settings-card--icon"
                    color="primary"
                  />
                  <FormattedMessage
                    id="page.settings.categories.title"
                    defaultMessage="Categories"
                    values={{ count: customCategories || 0 }}
                  />
                </h1>
                <p className="settings-card--description">
                  <FormattedMessage
                    id="page.settings.categories.description"
                    defaultMessage="Create your own categories and mix them with the default ones"
                  />
                </p>
              </IonCardContent>
            </IonCard>
            <IonCard className="settings-card settings-card_clickable" onClick={showEncryptModal}>
              <IonCardContent>
                <h1 className="settings-card--title">
                  <IonIcon
                    icon={fingerPrintOutline}
                    className="settings-card--icon"
                    color="primary"
                  />
                  {!profile?.publicKey && (
                    <IonBadge color="danger" className="settings-badge" mode="ios">
                      !
                    </IonBadge>
                  )}

                  {profile?.publicKey ? (
                    <FormattedMessage
                      id="page.settings.privacy.encrypted.title"
                      defaultMessage="All personal data is encrypted"
                    />
                  ) : (
                    <FormattedMessage
                      id="page.settings.privacy.none.title"
                      defaultMessage="Data is not encrypted"
                    />
                  )}
                </h1>
                <p className="settings-card--description">
                  {profile?.publicKey ? (
                    privateKey ? (
                      <FormattedMessage
                        id="page.settings.privacy.encrypted.unlocked"
                        defaultMessage="The app is authorized, tap to view the settings"
                      />
                    ) : (
                      <FormattedMessage
                        id="page.settings.privacy.encrypted.locked"
                        defaultMessage="Tap to view the privacy settings and unlock the data"
                      />
                    )
                  ) : (
                    <FormattedMessage
                      id="page.settings.privacy.none.description"
                      defaultMessage="Turn on the encryption and privacy protection of your data"
                    />
                  )}
                </p>
              </IonCardContent>
            </IonCard>
            {/* <IonCard className="settings-card settings-card_clickable" routerLink="/settings/types">
              <IonCardContent>
                <h1 className="settings-card--title">
                  <IonIcon
                    icon={duplicateOutline}
                    className="settings-card--icon"
                    color="primary"
                  />
                  Custom Paper Types
                </h1>
                <p className="settings-card--description">
                  Create custom types with your own specific meta data
                </p>
              </IonCardContent>
            </IonCard> */}
            <IonCard
              className="settings-card settings-card_clickable"
              onClick={() => setShowOCRModal(true)}
            >
              <IonCardContent>
                <h1 className="settings-card--title">
                  <IonIcon icon={glassesOutline} className="settings-card--icon" color="primary" />
                  {profile.autoOcr === false ? (
                    <FormattedMessage
                      id="page.settings.recognition.off.title"
                      defaultMessage="Papers will not be read automatically"
                    />
                  ) : (
                    <FormattedMessage
                      id="page.settings.recognition.on.title"
                      defaultMessage="Papers will be read automatically"
                    />
                  )}
                </h1>
                <p className="settings-card--description">
                  {profile.autoOcr === false ? (
                    <FormattedMessage
                      id="page.settings.recognition.off.description"
                      defaultMessage="You can extract the text from the papers using the “Read paper” button"
                    />
                  ) : (
                    <FormattedMessage
                      id="page.settings.recognition.on.description"
                      defaultMessage="Text will be extracted in the background for every added paper"
                    />
                  )}
                </p>
              </IonCardContent>
            </IonCard>
            {/* <IonCard className="settings-card">
              <IonCardContent>
                <h1 className="settings-card--title">
                  <IonIcon icon={peopleOutline} className="settings-card--icon" color="primary" />
                  <FormattedMessage id="page.settings.sharing.title" defaultMessage="Sharing" />
                </h1>
                <p className="settings-card--description">
                  <FormattedMessage
                    id="page.settings.coming-soon"
                    defaultMessage="Coming soon..."
                  />
                </p>
              </IonCardContent>
            </IonCard> */}
            <IonCard
              className="settings-card settings-card_clickable"
              onClick={() => setShowCurrenciesModal(true)}
            >
              <IonCardContent>
                <h1 className="settings-card--title">
                  <IonIcon icon={cashOutline} className="settings-card--icon" color="primary" />
                  {currenciesList.length > 0 ? (
                    <FormattedList value={currenciesList} />
                  ) : (
                    <FormattedMessage
                      id="page.settings.currencies.title"
                      defaultMessage="Currencies"
                    />
                  )}
                </h1>
                <p className="settings-card--description">
                  <FormattedMessage
                    id="page.settings.currencies.description"
                    defaultMessage="Choose which currencies to be listed in the amount fields"
                  />
                </p>
              </IonCardContent>
            </IonCard>
          </IonCol>
          <IonCol size="6" size-md="6" size-sm="6" size-xs="6" size-xxs="12">
            <IonCard
              className="settings-card settings-card_clickable"
              onClick={() => openFAQFromSite()}
            >
              <IonCardContent>
                <h1 className="settings-card--title">
                  <IonIcon icon={helpBuoyOutline} className="settings-card--icon" color="primary" />
                  <FormattedMessage
                    id="page.settings.faq.title"
                    defaultMessage="FAQ and App Help"
                  />
                </h1>
                <p className="settings-card--description">
                  <FormattedMessage
                    id="page.settings.faq.description"
                    defaultMessage="Tips and tricks about how to use the
                    app, and answers to the questions you have"
                  />
                </p>

                <p className="settings-card--description">
                  <IonButton
                    onClick={evt => {
                      evt.stopPropagation();
                      openFAQFromSite('/support/');
                    }}
                    mode="md"
                    fill="outline"
                    color="medium"
                    className="settings--support-button"
                  >
                    <IonLabel>
                      <FormattedMessage id="ui.buttons.support" defaultMessage="Support" />
                    </IonLabel>
                  </IonButton>

                  <IonButton
                    onClick={evt => {
                      evt.stopPropagation();
                      emitCustomEvent('showModal', 'welcome');
                    }}
                    mode="md"
                    size="small"
                    fill="outline"
                    color="medium"
                    className="settings--legal-button"
                  >
                    <IonLabel>
                      <FormattedMessage id="ui.buttons.setup" defaultMessage="Setup" />
                    </IonLabel>
                  </IonButton>
                </p>

                <p className="settings-card--description">
                  <IonButton
                    onClick={evt => {
                      evt.stopPropagation();
                      openFAQFromSite('/privacy/');
                    }}
                    mode="md"
                    size="small"
                    fill="outline"
                    color="medium"
                    className="settings--legal-button"
                  >
                    <IonLabel>
                      <FormattedMessage
                        id="ui.buttons.privacy-policy"
                        defaultMessage="Privacy Policy"
                      />
                    </IonLabel>
                  </IonButton>
                  <IonButton
                    onClick={evt => {
                      evt.stopPropagation();
                      openFAQFromSite('/terms/');
                    }}
                    mode="md"
                    size="small"
                    fill="outline"
                    color="medium"
                    className="settings--legal-button"
                  >
                    <IonLabel>
                      <FormattedMessage
                        id="ui.buttons.terms-and-conditions"
                        defaultMessage="Terms &amp; Conditions"
                      />
                    </IonLabel>
                  </IonButton>
                </p>
              </IonCardContent>
            </IonCard>
            <IonCard
              className="settings-card settings-card_clickable"
              onClick={() => setShowLangModal(true)}
            >
              <IonCardContent>
                <h1 className="settings-card--title">
                  <IonIcon icon={language} className="settings-card--icon" color="primary" />
                  {locales.find(locale => locale.id === intl.locale)?.title}
                </h1>
                <p>
                  <FormattedMessage
                    id="page.settings.language"
                    defaultMessage="Interface Language"
                  />
                </p>
              </IonCardContent>
            </IonCard>
            <IonCard
              className="settings-card settings-card_clickable"
              onClick={() => setThemesModalOpen(true)}
            >
              <IonCardContent>
                <h1 className="settings-card--title">
                  <IonIcon icon={brushOutline} className="settings-card--icon" color="primary" />
                  <FormattedMessage
                    id={`page.settings.themes.title.${device.theme || 'modern'}-${
                      device.darkMode || 'auto'
                    }`}
                    defaultMessage="The modern theme follows the system’s dark mode"
                  />
                </h1>
                <p className="settings-card--description">
                  <FormattedMessage
                    id="page.settings.themes.description"
                    defaultMessage="How the app is looking"
                  />
                </p>
              </IonCardContent>
            </IonCard>
            {isPlatform('capacitor') && (
              <IonCard
                className="settings-card settings-card_clickable"
                onClick={() => openAppStore()}
              >
                <IonCardContent>
                  <h1 className="settings-card--title">
                    <IonIcon
                      icon={starHalfOutline}
                      className="settings-card--icon"
                      color="primary"
                      style={{
                        transform: 'rotate(-5deg) translate(2px, -2px)',
                      }}
                    />
                    <FormattedMessage
                      id="page.settings.rate-the-app.title"
                      defaultMessage="Rate the app"
                    />
                  </h1>
                  <p className="settings-card--description">
                    {isPlatform('ios') ? (
                      <FormattedMessage
                        id="page.settings.rate-the-app.description.ios"
                        defaultMessage="Open Apple App Store, give us a rating and a review"
                      />
                    ) : (
                      <FormattedMessage
                        id="page.settings.rate-the-app.description.android"
                        defaultMessage="Open Google Play, give us a rating and a review"
                      />
                    )}
                  </p>
                </IonCardContent>
              </IonCard>
            )}
          </IonCol>
        </IonRow>
      </IonGrid>

      <LangModal isOpen={showLangModal} onDidDismiss={() => setShowLangModal(false)} />
      <InAppPurchasesModal isOpen={showIAPModal} onDidDismiss={() => setShowIAPModal(false)} />
      <CurrenciesModal
        isOpen={showCurrenciesModal}
        onDidDismiss={() => setShowCurrenciesModal(false)}
      />
      <OCRSettngsModal isOpen={showOCRModal} onDidDismiss={() => setShowOCRModal(false)} />
      <DeleteAccountModal isOpen={showDeleteModal} onDidDismiss={() => setShowDeleteModal(false)} />
      <ThemeModal isOpen={isThemesModalOpen} onDidDismiss={() => setThemesModalOpen(false)} />
      <CategoriesModal
        isOpen={isCategoriesModalOpen}
        onDidDismiss={() => setCategoriesModalOpen(false)}
      />

      <div className="content-footer settings-page--version">
        {!isPlatform('capacitor') &&
          (shrinkMenu ||
            localStorage.getItem('hidden.navapplinks') ||
            profile.hidden?.navapplinks) && (
            <>
              <a href="https://apps.apple.com/app/id1626640788">
                <img
                  alt="Download on the App Store"
                  src={`/assets/app-store/${intl.locale}.svg`}
                  style={{ height: '3rem', marginRight: '1rem' }}
                />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.leechylabs.papers">
                <img
                  alt="Get it on Google Play"
                  src={`/assets/google-play/${intl.locale}.png`}
                  style={{ height: '3rem' }}
                />
              </a>
              <br />
            </>
          )}
        &copy; 2017—{new Date().getFullYear()} Andrey Lechev | v. {app?.version || 'n/a'}
      </div>
    </AppPage>
  );
};

export default Settings;
