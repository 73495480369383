import { ReactElement, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useObjectCollection } from '../hooks/useObjectCollection';

// components
import {
  IonButton,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonTextarea,
  isPlatform,
  useIonPopover,
} from '@ionic/react';
import { FormattedMessage, useIntl } from 'react-intl';
import LinkPaperModal from './LinkPaperModal';
import LinkContactModal from './LinkContactModal';

// icons
import {
  addCircleOutline,
  chevronDownOutline,
  chevronUpOutline,
  ellipsisHorizontalOutline,
  ellipsisVerticalOutline,
  pricetagOutline,
  removeCircleOutline,
} from 'ionicons/icons';

// types
import { TFieldValue, TFieldValues } from '../models/FieldValues';
import { TFormFieldType } from '../models/Form';
import { PapersState } from '../models/Paper';
import { TStoreState } from '../store/store';
import { ContactsState } from '../models/Contact';
import LinkStackModal from './LinkStackModal';

type TMultipleTextFieldProps = {
  name: string;
  value: TFieldValues;
  onValueUpdate: (value: Array<TFieldValue | undefined>) => void;
  type?: TFormFieldType;
  label?: string;
  labelId?: string;
  placeholder?: string;
  placeholderId?: string;
  addMoreLabel?: string;
  addMoreLabelId?: string;
  encrypt?: boolean;
  unencrypt?: 'app' | 'document' | 'field';
};

const MultipleField = ({
  name,
  value,
  onValueUpdate,
  type = 'text',
  label,
  labelId,
  placeholder,
  placeholderId,
  addMoreLabel,
  addMoreLabelId,
  encrypt,
  unencrypt,
}: TMultipleTextFieldProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const isMultiple = Array.isArray(value);

  const papers = useSelector((state: TStoreState) => state.data.papers) as PapersState;
  const stacks = useObjectCollection('stacks');
  const contacts = useSelector(
    (state: TStoreState) => state.data?.contacts,
    shallowEqual,
  ) as ContactsState;

  // convert values
  const getNewValue = (): TFieldValue => {
    const newValue: TFieldValue = {
      value: '',
    };
    if (encrypt !== undefined) newValue.encrypt = encrypt;
    if (unencrypt !== undefined) newValue.unencrypt = unencrypt;
    return newValue;
  };

  const addOneMoreValue = (insertAfter?: number) => {
    if (insertAfter !== undefined) {
      const newValue = isMultiple ? [...value] : [value || getNewValue()];
      newValue.splice(insertAfter, 0, getNewValue());
      onValueUpdate(newValue);
    } else {
      if (isMultiple) {
        onValueUpdate([...value, getNewValue()]);
      } else {
        onValueUpdate([value || getNewValue(), getNewValue()]);
      }
    }
  };

  const removeValue = (index: number) => {
    if (isMultiple && value.length > 1) {
      onValueUpdate(value.filter((_, i) => i !== index));
    } else {
      onValueUpdate([getNewValue()]);
    }
  };

  const updateValue = (index: number, newValue: TFieldValue) => {
    if (isMultiple && value[index] !== newValue) {
      let newValues = [...value];
      newValues[index] = {
        ...value[index],
        ...newValue,
      };
      if (encrypt !== undefined) newValues[index].encrypt = encrypt;
      if (unencrypt !== undefined) newValues[index].unencrypt = unencrypt;
      onValueUpdate(newValues);
    } else if (!isMultiple) {
      onValueUpdate([newValue || getNewValue()]);
    }
  };

  const updateLabel = (index: number, newLabel: string | number) => {
    if (isMultiple && value[index]?.label !== newLabel) {
      let newValues = [...value.map(val => ({ ...val }))];
      newValues[index].label = newLabel.toString();
      onValueUpdate(newValues);
    }
  };

  // move items up and down
  const moveFirst = (index: number) => {
    if (isMultiple && index > 0) {
      let newValues = [...value];
      const first = newValues.splice(index, 1)[0];
      newValues.unshift(first);
      onValueUpdate(newValues);
    }
  };

  const moveUp = (index: number) => {
    if (isMultiple && index > 0) {
      const newValue = [...value];
      [newValue[index], newValue[index - 1]] = [newValue[index - 1], newValue[index]];
      onValueUpdate(newValue);
    }
  };

  const moveDown = (index: number) => {
    if (isMultiple && index < value.length - 1) {
      const newValue = [...value];
      [newValue[index], newValue[index + 1]] = [newValue[index + 1], newValue[index]];
      onValueUpdate(newValue);
    }
  };

  const moveLast = (index: number) => {
    if (isMultiple && index < value.length - 1) {
      const newValues = [...value];
      const last = newValues.splice(index, 1)[0];
      newValues.push(last);
      onValueUpdate(newValues);
    }
  };

  // labels
  const addLabel = (index: number) => {
    let newValue = isMultiple ? [...value] : [value];
    let itemValue = newValue[index] || getNewValue();
    itemValue = {
      ...itemValue,
      label: '',
    };
    newValue[index] = itemValue;
    onValueUpdate(newValue);
  };

  const removeLabel = (index: number) => {
    const newValue = isMultiple ? [...value] : [value];
    const itemValue = newValue[index];
    delete itemValue?.label;
    newValue[index] = itemValue;
    onValueUpdate(newValue);
  };

  // data display

  const getValue = (fieldValue: TFieldValue | undefined): string | number | null | undefined => {
    return fieldValue?.value?.toString() || '';
  };

  const getItemValue = (index: number): string | number | null | undefined => {
    if (isMultiple) {
      return getValue(value[index]);
    } else if (value) {
      return getValue(value);
    } else {
      return '';
    }
  };

  const getTextValue = (index: number): string | null | undefined => {
    const itemValue = getItemValue(index);
    if (itemValue !== undefined && itemValue !== null) {
      return itemValue.toString();
    }
    return itemValue;
  };

  const getItemLabel = (index: number): string | undefined => {
    if (isMultiple) {
      return value[index]?.label;
    } else {
      return value?.label;
    }
  };

  const getPaperLabel = (index: number): string | ReactElement => {
    const value = getItemValue(index);
    if (value) {
      if (papers?.items?.[value]) {
        return (
          <>
            <h2>
              {getValue(papers.items[value].title) || intl.formatMessage({ id: 'paper.untitled' })}
            </h2>
            <p>{getValue(papers.items[value].reference)}</p>
          </>
        );
      }
      return value.toString();
    }
    return (
      (placeholderId && intl.formatMessage({ id: placeholderId, defaultMessage: placeholder })) ||
      placeholder ||
      '—'
    );
  };

  const getContactLabel = (index: number): string | ReactElement => {
    const value = getItemValue(index);
    if (value) {
      if (contacts?.items?.[value]) {
        return (
          <>
            <h2>
              {getValue(contacts.items[value].displayName) ||
                getValue(contacts.items[value].names) ||
                intl.formatMessage({ id: 'contact.untitled' })}
            </h2>
          </>
        );
      }
      return value.toString();
    }
    return (
      (placeholderId && intl.formatMessage({ id: placeholderId, defaultMessage: placeholder })) ||
      placeholder ||
      '—'
    );
  };

  const getStacksLabel = (index: number): string | ReactElement => {
    const value = getItemValue(index);
    if (value) {
      if (stacks?.items?.[value]) {
        return (
          <>
            <h2>
              {getValue(stacks.items[value].name) || intl.formatMessage({ id: 'stack.untitled' })}
            </h2>
          </>
        );
      }
      return value.toString();
    }
    return (
      (placeholderId && intl.formatMessage({ id: placeholderId, defaultMessage: placeholder })) ||
      placeholder ||
      '—'
    );
  };

  // modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  /**
   * ActionSheet / Popover
   */
  // const [presentActionSheet, dismissActionSheet] = useIonActionSheet();
  // const showActionSheet = () => {
  //   presentActionSheet({
  //     buttons: [
  //     ]
  //   })
  // }

  const selectedItem = useRef<number>(-1);
  const [presentMenu, closeMenu] = useIonPopover(() => {
    const haveLabel =
      isMultiple &&
      typeof value[selectedItem.current] === 'object' &&
      value[selectedItem.current] !== null &&
      value[selectedItem.current]?.hasOwnProperty('label');
    return (
      <IonList>
        {type !== 'paper' && type !== 'contact' && type !== 'stacks' && (
          <IonItem
            button
            onClick={() => {
              haveLabel ? removeLabel(selectedItem.current) : addLabel(selectedItem.current);
              closeMenu();
            }}
            detail={false}
            lines="inset"
          >
            <IonIcon className="details__action-icon" icon={pricetagOutline} slot="start" />
            {haveLabel
              ? intl.formatMessage({ id: 'ui.field.label.remove' })
              : intl.formatMessage({ id: 'ui.field.label.add' })}
          </IonItem>
        )}
        <IonItem
          button
          onClick={() => {
            addOneMoreValue(selectedItem.current + 1);
            closeMenu();
          }}
          detail={false}
          lines={isMultiple && value.length > 1 ? 'inset' : 'none'}
        >
          <IonIcon
            className="details__action-icon"
            icon={addCircleOutline}
            color="success"
            slot="start"
          />
          {addMoreLabelId ? (
            <FormattedMessage id={addMoreLabelId} defaultMessage={addMoreLabel} />
          ) : (
            addMoreLabel
          )}
        </IonItem>
        {/* <IonItem button detail={false}>
          <IonIcon className="details__action-icon" icon={lockClosedOutline} slot="start" />
          {intl.formatMessage({ id: 'ui.field.encription.add' })}
        </IonItem> */}
        {isMultiple && value.length > 1 && (
          <>
            <IonItem
              button
              onClick={() => {
                removeValue(selectedItem.current);
                closeMenu();
              }}
              detail={false}
            >
              <IonIcon
                className="details__action-icon"
                icon={removeCircleOutline}
                color="danger"
                slot="start"
              />
              {intl.formatMessage({ id: 'ui.field.remove' })}
            </IonItem>
            <IonItem lines="none" detail={false}>
              <IonButton
                fill="clear"
                shape="round"
                color="dark"
                disabled={!isMultiple || value.length < 2 || selectedItem.current === 0}
                onClick={() => {
                  moveFirst(selectedItem.current);
                  closeMenu();
                }}
              >
                <IonIcon icon={chevronUpOutline} className="button-icon__first" />
                <IonIcon icon={chevronUpOutline} className="button-icon__second" />
              </IonButton>
              <IonButton
                fill="clear"
                shape="round"
                color="dark"
                disabled={!isMultiple || value.length < 2 || selectedItem.current === 0}
                onClick={() => {
                  moveUp(selectedItem.current);
                  closeMenu();
                }}
              >
                <IonIcon icon={chevronUpOutline} />
              </IonButton>
              <IonButton
                fill="clear"
                shape="round"
                color="dark"
                disabled={!isMultiple || value.length <= selectedItem.current + 1}
                onClick={() => {
                  moveDown(selectedItem.current);
                  closeMenu();
                }}
              >
                <IonIcon icon={chevronDownOutline} />
              </IonButton>
              <IonButton
                fill="clear"
                shape="round"
                color="dark"
                disabled={!isMultiple || value.length <= selectedItem.current + 1}
                onClick={() => {
                  moveLast(selectedItem.current);
                  closeMenu();
                }}
              >
                <IonIcon icon={chevronDownOutline} className="button-icon__first" />
                <IonIcon icon={chevronDownOutline} className="button-icon__second" />
              </IonButton>
            </IonItem>
          </>
        )}
      </IonList>
    );
  });

  const onFocus = () => {
    if (isPlatform('mobile')) {
      dispatch({ type: 'HIDE_TABS' });
    }
  };
  const onBlur = () => {
    dispatch({ type: 'SHOW_TABS' });
  };

  return (
    <IonItem mode="md" className="text-field__container">
      {(label || labelId) && (
        <IonLabel position="stacked">
          {labelId ? <FormattedMessage id={labelId} defaultMessage={label} /> : label}
        </IonLabel>
      )}
      {(Array.isArray(value) ? value : [value]).map((_, index: number) => (
        <div style={{ width: '100%' }} key={`item-${name}-${index}`}>
          {getItemLabel(index) !== undefined && (
            <div className="text-field__label_controls">
              <IonInput
                className="text-field text-field__label"
                value={getItemLabel(index)}
                onIonChange={e => updateLabel(index, e.detail.value || '')}
                type="text"
                inputmode="text"
                enterkeyhint="next"
                clearInput={true}
                aria-label={label || (labelId && intl.formatMessage({ id: labelId }))}
                placeholder={intl.formatMessage({ id: 'ui.field.label.placeholder' })}
                onIonFocus={onFocus}
                onIonBlur={onBlur}
                autocapitalize="sentences"
              />
            </div>
          )}
          <div className="text-field__controls">
            {type === 'textarea' && (
              <IonTextarea
                className="text-field"
                value={getTextValue(index)}
                onIonChange={e => updateValue(index, { value: e.detail.value || '' })}
                name={name}
                inputmode="text"
                enterkeyhint="next"
                autoGrow={true}
                aria-label={label || (labelId && intl.formatMessage({ id: labelId }))}
                placeholder={
                  (placeholderId &&
                    intl.formatMessage({ id: placeholderId, defaultMessage: placeholder })) ||
                  placeholder
                }
              ></IonTextarea>
            )}
            {(type === 'text' || type === 'date') && (
              <IonInput
                className="text-field"
                value={getItemValue(index)}
                onIonChange={e => updateValue(index, { value: e.detail.value || '' })}
                name={name}
                type={type}
                inputmode="text"
                enterkeyhint="next"
                clearInput={true}
                aria-label={label || (labelId && intl.formatMessage({ id: labelId }))}
                placeholder={
                  (placeholderId &&
                    intl.formatMessage({ id: placeholderId, defaultMessage: placeholder })) ||
                  placeholder
                }
                onIonFocus={onFocus}
                onIonBlur={onBlur}
              ></IonInput>
            )}
            {(type === 'paper' || type === 'contact' || type === 'stacks') && (
              <div className="linked-paper-field" onClick={() => setIsModalOpen(true)}>
                {type === 'paper' && getPaperLabel(index)}
                {type === 'contact' && getContactLabel(index)}
                {type === 'stacks' && getStacksLabel(index)}
              </div>
            )}
            {type === 'paper' && (
              <LinkPaperModal
                opened={isModalOpen}
                onDismiss={() => setIsModalOpen(false)}
                paperId={getItemValue(index)}
                setPaperId={(paperId: string) => updateValue(index, { value: paperId || '' })}
              />
            )}
            {type === 'contact' && (
              <LinkContactModal
                opened={isModalOpen}
                onDismiss={() => setIsModalOpen(false)}
                contactId={getItemValue(index)}
                setContactId={(contactId: string) => updateValue(index, { value: contactId || '' })}
              />
            )}
            {type === 'stacks' && (
              <LinkStackModal
                opened={isModalOpen}
                onDismiss={() => setIsModalOpen(false)}
                stackId={getItemValue(index)}
                setStackId={(stackId: string) => updateValue(index, { value: stackId || '' })}
              />
            )}

            <IonButton
              size="small"
              fill="clear"
              shape="round"
              color="dark"
              className="text-field__options"
              onClick={evt => {
                selectedItem.current = index;
                presentMenu({
                  event: evt.nativeEvent,
                  alignment: 'end',
                  arrow: false,
                });
              }}
            >
              <IonIcon md={ellipsisVerticalOutline} ios={ellipsisHorizontalOutline} />
            </IonButton>
          </div>
        </div>
      ))}
      {/* <div className="text-field__new">
        <IonButton
          size="small"
          fill="clear"
          color="medium"
          className="text-field__add"
          onClick={() => addOneMoreValue()}
        >
          {(addMoreLabel || addMoreLabelId) && addMoreLabelId ? (
            <FormattedMessage id={addMoreLabelId} defaultMessage={addMoreLabel} />
          ) : (
            addMoreLabel
          )}
          <IonIcon icon={addCircleOutline} slot="end" />
        </IonButton>
      </div> */}
    </IonItem>
  );
};

export default MultipleField;
