// hooks
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedNumber, useIntl } from 'react-intl';
import { useCollectionItem } from '../hooks/useCollectionItem';
import { shallowEqual, useSelector } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';

// utils
import classNames from 'classnames';
import { calculateAvailablePeriods, getContactName, getSortDate } from '../utilities/lists';
import { getCurrency, getValue } from '../utilities/values';
import {
  getMatchingItems,
  getSearchStrings,
  normString,
  TEntitySearchResults,
} from '../utilities/search';

// components
import { IonIcon, IonItem, IonLabel, IonList } from '@ionic/react';
import PapersListItem from './PapersListItem';
import EmptySearchResults from './EmptySearchResults';

// types
import { TFieldValue, TFilePreview } from '../models/FieldValues';
import { Paper, PapersState } from '../models/Paper';
import { ContactsState } from '../models/Contact';
import { chevronDown, chevronForward, walletOutline, warningOutline } from 'ionicons/icons';
import { TStoreState } from '../store/store';
import { updatePapersGroupsState } from '../thunks/settings';
import { useLocation } from 'react-router';

type PaperGroup = {
  total?: { [currency: string]: number };
  paid?: { [currency: string]: number };
  unpaid?: { [currency: string]: number };
  earned?: { [currency: string]: number };
  reimbursed?: { [currency: string]: number };
  reimbursedAmount?: { [currency: string]: number };
  items: Paper[];
};

type TPapersListProps = {
  routerLink?: string;
  searchText: string;
  clickHandler?: (paperId: string) => void;
  selectHandler?: (paperId: string) => void;
  disabledPapers?: string[];
  selectedPapers?: { [paperID: string]: boolean };
  papersToShow?: string[];
  periods: string;
  periodField: string;
  selectedPeriod: number;
  grouping?: string;
  fabListActive?: boolean;
};

const PapersList = ({
  routerLink,
  searchText = '',
  clickHandler,
  selectHandler,
  selectedPapers = {},
  disabledPapers = [],
  papersToShow,
  periods,
  periodField,
  selectedPeriod,
  grouping = 'none',
  fabListActive = false,
}: TPapersListProps): JSX.Element => {
  const intl = useIntl();
  const firestore = useFirestore();

  const location = useLocation();

  /**
   * Data
   */
  const uid = useSelector((state: TStoreState) => state.firebase.auth.uid);
  const papers = useSelector((state: TStoreState) => state.data.papers) as PapersState;
  const contacts = useSelector(
    (state: TStoreState) => state.data?.contacts,
    shallowEqual,
  ) as ContactsState;
  const fields = useCollectionItem('types', 'fields');
  const groupingField = useRef<any>(fields.item?.[grouping] || null);

  // get grouping settings
  useEffect(() => {
    if (grouping !== 'none') {
      const field = fields.item?.[grouping];
      if (field) {
        groupingField.current = field;
      }
    }
  }, [grouping, fields.item]);

  // calculate available periods
  const availableYearsAndMonths = useMemo(() => {
    return calculateAvailablePeriods(papers.items, periodField);
    // eslint-disable-next-line
  }, [papers.items, periodField]);

  const availablePeriods: string[] = useMemo(() => {
    return availableYearsAndMonths[periods === 'yearly' ? 0 : 1];
  }, [periods, availableYearsAndMonths]);

  /**
   * Search
   */
  const [searchStrings, setSearchStrings] = useState<string[]>([]);
  const contactsData = useRef<{ [id: string]: string }>({});
  const itemsData = useRef<{ [id: string]: string }>({});

  const itemsFound = useRef<TEntitySearchResults>();
  const contactsFound = useRef<TEntitySearchResults>();

  /**
   * Returns the decision whether to show the paper or not
   * based on current filters, search text and so on.
   *
   * @param paper
   * @returns
   */
  const filterPapers = (paper: Paper): boolean => {
    const periodDate =
      periodField === 'servicePeriod' && (paper?.periodEnd || paper?.periodStart)
        ? (getValue(paper.periodEnd) as string) || (getValue(paper.periodStart) as string)
        : (getValue(paper?.[periodField]) as string);
    // check is paper in the selected period
    if (
      periods !== 'all' &&
      availablePeriods[selectedPeriod] &&
      ((periodDate && periodDate.indexOf(availablePeriods[selectedPeriod]) !== 0) ||
        (!periodDate && availablePeriods[selectedPeriod] !== 'other'))
    ) {
      return false;
    }

    // there are papers to show
    if (papersToShow && papersToShow.length > 0 && !papersToShow.includes(paper.id)) {
      return false;
    }

    // not searching anything
    if (searchText === '') return true;

    // found matching items
    if (itemsFound.current?.[paper.id]) {
      return true;
    }

    return false;
  };

  const sortPapers = (a: Paper, b: Paper): -1 | 1 => {
    const aDate = getSortDate(a, periodField);
    const bDate = getSortDate(b, periodField);

    return aDate < bDate ? 1 : -1;
  };

  // initial data prepare
  const allPapersArr = useMemo(() => {
    if (papers.state.isLoaded && !papers.state.isEmpty) {
      return Object.keys(papers.items)
        .filter(key => key && papers.items[key] && key === papers.items[key].id)
        .map(key => papers.items[key]);
    } else {
      return [];
    }
  }, [papers.items]);

  const papersArr = useMemo(() => {
    if (papers.state.isLoaded && !papers.state.isEmpty) {
      return allPapersArr.filter(paper => filterPapers(paper)).sort(sortPapers);
    } else {
      return [];
    }
    // eslint-disable-next-line
  }, [allPapersArr, fields.item, searchStrings, periods, selectedPeriod, periodField]);

  // papers' grouping depending on the grouping option
  const groupedPapers = useMemo(() => {
    const papersGroups: Map<string, PaperGroup> = new Map();

    for (let i = 0; i < papersArr.length; i++) {
      const paper = papersArr[i];

      // amounts data
      const displayTotalValue = getValue(paper.displayTotal);
      const totalField = displayTotalValue ? paper.displayTotal : paper.total;
      const total =
        (paper.dontIncludeWhenGrouping as TFieldValue)?.value === true
          ? 0
          : parseFloat((getValue(totalField, '0') || 0).toString().replace(',', '.'));
      const isPaid = getValue(paper.paid);
      const paid = isPaid ? total : 0;
      const unpaid = isPaid ? 0 : total;

      const earnedField = paper.net || paper.reimbursed;
      const earned = parseFloat((getValue(earnedField, '0') || 0).toString().replace(',', '.'));
      const currency = getCurrency(earnedField || totalField);

      const reimbursed = parseFloat(
        (getValue(paper.reimbursedAmount, '0') || 0).toString().replace(',', '.'),
      );
      const reimbursedCurrency = getCurrency(paper.reimbursedAmount);

      // grouping ids
      let groupingIds: string[] = [];

      if (grouping === 'none') {
        groupingIds = ['other'];
      } else {
        if (
          (groupingField.current && groupingField.current.id === grouping) ||
          grouping === 'reimbursedAmount' // exception for the reimbursedAmount field
        ) {
          const groupingType = groupingField.current?.groupingType;
          const groupingDependencies = groupingField.current?.groupingDependencies;

          const value = getValue(paper[grouping]);
          if (value) {
            if (groupingType === 'is-defined' || grouping === 'reimbursedAmount') {
              groupingIds = ['true'];
            } else {
              groupingIds = value.toString().split(', ');
            }
          } else if (groupingDependencies?.some((dep: string) => getValue(paper[dep]))) {
            // no value, but if there is any value for the dependencies, the group is 'false'
            groupingIds = ['false'];
          } else {
            groupingIds = ['other'];
          }
        } else {
          // if there is no field, then just use the classic scheme
          const groupings = getValue(paper[grouping])?.toString() || 'other';
          groupingIds = groupings ? groupings.split(', ') : ['other'];
        }
      }

      // add paper to each of the groups
      for (let j = 0; j < groupingIds.length; j++) {
        const groupingId = groupingIds[j];

        // create the group if there isn't any yet
        if (!papersGroups.has(groupingId)) {
          papersGroups.set(groupingId, { items: [] });
        }
        const paperGroup = papersGroups.get(groupingId);

        // add amount values to each group
        addValue(paperGroup, 'total', total, currency);
        addValue(paperGroup, 'paid', paid, currency);
        addValue(paperGroup, 'unpaid', unpaid, currency);
        addValue(paperGroup, 'earned', earned, currency);
        addValue(paperGroup, 'reimbursed', reimbursed, reimbursedCurrency);

        // add the paper to the group
        paperGroup!.items.push(paper);
      }
    }

    // delete groups with no items
    papersGroups.forEach((group, key) => {
      if (group.items.length === 0) {
        papersGroups.delete(key);
      }
    });

    // convert to array to be able to sort
    const sortingArr = Array.from(papersGroups.entries());

    // sort the groups and return a map
    return new Map(
      sortingArr.sort((group1, group2) =>
        getSortDate(group1[1].items[0]) > getSortDate(group2[1].items[0]) ? -1 : 1,
      ),
    );
  }, [papersArr, grouping, groupingField.current]);

  const categories = useSelector((state: any) => state.settings.categories);
  const getCategoryLabel = (categoryId: string, intl: any) => {
    const category = categories?.find((cat: any) => cat.value === categoryId);
    if (category?.labelId) return intl.formatMessage({ id: category.labelId });
    return category?.label || intl.formatMessage({ id: `group.${grouping}.other` });
  };

  useEffect(() => {
    itemsData.current = {};
  }, [papers.items]);

  useEffect(() => {
    if (searchText === '') {
      if (searchStrings.length !== 0) {
        setSearchStrings([]);
      }
    } else {
      // prepare search strings
      const newSearchStrings = getSearchStrings(searchText);
      if (JSON.stringify(newSearchStrings) !== JSON.stringify(searchStrings)) {
        setSearchStrings(getSearchStrings(searchText));
        // found matching contacts
        const [cFound, cData] = getMatchingItems(
          contacts.items,
          newSearchStrings,
          contactsData.current,
        );
        contactsFound.current = cFound;
        contactsData.current = cData;

        // found matching items
        const [iFound, iData] = getMatchingItems(
          papers.items,
          [...newSearchStrings, ...Object.keys(contactsFound.current).map(key => normString(key))],
          itemsData.current,
        );

        itemsFound.current = iFound;
        itemsData.current = iData;
      }
    }
  }, [searchText]);

  const openState = useSelector((state: any) => state.settings.papers?.groupsState || {});

  // keep the groups open state
  const toggleOpenState = (groupId: string) => {
    const newState = { ...openState, [groupId]: !openState[groupId] };
    updatePapersGroupsState(firestore, uid, newState);
  };

  const isSelectionActive = Object.keys(selectedPapers).length > 0;

  console.log('papers list render');

  return (
    <IonList
      className={classNames({
        'leave-space-for-fab-at-the-bottom': true,
        'list-transparent': fabListActive,
      })}
    >
      {papersArr.length === 0 ? (
        <EmptySearchResults searchText={searchText} isPeriod={periods !== 'all'} type="paper" />
      ) : grouping === 'none' ? (
        papersArr.map((paper: any) => {
          const thumbs = paper.thumbnail?.map((thumb: TFilePreview) => thumb.url) || [];

          return (
            <PapersListItem
              key={'nogroups' + paper.id}
              paper={paper}
              routerLink={routerLink}
              currentPath={location.pathname}
              clickHandler={clickHandler}
              selectHandler={selectHandler}
              selected={selectedPapers[paper.id]}
              isSelectionActive={isSelectionActive}
              thumbs={thumbs}
            />
          );
        })
      ) : (
        Array.from(groupedPapers).map(([groupId, group]: [string, PaperGroup]) => (
          <Fragment key={groupId}>
            <IonItem
              button
              detail={true}
              detailIcon={openState[groupId] ? chevronDown : chevronForward}
              onClick={() => toggleOpenState(groupId)}
              className="group-item list-item"
            >
              <IonLabel className="ion-text-wrap">
                <p className="list-data list-data--primary">
                  {grouping === 'issuedBy' || grouping === 'issuedTo'
                    ? contacts?.items?.[groupId]
                      ? getContactName(contacts?.items?.[groupId], groupId)
                      : intl.formatMessage({ id: `group.${grouping}.other` })
                    : grouping === 'category'
                    ? getCategoryLabel(groupId, intl)
                    : intl.formatMessage({ id: `group.${grouping}.${groupId}` })}
                  <span className="list-data--count">{group.items.length}</span>
                </p>
              </IonLabel>
              <DisplayAmounts
                groupId={groupId}
                total={group.total}
                income={group.earned}
                unpaid={group.unpaid}
                reimbursed={group.reimbursed}
              />
            </IonItem>
            {openState[groupId] && (
              <div className="list-item__sub-items">
                {group.items.map((paper: any, idx: number) => {
                  const thumbs = paper.thumbnail?.map((thumb: TFilePreview) => thumb.url) || [];

                  return (
                    <PapersListItem
                      key={'open-' + paper.id + idx}
                      paper={paper}
                      routerLink={routerLink}
                      currentPath={location.pathname}
                      clickHandler={clickHandler}
                      selectHandler={selectHandler}
                      selected={selectedPapers?.[paper.id]}
                      isSelectionActive={isSelectionActive}
                      thumbs={thumbs}
                    />
                  );
                })}
              </div>
            )}
          </Fragment>
        ))
      )}
    </IonList>
  );
};

export default PapersList;

/**
 * Display the group amounts info block
 */
export const DisplayAmounts = ({
  groupId,
  total,
  income,
  reimbursed,
  unpaid,
}: {
  groupId: string;
  total: { [currency: string]: number } | undefined;
  income: { [currency: string]: number } | undefined;
  reimbursed: { [currency: string]: number } | undefined;
  unpaid: { [currency: string]: number } | undefined;
}) => {
  const isTotal = total && Object.keys(total).length > 0;
  const isIncome = income && Object.keys(income).length > 0;
  const isReimbursed = reimbursed && Object.keys(reimbursed).length > 0;
  const isUnpaid = unpaid && Object.keys(unpaid).length > 0;

  if (!isIncome && !isTotal) {
    return null;
  }

  return (
    <div
      className={classNames({
        'list-item__total': true,
        'list-item__total-income': !isTotal && isIncome,
      })}
      slot="end"
    >
      <p className="list-item__income">
        <ListAmounts amounts={isTotal ? total : income} groupId={groupId} type="total" />
      </p>
      {(isReimbursed || isUnpaid) && (
        <p className="list-data">
          {isReimbursed && (
            <span className="list-item__total-income">
              <IonIcon icon={walletOutline} />
              <ListAmounts amounts={reimbursed} groupId={groupId} type="reimbursed" />
            </span>
          )}
          {isUnpaid && (
            <span className="list-item__total-unpaid">
              <IonIcon icon={warningOutline} />
              <ListAmounts amounts={unpaid} groupId={groupId} type="unpaid" />
            </span>
          )}
        </p>
      )}
    </div>
  );
};

const ListAmounts = ({
  amounts,
  groupId,
  type,
}: {
  amounts: { [currency: string]: number } | undefined;
  groupId: string;
  type: string;
}) => {
  return (
    <>
      {amounts &&
        Object.keys(amounts).map((currency: string, index: number) => (
          <Fragment key={currency + type + groupId}>
            {index > 0 && <span>, </span>}
            <FormattedNumber value={amounts[currency] || 0} style="currency" currency={currency} />
          </Fragment>
        ))}
    </>
  );
};

/**
 * Utility functions
 */

/**
 * Add amount value to paper group
 *
 * @param group  pointer to the group object
 * @param field  name of the field to add the value to
 * @param value  amount value
 * @param currency  currency code
 */
function addValue(
  group: PaperGroup | undefined,
  field: 'total' | 'paid' | 'unpaid' | 'earned' | 'reimbursed' | 'reimbursedAmount',
  value: number,
  currency: string,
) {
  if (group && value && !isNaN(value)) {
    if (!group[field]) {
      group[field] = {};
    }
    group[field]![currency] = (group[field]![currency] || 0) + value;
  }
}
