import { IonIcon, IonImg } from '@ionic/react';
import { businessOutline, personOutline } from 'ionicons/icons';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Contact } from '../models/Contact';
import { TStoreState } from '../store/store';
import { getValue } from '../utilities/values';

type TMatchingContactProps = {
  contact: Contact;
};

const MatchingContact = ({ contact }: TMatchingContactProps) => {
  const intl = useIntl();
  const uid = useSelector((state: TStoreState) => state.firebase.auth.uid);
  const avatar = (contact?.avatar?.[0]?.url || contact?.avatars?.[0]?.url)?.replace('{uid}', uid);

  const name =
    getValue(contact.displayName) ||
    getValue(contact.names, intl.formatMessage({ id: 'contact.untitled' }));

  return (
    <div>
      {avatar ? (
        <IonImg src={avatar} style={{ objectFit: 'contain' }} className="matched-contact--avatar" />
      ) : (
        <IonIcon
          icon={getValue(contact.business) ? businessOutline : personOutline}
          color="medium"
          className="matched-contact--avatar"
        />
      )}
      {name}
    </div>
  );
};

export default MatchingContact;
